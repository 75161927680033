<template>
  <div id="app">
    <nav-bar v-if="currentPath" :userInteracted="userInteracted" />
    <div class="is-flex">
      <SidebarMenu v-if="!checkDefaultPassword" />
      <router-view v-if="!isLogin" />
      <router-view v-if="isLogin" :class="isPathNotif ? '' : isMobile ? 'mt-6 pt-5' : ''" />
    </div>
    <footer-bar />
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import NavBar from '../src/components/NavBar'
import FooterBar from '../src/components/FooterBar.vue'
import SidebarMenu from './components/SidebarMenu.vue'

export default {
  name: 'App',
  components: {
    SidebarMenu,
    NavBar,
    FooterBar
  },
  data () {
    return {
      userInteracted: false
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      isLogin: 'auth/isLogin',
      email: 'auth/email',
      user: 'auth/user',
      checkDefaultPassword: 'auth/checkDefaultPassword'
    }),
    currentPath () {
      const path = this.$route.path.split('/')
      const lastElement = path[path.length - 1]

      if (lastElement === 'change-default-password') return false
      return true
    },
    isPathNotif () {
      if (this.$route.path.includes('/notification')) return true
      return false
    },
    isMobile () {
      if (window.innerWidth <= 768) {
        return true
      }
      return false
    }
  },
  methods: {
    sessionClear () {
      sessionStorage.clear()
    },
    checkLogin () {
      this.$store.commit('setup/setUrl', `${process.env.VUE_APP_SHELL_URL_API}`)

      const rs = localStorage.getItem('webapp_shell_kanggo')
      const auth = JSON.parse(rs)

      if (auth && auth.token) {
        this.$store.commit('auth/setLoginStatus', true)
        this.$store.commit('auth/setToken', auth.token)
        this.$store.commit('auth/setTokenFirebase', auth.tokenFirebase)
        this.$store.commit('auth/setEmail', auth.email)
        this.$store.commit('auth/setUser', auth.user)
        if (auth.user.is_default_password === 1) {
          this.$store.commit('auth/setCheckDefaultPassword', true)
          this.$store.commit('auth/setFirstLogin', true)
        } else {
          this.$store.commit('auth/setCheckDefaultPassword', false)
          this.$store.commit('auth/setFirstLogin', false)
        }
        axios.defaults.headers.common.Authorization = auth.token
      } else {
        this.$store.commit('auth/resetState')
      }
    },
    onUserInteracted () {
      if (!this.userInteracted) {
        this.userInteracted = true
      }
    }
  },
  created () {
    this.checkLogin()
  },
  updated () {
    const rs = localStorage.getItem('webapp_shell_kanggo')
    if (rs) {
      const auth = JSON.parse(rs)

      if (auth.user.is_default_password === 1) {
        this.$router.push('/')
      }
    }
  },
  mounted () {
    document.addEventListener('click', this.onUserInteracted)
    document.addEventListener('keydown', this.onUserInteracted)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.onUserInteracted)
    document.removeEventListener('keydown', this.onUserInteracted)
  },
  watch: {
    userInteracted (val) {
      if (val === true) {
        document.removeEventListener('click', this.onUserInteracted)
        document.removeEventListener('keydown', this.onUserInteracted)
      }
    }
  }
}
</script>
