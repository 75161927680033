export const guideSessions = {
  code: 1,
  data: [
    {
      id: 1,
      name: 'Sesi Survey (Quarter)',
      data: [
        {
          name: 'Sesi 0',
          start_time: '08:00',
          end_time: '10:00'
        },
        {
          name: 'Sesi 1',
          start_time: '11:00',
          end_time: '13:00'
        },
        {
          name: 'Istirahat',
          break: true,
          start_time: '13:00',
          end_time: '15:00'
        },
        {
          name: 'Sesi 3',
          start_time: '15:00',
          end_time: '17:00'
        }
      ],
      duration: 4
    },
    {
      id: 2,
      name: 'Sesi Survey (Hexa)',
      data: [
        {
          name: 'SH 1',
          start_time: '09:00',
          end_time: '10:30'
        },
        {
          name: 'SH 2',
          start_time: '10:30',
          end_time: '12:00'
        },
        {
          name: 'Istirahat',
          break: true,
          start_time: '13:00',
          end_time: '14:30'
        },
        {
          name: 'SH 4',
          start_time: '14:30',
          end_time: '16:00'
        },
        {
          name: 'SH 5',
          start_time: '16:00',
          end_time: '17:30'
        },
        {
          name: 'SH 6',
          start_time: '17:30',
          end_time: '19:00'
        }
      ],
      duration: 6
    }
  ]
}
