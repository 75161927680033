import { render, staticRenderFns } from "./ProjectTableActive.vue?vue&type=template&id=604f8068"
import script from "./ProjectTableActive.vue?vue&type=script&lang=js"
export * from "./ProjectTableActive.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports