<template>
  <div class="wrapper-client">
    <div class="wrap-header">
      <img src="@/assets/img/wave.png" alt="wave" class="wave" />
      <div class="is-flex is-align-items-center is-justify-content-space-between wrap-action">
        <div class="is-flex is-align-items-center">
          <p class="mr-1 tag-client" v-if="client.client_type_code && client.client_type_name">
            {{ client.client_type_name }}
          </p>
        </div>
        <div>
          <ButtonApp
            @click="editVendor"
            width="170px"
            height="34px"
            :isBold="true"
            :isTransparent="true"
            :isBorderWhite="true"
            >Edit Informasi Client</ButtonApp
          >
        </div>
      </div>
    </div>
    <div class="wrap-banner is-flex is-justify-content-space-between is-align-items-center gap-5">
      <div
        class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100"
      >
        <div class="flex-column-start">
          <p class="averta-bold label-20">{{ client.name ? client.name : '-' }}</p>
          <p class="text-grey label-14">{{ client.company_name ? client.company_name : '-' }}</p>
        </div>
        <div
          v-if="!isSMRC && listBranch.site_managers.length > 0"
          class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start"
        >
          <p class="text-grey label-14">Site Manager</p>
          <div class="text-container">
            <p class="averta-bold label-14">{{ truncatedText }}</p>

            <b-dropdown
              aria-role="menu"
              scrollable
              max-height="200"
              expanded
              append-to-body
              can-close
              style="min-width: 100% !important"
            >
              <template #trigger>
                <div
                  class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center is-clickable"
                >
                  <p class="averta-bold label-14 color-red">Lihat lainnya</p>
                  <span style="transform: rotate(90deg)"><ChevronIcon /></span>
                </div>
              </template>

              <div class="is-flex is-flex-direction-column c-w-100 c-h-100 gap-3 p-2">
                <span class="px-4">
                  <p class="averta-bold label-14">Daftar Site Manager</p>
                </span>

                <b-dropdown-item
                  aria-role="listitem"
                  v-for="(item, index) in listBranch.site_managers"
                  :key="index"
                >
                  <div class="is-flex is-flex-direction-row">
                    <div
                      class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center"
                    >
                      <div
                        class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center gap-2"
                      >
                        <div class="avatar-image">
                          <img
                            v-if="item.photo === null"
                            src="https://nccf.in/wp-content/uploads/2024/08/WhatsApp-Image-2024-08-19-at-5.58.49-PM-1.jpeg"
                            alt="avatar"
                          />
                          <img v-else :src="item.photo" alt="avatar" />
                        </div>
                        <p class="averta-bold color-black">{{ item.name }}</p>
                      </div>
                    </div>
                  </div>
                </b-dropdown-item>
              </div>
            </b-dropdown>
          </div>
        </div>
        <img :src="client.logo" alt="logo" class="wrap-image" />
      </div>
      <div style="min-height: 45px !important" class="divider-vertical-line-1" v-if="isSMRC"></div>
      <div class="flex-row-start c-w-100" v-if="isSMRC">
        <div>
          <p class="text-grey label-14">Area</p>
          <p class="averta-bold label-20">{{ areasName.name ? areasName.name : '-' }}</p>
        </div>
      </div>
    </div>

    <SidebarClient
      :show="sidebarMenu === 'client'"
      :close="handleCloseMenu"
      @updateEdit="refreshData"
    />
  </div>
</template>

<script>
import ButtonApp from '@/components/button/ButtonApp.vue'
import SidebarClient from './SidebarClient.vue'
import { isSMRC } from '@/helpers'
import { ChevronIcon } from '@/components/icons'

export default {
  name: 'BannerClient',
  components: {
    ButtonApp,
    SidebarClient,
    ChevronIcon
  },
  props: {
    client: Object,
    areasName: Object,
    listBranch: Object,
    refreshData: Function
  },
  data () {
    return {
      sidebarMenu: null,
      names: [
        'Hendra Kurniawan',
        'Hendra Kurniawan',
        'Hendra Kurniawan',
        'Hendra Kurniawan',
        'Hendra Kurniawan',
        'Hendra Kurniawan'
      ]
    }
  },
  computed: {
    isSMRC () {
      return isSMRC(this.listBranch.code)
    },
    truncatedText () {
      const combinedText = this.listBranch.site_managers.map((item) => item.name).join(', ')
      return combinedText.length > 40 ? combinedText.substring(0, 40) + '...' : combinedText
    }
  },
  methods: {
    editVendor () {
      this.sidebarMenu = 'client'
      this.$store.commit('client/setPayloadClient', {
        typeSidebar: 'edit',
        dataForm: {
          ...JSON.parse(JSON.stringify(this.client)),
          id: this.$route.params.vendorId,
          imgFile: 'from-edit'
        },
        isEmptyForm: false
      })
      this.handleStoreSidebar(true)
    },
    handleCloseMenu () {
      this.sidebarMenu = null
      this.handleStoreSidebar(false)
    },
    handleStoreSidebar (showSidebar) {
      this.$store.commit('client/setPayloadClient', { showSidebar })
    }
  }
}
</script>

<style scoped>
.wrapper-client {
  background: linear-gradient(90deg, #ed4949 0%, #a40707 100%);
  border-radius: 24px;
}

.wrap-header {
  border-radius: 24px 24px 0px 0px;
  padding: 20px;
  position: relative;
}

.wave {
  object-fit: fill;
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 20px;
}

.wrap-action {
  position: relative;
}

.tag-client {
  background: #e1eeff;
  height: max-content;
  padding: 2px 12px;
  border-radius: 100px;
  color: #3185fc;
  font-family: 'Averta-Bold';
}

.wrap-banner {
  background: #fafafa;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 20px;
  border: 1px solid #e1e1e1;
}

.wrap-image {
  height: 32px;
}

.text-container {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  gap: 8px;
}

.avatar-image {
  display: flex;
  flex-direction: row;
  width: 25px;
  min-width: 25px;
  max-width: 25px;
  height: 25px;
  min-height: 25px;
  max-height: 25px;
  justify-content: center;
  align-items: center;
  background: pink;
  border-radius: 50%;
  overflow: hidden;
}

.avatar-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
</style>
