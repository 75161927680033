<template>
  <main>
    <div
      style="max-height: 100dvh"
      class="is-flex is-flex-direction-column is-justify-content-space-between is-align-items-center c-w-100 c-h-100"
      :class="{ 'p-2': isMobile, 'p-5': !isMobile }"
    >
      <div
        style="height: auto; min-height: auto; max-height: auto"
        class="is-flex is-flex-direction-column is-justify-content-space-between is-align-items-center c-w-100 gap-5"
      >
        <!-- Header Tab Active -->
        <ul
          class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100"
        >
          <div
            class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-100 gap-3"
          >
            <GroupChatIcon />
            <p class="averta-bold label-22">Grup Chat</p>
          </div>

          <li
            @click="handleClickTab('group-chat')"
            :style="
              tabActive === 'group-chat'
                ? 'border-bottom: 1px solid #e10009'
                : 'border-bottom: 1px solid #e1e1e1'
            "
            class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center c-w-100 is-clickable"
            style="min-height: 50px; display: none !important"
          >
            <p
              class="averta-bold color-red"
              :class="tabActive === 'group-chat' ? 'color-red' : 'color-gray'"
            >
              Group Chat
            </p>
          </li>
          <li
            @click="handleClickTab('comments')"
            :style="
              tabActive === 'comments'
                ? 'border-bottom: 1px solid #e10009'
                : 'border-bottom: 1px solid #e1e1e1'
            "
            class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center c-w-100 is-clickable"
            style="
              min-height: 50px;
              pointer-events: none !important;
              cursor: default;
              display: none !important;
            "
          >
            <p
              class="averta-bold color-red"
              :class="tabActive === 'comments' ? 'color-red' : 'color-gray'"
            >
              Komentar
            </p>
          </li>
        </ul>
        <!-- Header Tab Active -->

        <!-- Search Chat -->
        <div
          class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100 is-flex-wrap-wrap gap-5"
        >
          <div
            style="width: auto"
            class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center gap-2"
          >
            <button
              class="button-tab-active-group-chat"
              :class="{ isActive: tabActiveGroupChat === 'allChat' }"
              @click="handleClickTabGroupChat('allChat')"
            >
              <div
                class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center"
              >
                <p class="averta-bold label-16">Semua</p>
              </div>
            </button>

            <button
              class="button-tab-active-group-chat"
              :class="{ isActive: tabActiveGroupChat === 'chatActive' }"
              @click="handleClickTabGroupChat('chatActive')"
            >
              <div
                class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center"
              >
                <p class="averta-bold label-16">Aktif</p>
              </div>
            </button>

            <button
              class="button-tab-active-group-chat"
              :class="{ isActive: tabActiveGroupChat === 'chatArchive' }"
              @click="handleClickTabGroupChat('chatArchive')"
            >
              <div
                class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center"
              >
                <p class="averta-bold label-16">Arsip</p>
              </div>
            </button>
          </div>

          <div
            style="flex: 1"
            class="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center"
          >
            <input
              v-model="searchQuery"
              type="text"
              class="input-text-search-chat-group c-w-100"
              :placeholder="tabActive === 'group-chat' ? 'Cari ID' : 'Search Komentar'"
            />
          </div>
        </div>
        <!-- Search Chat -->

        <div class="divider-line-1"></div>
      </div>

      <div
        style="flex: 1; overflow: auto"
        class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center c-w-100"
      >
        <!-- List Chat Group -->
        <ul
          v-if="(tabActive === 'group-chat' && getDataGroupChat.length > 0) || isLoading"
          ref="chatList"
          @scroll="handleScroll"
          class="is-flex is-flex-direction-column is-justify-content-flex-start is-align-items-flex-start c-w-100 c-h-100"
        >
          <li
            v-for="(item, index) in filteredChatGroup"
            :key="index"
            @click="handleShowSidebarGroupChat(item.topic, item.survey_id, item)"
            class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100 py-4 px-4 is-hoverable is-clickable slide-animate"
            style="min-height: 125px; max-height: 100%; border-bottom: 1px solid #e1e1e1"
            :class="{
              'is-pr-20': !isMobile && isHandleShowSidebarGroupChat,
              'c-w-50': !isMobile && isHandleShowSidebarGroupChat,
              'c-w-100': isMobile && isHandleShowSidebarGroupChat,
              'p-0': isMobile && isHandleShowSidebarGroupChat,
              'm-0': isMobile && isHandleShowSidebarGroupChat
            }"
          >
            <div
              class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-100 gap-3"
            >
              <div class="avatar-image">
                <PhotoProfileGroupChatGray v-if="!item.is_open" />
                <PhotoProfileGroupChat v-else />
              </div>
              <div
                class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start gap-3"
              >
                <div
                  class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start"
                >
                  <div
                    class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center gap-1"
                  >
                    <p
                      class="averta-bold white-space-nowrap"
                      :class="[isMobile ? 'label-14' : 'label-20']"
                    >
                      ID{{ item.survey_id }}
                    </p>
                    <p
                      class="averta-bold white-space-nowrap"
                      :class="[isMobile ? 'label-14' : 'label-20']"
                    >
                      -
                    </p>
                    <p
                      v-if="isHandleShowSidebarGroupChat"
                      class="averta-bold white-space-nowrap"
                      :class="[isMobile ? 'label-14' : 'label-20']"
                    >
                      {{
                        isMobile && item.vendor_name && item.vendor_name.length > 30
                          ? item.vendor_name.slice(0, 4) + '...'
                          : item && item.vendor_name && item.vendor_name.length > 30
                          ? item.vendor_name
                            ? item.vendor_name.slice(0, 20) + '...'
                            : ''
                          : item.vendor_name
                      }}
                    </p>
                    <p
                      v-else
                      class="averta-bold white-space-nowrap"
                      :class="[isMobile ? 'label-14' : 'label-20']"
                    >
                      {{
                        isMobile && item.vendor_name && item.vendor_name.length > 30
                          ? item.vendor_name.slice(0, 4) + '...'
                          : item.vendor_name
                      }}
                    </p>
                  </div>
                  <p class="averta-reguler" :class="[isMobile ? 'label-14' : 'label-20']">
                    {{ item.store_name }}
                  </p>
                </div>
                <p
                  v-if="isHandleShowSidebarGroupChat"
                  class="averta-reguler white-space-nowrap"
                  :class="[isMobile ? 'label-14' : 'label-20']"
                >
                  {{
                    isMobile && item.last_message && item.last_message.length > 30
                      ? item.last_message.slice(0, 5) + '...'
                      : item && item.last_message && item.last_message.length > 100
                      ? item.last_message
                        ? item.last_message.slice(0, 30) + '...'
                        : ''
                      : item.last_message
                  }}
                </p>
                <p
                  v-else
                  class="averta-reguler white-space-nowrap"
                  :class="[isMobile ? 'label-14' : 'label-20']"
                  style="max-width: 100px !important"
                >
                  {{
                    isMobile && item.last_message && item.last_message.length > 30
                      ? item.last_message.slice(0, 5) + '...'
                      : item && item.last_message && item.last_message.length > 100
                      ? item.last_message
                        ? item.last_message.slice(0, 100) + '...'
                        : ''
                      : item.last_message
                  }}
                </p>
              </div>
            </div>
            <div
              class="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center c-w-100 gap-3"
            >
              <div
                class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-end gap-2"
              >
                <div
                  class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center gap-10"
                >
                  <p
                    class="averta-bold"
                    :class="{
                      'color-gray': !item.is_open,
                      'color-green': item.is_open,
                      'label-14': isMobile,
                      'label-16': !isMobile
                    }"
                  >
                    •
                    {{ item.is_open ? 'Aktif' : 'Arsip' }}
                  </p>

                  <p
                    class="averta-reguler"
                    :class="{ 'label-14': isMobile, 'label-16': !isMobile }"
                  >
                    {{ moment(item.last_update).format('DD MMM') }}
                  </p>
                </div>

                <code v-if="item.unread > 0">
                  <p class="averta-reguler label-14">{{ item.unread }}</p>
                </code>
              </div>
            </div>
          </li>
          <div
            v-if="filteredChatGroup.length === 0"
            class="c-w-100 c-h-50 is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
          >
            <ProyekEmptyIcon />
            <p class="averta-bold label-16">Group Chat Yang Anda Cari Tidak Ditemukan</p>
          </div>
        </ul>
        <div
          v-else
          class="c-w-100 c-h-100 is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
        >
          <ProyekEmptyIcon />
          <p class="averta-bold label-16">Belum ada group chat</p>
        </div>
      </div>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false">
        <SpinnerIcon />
      </b-loading>
      <!-- List Chat Group -->
    </div>

    <SidebarGroupChatDetailSm
      @readGroupChat="getGroupChat(false)"
      :selectGroupChat="selectGroupChat"
      @closeSidebarGroupChat="handleShowSidebarGroupChat"
      :openSidebarGroupChat="isHandleShowSidebarGroupChat"
    />
  </main>
</template>

<script>
import {
  PhotoProfileGroupChat,
  PhotoProfileGroupChatGray,
  SpinnerIcon,
  ProyekEmptyIcon,
  GroupChatIcon
} from '@/components/icons'
import { alertErrorMessage } from '@/helpers'
import { mapGetters } from 'vuex'
import { getDataMultiTopic, unsubscribeAllListeners } from '@/services/firebaseMultiChat'
import SidebarGroupChatDetailSm from './components/SidebarGroupChatDetailSm'
import moment from 'moment'
import debounce from 'lodash/debounce'

export default {
  name: 'detailGroupChatSM',
  components: {
    PhotoProfileGroupChat,
    SidebarGroupChatDetailSm,
    SpinnerIcon,
    ProyekEmptyIcon,
    GroupChatIcon,
    PhotoProfileGroupChatGray
  },
  data () {
    return {
      tabActive: 'group-chat',
      tabActiveGroupChat: 'allChat',
      isHandleShowSidebarGroupChat: false,
      isLoading: false,
      getDataGroupChat: [],
      selectGroupChat: {},
      searchQuery: '',
      page: 1,
      multipleTopic: [],
      getDataChatRooms: [],
      intervalId: null,
      isLoadingMore: false,
      moment: moment
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      user: 'auth/user',
      getNewChatMultiple: 'groupChat/getNewChatMultiple'
    }),
    filteredChatGroup () {
      if (!this.getDataGroupChat || !this.getDataGroupChat.length) {
        return []
      }

      const filteredByTab = this.getDataGroupChat.filter((item) => {
        if (this.tabActiveGroupChat === 'allChat') return true
        if (this.tabActiveGroupChat === 'chatActive') return item.is_open
        if (this.tabActiveGroupChat === 'chatArchive') return !item.is_open
        return false
      })

      if (!this.searchQuery) {
        return filteredByTab
      }

      return filteredByTab.filter((item) => {
        const surveyId = String(item.survey_id || '')
        return surveyId.includes(this.searchQuery)
      })
    },
    isMobile () {
      if (window.innerWidth <= 768) {
        return true
      }
      return false
    }
  },
  watch: {
    getNewChatMultiple: {
      handler (newVal) {
        if (
          (newVal > 0 || newVal.length > 0 || newVal.id) &&
          (newVal.status !== undefined || newVal.status === 'RECEIVED')
        ) {
          const findIdx = this.getDataChatRooms.find((item) => item.id === newVal.id)
          if (!findIdx) {
            this.getGroupChat(false)
            this.loadChatData()
          }
        } else {
          return false
        }
      },
      deep: true
    },
    searchQuery: {
      handler () {
        this.page = 1
      }
    },
    multipleTopic: {
      handler (newVal) {
        if (newVal && newVal.length > 0) {
          this.loadChatData()
        }
      },
      deep: true
    }
  },
  created () {
    this.debouncedGetGroupChat = debounce(this.getGroupChat, 300)
    this.getGroupChat()
    this.startPolling()
  },
  beforeDestroy () {
    this.stopPolling()
    unsubscribeAllListeners()
  },
  methods: {
    playAudioNewMessage () {
      const audio = new Audio(require('@/assets/audio/Notifikasi-Kanggo.mp3'))
      audio.play()
    },

    startPolling () {
      this.stopPolling()

      this.intervalId = setInterval(() => {
        this.getGroupChat(false)
      }, 1800000)
    },

    stopPolling () {
      if (this.intervalId) {
        clearInterval(this.intervalId)
        this.intervalId = null
      }
    },

    getGroupChat (showLoading = true) {
      if (this.isLoading) return

      if (showLoading) {
        this.isLoading = true
      }

      const payload = {
        url: this.url,
        page: this.page
      }

      this.$store
        .dispatch('groupChat/getGroupChat', payload)
        .then((response) => {
          this.getDataGroupChat = response.data.data
          this.getDataGroupChat.sort((a, b) => new Date(b.last_update) - new Date(a.last_update))

          const newData = response.data.data || []

          if (this.page === 1) {
            this.getDataGroupChat = newData
          } else {
            const uniqueData = newData.filter(
              (item) =>
                !this.getDataGroupChat.some((existingItem) => existingItem.topic === item.topic)
            )
            this.getDataGroupChat = [...this.getDataGroupChat, ...uniqueData]
          }

          if (this.getDataGroupChat && this.getDataGroupChat.length > 0) {
            this.multipleTopic = this.getDataGroupChat.map((item) => item.topic).filter(Boolean)
          }

          if (newData.length > 0) {
            this.page++
          }
        })
        .catch((error) => {
          console.error('Error fetching group chat:', error)
          alertErrorMessage(error)
        })
        .finally(() => {
          if (showLoading) {
            this.isLoading = false
          }
        })
    },

    handleScroll () {
      const list = this.$refs.chatList
      if (!list) return

      if (list.scrollTop + list.clientHeight >= list.scrollHeight - 50) {
        if (!this.isLoadingMore) {
          this.isLoadingMore = true
          this.debouncedGetGroupChat()
        }
      }
    },

    handleClickTab (tab) {
      if (this.tabActive !== tab) {
        this.tabActive = tab
        this.page = 1
        this.getGroupChat()
      }
    },

    handleClickTabGroupChat (tab) {
      if (this.tabActiveGroupChat !== tab) {
        this.tabActiveGroupChat = tab
      }
    },

    handleShowSidebarGroupChat (topic, id, data) {
      this.selectGroupChat = { topic, id, ...data }
      this.isHandleShowSidebarGroupChat = !this.isHandleShowSidebarGroupChat
    },

    handleCloseSidebar (value) {
      this.isHandleShowSidebarGroupChat = value
      if (!value) {
        this.getGroupChat(false)
      }
    },

    async loadChatData () {
      if (!this.multipleTopic || this.multipleTopic.length === 0) {
        console.log('No topics to load')
        return { data: [] }
      }

      try {
        const result = await getDataMultiTopic(this.multipleTopic)

        if (result.error) {
          console.error('Error loading chat data:', result.error)
          return { data: [] }
        }

        this.getDataChatRooms = result.data || []
        return result
      } catch (error) {
        console.error('Error in loadChatData:', error)
        return { data: [] }
      }
    },

    truncateText (text, maxLength) {
      if (!text) return ''
      return text.length > maxLength ? text.slice(0, maxLength) + '...' : text
    },

    formatDate (date) {
      if (!date) return ''
      return moment(date).format('DD MMM')
    }
  }
}
</script>

<style scoped>
main {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 auto;
  padding: 0;
}

.input-text-search-chat-group {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  height: 50px;
  min-height: 50px;
  max-height: 150px;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 1px solid #e1e1e1;
  padding: 1rem 1rem;
  border-radius: 15px;
  field-sizing: content !important;
  resize: none !important;
}

.avatar-image {
  display: flex;
  flex-direction: row;
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  height: 50px;
  min-height: 50px;
  max-height: 50px;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: 50%;
  overflow: hidden;
}

.unread-badge {
  display: flex;
  flex-direction: row;
  width: max-content;
  min-width: 40px;
  max-width: none;
  height: max-content;
  min-height: 40px;
  max-height: none;
  justify-content: center;
  align-items: center;
  background: #e10009;
  border-radius: 50%;
  padding: 5px;
  color: #ffffff;
}

.is-hoverable:hover {
  background: #f5f5f5;
}

.slide-animate {
  transition: width 0.85s ease-in-out, right 0.45s ease-in-out, opacity 0.45s ease-in-out;
}

.color-red {
  color: #e10009;
}

.color-gray {
  color: #666666;
}

.color-green {
  color: #10bd41;
}

code {
  display: flex;
  flex-direction: row;
  width: max-content;
  min-width: 40px;
  max-width: none;
  height: max-content;
  min-height: 40px;
  max-height: none;
  justify-content: center;
  align-items: center;
  background: #e10009;
  border-radius: 50%;
  padding: 5px;
  color: #ffffff;
}

.button-tab-active-group-chat {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: auto;
  max-width: 100%;
  height: 50px;
  max-height: 50px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 1rem 2rem;
  background: transparent;
  border-radius: 30px;
  cursor: pointer;
  color: #868686;
  border: 1px solid #868686;
  transition: all 0.3s ease-in-out;
}

.button-tab-active-group-chat.isActive {
  background: #fff5f5;
  color: #d9272d;
  border: 1px solid #d9272d;
}

@media screen and (max-width: 768px) {
  .c-w-50 {
    width: 100% !important;
  }

  .input-text-search-chat-group {
    margin-top: 10px;
  }
}
</style>
