<template>
  <div>
    <b-modal
      v-model="showModalReschedule"
      :can-cancel="false"
      :on-cancel="() => closeModalReschedule({ closeFromParent: true })"
    >
      <!-- :style="{ width: isSMRC ? 'auto' : '30em' }" -->
      <div class="modal-schedule-template" :style="{width: 'auto'}">
        <div class="modal-body w-100">
          <header class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="averta-bold label-18">Sesuaikan Jadwal</p>
            <div
              class="is-clickable"
              @click="() => closeModalReschedule({ closeFromParent: true })"
            >
              <b-icon icon="close"></b-icon>
            </div>
          </header>
          <div v-if="dateSelectedProps" class="header-date-survey">
            <CalendarIcon />
            <div class="ml-3">
              <p class="color-blue-1 label-12">Tanggal & Waktu Survey Awal</p>
              <p class="averta-black label-16">
                {{ dateSurveySelected }}
                <!-- {{ isSMRC && timeSessionProps ? `(${timeSessionProps})` : '' }} -->
                {{ timeSessionProps ? `(${timeSessionProps})` : '' }}
              </p>
            </div>
          </div>
          <div class="modal-date-wrapper is-flex">
            <div class="flex-1">
              <p class="averta-bold">Pilih satu tanggal</p>
              <div class="is-flex is-justify-content-center mt-5 mb-6 date-survey-wrapper">
                <v-date-picker
                  v-model="calendarReschedule"
                  mode="date"
                  is-expanded
                  @dayclick="onDayClick"
                />
              </div>
              <!-- <ButtonApp
                v-if="!isSMRC"
                :is-bold="true"
                width="100%"
                @click="handleSubmitReschedule"
              >
                <div class="is-flex is-align-items-center is-justify-content-center">
                  <p class="py-2 mr-2">Lanjutkan</p>
                  <ArrowRightIcon color="white" />
                </div>
              </ButtonApp> -->
            </div>
            <!-- v-if="isSMRC" -->
            <div class="time-survey flex-1 p-5">
              <p class="averta-bold">Pilih Sesi Survey Tersedia</p>
              <p class="color-grey mb-3">
                Pilihan Sesi Survey yang muncul adalah sesi yang dapat dipilih
              </p>
              <div class="body-time-survey">
                <div v-if="isLoading">
                  <SpinnerIcon />
                </div>
                <div v-else-if="dataSesi.length === 0" class="empty-time-survey">
                  <ProyekEmptyIcon width="160" height="140" />
                  <p class="has-text-centered averta-black" style="width: 200px">
                    Tentukan tanggal survey terlebih dahulu
                  </p>
                </div>
                <div v-else>
                  <div
                    v-for="(item, index) in dataSesi"
                    :key="index"
                    class="item-time-survey is-justify-content-space-between"
                    @click="handleSelectSesi(item)"
                    :style="{
                      cursor: !item.session_available ? 'not-allowed' : 'pointer',
                      opacity: !item.session_available ? 0.5 : 1,
                      background:
                        selectedSesi && selectedSesi.session_id === item.session_id
                          ? 'rgba(0, 0, 0, 0.1)'
                          : ''
                    }"
                  >
                    <div class="is-flex is-align-items-center">
                      <input
                        type="radio"
                        :value="item.session_id"
                        :checked="selectedSesi && selectedSesi.session_id === item.session_id"
                        :disabled="!item.session_available"
                      />
                      <div class="is-flex is-flex-direction-row gap-2">
                        <p class="ml-2">{{ item.session_name }}</p>
                        <p class="ml-2" v-if="item.session_start_time && item.session_end_time">
                          |
                        </p>
                        <p
                          class="ml-2"
                          v-if="
                            item.session_start_time || !item.session_start_time === 'Custom Survey'
                          "
                          :style="{
                            display: item.session_id === 0 ? 'none' : 'flex'
                          }"
                        >
                          {{ moment(item.session_start_time, 'HH:mm').format('HH:mm') }}
                        </p>
                        <p class="ml-2" v-if="item.session_start_time && item.session_end_time">
                          -
                        </p>
                        <p
                          class="ml-2"
                          v-if="item.session_end_time"
                          :style="{
                            display: item.session_id === 0 ? 'none' : 'flex'
                          }"
                        >
                          {{ moment(item.session_end_time, 'HH:mm').format('HH:mm') }}
                        </p>
                      </div>
                    </div>
                    <p v-if="!item.session_available" class="averta-reguler-italic color-red">
                      Tidak Tersedia
                    </p>
                  </div>
                </div>
              </div>

              <ButtonApp
                :is-bold="true"
                :is-disabled="isDisabled"
                width="100%"
                @click="handleSubmitReschedule"
              >
                <div class="is-flex is-align-items-center is-justify-content-center">
                  <p class="py-2 mr-2">Lanjutkan</p>
                  <ArrowRightIcon color="white" />
                </div>
              </ButtonApp>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="showModalConfirmReschedule" :can-cancel="false">
      <div class="modal-confirm-template">
        <div class="is-flex is-justify-content-center">
          <img :src="require('@/assets/img/delete-area-component.png')" alt="" />
        </div>
        <p class="averta-bold label-20 my-3">Ubah Jadwal Survey?</p>
        <p class="desc-modal mb-3">
          Pastikan seluruh informasi yang sudah disesuaikan telah tepat dan benar.
        </p>
        <div
          class="is-flex is-flex-direction-column is-align-items-center tgl-submit-wrapper py-3 mb-5"
        >
          <p class="label-10 color-blue-1">Tanggal Survey</p>
          <p class="averta-bold label-12 has-text-centered">
            {{ convertDateSelected(dateSelectedProps) }}
            {{ timeSessionProps ? `(${timeSessionProps})` : '' }}
            menjadi <br />
            {{ convertDateSelected(calendarReschedule) }}
            {{
              selectedSesi
                ? `(${
                    selectedSesi.start_time || selectedSesi.session_id === 0
                      ? selectedSesi.session_start_time
                      : selectedSesi.session_start_time.slice(0, 5)
                  })`
                : ''
            }}
          </p>
        </div>
        <div class="is-flex is-align-items-center c-w-100">
          <button
            @click="handleCancelConfirm"
            class="btn-reject color-red mr-3 averta-bold is-clickable flex-1"
          >
            Cek Kembali
          </button>
          <div class="flex-1">
            <ButtonApp
              :is-bold="true"
              height="45px"
              width="100%"
              @click="handleConfirmReschedule"
            >
              <p>Proses</p>
            </ButtonApp>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="showModalSuccess" :can-cancel="true" :on-cancel="handleCloseSuccessConfirm">
      <div class="modal-confirm-template">
        <div class="is-flex is-justify-content-center">
          <img :src="require('@/assets/img/survey-selesai.png')" width="200" alt="" />
        </div>
        <p class="averta-bold label-20 my-5">Jadwal Berhasil Diubah</p>
        <p class="desc-modal mb-5">Rencana kunjungan survey telah disesuaikan dengan jadwal baru</p>
        <ButtonApp :is-bold="true" width="100%" @click="handleCloseSuccessConfirm">
          <p class="py-2 mr-2">Tutup</p>
        </ButtonApp>
      </div>
    </b-modal>

    <b-loading :is-full-page="true" v-model="isLoadingSpinner" :can-cancel="false">
      <SpinnerIcon />
    </b-loading>
  </div>
</template>

<script>
import moment from 'moment'
import { ArrowRightIcon, SpinnerIcon, ProyekEmptyIcon, CalendarIcon } from '@/components/icons'
import ButtonApp from '@/components/button/ButtonApp.vue'
import { alertErrorMessage } from '@/helpers'
import { mapGetters } from 'vuex'

export default {
  name: 'ModalCalendarReschedule',
  props: {
    showModalRescheduleProps: {
      type: Boolean,
      required: true
    },
    surveyDateTo: {
      type: String
    },
    surveyDateFrom: {
      type: String
    },
    pathAPIProps: {
      type: String
    },
    isFromBS: {
      type: Boolean,
      default: false
    },
    isSMRC: {
      type: Boolean,
      default: false
    },
    dateSelectedProps: {
      type: String
    },
    timeSessionProps: {
      type: String
    },
    idTimeSessionProps: {
      type: [String, Number]
    },
    getDataVendorId: {
      type: Number
    }
  },
  components: {
    ArrowRightIcon,
    ButtonApp,
    SpinnerIcon,
    ProyekEmptyIcon,
    CalendarIcon
  },
  data () {
    return {
      isLoadingSpinner: false,
      showModalReschedule: false,
      showModalConfirmReschedule: false,
      showModalSuccess: false,
      calendarReschedule: this.dateSelectedProps, // passing props

      isLoading: false,
      dataSesi: [],
      selectedSesi: null,
      moment: moment,

      initialRender: false
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    isDisabled () {
      // return !this.calendarReschedule || (this.isSMRC && !this.selectedSesi)
      return !this.calendarReschedule || !this.selectedSesi || this.initialRender
    },
    dateSurveySelected () {
      return moment(this.dateSelectedProps).format('dddd, DD MMMM YYYY')
    }
  },
  methods: {
    closeModalReschedule (props) {
      const { closeFromParent = false } = props
      if (closeFromParent) {
        this.dataSesi = []
        this.selectedSesi = null
        this.calendarReschedule = null
      }
      this.showModalReschedule = false
      this.$emit('handleCloseModalReschedule', { isOpenModal: false })
    },
    convertDateSelected (date) {
      if (!date) return '-'
      return moment.utc(date).format('dddd, DD MMMM YYYY')
    },
    handleSubmitReschedule () {
      // if ((this.calendarReschedule === this.dateSelectedProps) && this.isFromBS) {
      //   return alertErrorMessage('Jadwal survey tidak boleh sama')
      // }
      this.closeModalReschedule({ closeFromParent: false })
      this.showModalConfirmReschedule = true
    },
    handleConfirmReschedule () {
      this.isLoadingSpinner = true
      const payload = {
        url: this.url,
        path: this.pathAPIProps,
        data: {
          survey_id: this.$route.params.surveyId,
          status: 'approve',
          date: this.calendarReschedule
        }
      }

      // if (this.isSMRC) {
      payload.data.time_sesi_id = this.selectedSesi.session_id
      payload.data.start_time = this.selectedSesi.session_start_time
      payload.data.end_time = this.selectedSesi.session_end_time
      // }

      this.$store
        .dispatch('project/postReschedule', payload)
        .then(() => {
          this.isLoadingSpinner = false
          this.showModalConfirmReschedule = false
          this.showModalSuccess = true
          this.calendarReschedule = null
          this.selectedSesi = null
        })
        .catch((error) => {
          alertErrorMessage(error)
          this.isLoadingSpinner = false
        })
    },
    handleCancelConfirm () {
      this.showModalConfirmReschedule = false
      this.showModalReschedule = true
    },
    handleCloseSuccessConfirm () {
      this.showModalSuccess = false
      this.activeTab = 2
      this.$emit('handleCloseModalReschedule', { isOpenModal: false, isFinish: true })
    },
    onDayClick (day) {
      if (day.initial) {
        this.initialRender = true
      } else {
        this.initialRender = false
        this.selectedSesi = null
      }

      this.getSesi()
      this.calendarReschedule = day.id
    },
    handleSelectSesi (data) {
      if (!data.session_available) return
      if (data.initial) {
        this.initialRender = true
      } else {
        this.initialRender = false
      }

      this.selectedSesi = data
    },
    getSesi () {
      this.isLoading = true
      const payload = {
        url: this.url,
        survey_id: this.$route.params.surveyId,
        date: moment(this.calendarReschedule || this.dateSelectedProps).format('YYYY-MM-DD'),
        vendor_id: this.getDataVendorId
      }
      this.$store
        .dispatch('project/getSesiSurvey', payload)
        .then((res) => {
          if (res.status === 200) {
            this.dataSesi = res.data.data

            if (this.initialRender) {
              const findSesi = this.dataSesi.find((sesi) => sesi.session_id === this.idTimeSessionProps || sesi.session_start_time.slice(0, 5) === this.timeSessionProps)
              if (findSesi) {
                this.handleSelectSesi({ ...findSesi, initial: true })
              }
            }
          }
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          alertErrorMessage(err)
        })
    }
  },
  watch: {
    showModalRescheduleProps (val) {
      this.showModalReschedule = val
      if (val) {
        this.onDayClick({ id: moment(this.dateSelectedProps).format('YYYY-MM-DD'), initial: true })
        // if (!this.calendarReschedule) {
        //   this.calendarReschedule = this.surveyDateTo
        // }
        // if (this.timeSessionProps) {
        //   this.selectedSesi = { id: JSON.parse(JSON.stringify(this.idTimeSessionProps)), start_time: JSON.parse(JSON.stringify(this.timeSessionProps)) }
        // }
        // this.getSesi()
      }
    }
  }
}
</script>

<style scoped>
.btn-reject {
  border: none;
  outline: none;
  padding: 1em 0em;
  border: 1px solid #d9272d;
  background: transparent;
  border-radius: 100px;
}

.modal-schedule-template {
  width: auto;
  margin: auto;
}

.modal-body {
  overflow: hidden;
  background: white;
  border-radius: 10px;
  height: 100%;
}

.modal-schedule-template header {
  background-color: #911a1e;
  height: 5em;
  color: white;
  padding: 0 20px;
}

.modal-date-wrapper {
  background: white;
  padding: 1em 1.5em;
  position: relative;
}

.modal-confirm-template {
  width: 30em;
  margin: auto;
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 2em;
  align-items: center;
}

.desc-modal {
  width: 80%;
  text-align: center;
}

.tgl-submit-wrapper {
  background: #fafafa;
  border-radius: 20px;
  width: 100%;
}

.body-time-survey {
  height: 18em;
  margin-bottom: 1em;
  border-bottom: 1px solid #c1c7d3;
  overflow-y: auto;
}

.empty-time-survey {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  top: 1em;
}

.item-time-survey {
  display: flex;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #e1e1e1;
  background: #fafafa;
  padding: 15px;
  margin-bottom: 0.8em;
}

.item-time-survey input[type='radio'] {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
  transform: scale(1.5);
}

.header-date-survey {
  background: #e1eeff;
  padding: 15px 20px;
  display: flex;
  align-items: center;
}
</style>

<style>
.date-survey-wrapper .vc-container {
  border: none;
}
</style>
