<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_1_88177"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="6"
      y="0"
      width="12"
      height="24"
    >
      <path
        d="M16 6.74761V17.3276C16 19.4176 14.47 21.2776 12.39 21.4776C10 21.7076 8 19.8376 8 17.4976V5.13761C8 3.82761 8.94 2.63761 10.24 2.50761C11.74 2.35761 13 3.52761 13 4.99761V15.4976C13 16.0476 12.55 16.4976 12 16.4976C11.45 16.4976 11 16.0476 11 15.4976V6.74761C11 6.33761 10.66 5.99761 10.25 5.99761C9.84 5.99761 9.5 6.33761 9.5 6.74761V15.3576C9.5 16.6676 10.44 17.8576 11.74 17.9876C13.24 18.1376 14.5 16.9676 14.5 15.4976V5.16761C14.5 3.07761 12.97 1.21761 10.89 1.01761C8.51 0.787613 6.5 2.65761 6.5 4.99761V17.2676C6.5 20.1376 8.6 22.7076 11.46 22.9776C14.75 23.2776 17.5 20.7176 17.5 17.4976V6.74761C17.5 6.33761 17.16 5.99761 16.75 5.99761C16.34 5.99761 16 6.33761 16 6.74761Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0_1_88177)">
      <rect width="24" height="24" fill="#868686" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ClipPaperIcon',
  props: {
    size: {
      type: String,
      default: '24'
    }
  }
}
</script>
