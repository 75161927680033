/* eslint-disable camelcase */
import axios from 'axios'

// const WIDTH_KETERANGAN = 0 // 300
// const WIDTH_STICKY_TITLE_LEFT = 350
// const WIDTH_STICKY_TITLE_RIGHT = 80
// const WIDTH_VOL = 70
// const WIDTH_SAT = 70
// const WIDTH_HARGA = 150
// const WIDTH_MATERIAL = 240
// const WIDTH_UPAH = 240
// // const WIDTH_TOTAL_PRICE = 0
// const WIDTH_TOTAL_PRICE = 200

const WIDTH_KETERANGAN = 0 // 300
const WIDTH_STICKY_TITLE_LEFT = 350
const WIDTH_STICKY_TITLE_RIGHT = 60
const WIDTH_VOL = 50
const WIDTH_SAT = 50
const WIDTH_HARGA = 130
const WIDTH_MATERIAL = 220
const WIDTH_UPAH = 220
// const WIDTH_TOTAL_PRICE = 0
const WIDTH_TOTAL_PRICE = 180

const WIDTH_TABLE =
  WIDTH_STICKY_TITLE_LEFT +
  WIDTH_STICKY_TITLE_RIGHT +
  WIDTH_KETERANGAN +
  WIDTH_VOL +
  WIDTH_SAT +
  WIDTH_HARGA +
  WIDTH_MATERIAL +
  WIDTH_UPAH +
  WIDTH_TOTAL_PRICE

const WIDTH_ROW_TOTAL_PRICE =
  WIDTH_STICKY_TITLE_RIGHT +
  WIDTH_KETERANGAN +
  WIDTH_VOL +
  WIDTH_SAT +
  WIDTH_HARGA +
  WIDTH_MATERIAL +
  WIDTH_UPAH +
  WIDTH_TOTAL_PRICE

const WIDTH_LAYOUT_LEFT = '25%'
const WIDTH_LAYOUT_MIDDLE = '65%'
const WIDTH_LAYOUT_RIGHT = '10%'

const WIDTH_PERCENT_HARGA = '21%'
const WIDTH_PERCENT_VOL = '18%'
const WIDTH_PERCENT_SAT = '19%'
const WIDTH_PERCENT_MATERIAL = '21%'
const WIDTH_PERCENT_UPAH = '21%'
const WIDTH_PERCENT_TOTAL_PRICE = '0%'
// const WIDTH_PERCENT_HARGA = '18%'
// const WIDTH_PERCENT_VOL = '13%'
// const WIDTH_PERCENT_SAT = '17%'
// const WIDTH_PERCENT_MATERIAL = '17%'
// const WIDTH_PERCENT_UPAH = '17%'
// const WIDTH_PERCENT_TOTAL_PRICE = '18%'

const HEIGHT_STICKY_TITLE_SECTION = 50
const HEIGHT_INIT_COLUMN = 50
const HEIGHT_COLUMN_HEADER = 50

const TBL_WIDTH_MATERIAL = 140
const TBL_WIDTH_JASA = 140
const TBL_WIDTH_SATUAN = 140
const TBL_WIDTH_UPDATED_AT = 140
const TBL_WIDTH_ACTION = 80

const TBL_WIDTH_PERCENT_MATERIAL = '23%'
const TBL_WIDTH_PERCENT_JASA = '24%'
const TBL_WIDTH_PERCENT_SATUAN = '24%'
const TBL_WIDTH_PERCENT_UPDATED_AT = '23%'
const TBL_WIDTH_PERCENT_ACTION = '6%'

const TBL_WIDTH_PERCENT_NO = '15%'
const TBL_WIDTH_PERCENT_ID = '15%'
const TBL_WIDTH_PERCENT_NOTE_ITEM = '70%'
const TBL_WIDTH_PERCENT_UNIT_NAME = '15%'

const TBL_HEIGHT_COLUMN_HEADER = 60
const TBL_HEIGHT_COLUMN_BODY = 65

const OVER_HEAD_PERCENT = 0.05

const state = () => ({
  selectedMaterials: [],
  selectedUnit: null,
  staticUnitPrice: {
    WIDTH_KETERANGAN,
    WIDTH_STICKY_TITLE_LEFT,
    WIDTH_STICKY_TITLE_RIGHT,
    WIDTH_VOL,
    WIDTH_SAT,
    WIDTH_HARGA,
    WIDTH_MATERIAL,
    WIDTH_UPAH,
    WIDTH_TABLE,
    WIDTH_TOTAL_PRICE,
    WIDTH_ROW_TOTAL_PRICE,
    OVER_HEAD_PERCENT,

    WIDTH_LAYOUT_LEFT,
    WIDTH_LAYOUT_MIDDLE,
    WIDTH_LAYOUT_RIGHT,

    WIDTH_PERCENT_VOL,
    WIDTH_PERCENT_SAT,
    WIDTH_PERCENT_HARGA,
    WIDTH_PERCENT_MATERIAL,
    WIDTH_PERCENT_UPAH,
    WIDTH_PERCENT_TOTAL_PRICE,

    TBL_WIDTH_MATERIAL,
    TBL_WIDTH_JASA,
    TBL_WIDTH_SATUAN,
    TBL_WIDTH_UPDATED_AT,
    TBL_WIDTH_ACTION,

    TBL_WIDTH_PERCENT_MATERIAL,
    TBL_WIDTH_PERCENT_JASA,
    TBL_WIDTH_PERCENT_SATUAN,
    TBL_WIDTH_PERCENT_UPDATED_AT,
    TBL_WIDTH_PERCENT_ACTION,

    TBL_WIDTH_PERCENT_NO,
    TBL_WIDTH_PERCENT_ID,
    TBL_WIDTH_PERCENT_NOTE_ITEM,
    TBL_WIDTH_PERCENT_UNIT_NAME,

    HEIGHT_STICKY_TITLE_SECTION,
    HEIGHT_INIT_COLUMN,
    HEIGHT_COLUMN_HEADER,
    TBL_HEIGHT_COLUMN_HEADER,
    TBL_HEIGHT_COLUMN_BODY,

    DATA_JOBS: [
      {
        title: 'Material'
      },
      {
        title: 'Alat Kerja'
      },
      {
        title: 'Alat Bantu'
      },
      {
        title: 'Upah Kerja'
      }
    ],
    DATA_HEADER: [
      // {
      //   title: 'KETERANGAN',
      //   width: WIDTH_KETERANGAN + 'px'
      // },
      {
        title: 'HARGA PER SATUAN',
        width: WIDTH_PERCENT_HARGA
        // width: WIDTH_HARGA + 'px'
      },
      {
        title: 'VOLUME',
        width: WIDTH_PERCENT_VOL
        // width: WIDTH_VOL + 'px'
      },
      {
        title: 'SATUAN',
        width: WIDTH_PERCENT_SAT
        // width: WIDTH_SAT + 'px'
      },
      {
        title: 'MATERIAL',
        width: WIDTH_PERCENT_MATERIAL
        // width: WIDTH_MATERIAL + 'px'
      },
      {
        title: 'UPAH',
        width: WIDTH_PERCENT_UPAH
        // width: WIDTH_UPAH + 'px'
      }
      // {
      //   title: 'JUMLAH',
      //   width: WIDTH_PERCENT_TOTAL_PRICE
      // // width: WIDTH_TOTAL_PRICE + 'px'
      // }
    ]
  },
  formDetailUnitPrice: {
    index: null,
    code: null,
    name: null,
    volume: null,
    price: null,
    unit_id: null,
    unit_name: null
  },
  detailUnitPrice: {
    materials: null,
    workTools: null,
    auxiliaryTools: null,
    workerFees: null
  },
  dataAPIUnitPrice: null,
  unitPrice: {
    note_item_working: null,
    unit_price: null,
    service_price: null,
    material_price: null,
    unit: null,
    detail: null,
    max_volume: null,
    session_quota: null
  },
  resetUnitPrice: false
})

const getters = {
  getDataAPIUnitPrice: state => {
    return state.dataAPIUnitPrice
  },
  staticUnitPrice: (state, getters, rootState) => {
    return state.staticUnitPrice
  },
  getSelectedMaterials: (state, getters, rootState) => {
    return state.selectedMaterials
  },
  getDetailUnitPrice: (state, getters, rootState) => {
    return state.detailUnitPrice
  },
  getFormDetailUnitPrice: (state, getters, rootState) => {
    return state.formDetailUnitPrice
  },
  getUnitPrice: state => {
    return state.unitPrice
  },
  getResetUnitPrice: state => {
    return state.resetUnitPrice
  }
}

const actions = {
  getDetailUnitPrice ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${payload.url}${process.env.VUE_APP_UNIT_PRICE}/${payload.id}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getListItemWorkingUnitPrice ({ commit, state }, payload) {
    let temp = ''
    if (payload.vendor_id) temp += `&vendor_id=${payload.vendor_id}`
    if (payload.id) temp += `&id=${payload.id}`
    if (payload.search) temp += `&search=${payload.search}`
    if (payload.is_active) temp += `&is_active=${payload.is_active}`
    if (payload.sort_by) temp += `&sort_by=${payload.sort_by}`
    if (payload.sort_type) temp += `&sort_type=${payload.sort_type}`

    const result = temp ? `?${temp}` : ''

    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_UNIT_PRICE}${result}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  deleteItemWorkingUnitPrice ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`${payload.url}${process.env.VUE_APP_UNIT_PRICE}/${payload.id}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  addEditUnitPrice ({ commit, state }, payload) {
    const {
      vendor_id,
      note_item_working,
      unit_price,
      unit,
      service_price,
      material_price,
      detail,
      max_volume,
      session_quota
    } = payload

    const body = {
      vendor_id,
      note_item_working,
      unit_price,
      unit_id: unit.id,
      max_volume,
      session_quota
    }

    if (service_price || service_price === 0 || service_price === '0') body.service_price = service_price
    if (material_price || material_price === 0 || service_price === '0') body.material_price = material_price
    if (detail) body.detail = detail

    if (payload.note_id) {
      return new Promise((resolve, reject) => {
        axios.put(`${payload.url}${process.env.VUE_APP_UNIT_PRICE}/${payload.note_id}`, body)
          .then((response) => {
            resolve(response)
          }).catch((error) => {
            reject(error)
          })
      })
    }
    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${process.env.VUE_APP_UNIT_PRICE}`, body)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getListHistory ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${payload.url}${process.env.VUE_APP_UNIT_PRICE}/${payload.id}/history`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

const mutations = {
  setDataAPIUnitPrice (state, val) {
    state.dataAPIUnitPrice = val
  },
  setSelectedMaterials (state, payload) {
    state.selectedMaterials = payload
  },
  setDetailUnitPrice (state, payload) {
    state.detailUnitPrice = payload
  },
  setFormDetailUnitPrice (state, payload) {
    if (payload === 'reset') {
      state.formDetailUnitPrice = {
        index: null,
        code: null,
        name: null,
        volume: null,
        price: null,
        unit_id: null,
        unit_name: null
      }
    } else {
      state.formDetailUnitPrice = payload
    }
  },
  setUnitPrice (state, val) {
    const {
      note_item_working = null,
      unit_price = null,
      service_price = null,
      material_price = null,
      unit = null,
      detail = null,
      max_volume = null,
      session_quota = null
    } = val

    function isEmpty (value) {
      return value === 'empty' ? null : value
    }

    function isEmptyPrice (value) {
      if (value === 'empty') return null

      let number = 0
      if (typeof value === 'string') {
        number = parseFloat(value.replace(/\./g, ''))
      } else if (typeof value === 'number') {
        number = value
      }
      return number
    }

    if (note_item_working) { state.unitPrice.note_item_working = isEmpty(note_item_working) }
    if (unit_price) state.unitPrice.unit_price = isEmptyPrice(unit_price)
    if (service_price) { state.unitPrice.service_price = isEmptyPrice(service_price) }
    if (material_price) { state.unitPrice.material_price = isEmptyPrice(material_price) }
    if (unit) state.unitPrice.unit = isEmpty(unit)
    if (detail) state.unitPrice.detail = isEmpty(detail)
    if (max_volume) { state.unitPrice.max_volume = isEmpty(max_volume) }
    if (session_quota) { state.unitPrice.session_quota = isEmpty(session_quota) }
  },
  resetDataUnitPrice (state) {
    state.unitPrice = {
      note_item_working: null,
      unit_price: null,
      service_price: null,
      material_price: null,
      unit: null,
      detail: null,
      max_volume: null,
      session_quota: null
    }
  },
  setResetUnitPrice (state) {
    state.resetUnitPrice = true
    setTimeout(() => {
      state.resetUnitPrice = false
    }, 1000)
  },
  setSelectedUnit (state, payload) {
    state.selectedUnit = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
