<template>
<div :style="{transform: `rotate(${rotate}deg)`}">
  <svg :width="size" :height="size" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_4_95862" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="5" y="2" width="10" height="16">
    <path d="M5.83269 17.5129C6.24102 17.9212 6.89936 17.9212 7.30769 17.5129L14.2327 10.5879C14.5577 10.2629 14.5577 9.73789 14.2327 9.41289L7.30769 2.48789C6.89936 2.07956 6.24102 2.07956 5.83269 2.48789C5.42436 2.89622 5.42436 3.55456 5.83269 3.96289L11.866 10.0046L5.82436 16.0462C5.42436 16.4462 5.42436 17.1129 5.83269 17.5129Z" fill="black"/>
    </mask>
    <g mask="url(#mask0_4_95862)">
    <rect width="20" height="20" :fill="color"/>
    </g>
    <defs>
    <linearGradient id="paint0_linear_4_95862" x1="-8.1504e-08" y1="9.81818" x2="16.1226" y2="17.7248" gradientUnits="userSpaceOnUse">
    <stop :stop-color="color"/>
    <stop offset="0.9999" :stop-color="color"/>
    </linearGradient>
    </defs>
</svg>
</div>
</template>

<script>
export default {
  name: 'ChevronIcon',
  props: {
    color: {
      type: String,
      default: '#D9272D'
    },
    size: {
      type: String,
      default: '20'
    },
    rotate: {
      type: String,
      default: '0'
    }
  }
}
</script>
