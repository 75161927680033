<template>
  <svg :width="size" height="auto" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.3444 3.28285L6.81948 5.75793L11.7685 0.80835M1.42773 3.28285L3.90282 5.75793M8.8524 0.80835L6.9694 2.70827"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'MessageChecklistDouble',
  props: {
    size: {
      type: String,
      default: '18'
    },
    color: {
      type: String,
      default: '#868686'
    }
  }
}
</script>
