import axios from 'axios'

const state = () => ({
  dataJadwalPekerjaan: null
})

const getters = {
  getDataJadwalPekerjaan: (state, getters, rootState) => {
    return state.dataJadwalPekerjaan
  }
}

const actions = {
  getDataPilihTukang ({ commit, state, rootState }, payload) {
    return new Promise((resolve, reject) => {
      const dataFromBS = rootState.project.dataUpdateItemPekerjaanBS

      axios.get(`${payload.url}${process.env.VUE_APP_STEP_V3}/skill?survey_id=${payload.survey_id || dataFromBS.surveyId}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  postDataPilihTukang ({ commit, state, rootState }, payload) {
    const dataFromBS = rootState.project.dataUpdateItemPekerjaanBS

    const req = {
      survey_id: payload.survey_id || dataFromBS.surveyId,
      duration: payload.duration,
      area_components: payload.area_components
    }

    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${process.env.VUE_APP_STEP_V3}/skill`, req)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getDataJadwalPekerjaan ({ commit, state, rootState }, payload) {
    const dataFromBS = rootState.project.dataUpdateItemPekerjaanBS

    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_STEP_V3}/schedule?survey_id=${payload.survey_id || dataFromBS.surveyId}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  postDataJadwalPekerjaan ({ commit, state, rootState }, payload) {
    const dataFromBS = rootState.project.dataUpdateItemPekerjaanBS

    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${process.env.VUE_APP_STEP_V3}/schedule`, {
        survey_id: payload.survey_id || dataFromBS.surveyId,
        duration: payload.duration,
        area_components: payload.area_components
      }).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getDataPreview ({ commit, state, rootState }, payload) {
    const dataFromBS = rootState.project.dataUpdateItemPekerjaanBS

    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_STEP_V3}/overview?survey_id=${payload.survey_id || dataFromBS.surveyId}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  createPenawaran ({ commit, state, rootState }, payload) {
    const dataFromBS = rootState.project.dataUpdateItemPekerjaanBS

    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${process.env.VUE_APP_STEP_V3}/overview/submit`, {
        survey_id: payload.survey_id || dataFromBS.surveyId
      })
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  }
}

const mutations = {
  setDataJadwalPekerjaan (state, val) {
    state.dataJadwalPekerjaan = val
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
