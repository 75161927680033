/* eslint-disable no-unused-vars */
import axios from 'axios'

const state = () => ({
  newChat: null,
  newMember: null,
  setNewChatMultiple: null
})

const getters = {
  getNewChat: (state) => {
    return state.newChat
  },
  getNewMember: (state) => {
    return state.newMember
  },
  getNewChatMultiple: (state) => {
    return state.setNewChatMultiple
  }
}

const actions = {
  getGroupChat ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${payload.url}${process.env.VUE_APP_CHAT_V1}/?limit=1000&page=${payload.page}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.error('Error:', error)
          reject(error)
        })
    })
  },
  readGroupChat ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${payload.url}${process.env.VUE_APP_CHAT_V1}/${payload.topic}/read`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.error('Error:', error)
          reject(error)
        })
    })
  },
  deleteWorkersGroupChat ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${payload.url}${process.env.VUE_APP_CHAT_V1}/${payload.topic}/members/${payload.id}/remove?role=${payload.role}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.error('Error:', error)
          reject(error)
        })
    })
  },
  reInviteWorkersGroupChat ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${payload.url}${process.env.VUE_APP_CHAT_V1}/${payload.topic}/members/${payload.id}/reinvite?role=${payload.role}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.error('Error:', error)
          reject(error)
        })
    })
  },
  postGroupChat ({ commit }, payload) {
    const body = {
      created_at: payload.created_at,
      message: payload.message,
      sender_id: payload.sender_id,
      sender_image: payload.sender_image,
      sender_phone: payload.sender_phone,
      sender_name: payload.sender_name,
      sender_role: payload.sender_role,
      reply_to: payload.reply_to !== null || '' ? payload.reply_to : ''
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`${payload.url}${process.env.VUE_APP_CHAT_V1}/${payload.topic}`, body)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.error('Error:', error)
          reject(error)
        })
    })
  },
  postGroupChatOnlyImage ({ commit }, payload) {
    const formData = new FormData()
    if (Array.isArray(payload.image)) {
      payload.image.forEach(image => {
        formData.append('image', image)
      })
    } else {
      formData.append('image', payload.image)
    }
    formData.append('created_at', payload.created_at)
    formData.append('sender_id', payload.sender_id)
    formData.append('sender_image', payload.sender_image)
    formData.append('sender_phone', payload.sender_phone)
    formData.append('sender_name', payload.sender_name)
    formData.append('sender_role', payload.sender_role)
    if (payload.reply_to) {
      formData.append('reply_to', payload.reply_to)
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`${payload.url}${process.env.VUE_APP_CHAT_V1}/${payload.topic}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.error('Error:', error)
          reject(error)
        })
    })
  },
  postGroupChatMessageImage ({ commit }, payload) {
    const formData = new FormData()
    if (Array.isArray(payload.image)) {
      payload.image.forEach(image => {
        formData.append('image', image)
      })
    } else {
      formData.append('image', payload.image)
    }
    formData.append('message', payload.message)
    formData.append('created_at', payload.created_at)
    formData.append('sender_id', payload.sender_id)
    formData.append('sender_image', payload.sender_image)
    formData.append('sender_phone', payload.sender_phone)
    formData.append('sender_name', payload.sender_name)
    formData.append('sender_role', payload.sender_role)
    if (payload.reply_to) {
      formData.append('reply_to', payload.reply_to)
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`${payload.url}${process.env.VUE_APP_CHAT_V1}/${payload.topic}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.error('Error:', error)
          reject(error)
        })
    })
  }
}

const mutations = {
  setNewChat (state, payload) {
    state.newChat = payload
  },
  setNewMember (state, payload) {
    state.newMember = payload
  },
  setNewChatMultiple (state, payload) {
    state.setNewChatMultiple = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
