import axios from 'axios'
import { defaultStateClient, defaultStateStore } from '../default-state/client'

const state = () => ({
  // ================================== GLOBAL
  detailClient: null,
  detailBranch: null,
  detailPICBranch: null,
  dataMaps: null,

  // ================================== CLIENT
  ...defaultStateClient(),
  listClientType: [],

  // ================================== BRANCH
  payloadAddBranch: {
    clientSelected: {},
    branchName: '',
    dataPic: []
  },
  branchImgFile: null,
  payloadAddPicBranch: {
    clientSelected: {},
    branchSelected: {},
    dataPic: []
  },
  clearDataPayloadBranch: false,
  clearDataPayloadPicBranch: false,
  addBranchFromList: false,
  dataQuotaDivder: null,

  // ================================== STORE
  ...defaultStateStore()

})

const getters = {
  // ================================== GLOBAL
  getDetailClient: (state, getters, rootState) => {
    return state.detailClient
  },
  getDetailBranch: (state, getters, rootState) => {
    return state.detailBranch
  },
  getDetailPICBranch: (state, getters, rootState) => {
    return state.detailPICBranch
  },
  getDataMaps: (state, getters, rootState) => {
    return state.dataMaps
  },

  // ================================== CLIENT
  getPayloadClient: (state, getters, rootState) => {
    return state.payloadClient
  },
  getPayloadDataFormClient: (state, getters, rootState) => {
    return state.payloadClient.dataForm
  },
  getClientTypeSidebar: (state, getters, rootState) => {
    return state.payloadClient.typeSidebar
  },
  getClientShowSidebar: (state, getters, rootState) => {
    return state.payloadClient.showSidebar
  },
  getClientCloseSidebar: (state, getters, rootState) => {
    return state.payloadClient.closeSidebar
  },
  getClientIsEmptyForm: (state, getters, rootState) => {
    return state.payloadClient.isEmptyForm
  },
  getListClientType: (state, getters, rootState) => {
    return state.listClientType
  },

  // ================================== BRANCH
  getPayloadAddBranch: (state, getters, rootState) => {
    return state.payloadAddBranch
  },
  getPayloadAddPicBranch: (state, getters, rootState) => {
    return state.payloadAddPicBranch
  },
  getClearDataPayloadBranch: (state, getters, rootState) => {
    return state.clearDataPayloadBranch
  },
  getClearDataPayloadPicBranch: (state, getters, rootState) => {
    return state.clearDataPayloadPicBranch
  },
  getPayloadClientSelectedBranch: (state, getters, rootState) => {
    return state.payloadAddBranch.clientSelected
  },
  getPayloadClientSelectedPIC: (state, getters, rootState) => {
    return state.payloadAddPicBranch.clientSelected
  },
  getAddBranchFromList: (state, getters, rootState) => {
    return state.addBranchFromList
  },
  getBranchImgFile: (state, getters, rootState) => {
    return state.branchImgFile
  },

  // ================================== STORE
  getPayloadStore: (state, getters, rootState) => {
    return state.payloadStore
  },
  getTypeSidebarStore: (state, getters, rootState) => {
    return state.payloadStore.typeSidebar
  },
  getDataQuotaDivder: (state, getters, rootState) => {
    return state.dataQuotaDivder
  }
}

const actions = {
  getListVendor ({ commit, state }, payload) {
    let concatUrl = ''

    if (payload.keyword) {
      concatUrl = `?search=${payload.keyword}`
    }

    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_VENDORS}${concatUrl}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getListClientType ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_CLIENT_TYPE}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getListBranch ({ commit, state }, payload) {
    let concatUrl = ''

    if (payload.search) {
      concatUrl = `?search=${payload.search}`
    }

    if (payload.area_id) {
      if (concatUrl[0] === '?') {
        concatUrl = `${concatUrl}&area_id=${payload.area_id}`
      } else {
        concatUrl = `?area_id=${payload.area_id}`
      }
    }

    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_VENDORS}/${payload.vendor_id}/detail${concatUrl}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getListStore ({ commit, state }, payload) {
    let concatUrl = ''

    if (payload.search) {
      concatUrl = `?search=${payload.search}`
    }

    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_BRANCH}/${payload.branch_id}/detail${concatUrl}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getDetailStore ({ commit, state }, payload) {
    let concatUrl = ''

    if (payload.search) {
      concatUrl = `?search=${payload.search}`
    }

    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_STORE}/${payload.store_id}/detail${concatUrl}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getListVendorFilter ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_VENDORS}/filter`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getListBranchFilter ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_VENDORS}/filter/${payload.client_id}/branch`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getListSchedule ({ commit, state }, payload) {
    let concatUrl = ''

    if (payload.selected_date) {
      concatUrl = `&selected_date=${payload.selected_date}`
    }
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}v1/schedules?branch_id=${payload.branch_id}&start_date=${payload.start_date}&end_date=${payload.end_date}${concatUrl}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  createClient ({ commit, state }, payload) {
    const data = new FormData()
    const config = {
      Headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    data.append('name', payload.name)
    data.append('company_name', payload.company_name)
    data.append('logo', payload.logo)
    // data.append('project_group_session_ids[]', payload.project_group_session_ids)
    data.append('survey_group_session_ids[]', payload.survey_group_session_ids)
    // data.append('primary_group_session_id', payload.primary_group_session_id)

    payload.site_manager_ids.forEach((id) => {
      data.append('site_manager_ids[]', id)
    })

    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${process.env.VUE_APP_VENDORS}`, data, config
      ).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  updateClient ({ commit, state }, payload) {
    const data = new FormData()
    const config = {
      Headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    data.append('name', payload.name)
    data.append('company_name', payload.company_name)
    data.append('logo', payload.logo)
    // data.append('project_group_session_ids[]', payload.project_group_session_ids)
    data.append('survey_group_session_ids[]', payload.survey_group_session_ids)
    // data.append('primary_group_session_id', payload.primary_group_session_id)

    payload.site_manager_ids.forEach((id) => {
      data.append('site_manager_ids[]', id)
    })

    return new Promise((resolve, reject) => {
      axios.put(`${payload.url}${process.env.VUE_APP_VENDORS}/${payload.client_id}/update`, data, config
      ).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  createBranch ({ commit, state }, payload) {
    let payloadReq = payload.data
    let config = {
      Headers: {
        'Content-Type': 'application/json'
      }
    }

    const data = new FormData()
    if (payload.data.branchSMRC) {
      data.append('vendor_id', payload.data.vendor_id)
      data.append('branch_name', payload.data.branch_name)
      data.append('logo', payload.data.logo)
      data.append('area_id', payload.data.area_id)

      payload.data.users.forEach((user, index) => {
        data.append(`users[${index}][name]`, user.name)
        data.append(`users[${index}][email]`, user.email)
        data.append(`users[${index}][phone]`, user.phone)
      })

      payloadReq = data
      config = {
        Headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    }

    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${process.env.VUE_APP_BRANCH}`, payloadReq, config)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  updateBranch ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      let payloadReq = { name: payload.name }
      let config = {
        Headers: {
          'Content-Type': 'application/json'
        }
      }

      const data = new FormData()
      if (payload.logo) {
        data.append('name', payload.name)
        data.append('logo', payload.logo)

        payloadReq = data
        config = {
          Headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      }

      axios.put(`${payload.url}${process.env.VUE_APP_BRANCH}/${payload.branch_id}/update`, payloadReq, config).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  updatePICBranch ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${payload.url}${process.env.VUE_APP_BRANCH}/pic/${payload.user_id}/update`, payload.data
      ).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  deletePICBranch ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`${payload.url}${process.env.VUE_APP_BRANCH}/${payload.branch_id}/pic/${payload.user_id}/delete`
      ).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  createStore ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${process.env.VUE_APP_STORE}`, payload.data
      ).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  updateStore ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${payload.url}${process.env.VUE_APP_STORE}/${payload.store_id}/update`, payload.data
      ).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  updateAddressStore ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${payload.url}${process.env.VUE_APP_STORE}/${payload.store_id}/address/update`, payload.data
      ).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  updatePICStore ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${payload.url}${process.env.VUE_APP_STORE}/pic/${payload.user_id}/update`, payload.data
      ).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  deletePICStore ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`${payload.url}${process.env.VUE_APP_STORE}/${payload.store_id}/pic/${payload.user_id}/delete`
      ).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  searchAddressGMap ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_MAPS}?keyword=${payload.keyword}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },

  // ================================== Area / Kawasan
  getAreasSMRC ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_VENDORS}/areas?vendor_id=${payload.vendor_id}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },

  // ================================== Unit SMRC
  getUnitsSMRC ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_BRANCH}/units?branch_id=${payload.branch_id}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },

  // ================================== Get Role User SMRC
  getUserRoleSMRC ({ commit, state }, payload) {
    const roleId = payload.role_id
    const vendorId = payload.vendor_id
    const areaId = payload.area_id
    const branchId = payload.branch_id
    const storeId = payload.store_id
    let temp = `role_id=${roleId}&`

    // Head of Engineering (6)      = roleId, vendorId
    // Area Koordinator (5)         = roleId, areaId
    // Chief Engineerging (2)       = roleId, branchId
    // Koordinator Engineering (3)  = roleId, storeId
    // Admin (4)                    = roleId, storeId
    // Property Manager (7)         = roleId, storeId

    switch (roleId) {
      case 6:
      case '6':
        temp += `vendor_id=${vendorId}`
        break

      case 5:
      case '5':
        temp += `area_id=${areaId}`
        break

      case 2:
      case 7:
      case '2':
      case '7':
        temp += `branch_id=${branchId}`
        break

      case 3:
      case 4:
      case '3':
      case '4':
        temp += `store_id=${storeId}`
        break

      default:
        break
    }

    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_USERS}/user-b2b?${temp}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },

  // ================================== Create Head of Engineering, Area Koordinator, Admin
  createRoleSMRC ({ commit, state }, payload) {
    // Head of Engineering (6)      = roleId, vendorId
    // Area Koordinator (5)         = roleId, areaId
    // Koordinator Engineering (3)  = roleId, branchId
    // Admin (4)                    = roleId, branchId

    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${process.env.VUE_APP_USERS}/add-users-branches`, payload.data
      ).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  // ================================== Update Head of Engineering, Area Koordinator, Admin
  updateRoleSMRC ({ commit, state }, payload) {
    // name, email, phone
    return new Promise((resolve, reject) => {
      axios.put(`${payload.url}${process.env.VUE_APP_USERS}/${payload.user_id}/update-user-b2b`, payload.data
      ).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  getHistorySPKSIK ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_BRANCH}/document/${payload.document_id}/histories`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },

  // Get User Role Site Manager (Only)
  getRoleSiteManager ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_USERS}/site-manager`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  }
  // Get User Role Site Manager (Only)
}

const mutations = {
  // ================================== GLOBAL
  setDetailClient (state, payload) {
    state.detailClient = payload.data
  },
  setDetailBranch (state, payload) {
    state.detailBranch = payload.data
  },
  setDetailPICBranch (state, payload) {
    state.detailPICBranch = payload
  },
  setDataMaps (state, payload) {
    state.dataMaps = payload
  },

  // ================================== CLIENT
  setPayloadClient (state, payloadClient) {
    if (typeof payloadClient.showSidebar === 'boolean') {
      state.payloadClient.showSidebar = payloadClient.showSidebar
    }
    if (typeof payloadClient.isEmptyForm === 'boolean') {
      state.payloadClient.isEmptyForm = payloadClient.isEmptyForm
    }
    if (payloadClient.typeSidebar) {
      state.payloadClient.typeSidebar = payloadClient.typeSidebar
    }
    if (payloadClient.showSidebar) {
      state.payloadClient.showSidebar = payloadClient.showSidebar
    }
    if (payloadClient.dataForm) {
      // eslint-disable-next-line camelcase
      // const { company_name, name, logo, imgFile, id, getSelectDataIdProject, getSelectDataIdSurvey, session } = payloadClient.dataForm
      // state.payloadClient.dataForm = { company_name, name, logo, imgFile, id, getSelectDataIdProject, getSelectDataIdSurvey, session }
      state.payloadClient.dataForm = payloadClient.dataForm
    }
    if (payloadClient.siteManagers) {
      state.payloadClient.dataForm = { ...state.payloadClient.dataForm, site_managers: payloadClient.siteManagers }
    }
  },
  setClearPayloadClient (state) {
    state.payloadClient = defaultStateClient().payloadClient
  },
  setListClientType (state, payload) {
    state.listClientType = payload
  },

  // ================================== BRANCH
  setPayloadAddBranch (state, payloadAddBranch) {
    if (payloadAddBranch.type === 'clientSelected') {
      state.payloadAddBranch.clientSelected = payloadAddBranch.clientSelected
      // Reset clear payload branch
      if (state.clearDataPayloadBranch) {
        state.clearDataPayloadBranch = false
      }
    } else if (payloadAddBranch.type === 'branchName') {
      state.payloadAddBranch.branchName = payloadAddBranch.branchName
    } else if (payloadAddBranch.type === 'branchLogo') {
      // ONLY SMRC
      state.branchImgFile = payloadAddBranch.branchImgFile
    } else if (payloadAddBranch.type === 'dataPic') {
      state.payloadAddBranch.dataPic = payloadAddBranch.dataPic
    } else if (payloadAddBranch.type === 'changeClient') {
      state.payloadAddBranch = payloadAddBranch.data
    } else if (payloadAddBranch.type === 'clearPayload') {
      state.payloadAddBranch = payloadAddBranch.data
    }
  },
  setPayloadAddPicBranch (state, payloadAddPicBranch) {
    if (payloadAddPicBranch.type === 'clientSelected') {
      state.payloadAddPicBranch.clientSelected = payloadAddPicBranch.clientSelected
      // Reset clear payload pic
      if (state.clearDataPayloadPicBranch) {
        state.clearDataPayloadPicBranch = false
      }
    } else if (payloadAddPicBranch.type === 'branchSelected') {
      state.payloadAddPicBranch.branchSelected = payloadAddPicBranch.branchSelected
    } else if (payloadAddPicBranch.type === 'dataPic') {
      state.payloadAddPicBranch.dataPic = payloadAddPicBranch.dataPic
    } else if (payloadAddPicBranch.type === 'changeClient') {
      state.payloadAddPicBranch = payloadAddPicBranch.data
    } else if (payloadAddPicBranch.type === 'clearPayload') {
      state.payloadAddPicBranch = payloadAddPicBranch.data
    }
  },
  setClearDataPayloadBranch (state, clearDataPayloadBranch) {
    state.clearDataPayloadBranch = clearDataPayloadBranch
  },
  setClearDataPayloadPicBranch (state, clearDataPayloadPicBranch) {
    state.clearDataPayloadPicBranch = clearDataPayloadPicBranch
  },
  setAddBranchFromList (state, addBranchFromList) {
    state.addBranchFromList = addBranchFromList
  },

  // ================================== STORE
  setPayloadStore (state, payload) {
    if (payload.formStore) {
      state.payloadStore.formStore = payload.formStore
    }
    if (payload.formPIC) {
      state.payloadStore.formPIC = payload.formPIC
    }
    if (payload.selectedStore) {
      state.payloadStore.selectedStore = payload.selectedStore
    }
  },
  setClearPayloadStore (state) {
    state.payloadStore = defaultStateStore().payloadStore
  },
  setTypeSidebarStore (state, payload) {
    state.payloadStore.typeSidebar = payload
  },
  setDataQuotaDivder (state, payload) {
    state.dataQuotaDivder = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
