<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" fill="#FCE5E6" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.6665 16.0128C7.6665 11.6229 11.1748 7.66699 16.0165 7.66699C20.7498 7.66699 24.3332 11.5478 24.3332 15.9878C24.3332 21.1372 20.1332 24.3337 15.9998 24.3337C14.6332 24.3337 13.1165 23.9664 11.8998 23.2487C11.4748 22.99 11.1165 22.798 10.6582 22.9482L8.97484 23.449C8.54984 23.5825 8.1665 23.2487 8.2915 22.798L8.84984 20.9286C8.9415 20.6698 8.92484 20.3944 8.7915 20.1774C8.07484 18.8588 7.6665 17.4149 7.6665 16.0128ZM14.9165 16.0128C14.9165 16.6054 15.3915 17.0811 15.9832 17.0895C16.5748 17.0895 17.0498 16.6054 17.0498 16.0212C17.0498 15.4286 16.5748 14.9529 15.9832 14.9529C15.3998 14.9446 14.9165 15.4286 14.9165 16.0128ZM18.7582 16.0212C18.7582 16.6054 19.2332 17.0895 19.8248 17.0895C20.4165 17.0895 20.8915 16.6054 20.8915 16.0212C20.8915 15.4286 20.4165 14.9529 19.8248 14.9529C19.2332 14.9529 18.7582 15.4286 18.7582 16.0212ZM12.1415 17.0895C11.5582 17.0895 11.0748 16.6054 11.0748 16.0212C11.0748 15.4286 11.5498 14.9529 12.1415 14.9529C12.7332 14.9529 13.2082 15.4286 13.2082 16.0212C13.2082 16.6054 12.7332 17.0811 12.1415 17.0895Z"
      fill="#E10009"
    />
  </svg>
</template>

<script>
export default {
  name: 'GroupChatIcon'
}
</script>
