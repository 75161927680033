<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1_67384)">
      <path
        d="M3.68463 1.9935C5.24213 1.7905 6.82638 1.6885 8.43738 1.6875C10.0476 1.6875 11.6339 1.79175 13.1901 1.9935C14.6316 2.181 15.6584 3.38925 15.7439 4.78875C15.4931 4.7052 15.2333 4.65207 14.9699 4.6305C12.8685 4.45644 10.7563 4.45644 8.65488 4.6305C6.88638 4.7775 5.62488 6.273 5.62488 7.956V11.1705C5.62417 11.7883 5.79421 12.3943 6.11622 12.9216C6.43822 13.4489 6.89968 13.877 7.44963 14.1585L5.45988 16.1475C5.38121 16.2261 5.28103 16.2796 5.17198 16.3012C5.06293 16.3229 4.94991 16.3117 4.84719 16.2692C4.74446 16.2267 4.65665 16.1547 4.59483 16.0622C4.53302 15.9698 4.49998 15.8612 4.49988 15.75V12.7275C4.22778 12.6985 3.95601 12.6665 3.68463 12.6315C2.17863 12.435 1.12488 11.1247 1.12488 9.6465V4.9785C1.12488 3.501 2.17863 2.18925 3.68463 1.9935Z"
        fill="#E10009"
      />
      <path
        d="M11.8124 5.62501C10.7799 5.62501 9.75838 5.66726 8.74788 5.75176C7.59288 5.84776 6.74988 6.82726 6.74988 7.95676V11.1705C6.74988 12.3008 7.59588 13.281 8.75238 13.3755C9.68488 13.452 10.6269 13.4933 11.5784 13.4993L13.6649 15.585C13.7435 15.6636 13.8437 15.7171 13.9528 15.7387C14.0618 15.7604 14.1748 15.7493 14.2776 15.7067C14.3803 15.6642 14.4681 15.5922 14.5299 15.4998C14.5917 15.4073 14.6248 15.2987 14.6249 15.1875V13.395L14.8724 13.3755C16.0289 13.2818 16.8749 12.3008 16.8749 11.1705V7.95601C16.8749 6.82726 16.0311 5.84776 14.8769 5.75101C13.8576 5.66638 12.8352 5.62434 11.8124 5.62501Z"
        fill="#E10009"
      />
      <rect x="11.9999" width="6" height="6" rx="3" fill="#840005" />
    </g>
    <defs>
      <clipPath id="clip0_1_67384">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'NewMessageBadgeIcon',
  props: {
    color: {
      type: String,
      default: 'inherit'
    }
  }
}
</script>
