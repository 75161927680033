<template>
  <ClientSkeleton v-if="isLoading" class="px-6 pb-6 pt-5" />
  <div class="container is-fluid pt-5 pb-6 px-6 min-h-90vh" v-else>
    <div style="min-height: 50px" class="is-flex is-justify-content-space-between mb-5">
      <div class="is-flex is-align-items-center is-clickable" @click="goBack()">
        <ArrowLeftIcon class="mr-3" />
        <p class="averta-bold is-size-5">Daftar Area</p>
      </div>

      <ButtonApp @click="handleAddHeadOfEngineer" height="50px" v-if="tabActive === 'headOfengineer'">
        <p class="averta-bold color-white py-2 px-5">+ Tambah {{ titlePusat }}</p>
      </ButtonApp>
    </div>

    <BannerClientAreas v-if="dataClient" :client="dataClient" :dataClientType="dataClientType" :refreshData="getListArea" />

     <div class="flex-center-vertical mb-5">
        <div class="btn-tabs-2 mr-3" @click="handleClickTab('area')" :class="{'is-active': tabActive === 'area'}">
          <p>Daftar Area</p>
        </div>
        <div class="btn-tabs-2" @click="handleClickTab('headOfengineer')" :class="{'is-active': tabActive === 'headOfengineer'}">
          <p>Daftar Akun {{ titlePusat }}</p>
        </div>
      </div>

      <TabListArea
        v-if="tabActive === 'area'"
        :areas="dataAreas"
        :refreshData="getListArea"
      />

      <TabListHeadOfEngineering
        v-if="tabActive === 'headOfengineer'"
        :data="dataHeadEngineering"
        :refreshData="getListHeadE"
      />

      <SidebarAddPICBranch
        type="head"
        :show="showSidebar"
        :close="handleCloseSidebar"
        @reloadData="getListHeadE($event)"
      />
  </div>
</template>

<script>
// Helpers
import { mapGetters } from 'vuex'
import { alertErrorMessage, titlePusat } from '@/helpers'
import { HEADOFENG } from '@/helpers/constants'

import TabListArea from './components/head-components/TabListArea.vue'
import TabListHeadOfEngineering from './components/head-components/TabListHeadOfEngineering.vue'
import BannerClientAreas from './components/BannerClientAreas.vue'

// Icons
import { ArrowLeftIcon } from '@/components/icons'

// Components
import ClientSkeleton from '@/components/skeleton/ClientSkeleton.vue'
import SidebarAddPICBranch from './components/branch-components/SidebarAddPICBranch.vue'
import ButtonApp from '@/components/button/ButtonApp.vue'

export default {
  name: 'ListArea',
  data () {
    return {
      tabActive: 'area',
      showSidebar: false,
      isLoading: false,
      isLoadingHeadE: false,
      dataClient: null,

      dataAreas: [],
      dataHeadEngineering: []
    }
  },
  components: {
    ArrowLeftIcon,
    ClientSkeleton,
    ButtonApp,
    TabListArea,
    TabListHeadOfEngineering,
    SidebarAddPICBranch,
    BannerClientAreas
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    titlePusat () {
      return titlePusat()
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    handleClickTab (tab) {
      this.tabActive = tab
    },
    handleAddHeadOfEngineer () {
      this.showSidebar = true
      this.showTabButton = true
    },
    handleCloseSidebar () {
      this.showSidebar = false
    },
    getListArea () {
      this.isLoading = true
      const payload = {
        url: this.url,
        vendor_id: this.$route.params.vendorId
      }

      this.$store
        .dispatch('client/getAreasSMRC', payload)
        .then(response => {
          const res = response.data.data
          console.log(res)
          this.dataAreas = res.list
          this.dataClientType = res.clientType

          this.$store.commit('client/setPayloadAddPicBranch', {
            type: 'clientSelected',
            clientSelected: res.vendor
          })
          this.$store.commit('client/setDetailClient', {
            data: res.vendor
          })

          this.dataClient = res.vendor

          this.isLoading = false
        })
        .catch(error => {
          alertErrorMessage(error)
          this.isLoading = false
        })
    },
    getListHeadE () {
      this.isLoadingHeadE = true
      const payload = {
        url: this.url,
        vendor_id: this.$route.params.vendorId,
        role_id: HEADOFENG
      }

      this.$store
        .dispatch('client/getUserRoleSMRC', payload)
        .then(response => {
          const res = response.data.data
          this.dataHeadEngineering = res
          this.isLoadingHeadE = false
        })
        .catch(error => {
          alertErrorMessage(error)
          this.isLoadingHeadE = false
        })
    }
  },
  mounted () {
    this.getListArea()
    this.getListHeadE()
  }
}
</script>
