<template>
  <svg :width="size" height="auto" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.34448 3.28285L3.81957 5.75793L8.76857 0.80835"
      stroke="#868686"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'MessageChecklistOne',
  props: {
    size: {
      type: String,
      default: '15'
    }
  }
}
</script>
