<template>
  <Skeleton v-if="isLoading" class="py-6 mb-6" />
  <div v-else class="w-100 h-100">
    <div class="is-flex is-align-items-center is-justify-content-space-between mb-5">
      <div class="w-100">
        <div class="durasi-wrapper p-4">
          <p class="averta-bold mb-2">Tentukan Durasi Proyek Yang Dibutuhkan <span style="color: red">*</span></p>
          <div class="input-durasi-wrapper is-flex is-align-items-center">
            <input type="number" class="input input-cs" v-model="duration" @input="handleChangDuration" min=0
              oninput="validity.valid||(value='');" />
            <p class="disabled-cs">Hari</p>
          </div>
        </div>
      </div>
    </div>
    <div class="w-100 px-sm-3">
      <div class="columns">
        <div class="column is-3 border-right">
          <span class="averta-bold is-size-5">Pilih Area</span>
          <div class="area-wrapper mt-4">

            <!-- sliceArea1 -->
            <div v-if="sliceArea1.length > 0" class="mb-3 slice-area-wrapper">
              <b-button v-for="(item, index) in sliceArea1" :key="item.area_id" class="mr-3 mb-5 mb-sm-0 btn-tabs"
                :class="{ 'is-active': area === item.area_id }" @click="changeIsActive(item, index)">
                <div class="white-space-normal is-flex is-flex-direction-column is-position-relative">
                  <p class="averta-bold white-space-md-nowrap">{{ item.area_name }}</p>

                  <div v-if="handleIsFillForm(index) && showError" class="alert-icon-wrapper">
                    <AlertIcon />
                  </div>
                </div>
              </b-button>
            </div>
            <!-- sliceArea1 -->

            <!-- sliceArea2 -->
            <div v-if="sliceArea2.length > 0" class="mb-3 slice-area-wrapper">
              <b-button v-for="(item, index) in sliceArea2" :key="item.area_id" class="mr-3 mb-5 mb-sm-0 btn-tabs"
                :class="{ 'is-active': area === item.area_id }" @click="changeIsActive(item, index)">
                <div class="white-space-normal is-flex is-flex-direction-column is-position-relative">
                  <p class="averta-bold white-space-md-nowrap">{{ item.area_name }}</p>

                  <div v-if="handleIsFillForm(index) && showError" class="alert-icon-wrapper">
                    <AlertIcon />
                  </div>
                </div>
              </b-button>
            </div>
            <!-- sliceArea2 -->

            <!-- sliceArea3 -->
            <div v-if="sliceArea3.length > 0" class="mb-3 slice-area-wrapper">
              <b-button v-for="(item, index) in sliceArea3" :key="item.area_id" class="mr-3 mb-5 mb-sm-0 btn-tabs"
                :class="{ 'is-active': area === item.area_id }" @click="changeIsActive(item, index)">
                <div class="white-space-normal is-flex is-flex-direction-column is-position-relative">
                  <p class="averta-bold white-space-md-nowrap">{{ item.area_name }}</p>

                  <div v-if="handleIsFillForm(index) && showError" class="alert-icon-wrapper">
                    <AlertIcon />
                  </div>
                </div>
              </b-button>
            </div>
            <!-- sliceArea3 -->

          </div>
          <p v-if="isKonsultan || dataUpdateItemPekerjaan.isUpdate || isBS || isSM" class="averta-bold color-red is-clickable edit-area-paragraph" @click="showModalEditArea">Edit Area</p>
        </div>
        <div class="column is-9">
          <TabComponentOne
            :dataAreas="areas"
            :dataComponents="sliceComponents"
            :indexArea.sync="indexArea"
            :duration="duration"
            :selectedAreaId.sync="area"
            :showError="showError"
            :selectedComponentId.sync="selectedComponentId"
            :isUseUnitPrice="isUseUnitPrice"
            @handleChangeStep="handleChangeStepOne($event)"
            @handleValidateForm="handleValidateForm($event)"
            @handleIsChange="handleIsChange($event)"
          />
        </div>
      </div>
    </div>

    <!-- Modal Edit Area -->
    <b-modal v-model="modalEditArea" :can-cancel="['escape', 'x', 'outside']" :full-screen="true">
      <div class="modal-edit-area">
        <CheckInPemeriksaan @handleEditArea="handleEditArea($event)" :isEditArea="true" />
      </div>
    </b-modal>
    <!-- Modal Edit Area -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Skeleton from '@/components/skeleton/Skeleton'
import TabComponentOne from './TabComponentOne'
import { AlertIcon } from '@/components/icons'
import CheckInPemeriksaan from '../CheckInPemeriksaan.vue'

export default {
  name: 'PilihTukang',
  props: ['showError'],
  components: {
    Skeleton,
    TabComponentOne,
    AlertIcon,
    CheckInPemeriksaan
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      user: 'auth/user',
      dataPenawaran: 'project/getDataDetailPenawaran',
      dataUpdateItemPekerjaan: 'project/getDataUpdateItemPekerjaanBS'
    }),
    isKonsultan () {
      if (this.user.roles[0].name === 'Konsultan Survey') {
        return true
      } return false
    },
    isBS () {
      if (this.user.roles[0].name === 'Account Manager' || this.user.roles[0].name === 'Business Support') {
        return true
      } return false
    },
    isSM () {
      const roleName = this.user.roles[0].name
      if (roleName === 'Site Manager') {
        return true
      }
      return false
    }
  },
  data () {
    return {
      isLoading: true,
      duration: null,

      oldDuration: null,
      oldArea: null,
      oldAreas: null,

      area: null,
      areas: null,
      indexArea: null,
      validateForm: [],
      validateNoteItem: [],
      modalEditArea: false,

      selectedComponentId: null,
      sliceArea1: [],
      sliceArea2: [],
      sliceArea3: [],
      sliceComponents: {
        sliceComponent1: [],
        sliceComponent2: [],
        sliceComponent3: []
      },
      responsePilihTukang: null,

      isUseUnitPrice: false
    }
  },
  methods: {
    handleChangDuration () {
      const obj = {
        ...this.responsePilihTukang,
        duration: this.duration,
        area_components: this.areas
      }
      this.$store.commit('project/setDataPilihTukang', obj)
    },
    handleChangeStepOne (payload) {
      const obj = {
        ...this.responsePilihTukang,
        duration: this.duration,
        area_components: this.areas
      }
      this.$store.commit('project/setDataPilihTukang', obj)
    },
    handleIsFillForm (index) {
      if (this.validateForm.find(item => item.area === index) || this.validateNoteItem.find(item => item.area === index)) {
        return true
      } else {
        return false
      }
    },
    handleValidateForm (payload) {
      this.validateForm = payload.validateForm
      this.validateNoteItem = payload.validateNoteItem

      if (this.validateForm.length < 1 && this.validateNoteItem.length < 1) {
        this.$emit('isCompleteValidate', true)
      } else {
        this.$emit('isCompleteValidate', false)
      }
    },
    handleIsChange (payload) {
      this.$emit('isChange', true)
    },
    changeIsActive (props, index) {
      this.indexArea = index
      this.area = props.area_id
      this.selectedComponentId = props.components[0].component_id
      this.sliceAreaOrComponent('component', props.components)
    },
    findAreaId (areaId) {
      const findOldArea = this.oldAreas.find((item) => item.area_id === areaId)
      if (findOldArea) return findOldArea
      return false
    },
    findComponentId (components, componentId) {
      const findOldComponents = components.find(
        (item) => item.component_id === componentId
      )
      if (findOldComponents) return findOldComponents
      return false
    },
    findDetailId (details, detailId) {
      const findOldDetails = details.find((item) => item.id === detailId)
      if (findOldDetails) return findOldDetails
      return false
    },
    sliceAreaOrComponent (type, array) {
      const result = []
      if (array.length > 13 && array.length < 19) {
        // Bagi menjadi dua bagian
        const middleIndex = Math.ceil(array.length / 2)
        result.push(array.slice(0, middleIndex))
        result.push(array.slice(middleIndex))
        result.sort((a, b) => b.length - a.length)
        if (type === 'area') {
          this.sliceArea1 = result[0]
          this.sliceArea2 = result[1]
          this.sliceArea3 = []
        } else if (type === 'component') {
          this.sliceComponents = {
            sliceComponent1: result[0],
            sliceComponent2: result[1],
            sliceComponent3: []
          }
        }
      } else if (array.length >= 19) {
        // Bagi menjadi tiga bagian
        const firstSplitIndex = Math.ceil(array.length / 3)
        const secondSplitIndex = 2 * firstSplitIndex
        result.push(array.slice(0, firstSplitIndex))
        result.push(array.slice(firstSplitIndex, secondSplitIndex))
        result.push(array.slice(secondSplitIndex))
        result.sort((a, b) => b.length - a.length)
        if (type === 'area') {
          this.sliceArea1 = result[0]
          this.sliceArea2 = result[1]
          this.sliceArea3 = result[2]
        } else if (type === 'component') {
          this.sliceComponents = {
            sliceComponent1: result[0],
            sliceComponent2: result[1],
            sliceComponent3: result[2]
          }
        }
      } else {
        // Jika kurang dari atau sama dengan 13, tidak perlu membagi
        result.push(array)
        result.sort((a, b) => b.length - a.length)
        if (type === 'area') {
          this.sliceArea1 = result[0]
          this.sliceArea2 = []
          this.sliceArea3 = []
        } else if (type === 'component') {
          this.sliceComponents = {
            sliceComponent1: result[0],
            sliceComponent2: [],
            sliceComponent3: []
          }
        }
      }
    },
    getDataPilihTukang (props = {}) {
      const { isEdit } = props
      this.isLoading = true

      const payload = {
        url: this.url,
        survey_id: this.$route.params.surveyId ? this.$route.params.surveyId : this.dataUpdateItemPekerjaan.surveyId
      }
      this.$store
        .dispatch('projectV2/getDataPilihTukang', payload)
        .then(response => {
          const res = response.data.data
          this.indexArea = 0

          this.area = res.area_components[0].area_id
          this.areas = res.area_components
          this.selectedComponentId = this.areas[0].components[0].component_id

          this.sliceAreaOrComponent('area', this.areas)
          this.sliceAreaOrComponent('component', this.areas[0].components)

          if (isEdit) {
            this.duration = this.oldDuration
          } else {
            this.duration = res.duration
          }
          // Jika durasi terisi, error akan muncul tanpa harus klik tombol Selanjutnya
          if (res.duration && res.duration > 0) {
            this.$emit('isAlreadyFill', true)
          }

          for (const [indexArea, itemArea] of this.areas.entries()) {
            for (const [indexComp, itemComp] of itemArea.components.entries()) {
              for (const [indexDetail, itemDetail] of itemComp.detail_components.entries()) {
                for (const [indexItem, itemItem] of itemDetail.item.entries()) {
                  // Item Pekerjaan terisi sesuai dengan nama detail component
                  if ((!itemItem.note_item_working || itemItem.note_item_working === '') && !itemDetail.detail_component_name.toLowerCase().includes('lain-lain')) {
                    this.areas[indexArea].components[indexComp].detail_components[indexDetail].item[indexItem].note_item_working = itemDetail.detail_component_name
                  }
                  if (itemItem.workers.length < 1) {
                    const newWorkers = {
                      max_day: null,
                      total_worker: null,
                      skill_id: null,
                      skill_image: null,
                      skill_name: null
                    }
                    this.areas[indexArea].components[indexComp].detail_components[indexDetail].item[indexItem].workers.push(newWorkers)
                  }
                }
              }
            }
          }

          if (isEdit) {
            this.areas.forEach((item, indexArea) => {
              const findArea = this.findAreaId(item.area_id)
              if (findArea) {
                item.components.forEach((component, indexComponent) => {
                  const findComponent = this.findComponentId(
                    findArea.components,
                    component.component_id
                  )
                  if (findComponent) {
                    component.detail_components.forEach((detail, indexDetail) => {
                      const findDetail = this.findDetailId(
                        findComponent.detail_components,
                        detail.id
                      )
                      if (findDetail) {
                        this.areas[indexArea].components[indexComponent].detail_components[
                          indexDetail
                        ].item = findDetail.item
                      }
                    })
                  }
                })
              }
            })
          }

          this.isLoading = false
          const obj = {
            ...res,
            duration: this.duration,
            area_components: this.areas
          }
          this.responsePilihTukang = obj
          this.$store.commit('project/setDataPilihTukang', obj)
          this.$store.commit('project/setDataUpdateItemPekerjaanBS', {
            initPilihTukang: obj
          })

          this.isUseUnitPrice = res.is_use_unit_price
        })
        .catch(error => {
          this.isLoading = false
          console.log(error)
          this.$buefy.toast.open({
            message:
              'Gagal memuat data, silahkan coba lagi',
            type: 'is-danger'
          })
        })
    },
    showModalEditArea () {
      this.oldArea = this.area
      this.oldAreas = this.areas
      this.oldDuration = this.duration
      this.modalEditArea = !this.modalEditArea
    },
    handleEditArea (props) {
      this.validateForm = []
      this.modalEditArea = false
      this.isLoading = true
      const payload = {
        url: this.url,
        survey_id: this.$route.params.surveyId,
        latest_step: 1
      }
      // reset data offering while edit area
      if (!this.isSM && (this.dataUpdateItemPekerjaan.isUpdate === false && !this.isBS)) {
        this.$store
          .dispatch('project/cancelOffering', payload)
          .then(() => {
            this.getDataPilihTukang({ isEdit: true })
          })
          .catch(error => {
            this.getDataPilihTukang({ isEdit: true })
            console.log(error)
          })
      } else {
        this.getDataPilihTukang({ isEdit: true })
      }
    }
  },
  mounted () {
    this.getDataPilihTukang()
  }
}
</script>

<style scoped>
.w-100 {
  width: 100%;
}

.h-100 {
  min-height: 85vh;
}

.white-space-normal {
  white-space: normal;
}

.btn-tabs {
  height: auto;
  border-radius: 100px;
  color: #9a9a9a;
  border: 1px solid #e1e1e1;
}

.btn-tabs.is-active {
  background: rgba(49, 133, 252, 0.1);
  color: #3185fc;
  border: 1px solid #3185fc;
}

.border-right {
  border-right: 1px solid #e1e1e1;
}

.durasi-wrapper {
  background: #fafafa;
  border-radius: 12px;
  width: 100%;
}

.disabled-cs {
  color: #868686;
  position: absolute;
  right: 15px;
  z-index: 2;
}

.input-cs {
  border: 1px solid #E1E1E1;
  border-radius: 10px;
  padding-right: 3.5em;
}

.input-durasi-wrapper {
  position: relative;
  width: 20em;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.area-wrapper {
  display: flex;
  flex-direction: column;
}

.alert-icon-wrapper {
  position: absolute;
  right: 10px;
  top: 10px;
}

.modal-edit-area {
  background: white;
  min-height: 100vh;
}

.edit-area-paragraph {
  margin: auto;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .area-wrapper {
    max-height: 65vh;
    overflow: auto;
  }
}

@media only screen and (max-width: 768px) {
  .area-wrapper {
    white-space: nowrap;
    overflow-x: scroll !important;
  }

  .area-wrapper {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
  }

  .area-wrapper::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }

  .white-space-md-nowrap {
    white-space: nowrap !important;
  }

  .border-right {
    border: none;
  }
}
@media only screen and (max-width: 600px) {
  .alert-icon-wrapper {
    position: relative !important;
    top: 0;
    right: 0;
    margin-left: 0.5rem;
  }

  .input-durasi-wrapper {
    width: 100%;
  }

  .px-sm-3 {
    padding: 0 20px;
  }

  .durasi-wrapper {
    padding-top: 18px !important;
    padding-bottom: 24px !important;
    border-radius: 0 !important;
  }

  .w-sm-100 {
    width: 100%
  }

  .edit-area-paragraph {
    margin-left: 10 px;
    text-align: left;
  }
}
</style>
