<template>
  <div
    class="py-6 px-0 container min-height wrapper-step c-w-sm-100 averta-reguler"
    :class="{ 'is-clipped': currentStep !== 5 }"
    :style="{ marginBottom: isMobile && isChangeData ? '0' : isChangeData ? '100px' : '0px' }"
  >
    <div
      style="min-height: 75px !important"
      v-if="isMobile"
      class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center px-3"
    >
      <div class="is-flex is-flex-direction-row is-align-items-center gap-2" @click="handleBack()">
        <ArrowLeftIcon class="is-clickable" />
        <p class="averta-bold label-16 is-clickable">
          {{ dataUpdateItemPekerjaan.isUpdate ? 'Update Item Pekerjaan' : 'Buat Penawaran Baru' }}
        </p>
      </div>
      <div
        v-if="isChangeData && currentStep !== 2 && currentStep !== 5"
        class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center gap-2"
      >
        <ButtonApp
          height="40px"
          width="100%"
          :isSecondary="true"
          @click="handleAlertResetCurrentStep(currentStep)"
          v-if="isMobile"
        >
          <UndoIcon />
        </ButtonApp>
        <ButtonApp height="40px" width="100%" @click="handleSaveData(currentStep)" v-if="isMobile">
          <p class="averta-bold">Simpan</p>
        </ButtonApp>
      </div>
    </div>
    <div class="notification is-light is-warning" v-if="message_alfa !== ''">
      <button class="delete" @click="closeNotification"></button>
      <div class="content">
        <p class="averta-bold label-16 has-text-black">{{ message_alfa.message }}</p>
        <ul class="mt-2" v-if="message_alfa.data.different_day_estimation !== null">
          <li>
            <span class="label-16 averta-bold has-text-black">Durasi Proyek</span>
            <ul>
              <li class="label-14">
                <span class="averta-reguler has-text-black">Alfamart: </span>
                <span class="averta-reguler has-text-danger">
                  {{ message_alfa.data.different_day_estimation.alfamart }} Hari
                </span>
              </li>
              <li class="label-14">
                <span class="averta-reguler has-text-black">Kanggo: </span>
                <span class="averta-reguler has-text-danger">
                  {{ message_alfa.data.different_day_estimation.kanggo }} Hari
                </span>
              </li>
            </ul>
          </li>
        </ul>
        <ul class="mt-2" v-if="message_alfa.data.different_volume.length > 0">
          <li>
            <span class="label-16 averta-bold has-text-black">Volume</span>
            <ul v-for="(items, index) in message_alfa.data.different_volume" :key="index">
              <li class="label-14">
                <span class="averta-reguler has-text-black">Item Pekerjaan:</span>
                <span class="averta-reguler has-text-danger">
                  {{ items.item_name }}
                </span>
              </li>
              <li class="label-14">
                <span class="averta-reguler has-text-black">Alfamart:</span>
                <span class="averta-reguler has-text-danger">
                  {{ items.alfamart }}
                </span>
              </li>
              <li class="label-14">
                <span class="averta-reguler has-text-black">Kanggo:</span>
                <span class="averta-reguler has-text-danger">
                  {{ items.kanggo }}
                </span>
              </li>
            </ul>
          </li>
        </ul>
        <ul class="mt-2" v-if="message_alfa.data.item_not_in_alfamart.length > 0">
          <li>
            <span class="label-16 averta-bold has-text-black">Item yang tidak ada di Alfamart</span>
            <ul v-for="(el, idx) in message_alfa.data.item_not_in_alfamart" :key="idx">
              <li class="label-14">
                <span class="averta-reguler has-text-black">Nama Area: </span>
                <span class="averta-reguler has-text-danger">
                  {{ el.area_name }}
                </span>
              </li>
              <li class="label-14">
                <span class="averta-reguler has-text-black">Nama Pekerjaan: </span>
                <span class="averta-reguler has-text-danger">
                  {{ el.nama_pekerjaan }}
                </span>
              </li>
              <li class="label-14">
                <span class="averta-reguler has-text-black">Volume: </span>
                <span class="averta-reguler has-text-danger">
                  {{ el.volume }}
                </span>
              </li>
            </ul>
          </li>
        </ul>
        <ul class="mt-2" v-if="message_alfa.data.item_not_in_kanggo.length > 0">
          <li>
            <span class="label-16 averta-bold has-text-black">Item yang tidak ada di Kanggo</span>
            <ul v-for="(data, indx) in message_alfa.data.item_not_in_kanggo" :key="indx">
              <li class="label-14">
                <span class="averta-reguler has-text-black">Nama Area: </span>
                <span class="averta-reguler has-text-danger">
                  {{ data.area_name }}
                </span>
              </li>
              <li class="label-14">
                <span class="averta-reguler has-text-black">Nama Pekerjaan: </span>
                <span class="averta-reguler has-text-danger">
                  {{ data.nama_pekerjaan }}
                </span>
              </li>
              <li class="label-14">
                <span class="averta-reguler has-text-black">Volume: </span>
                <span class="averta-reguler has-text-danger">
                  {{ data.volume }}
                </span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div
      class="is-flex is-align-items-center is-justify-content-space-between mb-5 mt-3 wrapper-button-steps thin-scroll"
    >
      <div class="is-flex is-align-items-center flex-1 wrapper-kembali">
        <ArrowLeftIcon class="mr-3 is-clickable" />
        <p class="averta-bold is-size-5 is-clickable" @click="handleBack()">
          Kembali
          {{ user.roles[0].name === 'Site Manager' ? 'ke Penawaran' : '' }}
        </p>
      </div>

      <div class="stepper-wrapper flex-2" v-if="currentStep !== 5">
        <div
          v-for="item in listStep"
          :key="item.id"
          class="stepper-item"
          :class="[
            item.class,
            { completed: currentRole === 'Site Manager' && currentRole === 'Account Manager' }
          ]"
        >
          <div
            class="is-flex step-wrapper py-2 px-3 mx-3 is-justify-content-center no-wrap"
            :class="[
              { active: currentStep === item.id },
              {
                'active-sm':
                  currentRole === 'Site Manager' &&
                  currentRole === 'Account Manager' &&
                  (currentStep === item.id || item.class)
              }
            ]"
          >
            <CheckIcon
              v-if="
                item.class || (currentRole === 'Site Manager' && currentRole === 'Account Manager')
              "
              :color="
                (item.class || currentStep === item.id) &&
                currentRole === 'Site Manager' &&
                currentRole === 'Account Manager'
                  ? '#fff'
                  : '#10BD41'
              "
            />
            <p v-else class="averta-bold">{{ item.id }}</p>
            <p
              class="ml-2"
              :class="[
                { 'averta-bold': currentStep === item.id || item.class },
                {
                  'averta-bold has-text-white':
                    currentRole === 'Site Manager' &&
                    currentRole === 'Account Manager' &&
                    (currentStep === item.id || item.class)
                }
              ]"
            >
              {{ item.name }}
            </p>
          </div>
        </div>
      </div>

      <div class="is-flex is-align-items-center flex-1 is-justify-content-flex-end wrapper-steps">
        <!-- <span v-if="currentRole === 'Site Manager' && currentRole === 'Account Manager'  &&
          isChangeData" @click="handleResetData(currentStep)" class="averta-bold text-gray">Reset Data</span> -->
        <span
          v-if="currentStep > 1"
          @click="previousStep(currentStep)"
          class="is-clickable text-primary-gradient averta-bold mr-4"
          >Sebelumnya</span
        >
        <b-button
          @click="nextStep(currentStep)"
          class="averta-bold px-5 is-flex is-justify-content-center mr-5"
          :class="
            isChangeData || isLoadingGetData || loadingSpinner
              ? 'btn-disabled'
              : checkBtnActive
              ? 'btn-active'
              : 'btn-disabled'
          "
          :style="{
            'pointer-events': isChangeData || isLoadingGetData || loadingSpinner ? 'none' : 'auto'
          }"
        >
          <span v-if="currentStep !== 5">Selanjutnya</span>
          <span v-else class="is-flex is-align-items-center"
            >{{
              user.roles[0].name === 'Konsultan Survey'
                ? 'Simpan & Lanjutkan ke Site Manager'
                : user.roles[0].name === 'Site Manager'
                ? 'Simpan & Lanjutkan ke Purchasing'
                : 'Simpan'
            }}
            <ArrowRightIconLong class="ml-2" />
          </span>
        </b-button>
      </div>
    </div>

    <p class="averta-bold is-size-5 mb-5 text-penawaran" v-if="currentStep !== 5">
      {{
        dataUpdateItemPekerjaan.isUpdate
          ? 'Update Item Pekerjaan'
          : currentRole === 'Site Manager' && currentRole === 'Account Manager'
          ? 'Sesuaikan Penawaran Dari Konsultan Survey'
          : 'Buat Penawaran Baru'
      }}
    </p>
    <PilihTukang
      v-if="currentStep === 1"
      @isCompleteValidate="handleCompletePilihTukang($event)"
      @isAlreadyFill="handleAlreadyFill(1)"
      :showError="showErrorPilihTukang"
      @isChange="handleIsChange($event)"
      ref="formPilihTukang"
    />
    <FotoSurvey
      v-if="currentStep === 2"
      @isCompleteValidate="handleCompleteFotoSurvey($event)"
      :showError="showErrorFotoSurvey"
      @isChange="handleIsChange($event)"
      ref="formFotoSurvey"
    />
    <JadwalPekerjaan
      v-if="currentStep === 3"
      :messageJadwalPekerjaan="messageJadwalPekerjaan"
      @isCompleteValidate="handleCompleteJadwalPekerjaan($event)"
      :showError="showErrorJadwalPekerjaan"
      @isChange="handleIsChange($event)"
      ref="formJadwalPekerjaan"
    />
    <LengkapiMaterial
      v-if="currentStep === 4"
      @isCompleteValidate="handleCompleteLengkapiMaterial($event)"
      :showError="showErrorLengkapiMaterial"
      @isChange="handleIsChange($event)"
      @sendDataAutoSaveToParent="saveDataLengkapiMaterial($event)"
      ref="formLengkapiMaterial"
    />
    <PreviewOffering v-if="currentStep === 5" :currentRole="currentRole" />

    <div class="btn-bottom">
      <div class="is-flex is-justify-content-space-between w-100">
        <span
          v-if="currentStep > 1"
          @click="previousStep(currentStep)"
          class="is-clickable text-primary-gradient averta-bold mr-4 flex-1 has-text-centered is-align-self-center"
          >Sebelumnya</span
        >

        <b-button
          @click="nextStep(currentStep)"
          class="btn-disabled averta-bold px-5 is-flex is-justify-content-center is-align-items-center flex-1"
          :class="{ 'btn-active': checkBtnActive }"
        >
          <div class="is-flex is-align-items-center py-1">
            <span class="mr-3">Selanjutnya</span>
            <ArrowRightIconLong />
          </div>
        </b-button>
      </div>
    </div>

    <!-- Floatiing Button Save -->
    <div
      v-if="!isMobile"
      class="floating-button-save"
      :class="{ show: isChangeData && currentStep !== 2 && currentStep !== 5 }"
    >
      <div
        class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center gap-4"
      >
        <EditStepIcon />
        <div
          class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start"
        >
          <p class="averta-bold label-18">Simpan Perubahan?</p>
          <p class="averta-regular label-14">Terdapat data yang diubah, pastikan kamu simpan</p>
        </div>
      </div>

      <div
        class="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center gap-4"
      >
        <ButtonApp
          height="40px"
          width="200px"
          :isSecondary="true"
          @click="handleAlertResetCurrentStep(currentStep)"
          v-if="!isMobile"
        >
          <p class="averta-bold">Tidak Jadi</p>
        </ButtonApp>

        <ButtonApp
          height="40px"
          width="200px"
          @click="handleSaveData(currentStep)"
          v-if="!isMobile"
        >
          <p class="averta-bold">Simpan Perubahan</p>
        </ButtonApp>
      </div>
    </div>
    <!-- Floatiing Button Save -->

    <b-loading :is-full-page="true" v-model="loadingSpinner" :can-cancel="false">
      <SpinnerIcon />
    </b-loading>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { alertErrorMessage } from '@/helpers'
import {
  ArrowLeftIcon,
  ArrowRightIconLong,
  CheckIcon,
  SpinnerIcon,
  EditStepIcon,
  UndoIcon
} from '@/components/icons'
import PilihTukang from './create-offering/PilihTukang.vue'
// import FotoSurvey from './create-offering/FotoSurvey.vue'
// import JadwalPekerjaan from './create-offering/JadwalPekerjaan.vue'
// import LengkapiMaterial from './create-offering/LengkapiMaterial.vue'
import PreviewOffering from './create-offering/PreviewOffering.vue'
import confirmOfferingImage from '@/assets/img/delete-area-component.png'
import successCreateImage from '../../../assets/img/survey-selesai.png'
import discardImage from '@/assets/img/discard-data.png'
import alertProject from '@/assets/img/IconAlertProject.png'
import ButtonApp from '@/components/button/ButtonApp.vue'

export default {
  name: 'NewOffering',
  components: {
    ArrowLeftIcon,
    ArrowRightIconLong,
    CheckIcon,
    SpinnerIcon,
    PilihTukang,
    FotoSurvey: () => import('./create-offering/FotoSurvey.vue'),
    JadwalPekerjaan: () => import('./create-offering/JadwalPekerjaan.vue'),
    LengkapiMaterial: () => import('./create-offering/LengkapiMaterial.vue'),
    PreviewOffering,
    ButtonApp,
    EditStepIcon,
    UndoIcon
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      dataPilihTukang: 'project/getDataPilihTukang',
      dataFotoSurvey: 'project/getDataFotoSurvey',
      dataJadwalPekerjaan: 'project/getDataJadwalPekerjaan',
      dataLengkapiMaterial: 'project/getDataLengkapiMaterial',
      dataUpdateItemPekerjaan: 'project/getDataUpdateItemPekerjaanBS',
      user: 'auth/user'
    }),
    checkBtnActive () {
      this.saveStep()
      if (this.currentStep === 1) {
        return this.completePilihTukang
      } else if (this.currentStep === 2) {
        return this.completeFotoSurvey
      } else if (this.currentStep === 3) {
        return this.completeJadwalPekerjaan
      } else if (this.currentStep === 4) {
        return this.completeLengkapiMaterial
      } else {
        return true
      }
    },
    isChangeData () {
      if (this.currentStep === 1) {
        return this.isChangePilihTukang
      } else if (this.currentStep === 2) {
        return this.isChangeFotoSurvey
      } else if (this.currentStep === 3) {
        return this.isChangeJadwalPekerjaan
      } else {
        return this.isChangeLengkapiMaterial
      }
    },
    currentPath () {
      const path = this.$route.path.split('/')
      const lastElement = path[path.length - 1]

      return lastElement
    },
    isBS () {
      return this.user.roles[0].name === 'Account Manager'
    },
    isSM () {
      return this.user.roles[0].name === 'Site Manager'
    },
    isMobile () {
      if (window.innerWidth <= 768) {
        return true
      }
      return false
    }
  },
  data () {
    return {
      message_alfa: '',
      messageJadwalPekerjaan: '',
      isBack: false,
      isFinish: false,
      loadingSpinner: false,
      listStep: [
        {
          id: 1,
          name: 'Pilih Tukang',
          class: ''
        },
        {
          id: 2,
          name: 'Foto Survey Konsultan',
          class: ''
        },
        {
          id: 3,
          name: 'Jadwal Pekerjaan',
          class: ''
        },
        {
          id: 4,
          name: 'Lengkapi Material',
          class: ''
        }
      ],
      currentStep: this.getDataCountLastesStep || 1,
      currentRole: '',

      isChangePilihTukang: false,
      isChangeFotoSurvey: false,
      isChangeJadwalPekerjaan: false,
      isChangeLengkapiMaterial: false,

      completePilihTukang: false,
      completeFotoSurvey: false,
      completeJadwalPekerjaan: false,
      completeLengkapiMaterial: false,

      showErrorPilihTukang: false,
      showErrorFotoSurvey: false,
      showErrorJadwalPekerjaan: false,
      showErrorLengkapiMaterial: false,
      companyId: process.env.VUE_APP_COMPANY_ID,
      isLeaving: false,
      showModalOnUnload: false,
      getDataCountLastesStep: '',
      isLoadingGetData: false
    }
  },
  methods: {
    updateClass (props, action) {
      if (!props) {
        return
      }

      const objIndex = this.listStep.findIndex((obj) => obj.id === props.id)
      const tempClass = this.listStep[objIndex].class

      if (action === 'next') {
        this.listStep[objIndex].class = tempClass.concat('completed')
      } else {
        this.listStep[objIndex].class = tempClass.replace('completed', '')
      }
    },
    checkProperties (obj) {
      for (var key in obj) {
        if (obj[key] !== null && obj[key] !== '') {
          return false
        } else {
          return { error: true, key }
        }
      }
      return true
    },
    async previousStep (currentStep) {
      if (currentStep < 5) {
        if (currentStep === 2) {
          this.currentStep -= 1
          this.updateClass(this.listStep[this.currentStep - 1], 'prev')
          this.isChangeFotoSurvey = false
          return
        }
        if (currentStep === 3 || currentStep === 4) {
          this.completeJadwalPekerjaan = true
          this.completeLengkapiMaterial = true
          this.isChangeJadwalPekerjaan = false
          this.isChangeLengkapiMaterial = false
        }
        // const res = await this.handlePostData(currentStep)
        // if (!res) {
        //   if (currentStep === 1) {
        //     this.showErrorPilihTukang = true
        //   } else if (currentStep === 2) {
        //     this.showErrorFotoSurvey = true
        //   } else if (currentStep === 3) {
        //     this.showErrorJadwalPekerjaan = true
        //   } else if (currentStep === 4) {
        //     // this.showErrorLengkapiMaterial = true
        //     this.currentStep -= 1
        //     this.updateClass(this.listStep[this.currentStep - 1], 'prev')
        //   }
        //   return
        // }
        if (currentStep > 1) {
          this.isChangePilihTukang = false
          this.currentStep -= 1
          this.updateClass(this.listStep[this.currentStep - 1], 'prev')
        }
        this.saveStep()
      } else if (currentStep === 5) {
        if (currentStep > 1) {
          this.currentStep -= 1
          this.updateClass(this.listStep[this.currentStep - 1], 'prev')
        }
      }
    },
    async nextStep (currentStep) {
      if (this.loadingSpinner) return
      this.loadingSpinner = true
      this.saveStep()

      if (currentStep < 5) {
        const res = await this.handlePostData(currentStep)
        if (!res) {
          if (currentStep === 1) {
            this.showErrorPilihTukang = true
          } else if (currentStep === 2) {
            this.showErrorFotoSurvey = true
          } else if (currentStep === 3) {
            this.showErrorJadwalPekerjaan = true
          } else if (currentStep === 4) {
            this.showErrorLengkapiMaterial = true
            localStorage.removeItem(`stepData-${this.$route.params.surveyId}`)
          }
          this.loadingSpinner = false
          return
        }

        this.isChangePilihTukang = false
        this.isChangeFotoSurvey = false
        this.isChangeJadwalPekerjaan = false
        this.isChangeLengkapiMaterial = false
        this.currentStep += 1
        this.updateClass(this.listStep[this.currentStep - 2], 'next')
      } else if (currentStep === 5) {
        this.confirmOffering()
      }
      this.loadingSpinner = false

      setTimeout(() => {
        this.isLoadingGetData = false
      }, 2000)
      this.isLoadingGetData = true
    },
    async handlePostData (value) {
      switch (value) {
        case 1:
          return await this.postDataPilihTukang()
        case 2:
          return await this.postDataFotoSurvey()
        case 3:
          return await this.postDataJadwalPekerjaan()
        case 4:
          return await this.postDataLengkapiMaterial()
        default:
          break
      }
    },
    async handleSaveData (value) {
      switch (value) {
        case 1:
          this.isChangePilihTukang = false
          return await this.saveDataPilihTukang()
        case 2:
          return await this.postDataFotoSurvey()
        case 3:
          this.isChangeJadwalPekerjaan = false
          return await this.saveDataJadwalPekerjaan()
        case 4:
          this.isChangeLengkapiMaterial = false
          return await this.saveDataLengkapiMaterial()
        default:
          break
      }
    },
    surveyDetail () {
      if (this.isSM) {
        this.$router.push(`/sm/project/survey/${this.$route.params.surveyId}/detail`)
      } else if (this.isBS) {
        this.$emit('showHideSidebar', 'cancel-item')
      } else {
        this.$router.push(`/project/survey/${this.$route.params.surveyId}/detail`)
      }
    },
    handleCompletePilihTukang (payload) {
      this.completePilihTukang = payload
    },
    handleCompleteFotoSurvey (payload) {
      this.completeFotoSurvey = payload
    },
    handleCompleteJadwalPekerjaan (payload) {
      this.completeJadwalPekerjaan = payload
    },
    handleCompleteLengkapiMaterial (payload) {
      this.completeLengkapiMaterial = payload
    },
    async postDataPilihTukang () {
      if (!this.completePilihTukang) {
        return false
      }
      const duration = this.dataPilihTukang.duration
      if (!duration || duration < 1) {
        this.$buefy.toast.open({
          message: 'Durasi Proyek tidak boleh kosong',
          type: 'is-danger'
        })
        return false
      }

      await this.dataPilihTukang.area_components.map((itemArea, indexArea) => {
        itemArea.components.map((itemComp, indexComp) => {
          itemComp.detail_components.map((itemDetail, indexDetail) => {
            itemDetail.item.map((itemItem, indexItem) => {
              if (itemItem.is_from_bs) {
                delete itemItem.is_from_bs
              }
            })
          })
        })
      })

      let value
      const payload = {
        url: this.url,
        survey_id: this.$route.params.surveyId,
        area_components: this.dataPilihTukang.area_components,
        duration: parseInt(duration)
      }

      const payloadStep = {
        url: this.url,
        survey_id: this.$route.params.surveyId,
        latest_step: this.currentStep
      }

      await this.$store
        .dispatch('projectV2/postDataPilihTukang', payload)
        .then((response) => {
          setTimeout(() => {
            this.$store('projectV2/updateLatestStep', payloadStep)
          }, 25)
          value = true
        })
        .catch((error) => {
          if (error.response.data.code === 3) {
            this.message_alfa = error.response.data
          } else {
            this.$buefy.toast.open({
              message: error.response.data.message,
              duration: 5000,
              type: 'is-danger'
            })
          }

          value = false
        })
      return value
    },
    async saveDataPilihTukang () {
      const duration = this.dataPilihTukang.duration

      await this.dataPilihTukang.area_components.map((itemArea, indexArea) => {
        itemArea.components.map((itemComp, indexComp) => {
          itemComp.detail_components.map((itemDetail, indexDetail) => {
            itemDetail.item.map((itemItem, indexItem) => {
              if (itemItem.is_from_bs) {
                delete itemItem.is_from_bs
              }
            })
          })
        })
      })

      let value

      const payload = {
        url: this.url,
        survey_id: this.$route.params.surveyId,
        area_components: this.dataPilihTukang.area_components,
        duration: parseInt(duration)
      }

      try {
        await this.$store.dispatch('projectV2/saveDataPilihTukang', payload)
        this.$buefy.toast.open({
          message: 'Data berhasil diubah',
          duration: 5000
        })

        return true
      } catch (error) {
        if (error.response && error.response.data.code === 3) {
          this.message_alfa = error.response.data
        } else {
          this.$buefy.toast.open({
            message: error.response.data.message,
            duration: 5000,
            type: 'is-danger'
          })
        }

        value = false
      }
      return value
    },
    async postDataFotoSurvey () {
      const payload = {
        url: this.url,
        survey_id: this.$route.params.surveyId,
        latest_step: this.currentStep
      }
      this.$store
        .dispatch('projectV2/updateLatestStep', payload)
        .then(() => {
          this.$emit('cancel-item')
        })
        .catch((error) => {
          console.log(error)
        })

      if (!this.completeFotoSurvey) {
        return false
      }

      if (!this.isChangeFotoSurvey) {
        return true
      }

      return true

      // const obj = {}
      // await this.dataFotoSurvey.map((itemArea, indexArea) => {
      //   itemArea.components.map((itemComp, indexComp) => {
      //     itemComp.detail_components.map((itemDetail, indexDetail) => {
      //       itemDetail.item.map((itemItem, indexItem) => {
      //         obj[`photo_area_${itemArea.area_id}_component_${itemComp.component_id}_detail_${itemDetail.id}_item_${indexItem + 1}`] = itemItem[`photo_area_${itemArea.area_id}_component_${itemComp.component_id}_detail_${itemDetail.id}_item_${indexItem + 1}`]
      //       })
      //     })
      //   })
      // })

      // // eslint-disable-next-line no-unused-vars
      // let value
      // const payload = {
      //   url: this.url,
      //   photo: obj,
      //   survey_id: this.$route.params.surveyId
      // }

      // await this.$store
      //   .dispatch('project/postDataFotoSurvey', payload)
      //   .then((response) => {
      //     value = true
      //   })
      //   .catch((error) => {
      //     value = false
      //     console.log(error)
      //     this.$buefy.toast.open({
      //       message: 'Gagal post data Foto Survey Konsultan, silahkan coba lagi',
      //       type: 'is-danger'
      //     })
      //   })

      // return value
    },
    async postDataJadwalPekerjaan () {
      if (!this.completeJadwalPekerjaan) {
        return false
      }

      await this.dataJadwalPekerjaan.map((itemArea, indexArea) => {
        itemArea.components.map((itemComp, indexComp) => {
          itemComp.workers.map((itemWorkers, indexWorkers) => {
            if (indexWorkers > 0 && !itemWorkers.item_id) {
              delete itemWorkers.day
            }
          })
        })
      })

      let value
      const payload = {
        url: this.url,
        survey_id: this.$route.params.surveyId,
        area_components: this.dataJadwalPekerjaan
      }

      const payloadStep = {
        url: this.url,
        survey_id: this.$route.params.surveyId,
        latest_step: this.currentStep
      }

      await this.$store
        .dispatch('projectV2/postDataJadwalPekerjaan', payload)
        .then((response) => {
          this.$store.dispatch('projectV2/updateLatestStep', payloadStep)
          value = true
          this.messageJadwalPekerjaan = ''
        })
        .catch((error) => {
          alertErrorMessage(error)
          value = false
          console.log(error)
          this.messageJadwalPekerjaan = error.response.data.message
          this.$buefy.toast.open({
            message:
              error.response.data.message || 'Gagal post data Jadwal Pekerjaan, silahkan coba lagi',
            duration: 5000,
            type: 'is-danger'
          })
        })
      return value
    },

    async saveDataJadwalPekerjaan () {
      await this.dataJadwalPekerjaan.map((itemArea, indexArea) => {
        itemArea.components.map((itemComp, indexComp) => {
          itemComp.workers.map((itemWorkers, indexWorkers) => {
            if (indexWorkers > 0 && !itemWorkers.item_id) {
              delete itemWorkers.day
            }
          })
        })
      })

      let value
      const payload = {
        url: this.url,
        survey_id: this.$route.params.surveyId,
        area_components: this.dataJadwalPekerjaan
      }

      try {
        await this.$store.dispatch('projectV2/postDataJadwalPekerjaan', payload)
        this.$buefy.toast.open({
          message: 'Data berhasil diubah',
          duration: 5000
        })

        return true
      } catch (error) {
        if (error.response && error.response.data.code === 3) {
          this.message_alfa = error.response.data
        } else {
          this.$buefy.toast.open({
            message: error.response.data.message,
            duration: 5000,
            type: 'is-danger'
          })
        }

        value = false
      }
      return value
    },
    async postDataLengkapiMaterial () {
      if (!this.completeLengkapiMaterial || this.dataLengkapiMaterial.length < 0) {
        return false
      }
      await this.dataLengkapiMaterial.map((itemArea, indexArea) => {
        itemArea.components.map((itemComp, indexComp) => {
          itemComp.detail_components.map((itemDetail, indexDetail) => {
            itemDetail.item.map((itemItem, indexItem) => {
              this.dataLengkapiMaterial[indexArea].components[indexComp].detail_components[
                indexDetail
              ].item[indexItem] = {
                note_item_working: itemItem.note_item_working,
                volume: itemItem.volume,
                unit_id: itemItem.unit_id,
                unit_name: itemItem.unit_name,
                max: itemItem.max,
                total_worker: itemItem.total_worker,
                materials: itemItem.materials || [],
                pictures: itemItem.pictures,
                item_id: itemItem.item_id,
                step_skill_item_id: itemItem.step_skill_item_id
              }
            })
          })
        })
      })

      let value
      const payload = {
        url: this.url,
        survey_id: this.$route.params.surveyId,
        area_components: this.dataLengkapiMaterial
      }

      const payloadStep = {
        url: this.url,
        survey_id: this.$route.params.surveyId,
        latest_step: this.currentStep
      }

      await this.$store
        .dispatch('projectV2/postDataLengkapiMaterial', payload)
        .then((response) => {
          this.$store.dispatch('projectV2/updateLatestStep', payloadStep)
          value = true
          this.completeJadwalPekerjaan = false
        })
        .catch((error) => {
          alertErrorMessage(error)
          value = false
          console.log(error)
          this.$buefy.toast.open({
            message:
              error.response.data.message ||
              'Gagal post data Lengkapi Material, silahkan coba lagi',
            duration: 5000,
            type: 'is-danger'
          })
        })
      return value
    },
    async saveDataLengkapiMaterial () {
      await this.dataLengkapiMaterial.map((itemArea, indexArea) => {
        itemArea.components.map((itemComp, indexComp) => {
          itemComp.detail_components.map((itemDetail, indexDetail) => {
            itemDetail.item.map((itemItem, indexItem) => {
              this.dataLengkapiMaterial[indexArea].components[indexComp].detail_components[
                indexDetail
              ].item[indexItem] = {
                note_item_working: itemItem.note_item_working,
                volume: itemItem.volume,
                unit_id: itemItem.unit_id,
                unit_name: itemItem.unit_name,
                max: itemItem.max,
                total_worker: itemItem.total_worker,
                materials: itemItem.materials || [],
                pictures: itemItem.pictures,
                item_id: itemItem.item_id,
                step_skill_item_id: itemItem.step_skill_item_id
              }
            })
          })
        })
      })

      let value
      const payload = {
        url: this.url,
        survey_id: this.$route.params.surveyId,
        area_components: this.dataLengkapiMaterial
      }

      await this.$store
        .dispatch('projectV2/saveDataLengkapiMaterial', payload)

        .then((response) => {
          this.$buefy.toast.open({
            message: 'Data berhasil diubah',
            duration: 5000
          })
          value = true
          this.completeJadwalPekerjaan = false
          // this.isLoadingGetData = true
        })
        .catch((error) => {
          alertErrorMessage(error)
          value = false
          console.log(error)
          this.$buefy.toast.open({
            message:
              error.response.data.message ||
              'Gagal post data Lengkapi Material, silahkan coba lagi',
            duration: 5000,
            type: 'is-danger'
          })
        })
      return value
    },
    confirmOffering () {
      this.$swal({
        imageUrl: confirmOfferingImage,
        imageAlt: 'confirm offering',
        width: '600px',
        padding: '10px 10px 30px 10px',
        title: `<p class="averta-bold label-22">${
          this.isSM
            ? 'Lanjutkan Penawaran Ke Purchasing?'
            : this.dataUpdateItemPekerjaan.isUpdate
            ? 'Simpan Perubahan ?'
            : 'Lanjut Proses Penawaran Ini?'
        }</p>`,
        html: `
        <p class="averta-regular" style="display: ${this.isBS ? 'none' : 'block'};">
          ${
            this.isSM
              ? 'Pastikan kamu sudah cek kembali penawaran ini sebelum di proses. Penawaran akan dikirimkan ke Purchasing'
              : this.dataUpdateItemPekerjaan.isUpdate
              ? 'Pastikan data penawaran telah sesuai'
              : `Pastikan kamu sudah cek kembali penawaran ini sebelum di proses. Penawaran akan dikirimkan ke ${
                  this.isBS ? 'Client' : this.isSM ? 'Purchasing' : 'Site Manager'
                }`
          }
        </p>`,
        showCancelButton: true,
        confirmButtonText: this.isBS
          ? 'Simpan'
          : `${
              this.isSM
                ? 'Kirim ke Purchasing'
                : this.dataUpdateItemPekerjaan.isUpdate
                ? 'Simpan'
                : 'Kirim Penawaran'
            }`,
        cancelButtonText: `${this.isSM ? 'Batalkan' : 'Cek Kembali'}`,
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        },
        reverseButtons: true
      }).then((result) => {
        localStorage.removeItem(`stepData-${this.$route.params.surveyId}`)
        if (result.isConfirmed) {
          this.loadingSpinner = true
          const payload = {
            url: this.url,
            survey_id: this.$route.params.surveyId
          }

          this.$store
            .dispatch('projectV2/createPenawaran', payload)
            .then((response) => {
              if (this.dataUpdateItemPekerjaan.isUpdate) {
                // this.$store.commit('project/setDataUpdateItemPekerjaanBS', {
                //   clearData: true
                // })
                this.$emit('showHideSidebar', 'success-update-item', 'cancel-item')
                this.$buefy.toast.open({
                  message: 'Berhasil Update Item Pekerjaan',
                  type: 'is-success'
                })
                this.$store.commit('project/setUpdatedItemPekerjaan', true)
              } else {
                this.surveyDetail()
                this.$swal({
                  width: '600px',
                  padding: '10px 10px 30px 10px',
                  html: `
                  <img src="${successCreateImage}" class="img-create-penawaran mb-4" />
                  <p class="averta-bold mb-4 label-22">${
                    this.isSM || this.isBS
                      ? 'Penawaran Berhasil Dilanjutkan'
                      : 'Penawaran berhasil dibuat'
                  }</p>
                  <p class="averta-reguler" style="display: ${this.isBS ? 'none' : 'block'};">
                    ${
                      this.isBS
                        ? 'Penawaran akan dilanjutkan ke Client.'
                        : this.isSM
                        ? 'Penawaran akan direview dan dilengkapi oleh Purchasing.'
                        : 'Penawaran berhasil dibuat. Site Manager akan melakukan review dan melengkapi informasi lainnya.'
                    }
                  </p>
                `,
                  showCancelButton: true,
                  confirmButtonText: `${this.isBS ? 'Tutup' : 'Lihat Detail'}`,
                  cancelButtonText: `${
                    this.isSM || this.isBS ? 'Lihat Daftar' : 'Lihat Daftar Proyek'
                  }`,
                  customClass: {
                    confirmButton: 'btn-hapus-detail-component',
                    cancelButton: 'btn-batal-detail-component'
                  },
                  reverseButtons: true
                }).then((result) => {
                  this.loadingSpinner = false
                  localStorage.removeItem(`stepData-${this.$route.params.surveyId}`)
                  this.isFinish = true
                  if (result.isConfirmed) {
                    this.surveyDetail()
                  } else {
                    if (this.isSM) {
                      this.$router.push('/sm/project')
                    } else if (this.isBS) {
                      this.$router.push('/am/project')
                    } else {
                      this.$router.push('/project')
                    }
                  }
                })
              }
            })
            .catch((error) => {
              alertErrorMessage(error)
              console.log(error)
              this.$buefy.toast.open({
                message:
                  error.response.data.message || 'Gagal kirim ke penawaran, silahkan coba lagi',
                type: 'is-danger'
              })
              this.loadingSpinner = false
            })
        }
      })
    },
    cancelOffering (next) {
      this.$swal({
        imageUrl: this.isChangeData ? alertProject : discardImage,
        imageAlt: 'cancel offering',
        imageWidth: this.isChangeData ? 250 : 500,
        width: '600px',
        padding: '10px 10px 30px 10px',
        title: `<p class="averta-bold label-22 style="margin-bottom: -20px;">
                  ${this.isChangeData ? 'Batalkan Perubahan Data?' : 'Keluar Dari Halaman Ini?'}
                </p>`,
        html: this.isChangeData
          ? '<p class="averta-reguler"> Jika kamu keluar dari halaman ini data yang diubah terakhir akan disimpan </p>'
          : '<p class="averta-reguler"> Anda Akan di Arahkan ke Halaman Detail </p>',
        showCancelButton: true,
        confirmButtonText:
          !this.isLeaving && this.isChangeData
            ? 'Keluar & Simpan'
            : this.isChangeData
              ? 'Iya, Batalkan'
              : 'Keluar',
        cancelButtonText: this.isChangeData ? 'Tetap Di Halaman Ini' : 'Tetap Di Halaman Ini',
        reverseButtons: !!this.isChangeData,
        customClass: {
          confirmButton: this.isChangeData
            ? 'btn-hapus-detail-component'
            : 'btn-batal-detail-component',
          cancelButton: this.isChangeData
            ? 'btn-batal-detail-component'
            : 'btn-hapus-detail-component'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          next(false)
          const payload = {
            url: this.url,
            survey_id: this.$route.params.surveyId,
            latest_step: this.currentStep
          }

          if (this.currentStep === 1 && !this.isLeaving && this.isChangeData) {
            this.saveDataPilihTukang()
          } else if (this.currentStep === 3 && !this.isLeaving && this.isChangeData) {
            this.saveDataJadwalPekerjaan()
          } else if (this.currentStep === 4 && !this.isLeaving && this.isChangeData) {
            this.saveDataLengkapiMaterial()
          }

          this.$store
            .dispatch('projectV2/updateLatestStep', payload)
            .then((response) => {
              next()
            })
            .catch((error) => {
              alertErrorMessage(error)
              next()
              console.log(error)
            })
        } else {
          next(false)
        }
      })
    },
    handleBack () {
      if (this.dataUpdateItemPekerjaan.isUpdate || this.isBS) {
        this.$swal({
          imageUrl: this.isChangeData ? alertProject : discardImage,
          imageAlt: 'cancel offering',
          imageWidth: this.isChangeData ? 250 : 500,
          width: '600px',
          padding: '10px 10px 30px 10px',
          title: `<p class="averta-bold label-22" style="margin-bottom: -20px;">
                  ${
                    this.isChangeData
                      ? 'Ada perubahan dari data sebelumnya'
                      : 'Keluar Dari Halaman Ini?'
                  }
                </p>`,
          html: this.isChangeData
            ? '<p class="averta-reguler"> Jika kamu keluar dari halaman ini data yang diubah terakhir akan disimpan </p>'
            : '<p class="averta-reguler"> Anda Akan di Arahkan ke Halaman Detail </p>',
          showCancelButton: true,
          confirmButtonText:
            !this.isLeaving && this.isChangeData
              ? 'Keluar & Simpan'
              : this.isChangeData
                ? 'Iya, Batalkan'
                : 'Keluar',
          cancelButtonText: this.isChangeData ? 'Tetap Di Halaman Ini' : 'Tetap Di Halaman Ini',
          reverseButtons: !!this.isChangeData,
          customClass: {
            confirmButton: this.isChangeData
              ? 'btn-hapus-detail-component'
              : 'btn-batal-detail-component',
            cancelButton: this.isChangeData
              ? 'btn-batal-detail-component'
              : 'btn-hapus-detail-component'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            const payload = {
              url: this.url,
              survey_id: this.$route.params.surveyId,
              latest_step: this.currentStep
            }

            if (this.currentStep === 1 && !this.isLeaving && this.isChangeData) {
              this.saveDataPilihTukang()
            } else if (this.currentStep === 3 && !this.isLeaving && this.isChangeData) {
              this.saveDataJadwalPekerjaan()
            } else if (this.currentStep === 4 && !this.isLeaving && this.isChangeData) {
              this.saveDataLengkapiMaterial()
            }

            this.$store
              .dispatch('projectV2/updateLatestStep', payload)
              .then(() => {
                this.$emit('showHideSidebar', 'cancel-item')
              })
              .catch((error) => {
                console.log(error)
              })
          }
        })
      } else {
        this.isBack = true
        this.surveyDetail()
      }
    },
    handleAlertResetCurrentStep (currentStep) {
      this.$swal({
        imageUrl: this.isChangeData ? alertProject : discardImage,
        imageAlt: 'cancel offering',
        imageWidth: this.isChangeData ? 250 : 500,
        width: '600px',
        padding: '10px 10px 30px 10px',
        title:
          '<p class="averta-bold label-22" style="margin-bottom: -20px;"> Batalkan Perubahan Data </p>',
        html: '<p class="averta-reguler"> Jika kamu klik Batalkan data akan kembali seperti sebelumnya </p>',
        showCancelButton: true,
        confirmButtonText: 'Batalkan',
        cancelButtonText: 'Nanti Dulu',
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          if (currentStep === 1) {
            this.$refs.formPilihTukang.getDataPilihTukang()
            this.isChangePilihTukang = false
          } else if (currentStep === 2) {
            this.$refs.formFotoSurvey.getDataFotoSurvey()
            this.isChangeFotoSurvey = false
          } else if (currentStep === 3) {
            this.$refs.formJadwalPekerjaan.getDataJadwalPekerjaan()
            this.isChangeJadwalPekerjaan = false
          } else if (currentStep === 4) {
            this.$refs.formLengkapiMaterial.getDataLengkapiMaterial()
            this.isChangeLengkapiMaterial = false
          }
        }
      })
    },
    handleAlreadyFill (page) {
      if (page === 1) {
        this.showErrorPilihTukang = true
      } else if (page === 2) {
        this.showErrorFotoSurvey = true
      } else if (page === 3) {
        this.showErrorJadwalPekerjaan = true
      }
    },
    handleIsChange () {
      if (this.currentStep === 1) {
        this.isChangePilihTukang = true
      } else if (this.currentStep === 2) {
        this.isChangeFotoSurvey = false
      } else if (this.currentStep === 3) {
        this.isChangeJadwalPekerjaan = true
      } else if (this.currentStep === 4) {
        this.isChangeLengkapiMaterial = true
      }
    },
    handleResetData (currentStep) {
      if (currentStep === 1) {
        this.$refs.formPilihTukang.getDataPilihTukang()
        this.isChangePilihTukang = false
      } else if (currentStep === 2) {
        this.$refs.formFotoSurvey.getDataFotoSurvey()
        this.isChangeFotoSurvey = false
      } else if (currentStep === 3) {
        this.$refs.formJadwalPekerjaan.getDataJadwalPekerjaan()
        this.isChangeJadwalPekerjaan = false
      } else if (currentStep === 4) {
        this.$refs.formLengkapiMaterial.getDataLengkapiMaterial()
        this.isChangeLengkapiMaterial = false
      }
    },
    closeNotification () {
      this.message_alfa = ''
    },
    saveStep () {
      if (this.currentStep === 5) {
        localStorage.removeItem(`stepData-${this.$route.params.surveyId}`)
        return
      }

      const surveyId = this.$route.params.surveyId
      if (!surveyId) {
        console.error('No surveyId found in route params')
        return
      }

      let savedData = {}
      try {
        savedData = JSON.parse(localStorage.getItem(`stepData-${surveyId}`)) || {
          currentStep: [],
          listStep: [],
          role: null
        }
      } catch (error) {
        console.error('Error parsing stepData from localStorage:', error)
        savedData = { currentStep: [], listStep: [], role: null }
      }

      savedData.currentStep = savedData.currentStep.filter((step) => step <= this.currentStep)

      if (!savedData.currentStep.includes(this.currentStep)) {
        savedData.currentStep.push(this.currentStep)
        savedData.currentStep.sort((a, b) => a - b)
      }

      savedData.role = this.user?.roles?.[0]?.name || savedData.role
      savedData.listStep = this.listStep

      localStorage.setItem(`stepData-${surveyId}`, JSON.stringify(savedData))
    },
    resetStepData () {
      const surveyId = this.$route.params.surveyId
      if (!surveyId) {
        console.error('No surveyId found in route params')
        return
      }

      localStorage.removeItem(`stepData-${surveyId}`)
    },
    getDataLastesStep () {
      const surveyId = this.$route.params.surveyId || this.dataUpdateItemPekerjaan.surveyId
      if (!surveyId) {
        console.error('No surveyId found')
        return
      }

      const payload = {
        url: this.url,
        survey_id: surveyId
      }

      this.$store
        .dispatch('projectV2/getDataLastesStep', payload)
        .then((response) => {
          const res = response.data.data
          this.getDataCountLastesStep = res.last_step
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
        })
    },
    handleBeforeUnload (event) {
      if (this.isChangeData && !this.isLeaving) {
        this.showModalOnUnload = true
        event.preventDefault()
        event.returnValue = 'Apakah Anda yakin ingin meninggalkan halaman ini?'
      }
    },
    // handleVisibilityChange () {
    //   if (document.visibilityState === 'hidden' && this.isChangeData) {
    //     this.cancelOffering()
    //   }
    // },
    handleGetDataCountLastesStep (newVal) {
      console.log('getDataCountLastesStep Watch', newVal)
    }
  },
  mounted () {
    window.addEventListener('beforeunload', this.handleBeforeUnload)
    // document.addEventListener('visibilitychange', this.handleVisibilityChange)
    this.currentRole = this.user.roles[0].name
    if (this.currentPath !== 'update-price') {
      this.$store.commit('project/setDataUpdateItemPekerjaanBS', {
        clearData: true
      })
    }
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'Login') {
      next()
    } else {
      if (this.currentStep === 1) {
        if (this.currentStep === 4) {
          next()
        } else {
          this.cancelOffering(next)
        }
      } else if (this.isBack) {
        // Handle "<- Kembali" scenario
        this.isBack = false
        this.cancelOffering(next)
      } else if (this.isFinish) {
        // Handle PreviewOffering scenario
        this.isFinish = false
        next()
      } else if (
        to.name !== 'DetailProject' &&
        to.name !== 'DetailSurvey' &&
        to.name !== 'Detail Project SM'
      ) {
        // Handle other scenarios where route is not 'DetailProject'
        this.cancelOffering(next)
      } else {
        // Handle other scenarios not covered above
        next(false)
        this.previousStep(this.currentStep)
      }
    }
  },
  created () {
    this.getDataLastesStep()
    const surveyId = this.$route.params.surveyId
    if (surveyId) {
      try {
        const savedData = localStorage.getItem(`stepData-${surveyId}`)
        if (savedData) {
          const parsedData = JSON.parse(savedData)
          if (parsedData && typeof parsedData === 'object') {
            if (Array.isArray(parsedData.currentStep)) {
              this.currentStep = Math.max(...parsedData.currentStep)
            }

            if (Array.isArray(parsedData.listStep)) {
              this.listStep = parsedData.listStep
            }
          } else {
            console.warn('Invalid data in localStorage for stepData:', parsedData)
          }
        }
      } catch (error) {
        console.error('Error reading stepData from localStorage:', error)
      }
    }
  },
  destroyed () {
    window.removeEventListener('beforeunload', this.handleBeforeUnload)
    document.removeEventListener('visibilitychange', this.handleVisibilityChange)
  },
  watch: {
    getDataCountLastesStep (newVal) {
      this.handleGetDataCountLastesStep(newVal)
    }
  }
}
</script>

<style scoped>
.w-100 {
  width: 100%;
}

.min-height {
  min-height: 85vh;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.text-gray {
  color: #9a9a9a;
  cursor: pointer;
  white-space: nowrap;
  margin-right: 1rem;
}

.text-primary-gradient {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.btn-disabled {
  background-color: #9a9a9a;
  border-radius: 100px;
  color: white;
}

.btn-active {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.25%);
  border-radius: 100px;
  color: white;
}

.step-wrapper {
  border-radius: 100px;
  text-align: center;
  background-color: #f5f5f5;
  min-width: 10em;
  position: relative;
  color: #868686;

  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-wrapper.active {
  color: #911a1e;
  background-color: #ffeeee;
}

.step-wrapper.active-sm {
  background-color: #10bd41 !important;
}

.no-wrap {
  white-space: nowrap;
}

.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
}

.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.stepper-item::before {
  position: absolute;
  content: '';
  border-bottom: 2px solid #e1e1e1;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: '';
  border-bottom: 2px solid #e1e1e1;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
}

.stepper-item.completed::after {
  position: absolute;
  content: '';
  border-bottom: 2px solid #c1ffd3;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3;
}

.stepper-item.completed .step-wrapper {
  background-color: #f0fff4;
  border: 1px solid #c1ffd3;
}

.stepper-item.completed .step-wrapper p {
  color: #10bd41;
}

.stepper-item.completed .step-wrapper.active-sm {
  background-color: #10bd41 !important;
}

.stepper-item:first-child::before {
  content: none;
}

.stepper-item:last-child::after {
  content: none;
}

.wrapper-kembali {
  display: flex !important;
}

.wrapper-kembali-mobile {
  display: none !important;
}

.text-penawaran {
  display: block;
}

.wrapper-steps {
  display: flex !important;
}

.btn-bottom {
  display: none !important;
}

.btn-active {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.25%);
  border-radius: 100px;
  color: white;
}

@media only screen and (max-width: 768px) {
  .text-penawaran {
    display: none;
  }

  .wrapper-steps {
    display: none !important;
  }

  .wrapper-button-steps {
    border-bottom: 1px solid #e1e1e1;
    padding: 0px 0px 16px 0px;
    margin-bottom: 0 !important;
    overflow-x: auto;
  }

  .wrapper-step {
    padding: 12px 0 !important;
  }

  .wrapper-kembali {
    display: none !important;
  }

  .wrapper-kembali-mobile {
    display: flex !important;
    align-items: center;
    margin-bottom: 24px;
    margin-top: 12px;
    padding: 0 24px;
  }
}

@media only screen and (max-width: 600px) {
  .btn-bottom {
    opacity: 1;
    display: flex !important;
    position: fixed;
    bottom: 0;
    z-index: 10;
    width: 100%;
    background-color: white;
    padding: 1rem;
    box-shadow: 0px -3px 30px rgba(0, 0, 0, 0.07);
  }
}

.floating-button-save {
  position: fixed;
  bottom: -100%;
  right: 50%;
  transform: translate(50%, 0);
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 45%;
  height: 100px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 0px 50px 11px rgba(0, 0, 0, 0.1);
  overflow: hidden !important;
  opacity: 0;
  transition: bottom 0.65s ease-in-out, opacity 0.5s ease-in-out, width 0.5s ease-in-out;
}

.floating-button-save.show {
  width: 65%;
  bottom: 65px;
  opacity: 1;
}
</style>

<style>
.img-create-penawaran {
  width: 50%;
}
</style>
