<template>
  <div class="sidebar-group-chat" :class="{ show: showSidebar ? true : false }">
    <div
      class="is-flex is-flex-direction-column is-justify-content-space-between is-align-items-center c-w-100 c-h-100"
    >
      <!-- Header -->
      <div
        v-if="!isMobile"
        class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100 px-5"
        style="max-height: 100px; min-height: 100px; border-bottom: 1px solid #e1e1e1"
      >
        <div
          class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-100 gap-3"
        >
          <div class="avatar-image">
            <PhotoProfileGroupChat />
          </div>
          <div
            class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start c-w-100"
          >
            <div
              class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center gap-2"
            >
              <p class="averta-bold label-20 white-space-nowrap">
                {{ dataHeader.survey_id }}
              </p>
              <p class="averta-bold label-20 white-space-nowrap">-</p>
              <p class="averta-bold label-20 white-space-nowrap">
                {{ dataHeader.vendor.name }}
              </p>
              <p class="averta-bold label-20 white-space-nowrap">-</p>

              <p class="averta-bold label-20 white-space-nowrap">
                {{ dataHeader.store.name }}
              </p>
              <p
                v-if="dataHeader.vendor.code === 'SMRCN'"
                class="averta-bold label-20 white-space-nowrap"
              >
                -
              </p>
              <p
                v-if="dataHeader.vendor.code === 'SMRCN'"
                class="averta-bold label-20 white-space-nowrap"
              >
                {{ dataHeader.store.unit_name }}
              </p>
            </div>

            <div
              class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center gap-2"
            >
              <p class="averta-reguler label-16">{{ membersCount }} Orang</p>
              <div
                v-if="tabActive === 'contentChat'"
                @click="handleClickTab('contentMembers')"
                class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center is-clickable gap-1"
              >
                <p class="averta-bold label-16 color-red">Lihat Anggota</p>
                <ChevronIcon color="#D9272D" size="15" />
              </div>
              <div
                v-if="tabActive === 'contentMembers'"
                @click="handleClickTab('contentChat')"
                class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center is-clickable gap-1"
              >
                <p class="averta-bold label-16 color-red">Live Chat</p>
                <ChevronIcon color="#D9272D" size="15" />
              </div>
            </div>
          </div>
        </div>
        <div
          class="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center c-w-20 is-clickable"
          @click="closeSidebarGroupChat(false)"
        >
          <XMarkIcon size="15" color="#000000" />
        </div>
      </div>
      <!-- Header -->

      <!-- Header Mobile -->
      <div
        v-if="isMobile"
        class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-flex-start c-w-100 px-5 py-4 gap-2"
        style="max-height: 100%; min-height: 100px; border-bottom: 1px solid #e1e1e1"
      >
        <div
          class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-flex-start c-w-100 gap-3"
        >
          <div class="avatar-image">
            <PhotoProfileGroupChat />
          </div>
          <div
            class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start c-w-100 gap-2"
          >
            <div
              class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center gap-3"
            >
              <p class="averta-bold label-20">
                ID{{ dataHeader.survey_id }} - {{ dataHeader.vendor.name }}
              </p>

              <div
                class="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center"
              >
                <div
                  class="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center is-clickable"
                  @click="closeSidebarGroupChat(false)"
                >
                  <XMarkIcon size="15" color="#000000" />
                </div>
              </div>
            </div>

            <div
              class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center gap-2"
            >
              <p class="averta-reguler label-16">{{ membersCount }} Orang</p>
              <div
                v-if="tabActive === 'contentChat'"
                @click="handleClickTab('contentMembers')"
                class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center is-clickable gap-1"
              >
                <p class="averta-bold label-16 color-red">Lihat Anggota</p>
                <ChevronIcon size="15" />
              </div>
              <div
                v-if="tabActive === 'contentMembers'"
                @click="handleClickTab('contentChat')"
                class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center is-clickable gap-1"
              >
                <p class="averta-bold label-16 color-red">Live Chat</p>
                <ChevronIcon size="15" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Header Mobile -->

      <div class="content-chat-group" v-if="isLoading || isLoadingChatData">
        <SpinnerIcon />
      </div>

      <!-- Body Content -->
      <div
        v-else
        class="content-chat-group"
        :style="{
          padding:
            tabActive === 'contentMembers' || tabActive === 'contentAddMembers' ? '0' : '1rem'
        }"
      >
        <div
          v-if="isShowDropdownReply"
          @click="isShowDropdownReply = false"
          class="dropdown-overlay"
        ></div>

        <!-- New Message Floating -->
        <div
          v-if="newMessageGroupChat && tabActive === 'contentChat'"
          class="new-message-floating"
          @click="scrollToBottom()"
        >
          <p class="averta-bold label-14 color-white">Pesan Baru</p>
        </div>
        <!-- New Message Floating -->

        <!-- Content Lihat Members -->
        <div v-if="tabActive === 'contentMembers'" class="content-list-members">
          <div
            class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center c-w-100"
          >
            <div
              class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100 px-5 py-3"
            >
              <p class="averta-bold label-16">Daftar Anggota</p>
              <div
                class="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center gap-4"
              >
                <p class="averta-reguler label-16">{{ membersCount }} Orang</p>
                <ButtonApp
                  v-if="isSM && conditionRoomGroupChatActive"
                  width="150px"
                  height="35px"
                  :isSecondary="true"
                  @click="handleClickTab('contentAddMembers')"
                >
                  <div
                    class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center"
                  >
                    <p class="averta-bold label-14">+ Tambah SM</p>
                  </div>
                </ButtonApp>
              </div>
            </div>

            <ul class="list-members px-5 py-3">
              <li
                class="list-members-item"
                v-for="(item, index) in getDataMembers.filter((item) => !item.is_deleted)"
                :key="item.id || index"
              >
                <div
                  class="is-flex is-flex-direction-row is-justify-content-space-beetwwen is-align-items-center c-w-100"
                >
                  <div
                    class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center gap-3 c-w-100"
                  >
                    <div class="avatar-image">
                      <img :src="item.image" v-if="item.image && item.image !== '-'" />
                      <img
                        v-else
                        src="https://nccf.in/wp-content/uploads/2024/08/WhatsApp-Image-2024-08-19-at-5.58.49-PM-1.jpeg"
                      />
                    </div>
                    <div
                      class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start c-w-100"
                    >
                      <p class="averta-bold label-14">{{ item.name }}</p>
                    </div>
                  </div>
                  <div
                    v-if="
                      item.role !== 'Site Manager' &&
                      item.role !== 'Konsultan' &&
                      conditionRoomGroupChatActive
                    "
                    class="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center gap-3 c-w-100"
                  >
                    <ButtonApp
                      v-if="isSM"
                      height="35px"
                      :isSecondary="true"
                      @click="buttonDeleteReInviteWorkers(item.id, item.name, item.role, false)"
                      :isDisabled="isLoadingRemoveWorker"
                    >
                      <div
                        class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center"
                      >
                        <p class="averta-bold label-12">Hapus dari Chat</p>
                      </div>
                    </ButtonApp>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div
            v-if="getDataMembers.filter((item) => item.is_deleted).length"
            class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center c-w-100"
          >
            <div
              class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100 px-5 py-3"
            >
              <p class="averta-bold label-16">Dihapus dari group chat</p>
            </div>

            <ul class="list-members px-5 py-3">
              <li
                class="list-members-item"
                v-for="(item, index) in getDataMembers.filter((item) => item.is_deleted)"
                :key="item.id || index"
              >
                <div
                  class="is-flex is-flex-direction-row is-justify-content-space-beetwwen is-align-items-center c-w-100"
                >
                  <div
                    class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center gap-3 c-w-100"
                  >
                    <div class="avatar-image">
                      <img :src="item.image" v-if="item.image && item.image !== '-'" />
                      <img
                        v-else
                        src="https://nccf.in/wp-content/uploads/2024/08/WhatsApp-Image-2024-08-19-at-5.58.49-PM-1.jpeg"
                      />
                    </div>
                    <div
                      class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start c-w-100"
                    >
                      <p class="averta-bold label-14">{{ item.name }}</p>
                    </div>
                  </div>
                  <div
                    v-if="
                      item.role !== 'Site Manager' &&
                      item.role !== 'Konsultan' &&
                      conditionRoomGroupChatActive
                    "
                    class="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center gap-3 c-w-100"
                  >
                    <ButtonApp
                      v-if="isSM"
                      height="35px"
                      :isSecondary="false"
                      @click="buttonDeleteReInviteWorkers(item.id, item.name, item.role, true)"
                      :isDisabled="isLoadingRemoveWorker"
                    >
                      <div
                        class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center"
                      >
                        <p class="averta-bold label-12">Undang Kembali</p>
                      </div>
                    </ButtonApp>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- Content Lihat Members -->

        <!-- Content Add Members -->
        <div v-if="tabActive === 'contentAddMembers'" class="content-add-members">
          <div
            class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-100 px-5 py-3"
          >
            <ButtonApp
              width="250px"
              height="35px"
              :isSecondary="false"
              @click="handleClickTab('contentMembers')"
            >
              <div
                class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center"
              >
                <p class="averta-bold label-14">Lihat Members</p>
              </div>
            </ButtonApp>
          </div>
          <ul class="list-members px-5 py-3">
            <li
              class="list-members-item"
              style="min-height: 80px"
              v-for="(item, index) in filteredSiteManagers"
              :key="item.id || index"
            >
              <div
                class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center gap-3 c-w-100"
              >
                <div class="wrapper-checkbox-input">
                  <input
                    type="checkbox"
                    :id="item.id"
                    :name="item.id"
                    class="checkbox-input is-clickable"
                    :checked="selectedItems.includes(item.id)"
                    @change="handleCheckboxChange($event, item.id)"
                  />
                </div>
                <div
                  class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-100 gap-4"
                >
                  <div class="avatar-image">
                    <img :src="item.photo" v-if="item.photo && item.photo !== '-'" />
                    <img
                      v-else
                      src="https://nccf.in/wp-content/uploads/2024/08/WhatsApp-Image-2024-08-19-at-5.58.49-PM-1.jpeg"
                    />
                  </div>
                  <p class="averta-bold label-14">{{ item.name }}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div
          v-if="
            (isSM || isKonsultan) &&
            tabActive === 'contentAddMembers' &&
            conditionRoomGroupChatActive
          "
          class="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-flex-start c-w-100 px-5 gap-5 py-5"
          style="
            max-height: 100%;
            min-height: 100px;
            border-top: 1px solid #e1e1e1;
            min-width: 100%;
            background-color: #ffffff !important;
          "
        >
          <ButtonApp
            width="150px"
            height="50px"
            :isSecondary="true"
            @click="handleClickTab('contentMembers')"
          >
            <div
              class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center"
            >
              <p class="averta-bold label-14">Batalkan</p>
            </div>
          </ButtonApp>
          <ButtonApp
            width="150px"
            height="50px"
            :isDisabled="isDisabledButtonSM"
            @click="postSiteManagerGroupChat()"
          >
            <div
              class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center"
            >
              <p class="averta-bold label-14">Tambah</p>
            </div>
          </ButtonApp>
        </div>
        <!-- Content Add Members -->

        <!-- Content Chat -->
        <div
          v-if="tabActive === 'contentChat'"
          class="wrapper-content-chat gap-5"
          :class="{ show: showSidebar ? true : false }"
          ref="cardComments"
        >
          <!-- Detail Project -->
          <div class="card-detail-project gap-4">
            <div
              class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-flex-start c-w-100"
            >
              <div
                class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start"
              >
                <p class="averta-bold label-14">{{ dataHeader.vendor.name }}</p>
                <p class="averta-reguler label-14">
                  ( {{ dataHeader.store.id }} ) {{ dataHeader.store.name }}
                </p>
              </div>
              <div
                class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start"
              >
                <p class="averta-bold label-14">ID{{ dataHeader.survey_id }}</p>
              </div>
            </div>
            <div class="divider-line-1"></div>
            <div
              class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start c-w-100"
            >
              <p class="averta-bold label-14">Alamat Pengerjaan</p>
              <p class="averta-reguler label-14">{{ dataHeader.project.address }}</p>
            </div>
            <div
              class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-flex-start c-w-100"
            >
              <div
                class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start c-w-100"
              >
                <p class="averta-bold label-14">PIC Client</p>
                <p class="averta-reguler label-14">{{ dataHeader.client.pic_name }}</p>
              </div>
            </div>
            <div
              class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start c-w-100"
            >
              <p class="averta-bold label-14">Deskripsi Masalah</p>
              <p class="averta-reguler label-14">
                {{ dataHistorySurvey.project.description || '-' }}
              </p>
            </div>
          </div>
          <!-- Detail Project -->

          <!-- Sender -->
          <div
            v-for="(item, index) in getDataChatRooms"
            :key="item.id || index"
            class="is-flex is-flex-direction-column c-w-100 gap-10"
          >
            <div v-if="!item.sender_id" class="wrapper-badge-joined-users">
              <div
                class="badge-joined-users"
                v-if="index === 0 || item.message !== getDataChatRooms[index - 1].message"
              >
                <p class="averta-bold color-black" style="text-align: center">{{ item.message }}</p>
              </div>
            </div>
            <!-- Sender -->

            <!-- Time -->
            <div
              v-if="
                index === 0 ||
                (getDataChatRooms[index - 1] &&
                  timeStamp(item.created_at) !== timeStamp(getDataChatRooms[index - 1].created_at))
              "
              class="wrapper-badge-joined-users"
            >
              <div class="badge-joined-users">
                <p class="averta-bold color-black">{{ timeStamp(item.created_at) }}</p>
              </div>
            </div>
            <!-- Time -->

            <div
              v-if="item.sender_id"
              class="wrapper-content-chat-user"
              :class="{ 'wrapper-content-chat-sender': isSenderMessage(item) }"
            >
              <div
                v-if="item.id"
                class="content-chat-user gap-3"
                :class="{ 'content-chat-sender': isSenderMessage(item) }"
              >
                <div class="avatar-image">
                  <img
                    :src="item.sender_image"
                    v-if="item.sender_image && item.sender_image !== '-'"
                  />
                  <img
                    v-else
                    src="https://nccf.in/wp-content/uploads/2024/08/WhatsApp-Image-2024-08-19-at-5.58.49-PM-1.jpeg"
                  />
                </div>
                <div
                  class="bubble-chat-user gap-1"
                  :class="{
                    'bubble-chat-sender': isSenderMessage(item),
                    'gap-1': !isSenderMessage(item)
                  }"
                  :id="'message-' + item.id"
                >
                  <!-- Dropdown Reply Chat -->
                  <div
                    :style="{ zIndex: activeDropdownId === item.id ? 100 : 0 }"
                    class="wrapper-dropdown"
                    @click="toggleDropdown(item.id)"
                  >
                    <b-dropdown aria-role="list" position="is-bottom-left">
                      <template #trigger>
                        <span
                          class="is-clickable is-flex is-flex-direction-row is-justify-content-center is-align-items-center border-radius-6"
                          style="
                            width: 22.5px;
                            min-width: 22.5px;
                            max-width: 22.5px;
                            height: 22.5px;
                            min-height: 22.5px;
                            max-height: 22.5px;
                            z-index: 9999;
                          "
                          :style="{
                            backgroundColor: isSenderMessage(item) ? '#F5F5F5' : '#ffffff'
                          }"
                        >
                          <ChevronIcon size="12" style="transform: rotate(90deg)" color="#000000" />
                        </span>
                      </template>
                      <b-dropdown-item
                        aria-role="listitem"
                        @click="handleReply(item) && (isShowDropdownReply = false)"
                        >Balas</b-dropdown-item
                      >
                    </b-dropdown>
                  </div>
                  <!-- Dropdown Reply Chat -->

                  <div
                    v-if="isSenderMessage(item)"
                    class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center gap-2"
                  >
                    <p class="averta-bold">{{ item.sender_name }}</p>
                    <p v-if="isSpecialRole(item.sender_role)" class="averta-reguler">|</p>
                    <p v-if="isSpecialRole(item.sender_role)" class="averta-reguler">
                      {{ item.sender_role }}
                    </p>
                  </div>

                  <!-- Bubble Reply -->
                  <div
                    v-if="item.reply_to"
                    class="wrapper-bubble-reply"
                    @click="scrollToMessage(item.reply_to.id)"
                  >
                    <div
                      class="content-bubble-chat"
                      :style="{ backgroundColor: isSenderMessage(item) ? '#F5F5F5' : '#ffffff' }"
                    >
                      <div
                        class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100"
                      >
                        <div
                          class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start gap-2"
                        >
                          <p class="averta-bold label-14">{{ item.reply_to.sender_name }}</p>
                          <div
                            class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center gap-1"
                          >
                            <ImageEmptyIcon v-if="item.reply_to.image_url" />
                            <p class="averta-reguler label-1">
                              {{ getReplyPreviewText(item.reply_to) }}
                            </p>
                          </div>
                        </div>
                        <div v-if="item.reply_to.image_url" class="wrapper-image-chat-reply">
                          <img :src="item.reply_to.image_url" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Bubble Reply -->

                  <div
                    class="is-flex is-flex-direction-column"
                    :style="{ paddingRight: item.image_url ? '0' : '3rem' }"
                  >
                    <div
                      v-if="item.image_url"
                      class="wrapper-image-chat cursor-pointer mt-0 mb-4"
                      @click="handleOpenModalImage(item.image_url)"
                    >
                      <img :src="item.image_url" />
                    </div>

                    <p
                      v-if="item.message"
                      class="text-content-bubble-chat-user label-14"
                      :class="{ 'text-content-bubble-chat-sender': isSenderMessage(item) }"
                    >
                      {{ item.message }}
                    </p>
                  </div>
                  <div
                    class="c-w-100 is-flex is-flex-direction-row is-justify-content-flex-end gap-2 is-align-items-center"
                  >
                    <p class="averta-regular color-black label-14">
                      {{ formatTime(item.created_at) }}
                    </p>
                    <MessageChecklistOne v-if="item.status === 'SENT'" />
                    <MessageChecklistDouble v-if="item.status === 'RECEIVED'" />
                    <MessageChecklistDouble v-if="item.status === 'READ'" color="#10BD41" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Sender -->
        </div>
        <!-- Content Chat -->
      </div>
      <!-- Body Content -->

      <!-- Footer -->
      <div
        v-if="(isSM || isKonsultan) && tabActive === 'contentChat' && conditionRoomGroupChatActive"
        class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center c-w-100"
        style="max-height: 100%; min-height: 100px; border-top: 1px solid #e1e1e1; min-width: 100%"
      >
        <!-- Wrapper Chat Reply - Only show when replyingTo exists -->
        <div
          class="wrapper-reply-chat is-clickable"
          v-if="replyingTo"
          @click="scrollToMessage(replyingTo.id)"
        >
          <div
            class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start p-4 c-w-100 gap-2"
          >
            <p>Membalas Ke:</p>
            <div class="wrapper-reply-group-chat">
              <div class="bubble-reply-group-chat">
                <div
                  class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center c-w-100"
                >
                  <div
                    class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100"
                  >
                    <div
                      class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start gap-1"
                    >
                      <p class="averta-bold label-14">
                        {{ isCurrentUser(replyingTo.sender_id) ? 'Kamu' : replyingTo.sender_name }}
                      </p>
                      <div
                        class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center gap-1"
                      >
                        <ImageEmptyIcon v-if="replyingTo.image_url" />
                        <p
                          v-if="replyingTo.message || replyingTo.image_url"
                          class="averta-regular label-14"
                        >
                          {{ getReplyContent(replyingTo) }}
                        </p>
                      </div>
                    </div>
                    <div v-if="replyingTo.image_url" class="wrapper-image-chat-reply">
                      <img
                        :src="replyingTo.image_url"
                        style="max-width: 100%; border-radius: 10px; object-fit: contain"
                      />
                    </div>
                  </div>
                </div>
                <div
                  @click.stop="cancelReply"
                  class="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center px-4"
                >
                  <span class="wrapper-bullet-red">
                    <XMarkIcon size="12" color="#ffffff" class="is-clickable" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Wrapper Chat Reply -->

        <div
          class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-flex-start c-w-100 px-5 gap-5 py-5"
        >
          <div class="avatar-image">
            <img
              v-if="user.profile_picture && user.profile_picture !== '-'"
              :src="user.profile_picture"
              alt=""
            />
            <img
              v-else
              src="https://nccf.in/wp-content/uploads/2024/08/WhatsApp-Image-2024-08-19-at-5.58.49-PM-1.jpeg"
            />
          </div>

          <!-- Wrapper Input Chat -->
          <div class="wrapper-input-text-message-chat">
            <textarea
              v-model="messageText"
              @keydown.enter="handleEnter"
              :placeholder="selectedImage ? '' : 'Masukkan pesan...'"
              class="input-text-message-chat"
              :disabled="isLoadingSendMessage"
              :style="{
                minHeight: selectedImage ? '150px' : 'auto',
                padding: selectedImage ? '1rem 7.5rem' : '1rem 3rem',
                maxWidth: '100%',
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
                whiteSpace: 'pre-wrap'
              }"
              wrap="soft"
            ></textarea>

            <div class="wrapper-preview-image" v-if="selectedImage">
              <div class="preview-image">
                <img
                  :src="selectedImage"
                  alt="Pratinjau Gambar"
                  style="max-width: 100%; border-radius: 8px"
                />
                <div class="close-image-preview is-clickable" @click="handleDeleteImage">
                  <XMarkIcon size="10" color="#ffffff" />
                </div>
              </div>
            </div>

            <span class="is-clickable icon-clip-group-chat" @click="openGallery">
              <ClipPaperIcon />
            </span>
            <input
              type="file"
              ref="fileInput"
              accept="image/*"
              @change="handleImageUpload"
              style="display: none"
              :disabled="isLoadingSendMessage"
            />
          </div>

          <ButtonApp
            width="100px"
            height="50px"
            :isDisabled="disableButtonSend"
            @click="sendMessage"
          >
            <div
              class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center"
            >
              <p class="averta-bold label-14">Kirim</p>
            </div>
          </ButtonApp>
        </div>
      </div>

      <!-- Kondisi is_open === false -->
      <div
        v-if="(conditionRoomGroupChatNotActive && tabActive === 'contentChat') || isBS"
        class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-100 px-5 gap-5 py-5"
        style="max-height: 100%; min-height: 100px; border-top: 1px solid #e1e1e1; min-width: 100%"
      >
        <ExpiredMessageIcon />
        <div
          class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start"
        >
          <p class="averta-bold label-16">
            {{
              isBS
                ? 'Hanya bisa dilakukan oleh Site Manager dan Konsultan'
                : 'Sesi Percakapan Berakhir'
            }}
          </p>
          <p class="averta-regular label-16">
            {{
              isBS
                ? 'Kamu tidak dapat mengirim pesan karena hanya bisa dilakukan oleh Site Manager dan Konsultan'
                : 'Pengerjaan telah selesai, kamu tidak dapat melanjutkan percakapan'
            }}
          </p>
        </div>
      </div>
      <!-- Kondisi is_open === false -->

      <!-- Footer -->
    </div>

    <!-- Image Modal -->
    <b-modal
      v-model="isOpeModalsImageChatGroup"
      :can-cancel="false"
      @close="isOpeModalsImageChatGroup = false"
    >
      <div class="wrapper-open-modals-image">
        <img :src="openImageSelected" @click="isOpeModalsImageChatGroup = false" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  ChevronIcon,
  XMarkIcon,
  MessageChecklistOne,
  MessageChecklistDouble,
  ClipPaperIcon,
  PhotoProfileGroupChat,
  ExpiredMessageIcon,
  SpinnerIcon,
  ImageEmptyIcon
} from '@/components/icons'
import ButtonApp from '@/components/button/ButtonApp.vue'
import moment from 'moment'
import { mapGetters } from 'vuex'
import { getDataMembers } from '@/services/firebaseMembers'
import { getChatData } from '@/services/firebaseChat'
import { alertErrorMessage } from '@/helpers'
import alertProject from '@/assets/img/IconAlertProject.png'

export default {
  name: 'sidebarGroupChatSM',
  props: {
    openSidebarGroupChat: {
      type: Boolean,
      default: false
    },
    dataHeader: {
      type: Object,
      default: () => {}
    },
    dataDetailProyek: {
      type: Object,
      default: () => {}
    },
    dataHistorySurvey: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    ChevronIcon,
    XMarkIcon,
    ButtonApp,
    MessageChecklistOne,
    MessageChecklistDouble,
    ClipPaperIcon,
    PhotoProfileGroupChat,
    ExpiredMessageIcon,
    SpinnerIcon,
    ImageEmptyIcon
  },
  data () {
    return {
      moment: moment,
      showSidebar: false,
      messageText: '',
      selectedImage: null,
      sender_image: null,
      roleName: '',
      unsubscribe: null,
      buttonDisabled: false,
      getDataMembers: [],
      getDataChatRooms: [],
      isLoadingSendMessage: false,
      isLoading: true,
      tabActive: 'contentChat',
      getDataSiteManager: [],
      isDisabledButtonSM: true,
      selectedItems: [],
      replyingTo: null,
      isOpeModalsImageChatGroup: false,
      openImageSelected: null,
      isLoadingRemoveWorker: false,
      disableButtonSend: false,
      newMessageGroupChat: false,
      isShowDropdownReply: false,
      activeDropdownId: null,
      isLoadingChatData: false,
      isScrolledToBottom: true,
      scrollThreshold: 100
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      url: 'setup/getUrl',
      getNewChat: 'groupChat/getNewChat',
      getNewMember: 'groupChat/getNewMember'
    }),
    isBS () {
      if (
        this.user.roles[0].name === 'Account Manager' ||
        this.user.roles[0].name === 'Business Support'
      ) {
        return true
      }
      return false
    },
    isSM () {
      return this.roleName === 'Site Manager'
    },
    isKonsultan () {
      return this.roleName === 'Konsultan Survey'
    },
    isDisabled () {
      if (!this.messageText && !this.selectedImage) {
        return true
      }
      return this.disableButtonSend
    },
    filteredSiteManagers () {
      if (!this.getDataSiteManager || !this.getDataMembers) return []
      return this.getDataSiteManager.filter((manager) => {
        if (!manager.homecare_user_id) return true
        return !this.getDataMembers.some((member) => member.id === manager.homecare_user_id)
      })
    },
    conditionRoomGroupChatActive () {
      return (
        this.dataHeader &&
        this.dataHeader.project_confirmation &&
        this.dataHeader.project_confirmation.length > 0 &&
        this.dataHeader.project_confirmation[0].created_at === null
      )
    },
    conditionRoomGroupChatNotActive () {
      return (
        this.dataHeader &&
        this.dataHeader.project_confirmation &&
        this.dataHeader.project_confirmation.length > 0 &&
        this.dataHeader.project_confirmation[0].created_at !== null
      )
    },
    membersCount () {
      return this.getDataMembers.filter((item) => !item.is_deleted).length || 0
    },
    topic () {
      return (this.dataDetailProyek && this.dataDetailProyek.topic_chat_group) || ''
    },
    isMobile () {
      if (window.innerWidth <= 768) {
        return true
      }
      return false
    }
  },
  watch: {
    getNewChat: {
      handler (newVal) {
        if (newVal > 0 || newVal.length > 0 || newVal.id) {
          this.$nextTick(() => {
            setTimeout(() => {
              this.focusTextarea()
            }, 300)
          })

          const findIdx = this.getDataChatRooms.find((item) => item.id === newVal.id)

          if (!findIdx) {
            this.getDataChatRooms.push(newVal)
            if (
              newVal.sender_id !== this.user.homecare_user_id &&
              newVal.sender_name !== this.user.name
            ) {
              this.playAudioNewMessage()
              this.checkScrollPosition()

              if (this.isScrolledToBottom) {
                this.$nextTick(() => {
                  this.scrollToBottom()
                })
                this.$buefy.toast.open({
                  message: 'Pesan Baru',
                  type: 'is-danger',
                  duration: 3000
                })
              } else {
                this.newMessageGroupChat = true
              }
            }
          }
        } else {
          return false
        }
      },
      deep: true
    },
    getNewMember: {
      handler (newVal) {
        if (newVal > 0 || newVal.length > 0 || newVal.id) {
          const findIdx = this.getDataMembers.find((item) => item.id === newVal.id)

          if (!findIdx) {
            this.getDataMembers.push(newVal)
          }
        } else {
          return false
        }
      },
      deep: true
    },
    getDataChatRooms: {
      handler () {
        this.$nextTick(() => {
          this.checkScrollPosition()
        })
      },
      deep: true
    },
    openSidebarGroupChat: {
      handler (newVal) {
        this.showSidebar = newVal

        if (newVal) {
          this.handleSidebarOpen()
        } else {
          this.handleSidebarClose()
        }
      },
      immediate: true
    }
  },
  created () {
    this.roleName = this.user?.roles?.[0]?.name || ''
    this.getRoleSiteManager()
    this.loadChatData()
  },
  mounted () {
    this.$nextTick(() => {
      const cardComments = this.$refs.cardComments
      if (cardComments) {
        cardComments.addEventListener('scroll', this.handleScroll)

        this.checkScrollPosition()
      }
    })
  },

  beforeDestroy () {
    const cardComments = this.$refs.cardComments
    if (cardComments) {
      cardComments.removeEventListener('scroll', this.handleScroll)
    }

    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout)
    }

    this.clearIntervals()
    this.handleSidebarClose()
  },
  methods: {
    toggleDropdown (itemId) {
      if (this.activeDropdownId === itemId) {
        this.activeDropdownId = null
        this.isShowDropdownReply = false
      } else {
        this.activeDropdownId = itemId
        this.isShowDropdownReply = true
      }
    },

    handleSidebarOpen () {
      this.focusTextarea()
      document.body.style.overflow = 'hidden'
      document.body.style.position = 'fixed'
      document.body.style.width = '100%'
      setTimeout(() => {
        this.isLoading = false
        setTimeout(() => {
          this.getNewDataChatGroup()
          setTimeout(() => {
            this.scrollToBottom()
          }, 0)
        }, 0)
      }, 1500)
    },

    handleSidebarClose () {
      this.replyingTo = null || '' || undefined
      document.body.style.overflow = ''
      document.body.style.position = ''
      document.body.style.width = ''
      this.clearIntervals()
    },

    clearIntervals () {
      if (this.intervalId) {
        clearInterval(this.intervalId)
        this.intervalId = null
      }
    },

    focusTextarea () {
      this.$nextTick(() => {
        const textarea = document.querySelector('.input-text-message-chat')
        if (textarea) {
          textarea.focus()
        }
      })
    },

    handleClickTab (tab) {
      this.tabActive = tab
      if (tab === 'contentChat') {
        this.$nextTick(() => {
          setTimeout(() => {
            this.scrollToBottom()
          }, 300)
        })
      }
    },

    handleEnter (event) {
      if (event.shiftKey) {
      } else {
        event.preventDefault()
        this.sendMessage()
      }
    },

    sendMessage () {
      if (!this.messageText && !this.selectedImage) {
        return false
      }

      this.disableButtonSend = true
      this.isLoadingSendMessage = true

      const payload = {
        url: this.url,
        topic: this.topic || this.dataDetailProyek.topic_chat_group,
        image: this.selectedImage ? this.selectedFile : null,
        created_at: new Date().toISOString(),
        message: this.messageText,
        sender_id: String(this.user.homecare_user_id),
        sender_image: this.user.profile_picture || '-',
        sender_phone: this.user.phone || '-',
        sender_name: this.user.name,
        sender_role: this.roleName
      }

      if (this.replyingTo) {
        payload.reply_to = this.replyingTo.id
      }

      const actionType =
        this.messageText && this.selectedImage
          ? 'groupChat/postGroupChatMessageImage'
          : this.messageText
            ? 'groupChat/postGroupChat'
            : 'groupChat/postGroupChatOnlyImage'

      this.$store
        .dispatch(actionType, payload)
        .then(() => {
          this.handleMessageSuccess()
        })
        .catch((error) => {
          console.error(error)
          this.handleMessageError()
        })
    },

    handleMessageSuccess () {
      this.resetMessageForm()
      this.getNewDataChatGroup()
      setTimeout(() => {
        this.scrollToBottom()
        this.focusTextarea()
      }, 300)

      this.$buefy.toast.open({
        message: 'Pesan Berhasil Terkirim',
        type: 'is-success',
        duration: 3000
      })
    },

    handleMessageError () {
      this.focusTextarea()
      this.resetMessageForm()
      this.getNewDataChatGroup()

      this.$buefy.toast.open({
        message: 'Pesan Gagal Terkirim',
        type: 'is-danger',
        duration: 3000
      })
    },

    resetMessageForm () {
      this.disableButtonSend = false
      this.isLoadingSendMessage = false
      this.messageText = '' || null || undefined
      this.selectedImage = null || '' || undefined
      this.selectedFile = null || '' || undefined
      this.replyingTo = null || '' || undefined
    },

    handleReply (message) {
      this.replyingTo = message
      this.isShowDropdownReply = false
      this.focusTextarea()
    },

    cancelReply () {
      this.replyingTo = null
    },

    openGallery () {
      this.$refs.fileInput.click()
    },

    handleImageUpload (event) {
      const file = event.target.files[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.selectedImage = e.target.result
        }
        this.selectedFile = file
        reader.readAsDataURL(file)
      }
    },

    handleDeleteImage () {
      this.selectedImage = null
      this.selectedFile = null
    },

    handleOpenModalImage (image) {
      this.openImageSelected = image
      this.isOpeModalsImageChatGroup = true
    },

    async loadChatData () {
      if (this.isLoadingChatData) return

      try {
        const result = await getChatData(this.topic)
        this.getDataChatRooms = result.data || []

        // Ensure uniqueness
        const uniqueChat = new Map()
        for (const chat of this.getDataChatRooms) {
          uniqueChat.set(chat.id, chat)
        }
        this.getDataChatRooms = Array.from(uniqueChat.values())

        return result
      } catch (error) {
        console.error('Error loading chat data:', error)
        return { data: [] }
      } finally {
        setTimeout(() => {
          this.scrollToBottom()
          this.focusTextarea()
        }, 300)

        this.isLoadingChatData = false
      }
    },

    async loadMemberData () {
      try {
        const result = await getDataMembers(this.topic)
        this.getDataMembers = result.data || []

        const uniqueMembers = new Map()
        for (const member of this.getDataMembers) {
          uniqueMembers.set(member.id, member)
        }
        this.getDataMembers = Array.from(uniqueMembers.values())
        return result
      } catch (error) {
        console.error('Error loading member data:', error)
        return { data: [] }
      }
    },

    getNewDataChatGroup () {
      this.clearIntervals()

      this.intervalId = setInterval(() => {
        this.loadChatData()
        this.loadMemberData()
        this.readGroupChat()
      }, 100)

      setTimeout(() => {
        this.clearIntervals()
      }, 500)
    },

    getRoleSiteManager () {
      this.$store
        .dispatch('client/getRoleSiteManager', { url: this.url })
        .then((response) => {
          this.getDataSiteManager = response.data.data || []
        })
        .catch((error) => {
          alertErrorMessage(error)
        })
    },

    readGroupChat () {
      const payload = {
        url: this.url,
        topic: this.topic
      }

      this.$store.dispatch('groupChat/readGroupChat', payload).catch((error) => {
        console.error('Error reading group chat:', error)
      })
    },

    handleCheckboxChange (event, itemId) {
      if (!Array.isArray(this.selectedItems)) {
        this.selectedItems = []
      }

      if (event.target.checked) {
        this.selectedItems.push(itemId)
      } else {
        this.selectedItems = this.selectedItems.filter((id) => id !== itemId)
      }

      this.isDisabledButtonSM = this.selectedItems.length === 0
    },

    postSiteManagerGroupChat () {
      if (!this.selectedItems.length) return

      const payload = {
        url: this.url,
        site_manager_ids: this.selectedItems,
        project_id: this.dataHeader.id
      }

      this.$store
        .dispatch('projectV2/postSiteManagerGroupChat', payload)
        .then(() => {
          this.tabActive = 'contentMembers'
          this.selectedItems = []
          this.isDisabledButtonSM = true
          this.$buefy.toast.open({
            message: 'Berhasil Menambahkan Site Manager ke Grup Chat',
            type: 'is-success',
            duration: 3000
          })
          this.getNewDataChatGroup()
        })
        .catch((error) => {
          console.error(error)
          this.$buefy.toast.open({
            message: 'Gagal Menambahkan Site Manager ke Grup Chat',
            type: 'is-danger',
            duration: 3000
          })
        })
    },

    buttonDeleteReInviteWorkers (id, name, role, reInvite) {
      if (!id) return
      this.isLoadingRemoveWorker = true

      this.$swal({
        imageUrl: alertProject,
        imageAlt: 'cancel offering',
        imageWidth: 250,
        width: '600px',
        padding: '10px 10px 30px 10px',
        title: `<p class="averta-bold label-22" style="margin-bottom: -10px;">${
          reInvite
            ? `Undang Kembali Tukang (${name}) dari Grup Chat?`
            : 'Hapus Tukang dari Grup Chat'
        }</p>`,
        html: `<p class="averta-reguler">${
          reInvite
            ? 'Pastikan kamu sudah memastikan sebelum undang kembali'
            : 'Tukang ini tidak akan bisa lagi mengakses chat lagi'
        }</p>`,
        showCancelButton: true,
        confirmButtonText: reInvite ? 'Oke, Undang Lagi' : 'Lanjutkan',
        cancelButtonText: reInvite ? 'Tidak Jadi' : 'Batalkan',
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn-hapus-detail-component ',
          cancelButton: 'btn-batal-detail-component'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const payload = {
            url: this.url,
            topic: this.topic,
            id: id,
            role: role
          }

          this.$store
            .dispatch(
              reInvite ? 'groupChat/reInviteWorkersGroupChat' : 'groupChat/deleteWorkersGroupChat',
              payload
            )
            .then(() => {
              this.isLoadingRemoveWorker = true
              this.$buefy.toast.open({
                message: reInvite
                  ? 'Berhasil Mengundang Kembali Tukang ke Grup Chat'
                  : 'Berhasil Menghapus Tukang dari Grup Chat',
                type: 'is-success',
                duration: 3000
              })
            })
            .catch((error) => {
              this.isLoadingRemoveWorker = false
              console.error(error)
              this.$buefy.toast.open({
                message: reInvite
                  ? 'Gagal Mengundang Kembali Tukang ke Grup Chat'
                  : 'Gagal Menghapus Tukang dari Grup Chat',
                type: 'is-danger',
                duration: 3000
              })
            })
            .finally(() => {
              this.getNewDataChatGroup()
              this.isLoadingRemoveWorker = false
              this.reInvite = false
            })
        } else {
          this.isLoadingRemoveWorker = false
        }
      })
    },

    scrollToBottom () {
      this.newMessageGroupChat = false
      this.$nextTick(() => {
        const cardComments = this.$refs.cardComments
        if (cardComments) {
          cardComments.scrollTo({
            top: cardComments.scrollHeight,
            behavior: 'smooth'
          })
          this.isScrolledToBottom = true
        }
      })
    },

    checkScrollPosition () {
      const cardComments = this.$refs.cardComments
      if (!cardComments) return

      const { scrollTop, scrollHeight, clientHeight } = cardComments
      const distanceFromBottom = scrollHeight - scrollTop - clientHeight
      this.isScrolledToBottom = distanceFromBottom < this.scrollThreshold
    },

    handleScroll () {
      if (this.scrollTimeout) {
        clearTimeout(this.scrollTimeout)
      }

      this.scrollTimeout = setTimeout(() => {
        this.checkScrollPosition()
      }, 100)
    },

    scrollToMessage (messageId) {
      this.$nextTick(() => {
        const messageElement = document.getElementById('message-' + messageId)

        if (messageElement && this.$refs.cardComments) {
          this.highlightedMessage = messageId
          messageElement.classList.add('highlighted-message')
          const cardComments = this.$refs.cardComments
          const messageTop = messageElement.offsetTop

          cardComments.scrollTo({
            top: messageTop - 100,
            behavior: 'smooth'
          })

          setTimeout(() => {
            messageElement.classList.remove('highlighted-message')
            this.highlightedMessage = null
          }, 2000)
        }
      })
    },

    playAudioNewMessage () {
      const audio = new Audio(require('@/assets/audio/Notifikasi-Kanggo.mp3'))
      audio.play()
    },

    formatTime (timestamp) {
      if (!timestamp) return ''
      try {
        const date = new Date(timestamp.seconds * 1000 + Math.floor(timestamp.nanoseconds / 1e6))
        return moment(date).format('H:mm')
      } catch (error) {
        console.error('Error formatting time:', error)
        return ''
      }
    },

    timeStamp (timestamp) {
      if (!timestamp) return ''

      try {
        const date = new Date(timestamp.seconds * 1000 + Math.floor(timestamp.nanoseconds / 1e6))
        const now = moment()
        const messageDate = moment(date)
        const formattedDate = messageDate.format('dddd, D MMMM YYYY')

        if (now.isSame(messageDate, 'day')) {
          return `Hari Ini - ${formattedDate}`
        } else if (now.clone().subtract(1, 'days').isSame(messageDate, 'day')) {
          return `Kemarin - ${formattedDate}`
        } else {
          return formattedDate
        }
      } catch (error) {
        console.error('Error formatting timestamp:', error)
        return ''
      }
    },

    truncateString (str, num) {
      if (!str) return ''
      if (str.length <= num) {
        return str
      }
      return str.slice(0, num) + '...'
    },

    getReplyPreviewText (replyTo) {
      if (!replyTo) return ''

      if (replyTo.message && replyTo.message.length > 0) {
        return this.truncateString(replyTo.message, 20)
      }
      return 'Foto'
    },

    getReplyContent (replyTo) {
      if (!replyTo) return ''

      if (replyTo.message && replyTo.image_url) {
        return this.truncateString(replyTo.message, 65)
      } else if (replyTo.image_url) {
        return 'Foto'
      } else if (replyTo.message) {
        return this.truncateString(replyTo.message, 65)
      }
      return ''
    },

    isCurrentUser (senderId) {
      return senderId === String(this.user.homecare_user_id)
    },

    isSenderMessage (item) {
      return item.sender_name !== this.user.name || item.sender_id === this.user.homecare_user_id
    },

    isSpecialRole (role) {
      return role === 'Konsultan' || role === 'Site Manager'
    },

    closeSidebarGroupChat (newValue) {
      this.messageText = ''
      this.selectedImage = null
      this.selectedFile = null
      this.replyingTo = null
      this.getDataChatRooms = []
      this.getDataMembers = []
      this.$emit('closeSidebarGroupChat', newValue)
      this.tabActive = 'contentChat'
      setTimeout(() => {
        this.isLoading = true
      }, 250)
    }
  }
}
</script>

<style scoped>
.sidebar-group-chat {
  position: fixed;
  top: 0;
  right: -100%;
  display: flex;
  flex-direction: column;
  width: 20dvw;
  height: 100dvh;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
  background: #ffffff;
  transition: width 0.85s ease-in-out, right 0.45s ease-in-out, opacity 0.45s ease-in-out;
  border-left: 1px solid #e1e1e1;
  z-index: 999;
  opacity: 0;
  -webkit-box-shadow: 0px 0px 74px 11px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 74px 11px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 74px 11px rgba(0, 0, 0, 0.2);
}

.sidebar-group-chat.show {
  right: 0;
  width: 50dvw;
  opacity: 1;
}

.avatar-image {
  display: flex;
  flex-direction: row;
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  height: 50px;
  min-height: 50px;
  max-height: 50px;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: 50%;
  overflow: hidden;
}

.avatar-image > img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.wrapper-checkbox-input {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  height: 50px;
  min-height: 50px;
  max-height: 50px;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: 50%;
  overflow: hidden;
}

.checkbox-input {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  width: 25px;
  height: 25px;
  align-items: center;
  background: transparent;
  border-radius: 10px !important;
}

.wrapper-input-text-message-chat {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  height: max-content;
  max-height: 100%;
  justify-content: center;
  align-items: center;
  background: transparent;
}

.input-text-message-chat {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  height: max-content;
  max-height: 150px;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 1px solid #e1e1e1;
  padding: 1rem 3rem;
  border-radius: 10px;
  field-sizing: content !important;
  resize: none !important;
  min-block-size: 3.5rlh;
  min-inline-size: 20ch;
  max-inline-size: 50ch;
}

.wrapper-preview-image {
  position: absolute;
  top: 45px;
  left: 75px;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  width: 65px;
  min-width: 65px;
  max-width: 65px;
  height: 65px;
  min-height: 65px;
  max-height: 65px;
  justify-content: center;
  padding: 0;
  align-items: center;
  background: transparent;
}

.preview-image {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  justify-content: center;
  align-items: center;
  background: transparent;
}

.close-image-preview {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  background: #e10009;
  border-radius: 50%;
}

.icon-clip-group-chat {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: 50%;
}

.content-chat-group {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: auto;
  max-height: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 1rem;
  overflow: hidden;
  background: #ffffff;
  background: url(https://storage.googleapis.com/kang-go.appspot.com/b2b-client/development/1740642695077_LU6aa.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446992400&Signature=q%2BPeZFFmEayvuxmrru%2FOLv67TgmHqJTQcobBrp4sTARudQ%2BAMQzQ%2B0xZLeS1NpzbeHLeVkiODgcBllygjxYTNqNnJnVnIIBujZ%2BXsghaSVIPesRJg7Acus%2FYdM9zXg2Mfp%2FBetr1NGk7nX9ghancvLnhYiEXQ3BW0B3O4CntCqAfY6EkWKo9CYcQO6cio5CAabSuvJyNr1jsoATQaP8rf6Q5i4zfCCy7a%2F6XzJ8A63jz0mDPrS8HlTtANta%2F5XU%2F2Bamnn5io95VWF2NgauzW8c%2BBNAnnasfbxH77ScdJc0wWjnkVgAbA0vzoYOuoriwMC0Kg31duyGcOb4vFMBtmg%3D%3D);
  background-size: contain;
  background-repeat: repeat;
  background-position: center;
}

.wrapper-content-chat {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: auto;
  max-width: 100%;
  height: 100%;
  min-height: auto;
  max-height: 100%;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  padding: 1rem 0;
  overflow: auto;
  background: transparent;
  overflow: auto;
  transition: opacity 1s ease-in;
  opacity: 0;
}

.content-list-members {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: auto;
  max-width: 100%;
  height: 100%;
  min-height: auto;
  max-height: 100%;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  padding: 1rem 0;
  overflow: auto;
  overflow: auto;
  background: white;
}

.content-add-members {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: auto;
  max-width: 100%;
  height: 100%;
  min-height: auto;
  max-height: 100%;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  padding: 1rem 0;
  overflow: auto;
  overflow: auto;
  background: white;
}

.list-members {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: auto;
  max-width: 100%;
  height: 100%;
  min-height: auto;
  max-height: 100%;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  overflow: auto;
  background: transparent;
}

.list-members-item {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: auto;
  max-width: 100%;
  height: 100px;
  min-height: 100px;
  max-height: 100%;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  overflow: auto;
  overflow: auto;
  background: transparent;
  border-bottom: 1px solid #e1e1e1;
}

.wrapper-content-chat.show {
  opacity: 1;
}

.wrapper-content-chat-user {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: auto;
  max-width: 100%;
  height: max-content;
  max-height: none;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 0 auto;
  padding: 0;
  background: transparent;
}

.content-chat-user {
  box-sizing: border-box;
  display: flex;
  flex-direction: row-reverse;
  width: 50%;
  min-width: 50%;
  max-width: 50%;
  height: max-content;
  max-height: none;
  justify-content: center;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  background: transparent;
}

.bubble-chat-user {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: auto;
  max-width: 100%;
  height: max-content;
  max-height: none;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0;
  padding: 1rem;
  background: #fce5e6;
  border-radius: 25px;
  box-shadow: 0px 5px 8px 0px #2525250d;
}

.wrapper-dropdown {
  position: absolute;
  top: 1rem;
  right: 0.85rem;
  z-index: 99;
}

.dropdown-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.357);
  z-index: 98;
  transition: opacity 0.3s ease;
}

.wrapper-reply-chat {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: auto;
  max-width: 100%;
  height: max-content;
  min-height: 50px;
  max-height: 100%;
  margin: 0 auto;
  padding: 0;
  background: #ffffff;
  z-index: 9999;
  border-radius: 20px;
}

.text-content-bubble-chat-user {
  font-weight: 500;
  color: #000000;
  word-break: break-all;
  white-space: pre-wrap;
  line-height: 1.6;
}

.wrapper-content-chat-sender {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: auto;
  max-width: 100%;
  height: max-content;
  max-height: none;
  justify-content: flex-end;
  align-items: flex-start;
  margin: 0 auto;
  padding: 0;
  background: transparent;
}

.content-chat-sender {
  display: flex;
  flex-direction: row;
  width: 50%;
  min-width: 50%;
  max-width: 50%;
  height: max-content;
  max-height: none;
  justify-content: center;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  background: transparent;
}

.bubble-chat-sender {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: auto;
  max-width: 100%;
  height: max-content;
  max-height: none;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0;
  padding: 1rem;
  background: #ffffff;
  border-radius: 25px;
  box-shadow: 0px 5px 8px 0px #2525250d;
}

.wrapper-bubble-reply {
  display: flex;
  flex-direction: row;
  width: 90%;
  min-width: auto;
  max-width: 90%;
  height: auto;
  max-height: none;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  margin-bottom: 1rem;
  padding: 0;
  background: #e10009;
  border-radius: 15px;
  cursor: pointer;
  box-shadow: 0px 5px 8px 0px #2525250d;
}

.content-bubble-chat {
  display: flex;
  flex-direction: column;
  width: 99%;
  min-width: auto;
  max-width: 99%;
  height: auto;
  max-height: 100%;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 1rem 1rem;
  background: pink;
  border-radius: 15px;
  box-shadow: 0px 5px 8px 0px #2525250d;
}

.text-content-bubble-chat-sender {
  font-weight: 500;
  color: #000000;
  word-break: break-all;
  white-space: pre-wrap;
  line-height: 1.6;
}

.wrapper-badge-joined-users {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: auto;
  max-width: 100%;
  height: auto;
  max-height: none;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0 !important;
}

.badge-joined-users {
  display: flex;
  flex-direction: row;
  width: auto;
  min-width: auto;
  max-width: auto;
  height: auto;
  max-height: none;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0.5rem 1rem;
  background: #f0f0f0;
  border-radius: 50px;
  border: 1px solid #e1e1e1;
}

.card-detail-project {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: auto;
  max-width: 100%;
  height: max-content;
  max-height: none;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 1rem;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 5px 8px 0px #2525250d;
}

.highlighted-message {
  background: #f1f1f1 !important;
  animation: highlight-pulse 2s ease-in-out;
}

@keyframes highlight-pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(225, 0, 9, 0.4);
  }
  50% {
    transform: scale(1.03);
    box-shadow: 0 0 0 10px rgba(225, 0, 9, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(225, 0, 9, 0);
  }
}

.wrapper-bullet-red {
  display: flex;
  flex-direction: row;
  width: 35px;
  min-width: 35px;
  max-width: 35px;
  height: 35px;
  min-height: 35px;
  max-height: 35px;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  background: red;
  border-radius: 50%;
}

.wrapper-reply-group-chat {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: auto;
  max-width: 100%;
  height: max-content;
  min-height: max-content;
  max-height: max-content;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  padding: 0;
  background: red;
  border-radius: 20px;
}

.bubble-reply-group-chat {
  display: flex;
  flex-direction: row;
  width: 99.5%;
  min-width: auto;
  max-width: 99.5%;
  height: max-content;
  min-height: max-content;
  max-height: max-content;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0.85rem 1.5rem;
  background: #fafafa;
  border-radius: 20px;
}

.wrapper-image-chat {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: auto;
  max-width: 100%;
  height: 250px;
  min-height: 250px;
  max-height: 250px;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  overflow: hidden;
  border-radius: 20px;
}

.wrapper-image-chat-reply {
  display: flex;
  flex-direction: row;
  width: 85px;
  min-width: 85px;
  max-width: 85px;
  height: 85px;
  min-height: 85px;
  max-height: 85px;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  border-radius: 10px;
  background: transparent;
  overflow: hidden !important;
}

.wrapper-image-chat-reply img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.wrapper-open-modals-image {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: auto;
  max-width: 100%;
  height: 700px;
  min-height: 700px;
  max-height: 700px;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.wrapper-open-modals-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.new-message-floating {
  position: absolute;
  bottom: 20px;
  display: flex;
  flex-direction: row;
  width: max-content;
  min-width: auto;
  max-width: max-content;
  height: auto;
  min-height: auto;
  max-height: auto;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 1rem 2rem;
  background: #e10009;
  border-radius: 20px;
  cursor: pointer;
  z-index: 9999;
  box-shadow: 0px 5px 8px 0px #2525250d;
}

@media (max-width: 768px) {
  .sidebar-group-chat.show {
    width: 100%;
  }

  .content-chat-user,
  .content-chat-sender {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  .wrapper-open-modals-image {
    height: 400px;
    min-height: 400px;
    max-height: 400px;
  }
}
</style>
