import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth, signInWithCustomToken, onAuthStateChanged } from 'firebase/auth'

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: 'kang-go',
  storageBucket: 'kang-go.appspot.com',
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
}

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)
const db = getFirestore(firebaseApp)
const auth = getAuth(firebaseApp)

// Track authentication state
let isAuthenticated = false

// Set up auth state listener
onAuthStateChanged(auth, (user) => {
  isAuthenticated = !!user
  console.log('Auth state changed:', isAuthenticated ? 'Logged in' : 'Logged out')
})

export function getFirebaseToken () {
  const dataLocalStorage = JSON.parse(localStorage.getItem('webapp_shell_kanggo'))
  const loginFirebaseToken = dataLocalStorage.user.firebase_token || dataLocalStorage.tokenFirebase
  loginWithFirebaseToken(loginFirebaseToken)
  return loginFirebaseToken
}

// Function to login using Firebase token
const loginWithFirebaseToken = async (getFirebaseToken) => {
  try {
    // Check if user is already logged in
    if (auth.currentUser) {
      console.log('User already logged in:', auth.currentUser.uid)
      return auth.currentUser
    }

    // Ensure token is provided
    if (!getFirebaseToken) {
      throw new Error('Firebase token is required')
    }

    // Sign in with the provided token
    const userCredential = await signInWithCustomToken(auth, getFirebaseToken)
    return userCredential.user
  } catch (error) {
    console.error('Firebase authentication error:', error.code, error.message)
    alert(error.message)
    throw error
  }
}

export { db, auth, loginWithFirebaseToken }
