<template>
  <svg width="45" height="auto" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.87484 15.5C2.454 15.5 1.2915 14.3375 1.2915 12.9167V6.45833C1.2915 5.0375 2.454 3.875 3.87484 3.875H14.2082C15.629 3.875 16.7915 5.0375 16.7915 6.45833V12.9167C16.7915 14.3375 15.629 15.5 14.2082 15.5H11.6248V19.375L7.74984 15.5H3.87484ZM27.1248 23.25C28.5457 23.25 29.7082 22.0875 29.7082 20.6667V14.2083C29.7082 12.7875 28.5457 11.625 27.1248 11.625H19.3748V12.9167C19.3748 15.7583 17.0498 18.0833 14.2082 18.0833V20.6667C14.2082 22.0875 15.3707 23.25 16.7915 23.25H19.3748V27.125L23.2498 23.25H27.1248Z"
      fill="url(#paint0_linear_1_73734)"
    />
    <path
      d="M9.81646 8.26636L8.26904 9.81377"
      stroke="white"
      stroke-width="0.904167"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.81807 9.81575L8.26807 8.26575"
      stroke="white"
      stroke-width="0.904167"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.05615 9.04301C6.05615 11.2831 6.80306 12.03 9.04313 12.03C11.2832 12.03 12.0301 11.2831 12.0301 9.04301C12.0301 6.80294 11.2832 6.05603 9.04313 6.05603C6.80306 6.05603 6.05615 6.80294 6.05615 9.04301Z"
      stroke="white"
      stroke-width="0.904167"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1_73734"
        x1="1.2915"
        y1="15.5"
        x2="29.7008"
        y2="15.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#891313" />
        <stop offset="1" stop-color="#D92727" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'ExpiredMessageIcon'
}
</script>
