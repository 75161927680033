import { render, staticRenderFns } from "./TabListKoordinatorEngineering.vue?vue&type=template&id=2d4509d2"
import script from "./TabListKoordinatorEngineering.vue?vue&type=script&lang=js"
export * from "./TabListKoordinatorEngineering.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports