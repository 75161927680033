<template>
  <ModalTemplate :showModal="isModalActive" :width="widthModal" @closeModal="closeModal">
      <template v-slot:headerModal>
          <div class="is-flex is-justify-content-space-between is-align-items-center">
              <div class="is-flex is-justify-content-space-between is-align-items-center">
                  <PlusGradient color="#ffffff" class="mr-2" />
                  <p class="averta-bold label-18">Tambah Keahlian</p>
              </div>
              <div class="is-clickable" @click="closeModal">
                  <b-icon icon="close"></b-icon>
              </div>
          </div>
      </template>
      <template v-slot:bodyModal>
          <div class="wrap-body-modal p-5">
              <!-- Tanggal Pengerjaan -->
              <div class="date-project is-flex is-justify-content-space-between is-align-items-center">
                  <div class="is-flex is-align-items-center">
                      <ScheduleIcon color="#D9272D" class="mr-3" />
                      <p class="averta-bold label-14">Tanggal Pengerjaan</p>
                  </div>
                  <p class="averta-bold label-14">{{ convertDate(selectedDate) }}</p>
              </div>
              <!-- Tanggal Pengerjaan -->

              <!-- Pilih Skill -->
              <div class="choose-skill is-flex is-justify-content-space-between is-align-items-center mt-5">
                  <div class="is-flex is-justify-content-space-between is-align-items-center">
                      <img v-if="fixSelectedSkill" :src="fixSelectedSkill.image_uri" alt="setting" width="39" class="mr-3" />
                      <img v-else :src="require('@/assets/img/setting-skill.png')" alt="setting" width="39" class="mr-3" />
                      <div>
                          <p class="text-grey mb-1">Jenis Keahlian</p>
                          <p class="averta-bold" v-if="fixSelectedSkill">{{ fixSelectedSkill.name }}</p>
                          <p class="averta-bold text-grey" v-else><i>Belum ditentukan</i></p>
                      </div>
                  </div>
                  <div>
                      <ButtonApp :isSecondary="true" :isBold="true" :isTransparent="true" :width="fixSelectedSkill ? '80px' : '150px'" height="40px" @click="showModalAddSkill">{{ fixSelectedSkill ? 'Ubah' : 'Pilih Keahlian' }}</ButtonApp>
                  </div>
              </div>
              <!-- Pilih Skill -->

              <div class="divider"></div>

              <!-- Sesi dan jumlah tukang -->
              <div class="wrap-sesi is-flex is-align-items-center is-justify-content-space-between">
                <div class="flex-1 wrap-dropdown mr-5">
                  <p class="text-grey mb-3">Sesi Pengerjaan <span class="text-merah">*</span></p>
                  <b-dropdown aria-role="list" v-model="sesi" :disabled="fixSelectedSkill ? false : true">
                    <template #trigger="{ active }">
                      <b-button :icon-right="active ? 'menu-up' : 'menu-down'" class="is-flex is-align-items-center is-justify-content-space-between">
                        <div class="is-flex is-align-items-center pr-6">
                          <p>{{ convertSesi(sesi) }}</p>
                        </div>
                      </b-button>
                    </template>
                    <b-dropdown-item aria-role="listitem" value="Full Day">Sesi Full Day</b-dropdown-item>
                    <b-dropdown-item aria-role="listitem" value="Pagi">Sesi Pagi</b-dropdown-item>
                    <b-dropdown-item aria-role="listitem" value="Sore">Sesi Sore</b-dropdown-item>
                    <b-dropdown-item aria-role="listitem" value="Malam">Sesi Malam</b-dropdown-item>
                    <b-dropdown-item aria-role="listitem" value="Sesi 2">Sesi 2</b-dropdown-item>
                    <b-dropdown-item aria-role="listitem" value="Sesi 4">Sesi 4</b-dropdown-item>
                  </b-dropdown>
                </div>
                <div class="flex-1">
                  <p class="text-grey mb-3">Jumlah Tukang <span class="text-merah">*</span></p>
                    <input
                      :disabled="fixSelectedSkill ? false : true"
                      type="number"
                      min="0"
                      placeholder="0"
                      oninput="validity.valid||(value='');"
                      class="input input-number averta-bold"
                      v-model="jumlahTukang"
                    />
                </div>
              </div>
              <!-- Sesi dan jumlah tukang -->

              <!-- Alert -->
              <div class="alert is-flex is-align-items-center mt-5" v-if="typeError !== false">
                <InfoIcon class="mr-2" />
                <p class="averta-bold label-12 text-merah">Pilih {{ typeError }} yang akan ditambahkan terlebih dahulu</p>
              </div>
              <!-- Alert -->

              <!-- Floating Button -->
              <div class="floating-button is-flex is-justify-content-end is-align-items-center">
                <ButtonApp :isBold="true" :isDisabled="typeError !== false" @click="handleAddSkill">
                  <p class="py-2 px-3">Tambah Keahlian</p>
                </ButtonApp>
              </div>
              <!-- Floating Button -->
          </div>

          <!-- Modal List Skill -->
          <ModalTemplate :showModal="modalSkills" :width="'40vw'" @closeModal="showModalAddSkill">
              <template v-slot:headerModal>
                <div class="is-flex is-justify-content-space-between is-align-items-center">
                    <div class="is-flex is-justify-content-space-between is-align-items-center">
                        <PlusGradient color="#ffffff" class="mr-2" />
                        <p class="averta-bold label-18">Pilih Keahlian</p>
                    </div>
                    <div class="is-clickable" @click="showModalAddSkill">
                        <b-icon icon="close"></b-icon>
                    </div>
                </div>
              </template>
              <template v-slot:bodyModal>
                <div class="wrap-body-modal-skill">

                  <!-- Search Skill -->
                  <b-field class="pt-5 pb-3 px-5">
                    <b-input class="search-wrapper" placeholder="Cari Keahlian" type="search" icon="magnify" v-model="searchSkill" @input="handleSearchSkill"></b-input>
                  </b-field>
                  <!-- Search Skill -->

                  <!-- List Skill -->
                  <div v-if="skills.length > 0 && !isLoading">
                    <!-- Selected Skill -->
                    <!-- <div v-if="selectedSkill && selectedSkill.id" class="is-selected">
                      <div class="is-flex is-justify-content-space-between is-align-items-center mb-4 px-5">
                        <div class="is-flex is-align-items-center">
                          <div class="img-wrapper p-2 mr-3">
                            <img :src="selectedSkill.image_uri" alt style="height: 100%" />
                          </div>
                          <p class="mr-2 averta-bold label-14">{{ selectedSkill.name }}</p>
                          <CheckIcon color="#10BD41" size="14" />
                        </div>
                        <div class="is-clickable is-flex">
                          <ButtonApp v-if="selectedSkill && selectedSkill.id" :isTransparent="true" :isSecondary="true" :isBold="true" @click="handleCancelSelectSkill">
                            <p class="color-red averta-regular py-1 px-3">Batalkan</p>
                          </ButtonApp>
                          <ButtonApp v-else :isDisabled="selectedSkill && selectedSkill.id" :isTransparent="true" :isSecondary="true" :isBold="true" @click="handleSelectSkills(selectedSkill)">
                            <p class="py-1 px-3">Pilih</p>
                          </ButtonApp>
                        </div>
                      </div>
                    </div> -->
                    <!-- Selected Skill -->

                    <!-- Non Selected Skill -->
                    <div v-for="item, indexSkill in skillSearch" :key="`${indexSkill}-skill`" :class="{'is-selected': selectedSkill.id === item.id, 'is-not-selected': selectedSkill.id && selectedSkill.id !== item.id}" class="is-hovered cursor-pointer" @click="handleSelectSkillsHovered(item)">
                      <div class="is-flex is-justify-content-space-between is-align-items-center mb-4 px-5">
                        <div class="is-flex is-align-items-center">
                          <div class="img-wrapper p-2 mr-3">
                            <img :src="item.image_uri" alt style="height: 100%" />
                          </div>
                          <p class="mr-2 averta-bold label-14">{{ item.name }}</p>
                          <CheckIcon v-if="selectedSkill.id === item.id" color="#10BD41" size="14" />
                        </div>
                        <div class="is-clickable is-flex">
                          <!-- <ButtonApp :isDisabled="selectedSkill.id !== item.id" :isTransparent="true" :isSecondary="true" :isBold="true" @click="handleSelectSkills(item)">
                            <p class="py-1 px-3">Pilih</p>
                          </ButtonApp> -->

                          <!-- <ButtonApp v-if="selectedSkill.id === item.id" :isTransparent="true" :isSecondary="true" :isBold="true" @click="handleCancelSelectSkill">
                            <p class="color-red averta-regular py-1 px-3">Batalkan</p>
                          </ButtonApp> -->
                          <ButtonApp :isTransparent="true" :isSecondary="true" :isBold="true"
                          @click.stop="selectedSkill.id === item.id ? handleCancelSelectSkill() : handleSelectSkills(item)">
                            <p class="py-1 px-3">{{ selectedSkill.id === item.id ? 'Batalkan' : 'Pilih' }} </p>
                          </ButtonApp>

                        </div>
                      </div>
                    </div>
                    <!-- Non Selected Skill -->

                    <!-- Empty Search -->
                    <div v-if="(!selectedSkill.id && skillSearch.length === 0) || (selectedSkill.id && skillSearch.slice(1).length === 0)" class="w-100 h-100 is-flex is-align-items-center is-justify-content-center is-flex-direction-column" :class="{'adjust-margin-top': selectedSkill.id && skillSearch.slice(1).length === 0}">
                      <ProyekEmptyIcon />
                      <p class="averta-black label-14">Pencarian tidak ditemukan</p>
                      <p class="label-14">Periksa kembali kata kunci pencarian yang dimasukan</p>
                    </div>
                  </div>
                  <!-- List Skill -->
                </div>

                  <!-- Floating Button Skill -->
                  <div class="floating-button-skill is-flex is-justify-content-end is-align-items-center">
                    <ButtonApp :isBold="true" :isDisabled="selectedSkill === null" @click="handleSaveSkill">
                      <p class="py-2 px-3">Simpan Keahlian</p>
                    </ButtonApp>
                  </div>
                  <!-- Floating Button Skill -->
              </template>
          </ModalTemplate>
          <!-- Modal List Skill -->

          <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false">
            <SpinnerIcon />
          </b-loading>
      </template>
  </ModalTemplate>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import ModalTemplate from '@/components/modal/ModalTemplate'
import ButtonApp from '@/components/button/ButtonApp'
import saveImage from '@/assets/img/delete-area-component.png'
import { PlusGradient, ScheduleIcon, InfoIcon, CheckIcon, SpinnerIcon, ProyekEmptyIcon } from '@/components/icons'

export default {
  name: 'ModalAddSkill',
  components: {
    ModalTemplate,
    PlusGradient,
    ScheduleIcon,
    ButtonApp,
    InfoIcon,
    CheckIcon,
    SpinnerIcon,
    ProyekEmptyIcon
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '75vw'
    },
    selectedDate: {
      type: String,
      default: null
    },
    componentId: {
      type: Number,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    typeError () {
      if (!this.fixSelectedSkill) {
        return 'keahlian'
      } else if (!this.sesi) {
        return 'sesi'
      } else if (!this.jumlahTukang || this.jumlahTukang < 1) {
        return 'jumlah tukang'
      }
      return false
    }
  },
  data () {
    return {
      isLoading: false,
      isModalActive: false,
      widthModal: this.width,
      sesi: null,
      searchSkill: '',
      skills: [],
      skillSearch: [],
      modalSkills: false,
      selectedSkill: {
        id: null
      },
      fixSelectedSkill: null,
      jumlahTukang: null
    }
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
      this.fixSelectedSkill = null
      this.sesi = null
      this.jumlahTukang = null
    },
    convertSesi (sesi) {
      switch (sesi) {
        case 'Full Day':
          return 'Sesi Full Day'

        case 'Pagi':
          return 'Sesi Pagi'

        case 'Sore':
          return 'Sesi Sore'

        case 'Malam':
          return 'Sesi Malam'

        case 'Sesi 2':
          return 'Sesi 2'

        case 'Sesi 4':
          return 'Sesi 4'

        default:
          return 'Pilih Sesi'
      }
    },
    getDataSkillTukang () {
      this.isLoading = true
      const payload = {
        url: this.url,
        search: this.searchSkill
      }
      this.$store
        .dispatch('skill/getListSkills', payload)
        .then((response) => {
          this.skills = response.data.data
          this.skillSearch = response.data.data
          if (this.fixSelectedSkill) {
            this.selectedSkill = this.fixSelectedSkill
            this.moveSkillToFirstPosition()
          } else {
            this.selectedSkill = {
              id: null
            }
          }
          this.isLoading = false
        })
        .catch((error) => {
          console.log(error)
          this.$buefy.toast.open({
            message: 'Gagal memuat data Skill Tukang, silahkan coba lagi',
            type: 'is-danger'
          })
          this.isLoading = false
        })
    },
    showModalAddSkill () {
      this.modalSkills = !this.modalSkills
      if (this.modalSkills) {
        this.getDataSkillTukang()
      }
    },
    handleSelectSkillsHovered (item) {
      if (this.selectedSkill.id === item.id) {
        this.selectedSkill = { id: null }
      } else {
        this.selectedSkill = item
      }
    },
    handleSelectSkills (item) {
      if (this.selectedSkill.id && this.selectedSkill.id !== item.id) return
      this.selectedSkill = item
    },
    handleCancelSelectSkill () {
      this.selectedSkill = {
        id: null
      }
    },
    handleSearchSkill () {
      const findSkill = this.skills.filter(item =>
        item.name.toLowerCase().includes(this.searchSkill.toLowerCase())
      )
      this.skillSearch = findSkill

      if (this.selectedSkill.id && findSkill) {
        this.moveSkillToFirstPosition()
      }
    },
    handleSaveSkill () {
      if (this.selectedSkill) {
        this.fixSelectedSkill = this.selectedSkill
        this.searchSkill = ''
        this.modalSkills = false
      }
    },
    handleAddSkill () {
      if (this.fixSelectedSkill && this.sesi && this.jumlahTukang && this.jumlahTukang > 0) {
        this.$swal({
          imageUrl: saveImage,
          imageAlt: 'save data',
          width: '540px',
          padding: '10px 10px 30px 10px',
          title: '<p class="averta-bold label-22">Tambah Keahlian</p>',
          html: '<p>Pastikan kamu sudah cek kembali seluruh informasi telah benar dan sesuai</p>',
          showCancelButton: true,
          confirmButtonText: 'Tambah Keahlian',
          cancelButtonText: 'Batalkan',
          reverseButtons: true,
          customClass: {
            confirmButton: 'btn-hapus-detail-component',
            cancelButton: 'btn-batal-detail-component'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true
            const payload = {
              url: this.url,
              data: {
                project_id: this.$route.params.proyekId,
                item_component_id: this.componentId,
                date: this.selectedDate,
                skill_id: this.fixSelectedSkill.id,
                sesi: this.sesi,
                total_worker: this.jumlahTukang
              }
            }

            this.$store.dispatch('projectV2/addSkill', payload).then(() => {
              this.$buefy.toast.open({
                message: 'Berhasil menambahkan keahlian',
                type: 'is-success'
              })
              this.reloadPage(this.selectedDate)
              this.isLoading = false
            }).catch((error) => {
              console.log(error.response)
              if (error.response.status === 400) {
                this.$swal({
                  imageUrl: saveImage,
                  imageAlt: 'save data',
                  width: '500px',
                  padding: '10px 10px 30px 10px',
                  title: '<p class="averta-bold label-22">Keahlian Tidak Dapat Ditambahkan</p>',
                  html: `
                <div>
                  <p class="mb-3 label-14">Terdapat keahlian yang sama dengan sesi yang berbeda telah ditambahkan.</p>
                  <p class="averta-bold py-4 px-2 has-background-info-light color-blue-1 border-radius-10 overflow-hidden">Hapus keahlian dengan sesi berbeda terlebih dahulu untuk menambahkan kembali keahlian ini</p>
                </div>
                `,
                  confirmButtonText: 'Mengerti',
                  customClass: {
                    confirmButton: 'btn-hapus-detail-component',
                    cancelButton: 'btn-batal-detail-component'
                  }
                }).then((result) => {
                  if (result.isConfirmed) {

                  }
                })
              }
              this.$buefy.toast.open({
                message: 'Gagal simpan data',
                type: 'is-danger'
              })
              this.isLoading = false
            })
          }
        })
      }
    },
    reloadPage () {
    // this.$emit('reloadPage', { selectedDate: this.selectedDate, componentId: this.componentId })
      this.$emit('reloadPage')
      this.closeModal()
    },
    convertDate (date) {
      if (!date) {
        return '-'
      }
      return moment(date).format('DD MMMM YYYY')
    },
    moveSkillToFirstPosition () {
    // Find the object with selected: true
      const findSkill = this.skills.find(item => item.id === this.selectedSkill.id)

      // Remove the selected object from its current position
      const removeSkill = this.skillSearch.indexOf(findSkill)
      if (removeSkill !== -1) {
        this.skillSearch.splice(removeSkill, 1)
      }

      // Add the selected object to the first position in the array
      this.skillSearch.unshift(findSkill)
    }
  },
  watch: {
    showModal (newValue) {
      this.isModalActive = newValue
    },
    width (newValue) {
      this.widthModal = newValue
    }
  }
}
</script>

<style scoped>

.wrap-body-modal {
  height: 70vh;
  overflow-y: auto;
  position: relative;
}

.date-project {
  background: #3185FC1A;
  padding: 8px 10px;
  border-radius: 100px;
}

.choose-skill {
background: #FAFAFA;
padding: 12px;
border-radius: 12px;
}

.divider {
border-bottom: 2px dashed #E1E1E1;
margin: 30px 0px;
}

.wrap-dropdown > div,
.wrap-dropdown > button {
width: 100%;
}

.input-number {
text-align: center;
}

.alert {
background: var(--Gradient-Red-Red-01, linear-gradient(90deg, #FDD 0%, #FFEFDB 99.97%));
padding: 8px;
border-radius: 8px;
}

.alert svg {
width: 18px;
height: 18px;
}

.floating-button {
position: absolute;
bottom: 0;
right: 0;
width: 100%;
padding: 12px;
border-radius: 0px 0px 12px 12px;
box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.05);
z-index: 1;
background: white;
}

.wrap-body-modal-skill {
  height: 73vh;
  overflow-y: auto;
  position: relative;
  padding-bottom: 80px;
}

.img-wrapper {
width: 59px;
height: 59px;
box-shadow: 6px 7px 50px rgba(0, 0, 0, 0.08);
border-radius: 12px;
overflow: hidden;
}

.is-hovered:hover {
background: rgba(16, 189, 65, 0.10) !important;
}

.is-selected {
background: rgba(16, 189, 65, 0.10);
}

.is-not-selected {
opacity: 0.5;
}

.floating-button-skill {
position: absolute;
bottom: 0;
right: 0;
width: 100%;
padding: 12px;
border-radius: 0px 0px 12px 12px;
box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.05);
z-index: 1;
background: white;
}

.adjust-margin-top {
margin-top: -40px;
}

</style>
