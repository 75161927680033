var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{class:[
    'navbarTipeOffering',
    _vm.isPriceActual ? 'background-color-actual-price' : 'background-color-tenant-price',
    'is-flex',
    'is-flex-direction-row',
    'is-justify-content-flex-start',
    'c-w-100',
    'px-6',
    'mb-4'
  ],style:(_vm.styleProps)},[_c('img',{staticClass:"navs-images",attrs:{"src":require("@/assets/img/ActualPriceIcon.svg"),"alt":"ActualPriceIcon"}}),_c('div',{staticClass:"is-flex is-flex-direction-column is-justify-content-center"},[_c('h5',{staticClass:"averta-bold"},[_vm._v(" "+_vm._s(_vm.isPriceActual ? 'Harga Dasar' : 'Harga Tenant')+" ")]),_c('p',{staticClass:"averta-regular"},[_vm._v(" Tampilan ini adalah detail penawaran yang disajikan "+_vm._s(_vm.isPriceActual ? ' oleh Kanggo untuk Client' : ' Client Kanggo untuk Customer/Tenant')+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }