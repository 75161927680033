<template>
  <div class="c-w-shell min-h-80vh is-flex">
    <div class="sidebar-monitoring" :class="{ 'minimize-sidebar': minimize }">
      <div class="flex-center-vertical is-justify-content-space-between p-4">
        <div class="flex-center is-clickable" @click="goBack">
          <ArrowLeftIcon color="#868686" />
          <p class="averta-bold color-grey ml-2 label-16">Kembali</p>
        </div>
        <img
          class="is-clickable minimize-icon"
          :src="require('@/assets/icons/minimize-icon-used.svg')"
          alt=""
          @click="handleMinimizeSidebar"
        />
      </div>

      <div class="v-calendar-wrapper-normal border-bottom-1 p-2">
        <v-date-picker v-model="selectedDate" :from-page="fromPage" is-expanded color="red" />
      </div>

      <div class="p-3 border-bottom-1">
        <p class="averta-bold white-space-nowrap">Cari Kata Kunci</p>
        <b-input
          placeholder="ID Survey"
          type="search"
          icon="magnify"
          class="search-left-monitoring"
          v-model="search"
          @keyup.native.enter="filteredData"
        >
        </b-input>
      </div>

      <div class="p-3 border-bottom-1">
        <p class="averta-bold white-space-nowrap">Filter Konsultan Survey</p>
        <b-autocomplete
          v-model="keywordConsultant"
          placeholder="Semua Konsultan Survey"
          class="search-left-monitoring"
          :open-on-focus="true"
          :data="filteredConsultant"
          field="name"
          @select="(option) => (selectedConsultant = option)"
          :clearable="true"
        />
      </div>

      <div class="p-3 border-bottom-1">
        <p class="averta-bold white-space-nowrap">Filter Client</p>
        <b-autocomplete
          v-model="client"
          placeholder="Semua Client"
          class="search-left-monitoring client"
          :open-on-focus="true"
          :data="filteredClient"
          field="name"
          @select="(option) => (selectedClient = option)"
          :clearable="true"
        />
      </div>
    </div>
    <div class="body-monitoring" ref="mainBody" :class="{ 'filled-monitoring': minimize }">
      <div class="header-current-date" :class="{ 'adjust-z-index': idHoverCard }">
        <div class="flex-center">
          <div class="date-minimize-icon flex-center" :class="{ 'expand-minimize-icon': minimize }">
            <img
              class="is-clickable"
              :src="require('@/assets/icons/minimize-icon-used.svg')"
              alt=""
              @click="handleMinimizeSidebar"
            />
          </div>
          <p class="averta-black label-20">{{ formatSelectedDate }}</p>
        </div>
        <div class="flex-center">
          <!-- <ButtonApp :isSecondary="true" class="mr-2" @click="openGuideSesi">
            <p class="p-1 averta-bold label-12">Panduan Sesi</p>
          </ButtonApp> -->
          <div class="previous-next-arrow mr-1 flex-center" @click="() => handlePrevNext('prev')">
            <img :src="require('@/assets/icons/arrow-back-used.svg')" alt="" />
          </div>
          <div class="previous-next-arrow flex-center" @click="() => handlePrevNext('next')">
            <img :src="require('@/assets/icons/arrow-forward-used.svg')" alt="" />
          </div>
        </div>
      </div>

      <div
        class="collapse-sesi-custom-wrapper"
        :class="{
          'minimize-sidebar': minimize,
          'removed-wrapper-sesi-custom': sesiCustom.length === 1 && sesiCustom[0].removed,
          'adjust-z-index': idHoverCard
        }"
        v-if="sesiCustom.length > 0"
      >
        <b-collapse
          class="collapse-sesi-custom"
          animation="slide"
          v-model="openCollapse"
          aria-id="sesi-custom"
        >
          <template #trigger="props">
            <div
              class="flex-center-vertical is-justify-content-space-between c-w-100"
              aria-controls="sesi-custom"
              :aria-expanded="props.open"
            >
              <div class="flex-center">
                <p class="averta-black label-15">
                  {{ sesiCustom.length }} Survey Custom
                  <span class="averta-reguler label-14">yang belum diatur jadwalnya</span>
                </p>
              </div>
              <ButtonApp :isSecondary="true" :isTransparent="true" :isBorderWhite="true">
                <div class="is-flex is-align-items-center py-1">
                  <p class="averta-bold mr-2 color-white">
                    {{ openCollapse ? 'Tutup Daftar' : 'Lihat Daftar' }}
                  </p>
                  <div class="chevron-sesi-custom" :class="{ open: !openCollapse }">
                    <ChevronIcon color="white" />
                  </div>
                </div>
              </ButtonApp>
            </div>
          </template>
          <div>
            <div class="svg-border">
              <svg width="100%" height="10">
                <line
                  x1="0"
                  y1="5"
                  x2="100%"
                  y2="5"
                  stroke="white"
                  stroke-width="2"
                  stroke-dasharray="10,5"
                />
              </svg>
            </div>
            <div class="body-sesi-custom color-black">
              <div class="list-survey-custom-wrapper">
                <div
                  v-for="(item, index) in sesiCustom"
                  :key="`sesi-custom=${index}`"
                  class="list-sesi-custom-div"
                >
                  <div
                    class="list-sesi-custom"
                    :class="{
                      'removed-sesi-custom': item.removed,
                      dragging: isReadyToDrag === item.id
                    }"
                    :draggable="isDragCustom && isReadyToDrag === item.id && !isFiltering"
                    @dragstart="onDragSesiCustomStart($event, item)"
                    @dragend="onDragSesiCustomEnd"
                    @mousedown="startHold($event, item)"
                    @mouseup="cancelHold"
                  >
                    <div
                      class="info-vendor-sesi-custom"
                      :class="{ 'flex-center-vertical': !isReadyToDrag || isReadyToDrag !== item.id }"
                    >
                      <div class="vendor-img-sesi-custom flex-center">
                        <img :src="item.vendor_logo" alt="" />
                      </div>
                      <p
                        class="averta-bold color-red text-ellipsis-3"
                        :class="{
                          'has-text-centered': isReadyToDrag && isReadyToDrag === item.id
                        }"
                      >
                        {{ item.id }} | {{ item.vendor_name }}
                        {{ item.no_unit ? '-' + item.no_unit : '' }}
                      </p>
                    </div>
                    <div class="address-sesi-custom" v-if="!isReadyToDrag || isReadyToDrag !== item.id">
                      <div>
                        <p class="color-grey">Alamat</p>
                        <p class="text-ellipsis-2 averta-bold label-13">{{ item.store_address }}</p>
                      </div>
                    </div>
                    <div class="flex-center" :class="{'drag-icon-sesi-custom': !isFiltering}">
                      <img
                        v-if="!isFiltering"
                        class="drag-icon pointer-events-none"
                        :src="require('@/assets/icons/fluent-drag-black-used.svg')"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>

      <div>
        <div
          v-if="isLoadingTable && showSpinnerSchedule"
          class="loading-schedule c-w-100 min-h-80vh flex-center"
        >
          <SpinnerIcon />
        </div>
        <div class="schedule-table" :class="{ 'loading-table': isLoadingTable }">
          <!-- Header -->
          <div
            class="header-table"
            :class="{ 'adjust-z-index': idHoverCard }"
            :style="{
              top: positionStickyHeader
            }"
          >
            <div
              class="cell header-empty sticky-left"
              :style="{ height: `${HEIGHT_COLUMN}px` }"
            ></div>
            <div
              class="cell header"
              :style="{ height: `${HEIGHT_COLUMN}px` }"
              v-for="(item, index) in consultants"
              :key="`consultan-${index}`"
            >
              <div v-if="item.empty"></div>
              <div
                v-else
                class="flex-center c-w-100 pr-1"
                :class="{ 'is-clickable': !item.name }"
                @click="() => handleSelectConsultant(item)"
              >
                <div class="consultan-img flex-center mr-2 flex-shrink-0">
                  <img
                    :src="
                      item.image && item.image !== '-'
                        ? item.image
                        : require('@/assets/icons/profile-picture-used.svg')
                    "
                    alt=""
                  />
                </div>
                <div class="has-text-left ellipsis-content flex-grow-0">
                  <p
                    class="label-14"
                    :class="[
                      item.name ? 'averta-black' : 'averta-reguler-italic',
                      { 'color-red': !item.name }
                    ]"
                  >
                    {{ item.name ? item.name : 'Pilih Konsultan' }}
                  </p>
                  <p v-if="item.name" class="color-red averta-bold label-12">{{ item.id }}</p>
                  <p v-if="item.vendor_name" class="label-12">
                    {{ item.vendor_name }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="body">
            <div
              class="d-contents is-flex is-relative"
              v-for="(time, idxTime) in timeSurvey"
              :key="`time-${idxTime}`"
            >
              <div
                class="cell time"
                :style="{
                  zIndex: 22,
                  height: `${HEIGHT_COLUMN}px`
                }"
              >
                <div v-if="time !== 'empty'" class="time-survey flex-center">
                  <p class="averta-black">{{ time }}</p>
                  <div class="line"></div>
                </div>
              </div>
              <div
                v-for="(consultant, idxConsultant) in consultants"
                :key="`card-consultant-${idxConsultant}`"
                class="cell body is-relative"
                :class="[
                  `selector-cell-${idxTime}-${idxConsultant}`,
                  {
                    'bg-grey-3': consultant.empty
                  }
                ]"
                :style="{ height: `${HEIGHT_COLUMN}px` }"
                :data-timeslot="time"
                :data-consultant="consultant.id"
                @drop="onDrop($event, time, consultant)"
                @dragenter.prevent="
                  onDragEnter({
                    idxTime,
                    idxConsultant,
                    time,
                    consultantId: consultant.id
                  })
                "
                @dragleave.prevent="onDragLeave()"
                @dragover.prevent="onDragOver($event)"
              >
                <div
                  v-for="(item, idxCard) in getCardsForSlot(time, consultant.id)"
                  :key="`card-events-${idxCard}`"
                  class="card-events"
                  :data-session-id="item.session_id"
                  :class="[
                    `card-events-${item.id}`,
                    {
                      'card-sesi-custom': item.session_id === 0,
                      'cursor-move': cursorMove && !isFiltering,
                      'z-index-305 border-white-1':
                        (idHoverCard === item.id || idHoverDragIcon === item.id) &&
                        !isStretching &&
                        !modalActionConsultant &&
                        !modalMove,
                      'remove-card': tempCard && tempCard.removed && tempCard.id === item.id,
                      'opacity-effect': consultant.is_assigned,
                      'pointer-events-none':
                        typeModalAction === 'move-sesi-custom'
                          ? pointerEventsNone
                          : pointerEventsNone && draggedEvent && item.id !== draggedEvent.id
                    }
                  ]"
                  :style="{
                    height: getCardStyle(item, idxTime),
                    backgroundColor: getCardBackground(item)
                  }"
                  :ref="`card-${item.id}`"
                  :draggable="cursorMove && !isFiltering"
                  @dragstart="onDragStart($event, item, consultant)"
                  @dragend="onDragEnd"
                  @mousemove="checkIfReaching"
                  @mouseleave="resetReaching"
                >
                  <div
                    v-if="!isFiltering"
                    class="drag-icon-wrapper"
                    @mouseover="() => handleHoverDragIcon(item.id)"
                    @mouseleave="() => handleHoverDragIcon(null)"
                  >
                    <img
                      class="mb-3 drag-icon pointer-events-none"
                      :src="require('@/assets/icons/fluent-drag-used.svg')"
                      alt=""
                    />
                  </div>
                  <div
                    class="body-card-events"
                    :style="{
                      height: !isFiltering ? 'calc(100% - 35px)' : '100%'
                    }"
                    @mouseover="() => handleHoverCard(item.id, consultant.id, item)"
                    @mouseleave="() => handleHoverCard(null)"
                  >
                    <div class="flex-center is-flex-direction-column c-h-100 overflow-auto">
                      <div class="max-c-h-100 c-w-100 px-2 description-card">
                        <div v-if="item.branch_logo || item.vendor_logo" class="vendor-card-img">
                          <img :src="item.branch_logo || item.vendor_logo" alt="" />
                        </div>
                        <p>ID: {{ item.id }}</p>
                        <p class="averta-bold">{{ item.store_name }}</p>
                        <p>
                          {{ item.session_name ? item.session_name + ': ' : '' }}
                          <span class="white-space-nowrap"
                            >{{ item.start_time }} - {{ item.end_time }}</span
                          >
                        </p>
                      </div>
                    </div>

                    <!-- TOOLTIP -->
                    <div
                      @mouseover="() => handleHoverCard(item.id, consultant.id)"
                      @mouseleave="() => handleHoverCard(null)"
                      class="tooltip-hover-card color-black"
                      :class="{ visible: idHoverCard === item.id && !isStretching }"
                    >
                      <div class="tooltip-header flex-center-vertical">
                        <div class="tooltip-vendor-img">
                          <img :src="item.vendor_logo" alt="" />
                        </div>
                        <div class="ellipsis-content flex-grow-0">
                          <p class="averta-black label-16-vmin">
                            {{ item.id }} | {{ item.store_name }}
                          </p>
                          <p class="has-text-left label-14-vmin">{{ item.vendor_name }}</p>
                        </div>
                      </div>
                      <div class="tooltip-body-monitoring">
                        <div class="tooltip-date flex-center is-flex-direction-column">
                          <p class="color-blue-1 label-12-vmin">Tanggal & Waktu Survey</p>
                          <p class="averta-bold label-14-vmin">{{ formatDate(item.start_date) }}</p>
                          <p class="color-grey label-14-vmin">
                            ({{ item.session_name ? item.session_name + ': ' : ''
                            }}{{ item.start_time }} - {{ item.end_time }})
                          </p>
                        </div>
                      </div>
                      <div class="is-flex tooltip-footer-monitoring">
                        <ButtonApp
                          width="100%"
                          :isSecondary="true"
                          class="mr-3"
                          @click="() => handleSecondaryTooltip(item, consultant)"
                        >
                          <p class="averta-bold label-14-vmin">
                            {{
                              typeof item.consultant_id === 'string' &&
                              item.consultant_id.includes('CONS')
                                ? 'Atur Konsultan'
                                : 'Hapus Konsultan'
                            }}
                          </p>
                        </ButtonApp>
                        <ButtonApp width="100%" @click="() => handleDetailConsultant(item.id)">
                          <p class="averta-bold label-14-vmin">Lihat Detail</p>
                        </ButtonApp>
                      </div>
                    </div>

                    <!-- EXPANDING CARD -->
                    <div
                      v-if="item.session_id === 0"
                      class="expand-handle"
                      @mousedown="($event) => startStretching($event, item, consultant)"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal Action Consultant  -->
      <b-modal v-model="modalActionConsultant" @close="handleCancelModalAction">
        <div class="flex-center">
          <div class="modal-delete-consultant">
            <div class="flex-center is-flex-direction-column c-w-100 mb-5 has-text-centered">
              <img :src="require('@/assets/img/warning-1.png')" alt="" />
              <p class="averta-black color-red label-16 mt-3">{{ titleModalAction }}</p>
              <p :style="{ width: widthDescModalAction }">{{ descModalAction }}</p>
            </div>
            <div v-if="tempCard" class="bg-lightgray border-radius-12 p-3">
              <div class="border-bottom-dashed flex-center-vertical pb-3 mb-3">
                <div class="modal-vendor-icon flex-center mr-2">
                  <img :src="tempCard.vendor_logo" alt="" />
                </div>
                <p class="averta-black color-red label-13">
                  {{ tempCard.id }} | {{ tempCard.vendor_name }}
                </p>
              </div>
              <div class="is-flex">
                <div class="flex-1 border-right-1-grey-13">
                  <p class="color-grey mb-1">Jadwal Survey</p>
                  <p class="averta-bold label-13">{{ formatDate(tempCard.start_date) }}</p>
                  <p class="label-12">
                    {{ tempCard.session_name ? tempCard.session_name + ': ' : '' }}
                    {{ tempCard.start_time }} - {{ tempCard.end_time }}
                  </p>
                </div>
                <div class="flex-1 ml-3">
                  <p class="color-grey mb-1">Konsultan Survey</p>
                  <div class="is-flex is-justify-content-space-between">
                    <div class="flex-center-vertical" style="flex: 1.3">
                      <div class="modal-consultant-img mr-2">
                        <img
                          :src="
                            tempConsultant && tempConsultant.image && tempConsultant.image !== '-'
                              ? tempConsultant.image
                              : require('@/assets/icons/profile-picture-used.svg')
                          "
                          alt=""
                        />
                      </div>
                      <div>
                        <p class="averta-bold label-13 text-ellipsis-2">
                          {{ (tempConsultant && tempConsultant.name) || '-' }}
                        </p>
                        <p v-if="tempConsultant && tempConsultant.name" class="color-red label-12">
                          {{ tempConsultant.id }}
                        </p>
                      </div>
                    </div>
                    <div class="flex-1" v-if="tempConsultant && tempConsultant.vendor_name">
                      <p class="label-12 ml-2 text-ellipsis-2">
                        {{ tempConsultant && tempConsultant.vendor_name }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="is-flex mt-4">
                <ButtonApp
                  width="100%"
                  :isSecondary="true"
                  class="mr-3"
                  @click="handleCancelModalAction"
                >
                  <p class="py-2 averta-bold">Batalkan</p>
                </ButtonApp>
                <ButtonApp width="100%" @click="handleConfirmModalAction">
                  <p class="py-2 averta-bold">{{ btnModalAction }}</p>
                </ButtonApp>
              </div>
            </div>
          </div>
        </div>
      </b-modal>

      <ModalSelectConsultant
        :type="typeModalConsultant"
        :consultants="listConsultants"
        :excluedConsultants="initConsultants"
        :open="modalSelectConsultant"
        @closeModal="closeModalSelectConsultant"
        @selected="onSelectConsultant"
      />

      <ModalGuideSesi :open="modalGuide" @closeModal="openGuideSesi" />

      <ModalErrorMove
        :open="modalMove"
        :availableSurveys="availableSurveys"
        @closeModal="closeModalErrorMove"
      />

      <ModalDialog
        :open="modalDialog"
        title="Waktu Survey Sudah Terisi"
        desc="Silakan pilih waktu lain untuk mengisi slot konsultan"
        @closeModal="closeModalDialog"
      />

      <!-- Floatiing Button Save -->
      <div class="floating-button-save" :class="{ show: isDropSurveyCustom }">
        <div
          class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center gap-4"
        >
          <div
            class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start"
          >
            <p class="averta-bold label-18">Simpan perubahan pada survey custom?</p>
            <p class="averta-regular label-14">
              Perluas tampilan untuk menyesuaikan jam survey konsultan
            </p>
          </div>
        </div>

        <div
          class="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center gap-4"
        >
          <ButtonApp
            height="40px"
            width="150px"
            :isSecondary="true"
            @click="handleCancelModalAction"
          >
            <p class="averta-bold">Tidak Jadi</p>
          </ButtonApp>
          <ButtonApp height="40px" width="150px" @click="modalActionConsultant = true">
            <p class="averta-bold">Simpan</p>
          </ButtonApp>
        </div>
      </div>

      <b-loading :is-full-page="true" :active="isLoading" :can-cancel="false">
        <SpinnerIcon />
      </b-loading>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { alertErrorMessage, toastMessage, randomTerseHexColor, getBackgroundColor } from '@/helpers'
import {
  updatedCards,
  randomConsultantId,
  mergeListConsultant,
  // eslint-disable-next-line no-unused-vars
  findOverlappingCards,
  // eslint-disable-next-line no-unused-vars
  findOverlappingCard2
} from '@/helpers/monitoring'

export default {
  name: 'MonitoringKonsultan',
  components: {
    ArrowLeftIcon: () => import('@/components/icons/ArrowLeftIcon.vue'),
    ChevronIcon: () => import('@/components/icons/ChevronIcon.vue'),
    ButtonApp: () => import('@/components/button/ButtonApp.vue'),
    ModalSelectConsultant: () => import('../components/ModalSelectConsultant.vue'),
    ModalGuideSesi: () => import('../components/ModalGuideSesi.vue'),
    ModalErrorMove: () => import('../components/ModalErrorMove.vue'),
    SpinnerIcon: () => import('@/components/icons/SpinnerIcon'),
    ModalDialog: () => import('@/components/modal/ModalDialog')
  },
  data () {
    return {
      isLoading: false,
      isLoadingClient: false,
      isLoadingKonsultan: false,
      isLoadingTable: false,

      minimize: false,
      showSpinnerSchedule: false,
      selectedDate: new Date(),
      search: '',
      keywordConsultant: '',
      client: '',

      cursorMove: false,
      idHoverDragIcon: null,
      idHoverCard: null,
      tempIdHoverCard: null,
      consultantIdHoverCard: null,
      tempConsultantIdHoverCard: null,
      fromPage: {
        year: parseInt(moment(new Date()).format('YYYY')),
        month: parseInt(moment(new Date()).format('M'))
      },

      modalActionConsultant: false,
      modalSelectConsultant: false,
      modalGuide: false,
      modalMove: false,
      modalDialog: false,

      tempCard: null,
      tempDataConsultant: null,
      dataFloating: null,
      openCollapse: false,

      selectedConsultant: null,
      selectedClient: null,
      listSearchConsultant: [],
      listConsultants: [],

      dataClients: [],
      initDataClients: [],

      timeSurvey: [],
      consultants: [],
      cardSurvey: [],
      initConsultants: [],
      initCardSurvey: [],
      sesiCustom: [],

      availableSurveys: [],
      availableSession: [],
      placeholderSession: null,

      draggedEvent: null,
      tempDraggedEvent: null,
      typeModalAction: '',
      typeModalConsultant: '',
      pointerEventsNone: false,

      dataStretching: null,
      isStretching: false,
      isDropSurveyCustom: false,

      startMouseY: 0,
      rowHeight: 80,
      cardHeight: 80,
      initialHeight: 80,
      scrollSpeed: 7, // Adjust speed
      scrollInterval: null, // Stores animation frame ID

      isReadyToDrag: null,
      isFiltering: null,
      isDragCustom: false,
      holdTimeout: null,

      HEIGHT_COLUMN: 80,
      WIDTH_COLUMN: 200
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    filteredConsultant () {
      return this.listSearchConsultant.filter((option) => {
        return (
          option.name &&
          option.name.toString().toLowerCase().indexOf(this.keywordConsultant.toLowerCase()) >= 0
        )
      })
    },
    filteredClient () {
      return this.dataClients.filter((option) => {
        return option.name.toString().toLowerCase().indexOf(this.client.toLowerCase()) >= 0
      })
    },
    formatSelectedDate () {
      return moment(this.selectedDate).format('dddd, DD MMMM YYYY')
    },
    positionStickyHeader () {
      if (this.sesiCustom.length === 0) return '70px'
      if (!this.openCollapse) return '168px'
      if (this.sesiCustom.length <= 2 && this.openCollapse) return '307px'
      if (this.sesiCustom.length > 2) return '318px'
      return '70px'
    },
    titleModalAction () {
      if (this.typeModalAction === 'delete') {
        return 'Hapus Konsultan Survey'
      } else if (
        this.typeModalAction === 'move' ||
        this.typeModalAction === 'move-sesi-custom' ||
        this.typeModalAction === 'expand'
      ) {
        return 'Tempatkan Jadwal Survey Telah Tepat?'
      }
      return ''
    },
    descModalAction () {
      if (this.typeModalAction === 'delete') {
        return 'Konsultan yang ditugaskan pada survey ini akan dihapus'
      } else if (
        this.typeModalAction === 'move' ||
        this.typeModalAction === 'move-sesi-custom' ||
        this.typeModalAction === 'expand'
      ) {
        return 'Pastikan jadwal survey yang telah kamu tentukan telah sesuai'
      }
      return ''
    },
    widthDescModalAction () {
      if (this.typeModalAction === 'delete') {
        return '200px'
      } else if (
        this.typeModalAction === 'move' ||
        this.typeModalAction === 'move-sesi-custom' ||
        this.typeModalAction === 'expand'
      ) {
        return '310px'
      }
      return ''
    },
    btnModalAction () {
      if (this.typeModalAction === 'delete') {
        return 'Hapus Konsultan Survey'
      } else if (
        this.typeModalAction === 'move' ||
        this.typeModalAction === 'move-sesi-custom' ||
        this.typeModalAction === 'expand'
      ) {
        return 'Simpan Jadwal'
      }
      return ''
    },
    tempConsultant () {
      if (this.typeModalAction === 'delete') {
        return this.tempCard.temp_consultant
      }
      return this.tempCard.consultant
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    formatDate (date, format) {
      if (!date) return ''
      return moment(date).format(format || 'dddd, DD MMMM YYYY')
    },
    randomBackground () {
      return randomTerseHexColor()
    },
    checkIfReaching (event) {
      // Get mouse coordinates relative to the div
      const card = event.currentTarget
      const cardRect = card.getBoundingClientRect()

      const mouseX = event.clientX - cardRect.left
      const mouseY = event.clientY - cardRect.top

      const radiusWidth = 30
      const radiusHeight = 20
      const spaceFromTop = 0

      const targetLeft = (card.offsetWidth - radiusWidth) / 2
      const targetTop = spaceFromTop
      const targetRight = targetLeft + radiusWidth
      const targetBottom = targetTop + radiusHeight

      // Check if the mouse is within the target area
      this.cursorMove =
        mouseX >= targetLeft &&
        mouseX <= targetRight &&
        mouseY >= targetTop &&
        mouseY <= targetBottom
    },
    resetReaching () {
      this.idHoverCard = null
      this.consultantIdHoverCard = null
    },
    closeModalErrorMove () {
      this.modalMove = false
      this.availableSurveys = []
      setTimeout(() => {
        if (this.draggedEvent && this.draggedEvent.id) {
          this.scrollTo(`card-${this.draggedEvent.id}`)
        }
        this.stopSmoothScroll()
        this.resetDraggedCard()
        this.resetReaching()
      }, 300)
    },
    closeModalDialog () {
      this.modalDialog = false
      this.cardSurvey.push(this.draggedEvent)
      this.initCardSurvey.push(this.draggedEvent)
      this.draggedEvent = null
      setTimeout(() => {
        this.handleCancelModalAction()
      }, 300)
    },
    closeModalSelectConsultant () {
      this.modalSelectConsultant = false
    },
    handleSelectConsultant (data, type) {
      if (!data.name) {
        this.modalSelectConsultant = !this.modalSelectConsultant
        this.typeModalConsultant = type || 'bulk'
        this.tempDataConsultant = data
      }
    },
    handleMinimizeSidebar () {
      this.minimize = !this.minimize
    },
    handlePrevNext (type) {
      const tempCurrentDate = JSON.parse(JSON.stringify(moment(this.selectedDate).toDate()))
      const currentDate = moment(this.selectedDate)

      if (type === 'prev') {
        this.selectedDate = currentDate.add(-1, 'days').toDate()
      } else if (type === 'next') {
        this.selectedDate = currentDate.add(1, 'days').toDate()
      }

      const updatedDate = moment(this.selectedDate)
      if (
        moment(tempCurrentDate).year() !== updatedDate.year() ||
        moment(tempCurrentDate).month() !== updatedDate.month()
      ) {
        this.fromPage = {
          year: updatedDate.year(),
          month: updatedDate.month() + 1 // Moment months are zero-based
        }
      }
    },
    handleHoverCard (cardId, consultantId, item) {
      if (this.isDropSurveyCustom) return

      if (!cardId) {
        const element = document.querySelectorAll('.transparent-card')
        if (element) {
          element.forEach((el) => el.classList.remove('transparent-card'))
        }
        this.resetReaching()
      } else {
        this.idHoverCard = cardId

        if (item) {
          const overlappingEvent = this.cardSurvey.filter((e) => {
            return (
              (e.id !== cardId &&
                e.consultant_id === consultantId &&
                ((e.start_time >= item.start_time && e.start_time < item.end_time) ||
                (e.end_time > item.start_time && e.end_time <= item.end_time) ||
                (e.start_time <= item.start_time && e.end_time >= item.end_time))
              )
            )
          })

          if (overlappingEvent && overlappingEvent.length > 0) {
            overlappingEvent.map((overlap) => {
              const element = document.querySelector(`.card-events-${overlap.id}`)
              if (element) {
                element.classList.add('transparent-card')
              }
            })
          }
        }
        // this.consultantIdHoverCard = consultantId
      }
    },
    handleHoverDragIcon (cardId) {
      this.idHoverDragIcon = cardId
    },
    handleCancelDeleteConsultant () {
      this.modalActionConsultant = false
      this.idHoverCard = this.tempIdHoverCard
      this.consultantIdHoverCard = this.tempConsultantIdHoverCard

      if (this.tempDraggedEvent) {
        this.typeModalAction = 'move'
        this.handleCancelMoveCard()
      }
    },
    handleDeleteConsultant () {
      this.isLoading = true

      const idPrev = `${this.tempCard.prev_consultant_id}`.includes('CONS')
        ? null
        : this.tempCard.prev_consultant_id
      const payload = {
        url: this.url,
        survey_id: this.tempCard.id,
        homecare_project_id: this.tempCard.homecare_project_id,
        worker_id: idPrev || this.tempCard.consultant.id || this.tempCard.consultant_id
      }

      this.$store
        .dispatch('monitoring/removeSurveySchedule', payload)
        .then(() => {
          this.isLoading = false
          toastMessage('Konsultan Survey Berhasil Dihapus', 'is-dark')

          this.modalActionConsultant = false
          const findIdx = this.cardSurvey.findIndex((item) => item.id === this.tempCard.id)
          this.tempCard.removed = true
          this.tempCard.consultant_id = null

          setTimeout(() => {
            this.cardSurvey.splice(findIdx, 1, this.tempCard)
            this.initCardSurvey.splice(findIdx, 1, this.tempCard)

            this.setDataSurvey(this.cardSurvey, this.initConsultants)
          }, 500)

          setTimeout(() => {
            this.scrollTo(`card-${this.tempCard.id}`)
            this.tempCard = null
          }, 800)
        })
        .catch((error) => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    handleDetailConsultant (id) {
      const routeData = this.$router.resolve(`/am/project/survey/${id}/detail`)
      window.open(routeData.href, '_blank')
    },
    resetDraggedCard () {
      this.draggedEvent = null
      this.cursorMove = false
      this.modalActionConsultant = false
      this.idHoverDragIcon = null
    },
    resetClassPlaceholder () {
      const oldElementAfter = document.querySelector('.after-element')
      const oldElementSesi = document.querySelectorAll('.slot-sesi')
      if (oldElementAfter) {
        oldElementAfter.classList.remove('after-element')
      }
      if (oldElementSesi) {
        oldElementSesi.forEach((el) => el.classList.remove('slot-sesi'))
      }

      const slotSesi = document.querySelectorAll('.cell.body')
      if (slotSesi) {
        slotSesi.forEach((el) => el.classList.remove('slot-sesi'))
      }
      this.availableSession = []
    },
    handleConfirmMoveCard () {
      this.isLoading = true
      const payload = {
        url: this.url,
        consultant_id: `${this.tempCard.consultant_id}`.includes('CONS')
          ? null
          : this.tempCard.consultant_id,
        surveys: [
          {
            id: this.tempCard.id,
            vendor_id: this.tempCard.vendor_id,
            date: this.tempCard.start_date,
            start_time: this.tempCard.start_time,
            end_time: this.tempCard.end_time,
            homecare_project_id: this.tempCard.homecare_project_id
          }
        ]
      }

      // if (this.typeModalAction === 'move-sesi-custom') payload.custom = true
      this.$store
        .dispatch('monitoring/assignMonitoringKonsultan', payload)
        .then(() => {
          if (this.typeModalAction === 'move-sesi-custom' || this.typeModalAction === 'expand') {
            const findIdx = this.sesiCustom.findIndex((item) => item.id === this.tempCard.id)
            if (findIdx > -1) {
              this.sesiCustom.splice(findIdx, 1, { ...this.tempCard, removed: true })
            }
            setTimeout(() => {
              if (findIdx > -1) this.sesiCustom.splice(findIdx, 1)
            }, 500)
          }

          toastMessage('Jadwal Survey Berhasil Diubah', 'is-dark')
          setTimeout(() => {
            this.scrollTo(`card-${this.tempCard.id}`)
          }, 400)
          setTimeout(() => {
            this.resetDraggedCard()
          }, 450)
          this.isLoading = false
          this.isDropSurveyCustom = false
        })
        .catch((error) => {
          this.isDropSurveyCustom = false
          this.isLoading = false
          alertErrorMessage(error)
          if (this.typeModalAction === 'move') {
            const item = this.cardSurvey.find((item) => item.id === this.tempDraggedEvent.id)
            if (item) {
              item.consultant_id = this.tempDraggedEvent.consultant_id
              item.start_time = this.tempDraggedEvent.start_time
              item.end_time = this.tempDraggedEvent.end_time
            }

            const itemInit = this.initCardSurvey.find(
              (item) => item.id === this.tempDraggedEvent.id
            )
            if (itemInit) {
              itemInit.consultant_id = this.tempDraggedEvent.consultant_id
              itemInit.start_time = this.tempDraggedEvent.start_time
              itemInit.end_time = this.tempDraggedEvent.end_time
            }
            this.resetDraggedCard()
          } else if (this.typeModalAction === 'move-sesi-custom') {
            this.handleCancelModalAction()
          }

          if (error.response.data.error && error.response.data.error[0].avail_start_time) {
            this.availableSurveys = error.response.data.error[0].avail_start_time
            this.modalMove = true
          }
        })
    },
    handleExpandCard () {
      this.isLoading = true
      const payload = {
        url: this.url,
        consultant_id: `${this.tempCard.consultant_id}`.includes('CONS')
          ? null
          : this.tempCard.consultant_id,
        surveys: [
          {
            id: this.tempCard.id,
            vendor_id: this.tempCard.vendor_id,
            date: this.tempCard.start_date,
            start_time: this.tempCard.start_time,
            end_time: this.tempCard.end_time,
            homecare_project_id: this.tempCard.homecare_project_id
          }
        ]
      }

      this.$store
        .dispatch('monitoring/assignMonitoringKonsultan', payload)
        .then(() => {
          const tmp = JSON.parse(JSON.stringify(this.tempCard))
          const findIdx = this.sesiCustom.findIndex((item) => item.id === tmp.id)
          if (findIdx > -1) {
            this.sesiCustom.splice(findIdx, 1, { ...tmp, removed: true })
          }
          setTimeout(() => {
            if (findIdx > -1) this.sesiCustom.splice(findIdx, 1)
          }, 500)

          toastMessage('Jadwal Survey Berhasil Diubah', 'is-dark')
          this.dataStretching = null
          this.tempCard = null
          this.modalActionConsultant = false
          this.isLoading = false
          this.isDropSurveyCustom = false
        })
        .catch((error) => {
          this.isLoading = false
          alertErrorMessage(error)
          this.handleCancelExpand()

          if (error.response.data.error && error.response.data.error[0].avail_start_time) {
            this.availableSurveys = error.response.data.error[0].avail_start_time
            this.modalMove = true
          }
        })
    },
    handleCancelExpand () {
      const findIdx = this.cardSurvey.findIndex((item) => item.id === this.dataStretching.id)
      this.cardSurvey.splice(findIdx, 1, this.dataStretching)
      this.initCardSurvey.splice(findIdx, 1, this.dataStretching)

      this.dataStretching = null
      this.tempCard = null
      this.modalActionConsultant = false
    },
    handleCancelMoveCard () {
      const findIdx = this.cardSurvey.findIndex((item) => item.id === this.tempDraggedEvent.id)
      this.resetDraggedCard()
      if (findIdx > -1) {
        if (this.typeModalAction === 'move-sesi-custom' || this.typeModalAction === 'expand') {
          this.cardSurvey.splice(findIdx, 1)
          this.initCardSurvey.splice(findIdx, 1)
        } else if (this.typeModalAction === 'move') {
          this.cardSurvey.splice(findIdx, 1, this.tempDraggedEvent)
          this.initCardSurvey.splice(findIdx, 1, this.tempDraggedEvent)
        }
        setTimeout(() => {
          this.scrollTo(`card-${this.tempDraggedEvent.id}`)
        }, 300)
      }
      if (this.typeModalAction === 'move-sesi-custom' || this.typeModalAction === 'expand') {
        this.openCollapse = true
        this.isDropSurveyCustom = false
      }
    },
    handleCancelModalAction () {
      if (this.typeModalAction === 'delete') {
        this.handleCancelDeleteConsultant()
      } else if (
        this.typeModalAction === 'move' ||
        this.typeModalAction === 'move-sesi-custom' ||
        this.isDropSurveyCustom
      ) {
        this.handleCancelMoveCard()
      } else if (this.typeModalAction === 'expand') {
        this.handleCancelExpand()
      }
      this.adjustWidthCards()
    },
    handleConfirmModalAction () {
      if (this.typeModalAction === 'delete') {
        this.handleDeleteConsultant()
      } else if (this.typeModalAction === 'move' || this.typeModalAction === 'move-sesi-custom') {
        this.handleConfirmMoveCard()
      } else if (this.typeModalAction === 'expand') {
        this.handleExpandCard()
      }
      this.adjustWidthCards()
    },
    handleSecondaryTooltip (card, consultant) {
      card.removed = false
      this.tempDraggedEvent = card
      if (typeof card.consultant_id === 'string' && card.consultant_id.includes('CONS')) {
        this.handleSelectConsultant(consultant, 'one-consultant')
        this.resetReaching()
      } else {
        this.openDeleteConsultant(card, consultant)
      }
    },
    openDeleteConsultant (card, consultant) {
      this.typeModalAction = 'delete'
      this.tempIdHoverCard = JSON.parse(JSON.stringify(this.idHoverCard))
      this.tempConsultantIdHoverCard = JSON.parse(JSON.stringify(this.consultantIdHoverCard))

      this.resetReaching()
      this.tempCard = {
        ...card,
        consultant
      }
      setTimeout(() => {
        this.modalActionConsultant = !this.modalActionConsultant
      }, 300)
    },
    startHold (event, data) {
      if (this.isFiltering) return

      this.holdTimeout = setTimeout(() => {
        this.isReadyToDrag = data.id
        this.isDragCustom = true
        // event.target.draggable = true // Enable dragging after 2 seconds
      }, 0)
    },
    cancelHold (event) {
      clearTimeout(this.holdTimeout)
      this.isReadyToDrag = null
      if (!this.isReadyToDrag) {
        this.isDragCustom = false
        // event.target.draggable = false // Prevent drag if released early
      }
    },
    onDragSesiCustomStart (event, data) {
      if (!this.isReadyToDrag) {
        event.preventDefault() // Prevent dragging if not ready
        return
      }
      this.isReadyToDrag = false // Reset after dragging starts

      if (this.isDropSurveyCustom || this.isFiltering) return
      this.draggedEvent = JSON.parse(JSON.stringify(data))
      this.typeModalAction = 'move-sesi-custom'
      this.pointerEventsNone = true
    },
    onDragSesiCustomEnd (event) {
      this.draggedEvent = null
      this.pointerEventsNone = false
      this.placeholderSession = null
      this.stopSmoothScroll()
      const oldElement = document.querySelector('.after-element')
      if (oldElement) {
        oldElement.classList.remove('after-element')
      }
    },
    onDragStart (event, data, consultant) {
      if (this.isDropSurveyCustom || this.isFiltering) return
      this.pointerEventsNone = true

      // action when dragging card
      this.availableSession = JSON.parse(JSON.stringify(data.avail_session || []))
      this.placeholderSession = JSON.parse(JSON.stringify(data))

      // reset removed for css needs
      if (data.removed) {
        delete data.removed
      }

      this.typeModalAction = 'move'
      if (!this.cursorMove) return
      this.draggedEvent = { ...data, consultant_id: consultant.id, temp_consultant: consultant }
      const findIdx = this.cardSurvey.findIndex((item) => item && item.id === data.id)

      setTimeout(() => {
        if (findIdx === -1) return
        this.cardSurvey.splice(findIdx, 1)
        this.initCardSurvey.splice(findIdx, 1)
      }, 0)
    },
    onDragEnd () {
      this.stopSmoothScroll()
      this.availableSession = []
      if (this.draggedEvent) {
        this.cardSurvey.push(this.draggedEvent)
        this.initCardSurvey.push(this.draggedEvent)
      }
    },
    onDrop (event, timeSlot, consultant) {
      // if (consultant.is_additional && this.typeModalAction === 'move-sesi-custom') {
      //   alertErrorMessage('Mohon tempatkan survey pada konsultan yang ada')
      //   return
      // }
      this.resetClassPlaceholder()
      this.stopSmoothScroll()
      this.adjustWidthCards()
      this.pointerEventsNone = false

      if (this.draggedEvent) {
        if (consultant.empty) {
          this.cardSurvey.push(this.draggedEvent)
          this.initCardSurvey.push(this.draggedEvent)
          this.draggedEvent = null
          return
        }

        // Check if session is available
        const isTempConsultant = `${consultant.id}`.includes('CONS')
        const isCustom = this.draggedEvent.session_id === 0 || isTempConsultant

        const isIncluded =
          !isCustom &&
          this.draggedEvent.avail_session.some((session) => {
            // return timeSlot === session.session_start_time.slice(0, 5)
            return this.placeholderSession.start_time === session.session_start_time.slice(0, 5)
          })

        if (!isCustom && !isIncluded) {
          this.availableSurveys = this.draggedEvent.avail_session.map((session) =>
            session.session_start_time.slice(0, 5)
          )
          this.modalMove = true
          this.resetReaching()
          return
        }

        // Check if newEndTime is valid
        // const startNew = this.timeSurvey.indexOf(timeSlot)
        // const endNew = this.timeSurvey.indexOf(this.draggedEvent.end_time) - this.timeSurvey.indexOf(this.draggedEvent.start_time)

        const startNew = this.timeSurvey.indexOf(
          isCustom ? timeSlot : this.placeholderSession.start_time
        )
        const endNew =
          this.timeSurvey.indexOf(
            isCustom ? this.draggedEvent.end_time : this.placeholderSession.end_time
          ) -
          this.timeSurvey.indexOf(
            isCustom ? this.draggedEvent.start_time : this.placeholderSession.start_time
          ) // Calculate duration

        const startIndex = startNew
        const duration = this.typeModalAction === 'move-sesi-custom' ? 1 : endNew

        // const newStartTime = timeSlot
        const newStartTime = isCustom ? timeSlot : this.placeholderSession.start_time
        const newEndTime = this.timeSurvey[startIndex + duration]

        if (!newEndTime) {
          toastMessage('Invalid time slot!', 'is-dark')
          this.cardSurvey.push(this.draggedEvent)
          this.initCardSurvey.push(this.draggedEvent)
          this.draggedEvent = null
          return
        }

        this.tempDraggedEvent = JSON.parse(JSON.stringify(this.draggedEvent))

        // Skip overlap check if dropping in original position
        if (
          timeSlot === 'empty' ||
          (this.draggedEvent.start_time === newStartTime &&
            this.draggedEvent.end_time === newEndTime &&
            this.draggedEvent.consultant_id === consultant.id)
        ) {
          this.cardSurvey.push(this.draggedEvent)
          this.initCardSurvey.push(this.draggedEvent)
          this.draggedEvent = null
          return
        }

        // Overlap detection
        const overlappingEvent = this.cardSurvey.find((e) => {
          const isSessionCustom = e.session_id === 0
          return (
            (isSessionCustom &&
              this.draggedEvent.session_id === 0 &&
              e.consultant_id === consultant.id &&
              e.start_time === this.draggedEvent.start_time &&
              e.end_time === this.draggedEvent.end_time) ||
            (!isSessionCustom &&
              e.id !== this.draggedEvent.id &&
              e.consultant_id === consultant.id &&
              ((e.start_time >= newStartTime && e.start_time < newEndTime) ||
                (e.end_time > newStartTime && e.end_time <= newEndTime) ||
                (e.start_time <= newStartTime && e.end_time >= newEndTime)))
          )
        })
        if (
          overlappingEvent &&
          this.typeModalAction !== 'move-sesi-custom' &&
          this.draggedEvent.session_id !== 0
        ) {
          // toastMessage('Waktu survey sudah terisi!', 'is-dark')
          this.modalDialog = true
          setTimeout(() => {
            if (this.modalDialog) {
              this.closeModalDialog()
            }
          }, 4000)
          return
        }

        // if (consultant.name && consultant.vendor.vendor_name !== this.draggedEvent.vendor_name) {
        //   toastMessage('Vendor Konsultan tidak sesuai!', 'is-dark')
        //   return
        // }

        this.draggedEvent.start_time = newStartTime
        this.draggedEvent.end_time = newEndTime
        this.draggedEvent.prev_consultant_id = JSON.parse(
          JSON.stringify(this.draggedEvent.consultant_id)
        )
        this.draggedEvent.consultant_id = consultant.id
        this.draggedEvent.consultant = consultant
        this.draggedEvent.is_additional = false
        this.draggedEvent.background = getBackgroundColor(this.draggedEvent.vendor_background || '')

        this.cardSurvey.push(this.draggedEvent)
        this.initCardSurvey.push(this.draggedEvent)
        this.tempCard = this.draggedEvent

        if (
          consultant.id.toString().includes('CONS') &&
          !this.tempDraggedEvent.consultant_id.toString().includes('CONS') &&
          this.typeModalAction !== 'move-sesi-custom'
        ) {
          this.resetDraggedCard()
          this.typeModalAction = 'delete'
          this.modalActionConsultant = true
          // } else if (
          //   consultant.id.toString().includes('CONS') &&
          //   this.draggedEvent.consultant_id.toString().includes('CONS')
          // ) {
          //   this.resetDraggedCard()
        } else {
          if (this.typeModalAction === 'move-sesi-custom') {
            this.openCollapse = false
            this.isDropSurveyCustom = true
            const temp = JSON.parse(JSON.stringify(this.draggedEvent))
            setTimeout(() => {
              this.scrollTo(`card-${temp.id}`)
            }, 300)
          } else {
            this.resetDraggedCard()
            this.modalActionConsultant = true
          }
        }
      }
      // document.removeEventListener('dragover', this.updateCursorPosition)
    },
    onDragEnter (props) {
      this.adjustWidthCards()
      const { idxTime, idxConsultant, time, consultantId } = props
      if (time === 'empty') return
      const sesiCustom = this.draggedEvent.session_id === 0
      const tempConsultant = `${consultantId}`.includes('CONS')

      if (sesiCustom || tempConsultant) {
        document.documentElement.style.setProperty(
          '--dynamic-height-cell',
          sesiCustom
            ? `${this.HEIGHT_COLUMN}px`
            : this.getCardStyle(
              {
                start_time: this.draggedEvent.start_time.slice(0, 5),
                end_time: this.draggedEvent.end_time.slice(0, 5)
              },
              idxTime
            )
        )

        if (tempConsultant && this.draggedEvent.start_time) {
          this.placeholderSession = {
            consultant_id: consultantId,
            start_time: this.draggedEvent.start_time.slice(0, 5),
            end_time: this.draggedEvent.end_time.slice(0, 5)
          }
        }
        const selector = document.querySelector(`.selector-cell-${idxTime}-${idxConsultant}`)
        if (selector) {
          const oldElement = document.querySelector('.after-element')
          if (oldElement) {
            oldElement.classList.remove('after-element')
          }
          selector.classList.toggle('after-element')
        }
      } else {
        const isExceed =
          time < this.placeholderSession.start_time ||
          time >= this.placeholderSession.end_time ||
          this.placeholderSession.consultant_id !== consultantId

        const resultTime = this.availableSession.find(
          (sesi) =>
            sesi.session_start_time.slice(0, 5) <= time && sesi.session_end_time.slice(0, 5) > time
        )
        if (!resultTime) return

        let findSession = null
        if (isExceed) {
          if (resultTime) {
            findSession = this.availableSession.find(
              (session) =>
                session.session_start_time.slice(0, 5) === resultTime.session_start_time.slice(0, 5)
            )

            if (findSession) {
              this.placeholderSession = {
                consultant_id: consultantId,
                start_time: findSession.session_start_time.slice(0, 5),
                end_time: findSession.session_end_time.slice(0, 5)
              }
            }
          }
        }
        if (isExceed) {
          const tempIdxTime = this.timeSurvey.indexOf(findSession.session_start_time.slice(0, 5))

          document.documentElement.style.setProperty(
            '--dynamic-height-cell',
            this.getCardStyle(
              {
                start_time: findSession.session_start_time.slice(0, 5),
                end_time: findSession.session_end_time.slice(0, 5)
              },
              tempIdxTime
            )
          )

          const selector = document.querySelector(`.selector-cell-${tempIdxTime}-${idxConsultant}`)
          if (selector) {
            const oldElement = document.querySelector('.after-element')
            if (oldElement) {
              oldElement.classList.remove('after-element')
            }
            selector.classList.toggle('after-element')
          }
        }
      }
    },
    onDragLeave () {},
    onDragOver (event) {
      event.preventDefault() // Allow drop

      const board = this.$refs.mainBody
      if (!board) return

      const { clientX, clientY } = event
      const { top, left, width, height } = board.getBoundingClientRect()

      const scrollThresholds = {
        top: this.sesiCustom.length < 1 ? 180 : !this.openCollapse ? 280 : 460,
        left: 150,
        right: 50,
        bottom: 50
      }

      let scrollX = 0
      let scrollY = 0

      // Detect edge zones and set scroll direction
      if (clientX < left + scrollThresholds.left) scrollX = -this.scrollSpeed
      else if (clientX > left + width - scrollThresholds.right) scrollX = this.scrollSpeed

      if (clientY < top + scrollThresholds.top) scrollY = -this.scrollSpeed
      else if (clientY > top + height - scrollThresholds.bottom) scrollY = this.scrollSpeed

      // Start smooth scrolling if needed
      if (scrollX !== 0 || scrollY !== 0) {
        this.startSmoothScroll(board, scrollX, scrollY)
      } else {
        this.stopSmoothScroll()
      }
    },
    onSelectConsultant (data) {
      const storeCard = []
      const isOneConsultant = this.typeModalConsultant === 'one-consultant'
      let idCard = null
      let isOverlap = false

      if (isOneConsultant) {
        this.cardSurvey.map((item) => {
          if (item.consultant_id === data.id) {
            const overlapping =
              (item.start_time >= this.tempDraggedEvent.start_time &&
                item.start_time < this.tempDraggedEvent.end_time) ||
              (item.end_time > this.tempDraggedEvent.start_time &&
                item.end_time <= this.tempDraggedEvent.end_time) ||
              (item.start_time <= this.tempDraggedEvent.start_time &&
                item.end_time >= this.tempDraggedEvent.end_time)
            if (overlapping) {
              idCard = item.id
              isOverlap = true
            }
          }
        })
      }

      this.cardSurvey.map((item) => {
        if (item.consultant_id === this.tempDataConsultant.id) {
          storeCard.push({
            id: item.id,
            vendor_id: item.vendor_id,
            date: item.start_date,
            start_time: item.start_time,
            end_time: item.end_time,
            homecare_project_id: item.homecare_project_id
          })
          // if (!idCard && isOneConsultant) idCard = item.id
          if (!idCard) idCard = item.id
        }
      })

      if (storeCard.length > 0 && !isOneConsultant) {
        const filterCard = this.cardSurvey.filter(
          (item) => item.consultant_id === data.id // consultant_id
        )

        storeCard.map((item) => {
          filterCard.map((card) => {
            const overlapping =
              (card.start_time >= item.start_time && card.start_time < item.end_time) ||
              (card.end_time > item.start_time && card.end_time <= item.end_time) ||
              (card.start_time <= item.start_time && card.end_time >= item.end_time)
            if (overlapping) {
              idCard = card.id
              isOverlap = true
            }
          })
        })
      }

      if (isOverlap) {
        this.modalSelectConsultant = false
        toastMessage('Waktu survey sudah terisi!', 'is-dark')
        setTimeout(() => {
          this.scrollTo(`card-${idCard}`)
          this.tempDataConsultant = null
        }, 800)
        return
      }

      this.isLoading = true
      const payload = {
        url: this.url,
        consultant_id: data.id,
        surveys: storeCard
      }
      this.$store
        .dispatch('monitoring/assignMonitoringKonsultan', payload)
        .then(() => {
          this.isLoading = false

          this.cardSurvey.map((item) => {
            if (item.consultant_id === this.tempDataConsultant.id) {
              item.consultant_id = data.id
              item.removed = false
            }
          })
          this.initCardSurvey.map((item) => {
            if (item.consultant_id === this.tempDataConsultant.id) {
              item.consultant_id = data.id
              item.removed = false
            }
          })

          setTimeout(() => {
            this.scrollTo(`card-${idCard}`)
          }, 500)
          toastMessage('Berhasil Assign Konsultan', 'is-dark')
          this.modalSelectConsultant = false
          this.tempDataConsultant = null
        })
        .catch((error) => {
          this.isLoading = false
          alertErrorMessage(error)
          if (error.response.data.error && error.response.data.error[0].avail_start_time) {
            this.availableSurveys = error.response.data.error[0].avail_start_time
            this.modalMove = true
          }
        })
    },
    startStretching (event, data, consultant) {
      event.preventDefault()

      this.isStretching = true
      this.dataStretching = { ...data, consultant }
      this.startMouseY = event.clientY

      document.body.style.cursor = 'ns-resize'

      window.addEventListener('mousemove', this.stretchCard)
      window.addEventListener('mouseup', this.stopStretching)

      // const board = this.$refs.mainBody
      // if (!board) return

      // const { clientX, clientY } = event
      // const { top, left, width, height } = board.getBoundingClientRect()

      // const scrollThresholds = {
      //   top: this.sesiCustom.length < 1 ? 180 : !this.openCollapse ? 280 : 460,
      //   left: 150,
      //   right: 50,
      //   bottom: 50
      // }

      // let scrollX = 0
      // let scrollY = 0

      // // Detect edge zones and set scroll direction
      // if (clientX < left + scrollThresholds.left) scrollX = -this.scrollSpeed
      // else if (clientX > left + width - scrollThresholds.right) scrollX = this.scrollSpeed

      // if (clientY < top + scrollThresholds.top) scrollY = -this.scrollSpeed
      // else if (clientY > top + height - scrollThresholds.bottom) scrollY = this.scrollSpeed

      // // Start smooth scrolling if needed
      // if (scrollX !== 0 || scrollY !== 0) {
      //   this.startSmoothScroll(board, scrollX, scrollY)
      // } else {
      //   this.stopSmoothScroll()
      // }
    },
    stretchCard (event) {
      if (this.isStretching) {
        event.preventDefault() // Ensure no unwanted behavior during dragging

        const mouseDiff = event.clientY - this.startMouseY

        const duration =
          this.timeSurvey.indexOf(this.dataStretching.end_time) -
          this.timeSurvey.indexOf(this.dataStretching.start_time)
        const cardHeight = duration * this.HEIGHT_COLUMN

        // Calculate raw height based on the mouse movement
        const rawHeight = cardHeight + mouseDiff

        // Determine the threshold for snapping (50% of rowHeight)
        const snapThreshold = this.rowHeight / 2

        // Calculate the closest row boundary with snapping
        let newHeight
        if (rawHeight > cardHeight + snapThreshold) {
          // Snap to the next row when moving downward
          newHeight = Math.ceil(rawHeight / this.rowHeight)
        } else if (rawHeight < cardHeight - snapThreshold) {
          // Snap to the previous row when moving upward
          newHeight = Math.floor(rawHeight / this.rowHeight)
        } else {
          // Stay at the current row if within the threshold
          newHeight = Math.round(cardHeight / this.rowHeight)
        }

        // Cap the height to stay within valid row boundaries
        newHeight = Math.max(1, Math.min(newHeight, this.timeSurvey.length))

        this.typeModalAction = 'expand'
        const findIdx = this.timeSurvey.findIndex((item) => item === this.dataStretching.start_time)
        this.tempCard = {
          ...this.dataStretching,
          end_time: this.timeSurvey[findIdx + newHeight]
        }
        const findIdxSurvey = this.cardSurvey.findIndex(
          (item) => item.id === this.dataStretching.id
        )

        this.cardSurvey.splice(findIdxSurvey, 1, this.tempCard)
        this.initCardSurvey.splice(findIdxSurvey, 1, this.tempCard)
        if (this.isDropSurveyCustom) {
          this.draggedEvent = this.tempCard
        }
      }
    },
    stopStretching () {
      this.isStretching = false
      document.body.style.cursor = ''

      // Remove global event listeners
      window.removeEventListener('mousemove', this.stretchCard)
      window.removeEventListener('mouseup', this.stopStretching)

      if (this.tempCard.end_time !== this.dataStretching.end_time && !this.isDropSurveyCustom) {
        this.modalActionConsultant = true
      }
    },
    startSmoothScroll (container, scrollX, scrollY) {
      if (this.scrollInterval) return // Prevent multiple intervals

      const smoothScroll = () => {
        container.scrollLeft += scrollX
        container.scrollTop += scrollY
        this.scrollInterval = requestAnimationFrame(smoothScroll)
      }

      this.scrollInterval = requestAnimationFrame(smoothScroll)
    },
    stopSmoothScroll () {
      if (this.scrollInterval) {
        cancelAnimationFrame(this.scrollInterval)
        this.scrollInterval = null
      }
    },
    openGuideSesi () {
      this.modalGuide = !this.modalGuide
    },
    isOverlapping (cardA, cardB) {
      return cardA.start_time < cardB.end_time && cardA.end_time > cardB.start_time
    },
    getCardsForSlot (timeSlot, consultantId) {
      const result = this.cardSurvey.filter(
        (item) => item && item.start_time === timeSlot && item.consultant_id === consultantId
      )
      return result
    },
    getCardStyle (event, idxTime) {
      const startIndex = this.timeSurvey.indexOf(event.start_time)
      const endIndex = this.timeSurvey.indexOf(event.end_time)
      const rowSpan = endIndex - startIndex // Calculate how many rows to span
      return idxTime === this.timeSurvey.length ? 0 : `${rowSpan * this.HEIGHT_COLUMN - 3}px`
    },
    getCardBackground (item) {
      if (item.vendor_background) {
        if (item.vendor_background.includes('#')) return item.vendor_background
        return `#${item.vendor_background}`
      } else {
        if (item.background.includes('#')) return item.background
        return `#${item.background}`
      }
    },
    getMonitoringKonsultan () {
      this.isDropSurveyCustom = false
      this.isLoadingTable = true
      this.consultants = []
      this.cardSurvey = []
      this.sesiCustom = []

      const body = {
        url: this.url,
        date: moment(this.selectedDate || new Date()).format('YYYY-MM-DD'),
        search: this.search || null,
        user_id: this.selectedConsultant ? this.selectedConsultant.id : null,
        client_id: this.selectedClient ? this.selectedClient.id : null
      }

      let loadingTimeout = null
      loadingTimeout = setTimeout(() => {
        this.showSpinnerSchedule = true
      }, 700)

      const container = document.querySelector('.body-monitoring')
      if (container) {
        container.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
      }

      this.$store
        .dispatch('monitoring/getMonitoringKonsultan', body)
        .then((response) => {
          this.showSpinnerSchedule = false
          clearTimeout(loadingTimeout)

          const res = response.data.data
          this.timeSurvey = ['empty', ...res.timeslots]
          this.setDataSurvey(res.surveys, res.consultants)

          this.sesiCustom = res.custom_surveys || []

          if (this.search || this.selectedConsultant || this.selectedClient) {
            this.filteredData()
          } else {
            this.isLoadingTable = false
          }
          if (this.isLoading) this.isLoading = false
        })
        .catch((error) => {
          alertErrorMessage(error)
          clearTimeout(loadingTimeout)
          this.isLoadingTable = false
          this.showSpinnerSchedule = false
          if (this.isLoading) this.isLoading = false
        })
    },
    setDataSurvey (cards, consultants, type) {
      const filterConsultant = consultants.filter((item) => item.id.toString().includes('CONS'))
      const filterCard = cards.filter((item) => !item.consultant_id)

      const temp = filterConsultant.map((item) => {
        return {
          consultant_id: item.id,
          list: cards.filter((c) => c.consultant_id === item.id)
        }
      })

      const result = updatedCards(filterCard, temp)

      const resCards = cards.map((obj) => {
        const replacement = result.cards.find((item) => item.id === obj.id)
        return replacement || { ...obj, removed: false }
      })

      // Convert arr to match listArr's key
      // const arrMapped = result.consultants.map((item) => ({ ...item, id: item.consultant_id }))
      const arrMapped = result.consultants

      // Merge while replacing duplicates
      // const merged = [...consultants, ...arrMapped].reduce((acc, obj) => {
      //   acc.set(obj.id, obj) // Replace if ID already exists
      //   return acc
      // }, new Map())
      // const merged = [...consultants, arrMapped]

      const merged = [...consultants, ...arrMapped].reduce((acc, obj) => {
        if (!acc.has(obj.id)) {
          // Only add if ID doesn't exist yet
          acc.set(obj.id, obj)
        }
        return acc
      }, new Map())

      const resConsultant = Array.from(merged.values())

      if (this.typeModalAction === 'delete') {
        resConsultant.push({
          id: randomConsultantId(),
          is_additional: true
        })
      } else {
        for (let i = 0; i < 5; i++) {
          const additional = {
            id: randomConsultantId(),
            is_additional: true
          }
          resConsultant.push(additional)
        }
      }

      this.cardSurvey = JSON.parse(JSON.stringify(resCards))
      this.initCardSurvey = JSON.parse(JSON.stringify(resCards))

      this.consultants = JSON.parse(JSON.stringify(resConsultant))
      this.initConsultants = JSON.parse(JSON.stringify(resConsultant))

      this.listConsultants = mergeListConsultant(this.listConsultants, resConsultant)
      this.adjustWidthCards()
    },
    scrollTo (target) {
      if (!target) return
      let element = this.$refs[target]
      if (Array.isArray(element)) {
        element = element[0]
      }

      if (element && typeof element.scrollIntoView === 'function') {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        })
        setTimeout(() => {
          element.classList.add('border-effect')
        }, 500)
        setTimeout(() => {
          element.classList.remove('border-effect')
        }, 3100)
      } else {
        console.error('Invalid element:', element)
      }
    },
    getClient () {
      this.isLoadingClient = true
      const payload = {
        url: this.url
      }

      this.$store
        .dispatch('client/getListVendor', payload)
        .then((response) => {
          const res = response.data.data

          this.initDataClients = JSON.parse(JSON.stringify(res.list))
          this.dataClients = JSON.parse(JSON.stringify(res.list))
          this.isLoadingClient = false
        })
        .catch((error) => {
          alertErrorMessage(error)
          this.isLoadingClient = false
        })
    },
    getListConsultant () {
      this.$store
        .dispatch('monitoring/getListConsultant', { url: this.url })
        .then((response) => {
          const res = response.data.data
          this.listSearchConsultant = JSON.parse(JSON.stringify(res))
          this.listConsultants = mergeListConsultant(
            this.initConsultants,
            JSON.parse(JSON.stringify(res))
          )
        })
        .catch((error) => {
          alertErrorMessage(error)
        })
    },
    filteredData () {
      if (this.isDropSurveyCustom) return
      this.isLoadingTable = true
      if (!this.search && !this.selectedClient && !this.selectedConsultant) {
        this.isFiltering = false
        this.getMonitoringKonsultan()
        return
      }
      let filteredHeader = this.initConsultants
      let filteredSchedule = this.initCardSurvey

      filteredSchedule = filteredSchedule.filter(
        (card) =>
          (!this.search || card.id.toString() === this.search) &&
          (!this.selectedClient || (card.vendor_id === this.selectedClient.id && card.start_time !== null && card.end_time !== null)) &&
          (!this.selectedConsultant || card.consultant_id === this.selectedConsultant.id)
      )

      const consultantIdsInCards = new Set(filteredSchedule.map((card) => card.consultant_id))
      filteredHeader = filteredHeader.filter((c) => consultantIdsInCards.has(c.id))
      if (filteredHeader.length === 0) {
        filteredHeader.push(this.selectedConsultant)
      }
      filteredHeader = filteredHeader.filter((item) => item !== null)

      while (filteredHeader.length <= 10) {
        filteredHeader.push({
          empty: true
        })
      }

      setTimeout(() => {
        this.isLoadingTable = false
        this.consultants = filteredHeader
        this.cardSurvey = filteredSchedule
        const container = document.querySelector('.body-monitoring')
        if (container) {
          container.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
        }
        this.isFiltering = true
      }, 300)
    },
    adjustWidthCards () {
      if (!this.isStretching) {
        // const cards = document.querySelectorAll('.card-events')
        // if (cards) {
        //   cards.forEach((card) => {
        //     const sessionId = card.getAttribute('data-session-id')
        //     card.style.width = sessionId === 0 ? '90%' : '100%'
        //     card.style.left = sessionId === 0 ? '10%' : '0'
        //   })
        // }
        setTimeout(() => {
          const cardOverlapping2 = findOverlappingCard2(JSON.parse(JSON.stringify(this.cardSurvey)))
          cardOverlapping2.map((item) => {
            const el = document.querySelector(`.card-events-${item.id}`)
            if (el) {
              el.style.width = item.width
              if (item.left) {
                el.style.left = item.left
              }
            }
          })
          // console.log(this.cardSurvey)
        }, 300)
      }
    }
  },
  mounted () {},
  created () {
    // this.isLoading = true
    this.getMonitoringKonsultan()
    this.getClient()
    this.getListConsultant()
  },
  watch: {
    search (val) {
      if (!val) {
        this.filteredData()
      }
    },
    selectedDate () {
      this.getMonitoringKonsultan()
    },
    selectedConsultant () {
      this.filteredData()
    },
    selectedClient () {
      this.filteredData()
    },
    availableSession (val) {
      const slotSesi = document.querySelectorAll('.cell.body')
      if (slotSesi) {
        slotSesi.forEach((el) => {
          const consultantId = el.getAttribute('data-consultant')
          const isTempConsultant = `${consultantId}`.includes('CONS')

          if (this.draggedEvent.session_id === 0 && !isTempConsultant) {
            // el.classList.add('slot-sesi')
          } else {
            const timeslot = el.getAttribute('data-timeslot')
            const isIncluded = JSON.parse(JSON.stringify(val)).some(
              (s) => s.session_start_time.slice(0, 5) === timeslot
            )
            if (isIncluded && !isTempConsultant) {
              el.classList.add('slot-sesi')
            }
          }
        })
      }
    }
  },
  beforeDestroy () {
    this.stopSmoothScroll()
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';
.sidebar-monitoring {
  width: 20%;
  height: 90vh;
  overflow: auto;
  border-right: 4px solid $color-grey-2;
  position: relative;
  background: $color-grey-6;
  padding-top: 8px;
  transition: width 0.3s ease-in-out;

  &.minimize-sidebar {
    width: 0px;
    overflow: hidden;
  }
}

.body-monitoring {
  width: 80%;
  height: 90vh;
  overflow: auto;
  position: relative;
  transition: all 0.5s ease-in-out;

  &.filled-monitoring {
    width: 100%;
  }
}

.date-minimize-icon {
  width: 0px;
  transition: width 0.5s ease-in-out, transform 0.5s ease-in-out;
  overflow: hidden;

  &.expand-minimize-icon {
    width: 30px;
    margin-right: 29px;
    margin-left: 18px;
    -webkit-transform: scale(-1, 1);
    -moz-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    transform: scale(-1, 1);

    img {
      width: 25px;
      height: 25px;
    }
  }
}

.v-calendar-wrapper-normal {
  width: 100%;
  height: 320px;
  overflow: hidden;
  background: $color-grey-6;
}

.minimize-icon {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 10px;
  flex-shrink: 0;
  z-index: 2;
  background: white;
}

.header-current-date {
  padding: 17px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #eaeaea;
  background: white;
  z-index: 29;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;

  &.adjust-z-index {
    z-index: 350;
  }
}

// ==================== TABLE BODY ====================

.schedule-table {
  display: grid;
  position: relative;
  transition: 0.2s opacity ease-in-out;
  opacity: 1;
  // right: 0;

  &.loading-table {
    // right: 250vw;
    opacity: 0;
  }
}

.loading-schedule {
  position: absolute;
  animation: loadingSchedule 0.7s cubic-bezier(0.25, 1, 0.5, 1) forwards;
}

@keyframes loadingSchedule {
  0% {
    opacity: 0;
    top: 30vh;
  }
  100% {
    opacity: 1;
    top: 0px;
  }
}

.header-table {
  display: flex;
  width: 100%;
  position: sticky;
  top: 70px;
  z-index: 30;
  background: #fafafa;
  border-top: 1px solid #e1e1e1;
  transition: top 0.3s ease-in-out;

  &.adjust-z-index {
    z-index: 350;
  }
}

.d-contents {
  display: contents;
}

.cell {
  width: 200px;
  border: 1px solid #e1e1e1;
  text-align: center;
  background: white;

  &.header {
    border: none !important;
    background: #fafafa;
    border-bottom: 1px solid #e1e1e1 !important;
    display: flex;
  }

  &.header-empty {
    background: #fafafa;
    border: none !important;
    z-index: 40;
    border-bottom: 1px solid #e1e1e1 !important;
    width: 100px;
  }

  &.time {
    position: sticky;
    left: 0;
    z-index: 10;
    border: none;
    border-right: 1px solid #e1e1e1;
    width: 100px;
    background: white;
  }

  &.drop-highlight {
    background: salmon;
  }

  &::after {
    content: '';
    opacity: 0;
    transition: opacity 0.1s ease-out;
  }

  &::before {
    content: '';
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }

  &.after-element::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--dynamic-height-cell, 80px);
    background: linear-gradient(0deg, #d6e7ff, #d6e7ff);
    z-index: 20;
    pointer-events: none;
    border-radius: 20px;
    border: 3px dashed #98c2fe;
    opacity: 1;
  }

  &.slot-sesi::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--dynamic-height-cell, 80px);
    z-index: 20;
    pointer-events: none;
    border-radius: 20px;
    border: 3px dashed #98c2fe;
    opacity: 1;
  }
}

.cell[data-timeslot]:hover {
  background-color: #f0f0f0;
}

.card-events {
  color: white;
  border-radius: 20px;
  margin: 0px 0;
  position: relative;
  min-height: 40px;
  height: 100%;
  width: 100%;
  z-index: 20;
  box-sizing: border-box;
  transition: border 0.3s ease-in-out, height 0.3s ease-in-out, width 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
  border: 1px solid transparent;

  &.remove-card {
    opacity: 0;
  }

  &.card-sesi-custom {
    width: 90%;
    border: 1px solid white;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 21;
    box-shadow: 0px 2px 15px #606060;

    .expand-handle {
      cursor: ns-resize;
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 5px;
      background: lightgray;
      opacity: 0;
    }
  }

  &.transparent-card {
    transition: opacity 0.3s ease-in-out !important;
    opacity: 0.5;
  }

  &.opacity-effect {
    opacity: 1;
    animation: opacityEffect 0.5s ease-in-out;
  }

  &.border-effect {
    opacity: 1;
    animation: borderEffect 1s 3 ease-in-out;
    animation-direction: alternate;
  }
}

@keyframes dropCardEffect {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes opacityEffect {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes borderEffect {
  0% {
    border: 4px solid #ffd5d5;
  }
  50% {
    border: 4px solid #ff8b8b;
  }
  100% {
    border: 4px solid #ff2626;
  }
}

.border-white-1 {
  border: 1px solid white;
}

.body-card-events {
  position: relative;
}

.description-card {
}

.tooltip-hover-card {
  width: 0;
  height: 0;
  position: absolute;
  top: 63%;
  left: 130px;
  box-shadow: 0px 10px 40px 0px #2525251f;
  background: white;
  z-index: 50;
  border-radius: 2.604vmin;
  transition: 0.2s all ease-in-out;
  opacity: 0;
  overflow: hidden;
  transform: scale(0);
  transform-origin: 0 0;
}

.tooltip-hover-card.visible {
  min-height: 250px;
  min-width: 400px;
  height: 32.552vmin;
  width: 52.083vmin;
  opacity: 1;
  transform: scale(1);
  transform-origin: 0 0;
}

.tooltip-hover-card {
  .tooltip-header {
    background: #fafafa;
    padding: 1.9vmin 2vmin;
    height: 24%;

    .tooltip-vendor-img {
      height: 3.4vmin;
      width: 6vmin;
      margin-right: 10px;
      flex-shrink: 0;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }

  .tooltip-body-monitoring {
    height: 54%;
    padding: 2vmin;
  }

  .tooltip-date {
    background: #fff4f4;
    border-radius: 2.604vmin;
    padding: 1.5vmin;
    height: 100%;
  }

  .tooltip-footer-monitoring {
    height: 22%;
    padding: 0 2.083vmin 1.563vmin 2.083vmin;
  }
}

.drag-icon-wrapper {
  height: 30px;
}

.drag-icon {
  height: 20px;
  margin-top: 5px;
}

.cursor-move {
  cursor: move;
}

.consultan-img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  overflow: hidden;

  &img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.vendor-card-img {
  background: white;
  border-radius: 100px;
  height: 22px;
  max-width: 90%;
  padding: 4px 10px;
  overflow: hidden;
  display: inline-flex;

  img {
    height: 100%;
    widows: 100%;
    object-fit: contain;
  }
}

.time-survey {
  position: absolute;
  right: 0;
  top: -10.5px;

  .line {
    width: 10px;
    height: 2px;
    background: #e1e1e1;
    margin-left: 5px;
  }
}

.previous-next-arrow {
  height: 35px;
  width: 35px;
  cursor: pointer;
}

.previous-next-arrow:hover {
  background: #f0f0f0;
}

.modal-delete-consultant {
  padding: 20px;
  padding-bottom: 25px;
  background: white;
  border-radius: 20px;
  width: 35em;
}

.modal-vendor-icon {
  height: 25px;
  border-radius: 100px;
  background: white;
  padding: 4px 10px;

  img {
    height: 100%;
    object-fit: contain;
  }
}

.modal-consultant-img {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  overflow: hidden;
  background: white;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.floating-card {
  position: absolute;
  top: 0;
  color: white;
  border-radius: 20px;
  height: 70px;
  width: 200px;
  z-index: 1000;
  pointer-events: none;
  box-sizing: border-box;
}

.collapse-sesi-custom-wrapper {
  width: 100%;
  padding: 17px 20px;
  border-top: 1px solid #e1e1e1;
  position: sticky;
  z-index: 30;
  top: 70px;
  left: 0;
  right: 0;
  background: white;
  transition: padding 0.5s ease-in-out, max-height 1s ease-in-out, opacity 0.7s ease-in-out;
  max-height: 450px;
  opacity: 1;

  &.minimize-sidebar {
    padding: 17px 38px;
  }

  &.removed-wrapper-sesi-custom {
    max-height: 0px;
    padding: 0;
    margin: 0;
    overflow: hidden;
    opacity: 0;
  }

  &.adjust-z-index {
    z-index: 350;
  }
}

.collapse-sesi-custom {
  background: linear-gradient(90deg, #891313 0%, #d92727 99.97%);
  border-radius: 20px;
  color: white;
  padding: 10px 20px;
}

.chevron-sesi-custom {
  position: relative;
  transition: all 0.2s ease-in-out;
  transform: rotate(270deg);
}

.chevron-sesi-custom.open {
  transform: rotate(90deg);
  transform-origin: center;
}

.body-sesi-custom {
  background: white;
  padding-left: 16px;
  padding-right: 16px;
  min-height: 6em;
  width: 100%;
  border-radius: 20px;
  margin-bottom: 10px;
  margin-top: 3px;
}

.vendor-img-sesi-custom {
  height: 30px;
  width: 90px;
  border-radius: 100px;
  background: white;
  overflow: hidden;
  padding: 5px 10px;
  margin-right: 10px;
  flex-shrink: 0;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.list-survey-custom-wrapper {
  max-height: 7em;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.list-sesi-custom-div {
  width: 49.5%;
  height: 82px;
  display: flex;
  justify-content: flex-end;
}

.list-sesi-custom {
  width: 100%;
  height: 100%;
  display: flex;
  background: #f5f5f5;
  padding: 10px;
  border-radius: 12px;
  // margin-bottom: 10px;
  transition: height 0.5s ease-in-out, width 0.3s ease-in-out;
  overflow: hidden;
  border: 1px solid #e1e1e1;
  transform-origin: right;

  &.removed-sesi-custom {
    height: 0px;
    padding: 0px;
    margin: 0;
  }

  &.dragging {
    width: 200px;
  }
}

.info-vendor-sesi-custom {
  flex: 1.9;
  border-right: 1px solid #e1e1e1;
  padding-right: 15px;
  margin-right: 20px;
}

.address-sesi-custom {
  flex: 2;
  border-right: 1px solid #e1e1e1;
  padding-right: 15px;
  margin-right: 10px;

  div {
    max-width: 35em;
  }
}

.drag-icon-sesi-custom {
  padding: 0 10px;
  height: 35px;
  align-self: center;
  // pointer-events: none;
  cursor: move;
  flex-shrink: 0;
}

.drag-div {
  width: 100px;
  height: 100px;
  background: salmon;
}
</style>

<style>
.v-calendar-wrapper-normal .vc-container {
  border: none !important;
  font-family: 'Averta' !important;
  background: #fafafa;
}

.v-calendar-wrapper-normal .vc-grid-cell {
  margin-bottom: 10px !important;
}

.v-calendar-wrapper-normal .vc-day-content {
  height: 25px !important;
  width: 25px !important;
  font-size: 14px !important;
}

.v-calendar-wrapper-normal .vc-weekday {
  font-size: 14px !important;
}

.v-calendar-wrapper-normal .vc-highlight.vc-highlight-base-start {
  height: 100% !important;
}

.v-calendar-wrapper-normal .vc-highlight.vc-highlight-base-end {
  height: 100% !important;
}

.v-calendar-wrapper-normal .vc-highlight.vc-highlight-base-middle {
  height: 100% !important;
  width: 100% !important;
}

.v-calendar-wrapper-normal .vc-highlight {
  width: 27px !important;
  height: 27px !important;
}

.search-left-monitoring input {
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 4px;
}

.collapse-sesi-custom .collapse-trigger {
  width: 100%;
}

.floating-button-save {
  position: fixed;
  bottom: -100px;
  right: 50%;
  transform: translate(50%, 0);
  z-index: 58;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 30%;
  height: 85px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 0px 50px 11px rgba(0, 0, 0, 0.1);
  overflow: hidden !important;
  opacity: 0;
  transition: bottom 0.65s ease-in-out, opacity 0.5s ease-in-out, width 0.5s ease-in-out;
}

.floating-button-save.show {
  width: 50%;
  bottom: 65px;
  opacity: 1;
}
</style>

<style>
.search-left-monitoring.client .dropdown-menu .dropdown-content {
  max-height: 150px !important;
}
</style>
