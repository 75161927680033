<template>
  <div class="container-tab-wrapper">
    <div v-if="isLoading" style="height: 15em;">
      <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false">
        <SpinnerIcon />
      </b-loading>
    </div>
    <div v-else-if="Object.keys(this.data).length === 0" class="columns is-centered">
      <div class="column has-text-centered">
        <ProyekEmptyIcon />
        <p class="averta-bold mt-2">
          Detail Survey Proyek Kosong
        </p>
      </div>
    </div>
    <div v-else class="columns is-variable is-2">
      <div class="column is-8">
        <div class="is-flex flex-sm-column">
          <div class="card card-wrapper-costum mr-4 mr-sm-0 mb-sm-2 mb-0 flex-1 p-4">
            <p class="text-title averta-bold">Deskripsi Proyek</p>
            <div class="mb-3">
              <p class="label-14 color-gray">Pesanan</p>
              <p class="averta-bold">{{project.type}}</p>
            </div>
            <div class="mb-3">
              <p class="label-14 color-gray">Pesanan Dibuat</p>
              <p class="averta-bold">{{project.client_name}}</p>
            </div>
            <div class="mb-3">
              <p class="label-14 color-gray">Deskripsi Proyek</p>
              <p class="averta-bold">{{project.description || '-'}}</p>
            </div>
          </div>
          <CardClientInformation :client="client" />
        </div>
        <div class="card card-wrapper-costum p-4 mb-3 mt-4">
          <p class="averta-bold is-size-5 mb-4">Detail Proyek</p>
          <div class="wrapper-tanggal-survey is-flex is-justify-content-space-between mb-3">
            <div class="is-flex is-align-items-center flex-sm-column align-items-sm-start flex-sm-row">
              <CalendarIcon class="mr-3" />
              <div class="is-flex is-flex-direction-column">
                <p class="color-blue" style="font-size: 12px;">Tanggal Survey</p>
                <p class="averta-bold">{{ formatDate(detail.date) }}</p>
              </div>
            </div>
            <div class="wrapper-skill">
              <div class="is-flex is-align-items-center averta-bold is-justify-content-end justify-content-sm-start">
                <img
                  v-if="detail.skill_image"
                  :src="detail.skill_image"
                  alt="skill_image"
                  class="skill-image mr-3"
                />
                <p>{{ detail.skill_name ? detail.skill_name : '-' }}</p>
              </div>
            </div>
          </div>
          <div class="wrapper-tukang" v-if="detail.status !== 'Survey Dibatalkan' && !isMobile">
            <div class="is-flex is-justify-content-space-between is-align-items-center wrapper-detail-tukang flex-sm-column align-items-sm-start c-w-100">
              <div class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100">
                <div class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center">
                  <div v-if="detail.worker_image && detail.worker_image !== '-'" class="mr-3 img-photo-tukang">
                    <img :src="detail.worker_image" alt="worker_image" class="w-100">
                  </div>
                  <div>
                    <p class="averta-bold" :class="{ 'averta-bold-italic': detail.worker_name === '-' }">
                    {{ detail.worker_name &&
                       detail.worker_name !==
                       '-' ?
                       detail.worker_name :
                       'Belum diassign' }}
                    </p>
                    <p v-if="detail.worker_id">{{ detail.worker_id ? 'ID' + detail.worker_id : '-'}}</p>
                  </div>
                </div>
                <div class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center gap-7">
                  <div v-if="detail.worker_id" class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center">
                    <p class="averta-black">Absensi :</p>
                  </div>
                  <div v-if="detail.worker_id" class="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center gap-4">
                    <div class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-end">
                      <p class="averta-bold color-red" :class="{ 'averta-bold-italic': detail.worker_name === '-' }">
                        Hadir
                      </p>
                      <p v-if="detail.worker_id"
                      :class="{'averta-bold': isValidDate(detail.attendance_start_date), 'color-black averta-regular-italic': !isValidDate(detail.attendance_start_date)}">
                        {{ detail.attendance_start_date ?
                          absen(detail.attendance_start_date) :
                          'Belum Absen' }}
                      </p>
                      <p :style="{ display: detail.attendance_start_date ? 'flex' : 'none'}" class="averta-regular color-black">
                        {{ moment(detail.attendance_start_date).format('dddd, DD MMM YYYY') }}
                      </p>
                    </div>
                    <div class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-end">
                      <div v-if="detail.worker_id" class="card-photo-before-after" :class="{ 'cursor-pointer': showImageBefore.filename !== '-' }">
                        <EmptyImageIcon v-if="showImageBefore && showImageBefore.filename === '-'"  size="100%" />
                        <img
                          v-else
                          :src="showImageBefore && showImageBefore.filename"
                          alt="worker_image"
                          @click="handlePreviewImage(detail.images.filter(image => image.name === 'Absensi Hadir'), 'photoSurvey')"
                        />
                      </div>
                    </div>
                  </div>
                  <div v-if="detail.worker_id" class="divider-vertical-line-1" style="min-height: 35px;"></div>
                  <div v-if="detail.worker_id" class="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center gap-4">
                    <div class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-end">
                      <p class="averta-bold color-red" :class="{ 'averta-bold-italic': detail.worker_name === '-' }">
                        Pulang
                      </p>
                      <p v-if="detail.worker_id"
                      :class="{'averta-bold': isValidDate(detail.attendance_end_date), 'color-black averta-regular-italic': !isValidDate(detail.attendance_end_date)}">
                      {{ detail.attendance_end_date ?
                        absen(detail.attendance_end_date) :
                        'Belum Absen'
                      }}
                      </p>
                      <p :style="{ display: detail.attendance_end_date ? 'flex' : 'none'}" class="averta-regular color-black">
                        {{ moment(detail.attendance_end_date).format('dddd, DD MMM YYYY') }}
                      </p>
                    </div>
                    <div v-if="detail.worker_id" class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-end">
                      <div class="card-photo-before-after" :class="{ 'cursor-pointer': showImageAfter.filename !== '-' }">
                        <EmptyImageIcon v-if="showImageAfter && showImageAfter.filename === '-'"  size="100%" />
                        <img
                          v-else
                          :src="showImageAfter && showImageAfter.filename"
                          alt="worker_image"
                          @click="handlePreviewImage(detail.images.filter(image => image.name === 'Absensi Pulang'), 'photoSurvey')"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="is-flex wrapper-absen"
              v-if="detail.worker_name === '-' ||
              (detail.worker_name === '-' && detail.status === 'Survey Berlangsung') ||
              (detail.worker_name === '-' && detail.status === 'Survey Diproses')">
                <ButtonApp @click="handleOpenModalAssignConsultant" :isSecondary="true" height="35px">
                  <p class="averta-bold">Assign Konsultan</p>
                </ButtonApp>
                <!-- <p>Absen Masuk : <span style="padding-right: 2px" :class="{'averta-bold': isValidDate(detail.attendance_start_date), 'color-red is-italic': !isValidDate(detail.attendance_start_date)}">{{ absen(detail.attendance_start_date)}}</span></p>
                <p class="mx-3 is-hidden-mobile">|</p>
                <p>Absen Keluar : <span style="padding-right: 2px" :class="{'averta-bold': isValidDate(detail.attendance_end_date), 'color-red is-italic': !isValidDate(detail.attendance_end_date)}">{{ detail.attendance_end_date ? absen(detail.attendance_end_date) : 'Belum Absen' }}</span>
                </p> -->
              </div>
            </div>
            <div class="divider-dashed-line-1" v-if="detail.worker_id"></div>
            <div class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100" v-if="detail.worker_id">
              <div class="is-flex is-flex-direction-column is-justify-content-flex-start is-align-items-flex-start c-w-100">
                <p class="averta-bold">Catatan dari konsultan</p>
                <p class="white-space-preline">{{ detail.note !== 'null' && detail.note ? detail.note : '-' }}</p>
                <p class="white-space-preline mt-2 averta-regular-italic"
                 :style="{display: detail.note_updated_at === null ? 'none' : 'flex'}">Diperbaharui oleh:
                  {{ detail.note_updated_by }}
                  ({{ moment(detail.note_updated_at).format('dddd, DD MMMM YYYY - HH:mm') }})
                </p>
              </div>
              <div class="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-flex-start c-w-100"
              v-if="(isSM && (detail.status === 'Survey Berlangsung' || detail.status === 'Survey Diproses' ||
                    detail.status === 'Proses Business Support' || detail.status === 'Survey Selesai' ||
                    detail.status === 'Proses Site Manager' || detail.status === 'Proses Purchasing') &&
                    detail.worker_id) ||
                    (isBS && (detail.status === 'Survey Berlangsung' || detail.status === 'Survey Diproses' ||
                    detail.status === 'Proses Business Support' || detail.status === 'Survey Selesai' ||
                    detail.status === 'Proses Site Manager' || detail.status === 'Proses Purchasing') &&
                    detail.worker_id)">
                <p class="averta-bold color-red cursor-pointer" @click="handleOpenModalEditNoteSurvey">
                  Ubah Catatan
                </p>
              </div>
            </div>
          </div>

          <p class="averta-bold mb-3 mt-4">Foto Kehadiran Survey</p>
          <p v-if="!detail.images" class="is-italic has-text-grey">Empty</p>
          <div v-if="detail.images.length > 0" class="wrapper-file-photo is-flex is-align-items-flex-start is-justify-content-flex-start gap-10">
            <div
              class="wrapper-file flex-shrink-0 word-wrap-break"
              v-for="dataImage, indexImage in detail.images.filter(image => image.name !== 'Absensi Hadir' && image.name !== 'Absensi Pulang')"
              :key="dataImage.id" style="min-width: auto;"
            >
              <div
              :class="
              detail.images.length === 6 ? 'image-survey' :
              detail.images.length === 7 ? 'image-survey-5' : 'image-survey-6'"
              v-if="dataImage && dataImage.filename === '-'" class="image-survey mb-2">
                <EmptyImageIcon size="100%" />
              </div>
              <img v-else class="image-survey mb-2 is-clickable"
              :src="dataImage && dataImage.filename" :alt="dataImage && dataImage.name"
              :class="
              detail.images.length === 6 ? 'image-survey' :
              detail.images.length === 7 ? 'image-survey-5' : 'image-survey-6'"
               @click="handlePreviewImage(detail.images.filter(image => image.name !== 'Absensi Hadir' && image.name !== 'Absensi Pulang' && image.filename !== '-'), 'photoSurvey', indexImage)">
              <b-tooltip :position="indexImage > 4 ? indexImage + 1 >= detail.images.length - 3 ? 'is-left' : 'is-right' : 'is-right'" type="is-dark" :triggers="['hover']" :auto-close="true"
                append-to-body>
                <template v-slot:content>
                  <div style="max-width: 300px;" class="word-wrap-break">
                    <p class="white-space-nowrap">{{ dataImage.name }}</p>
                  </div>
                </template>
                <p class="averta-bold image-name m-0 p-0"
                :class="
                detail.images.length === 6 ? 'image-name' :
                detail.images.length === 7 ? 'image-name image-name-5' : 'image-name image-name-6'"
                >{{ dataImage.name }}</p>
              </b-tooltip>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-4">
        <!-- Status Proyek -->
        <StatusProyekComponent :dataSurvey="data" title="Status Proyek" />

        <!-- Penawaran Dalam Proses Internal -->
        <StatusProyekComponent v-if="data && data.offering_history" :dataSurvey="data" title="Penawaran Dalam Proses Internal" :isConfirmOffering="true" />

        <!-- <div v-if="role === 'am' && status === 'Survey Selesai'" class="card card-wrapper-costum p-4">
          <p class="averta-black is-size-5 mb-4">Total Harga</p>
          <div class="is-flex is-align-items-center averta-bold">
            <img src='source_img' alt="skill_image" class="skill-image mr-3">
            <p>Konsultan</p>
          </div>
          <div class="averta-bold mt-3 is-flex is-align-items-center is-justify-content-space-between">
            <p>1 x Rp {{formatThousand(0)}}</p>
            <p class="address">GRATIS</p>
          </div>
          <div class="total-wrapper averta-bold">
            <p>Total : </p>
            <p class="color-blue ml-2">GRATIS</p>
          </div>
        </div> -->
      </div>

      <ModalPreviewImage :indexImg="indexImg" :isActive="previewImage" :isOneImage="true" :dataImage="imagePreview" @closeModal="closePreviewImage()" />

    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { CalendarIcon, ProyekEmptyIcon, SpinnerIcon, EmptyImageIcon } from '@/components/icons'
import { mapGetters } from 'vuex'
import StatusProyekComponent from '@/components/project/detail/StatusProyekComponent'
import ModalPreviewImage from '@/components/modal/ModalPreviewImage'
import CardClientInformation from '@/components/card/CardClientInformation'

export default {
  name: 'TabHistorySurvey',
  props: ['data', 'role', 'status', 'surveyId'],
  components: {
    CalendarIcon,
    ProyekEmptyIcon,
    SpinnerIcon,
    StatusProyekComponent,
    ModalPreviewImage,
    EmptyImageIcon,
    CardClientInformation
  },
  data () {
    return {
      isLoading: true,
      client: null,
      detail: null,
      project: null,
      status_history: null,
      idSurvey: null,

      previewImage: false,
      imagePreview: null,
      indexImg: 0,
      moment: moment
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      user: 'auth/user'
    }),
    isMobile () {
      if (window.innerWidth <= 768) {
        return true
      }
      return false
    },
    isSM () {
      if (this.user.roles[0].name === 'Site Manager') {
        return true
      } return false
    },
    isBS () {
      if (this.user.roles[0].name === 'Account Manager' || this.user.roles[0].name === 'Business Support') {
        return true
      }
      return false
    },
    showImageBefore () {
      return this.detail.images.find(
        (image) => image.name === 'Absensi Hadir'
      )
    },
    showImageAfter () {
      return this.detail.images.find(
        (image) => image.name === 'Absensi Pulang'
      )
    }
  },
  methods: {
    formatDate (date) {
      if (!date) {
        return '-'
      }

      return moment(date).format('DD MMMM YYYY')
    },
    formatThousand (x) {
      if (!x && x !== 0) {
        return '-'
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    formatBytes (bytes, decimals = 2) {
      if (!+bytes) return '0 Bytes'

      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },
    absen (date) {
      const varDate = moment(date)
      if (varDate.isValid() && date) {
        return moment.utc(date).format('HH:mm')
      }
      return 'Belum Absen'
    },
    isValidDate (date) {
      if (moment(date).isValid() && date) {
        return true
      } return false
    },
    handlePreviewImage (data, type, index) {
      this.indexImg = index
      if (type === 'photoSurvey') {
        this.imagePreview = data.map((item) => {
          return item.filename
        })
      } else {
        if (data && data !== '-') {
          this.imagePreview = data
        }
      }

      this.previewImage = true
    },
    closePreviewImage () {
      this.imagePreview = null
      this.previewImage = false
    },
    extractFileName (filename) {
      if (!filename) {
        return '-'
      }

      const parts = filename.split('.')
      parts.pop()
      return parts.join('.')
    }
  },
  mounted () {
    this.isLoading = true
    if (Object.keys(this.data).length > 0) {
      this.client = this.data.client
      this.detail = this.data.detail
      // this.notes = this.data.notes
      this.project = this.data.project
      this.status_history = this.data.status_history
      this.isLoading = false
    } else {
      this.isLoading = false
    }
  }
}
</script>

<style scoped>
.w-100 {
  width: 100%;
}

.color-blue {
  color: #3185fc;
}

.flex-1 {
  flex: 1;
}

.label {
  color: #868686;
}

.color-red {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.text-title {
  font-size: 20px;
  margin-bottom: 1rem;
}

.detail-material-wrapper {
  background: #fafafa;
  border-radius: 20px;
}

.material-wrapper {
  background: #fff;
  border-radius: 20px;
}

.fs-12 {
  font-size: 12px;
}

.btn-primary {
  background: linear-gradient(
    116.12deg,
    #d9272d 16.15%,
    #eb4600 83.24%
  ) !important;
  border-radius: 100px;
}

.btn-disabled {
  background: #9a9a9a;
  border-radius: 100px;
}

.material-photo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 57px;
  flex-shrink: 0;
}

.material-photo-wrapper img {
  width: 100%;
}

.material-photo-wrapper {
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 57px;
  height: 57px;
  border-radius: 8px;
}

.material-photo-wrapper img {
  width: 100%;
  height: 100%;
}

.photo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: #fff;
}

.photo-wrapper img {
  width: 100%;
  height: 100%;
}

.note-konsultan {
  margin-top: 1rem;
  padding-top: 0.8rem;
  border-top: 1px dashed #9A9A9A;
  max-height: 120px;
  overflow: auto;
}

.wrapper-tukang {
  background: #F8F8F8;
  padding: 14px;
  border-radius: 10px;
}

.wrapper-file-photo {
  background: #F6F6F6;
  padding: 12px;
  border-radius: 12px;
  height: auto;
  overflow-x: auto;
}

.wrapper-file {
  display: flex;
  flex-direction: column;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0 !important;
  height: auto;
  width: auto;
}

.image-survey {
  width: 150px;
  height: 150px;
  object-fit: cover;
  padding: 0;
  border-radius: 8px;
}

.image-survey-5 {
  width: 125px;
  height: 125px;
  object-fit: cover;
  padding: 0;
  border-radius: 8px;
}

.image-survey-6 {
  width: 70px;
  height: 70px;
  object-fit: cover;
  padding: 0;
  border-radius: 8px;
}

.image-name {
  width: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  text-align: center;
  word-wrap: break-word;
}

.image-name-5 {
  width: 125px !important;
}

.image-name-6 {
  width: 80px !important;
}

.steps {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.steps ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.steps li {
  display: flex;
  padding-bottom: 1rem;
}

.step-marker {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
  position: relative;
}

.step-marker:before {
  content: "";
  position: absolute;
  height: 35px;
  border-left: 2px dashed #ccc;
  bottom: -25px;
  z-index: 10;
}

.step-marker.completed:before {
  border-left: 2px dashed #10BD41;
}

.step-marker.end:before {
  border: none !important;
}

.skill-image {
  width: 30px;
  object-fit: contain;
}

.text-absen {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.wrapper-tanggal-survey {
  flex-direction: column;
}

@media only screen and (max-width: 768px) {
  .wrapper-tanggal-survey {
    display: block !important;
  }

  .wrapper-absen {
    display: block !important;
    margin-top: 16px;
  }

  .wrapper-tukang {
    display: block !important;
  }
}

@media screen and (max-width: 600px) {
  .flex-sm-column {
    flex-direction: column;
  }

  .mb-sm-2 {
    margin-bottom: 1rem !important;
  }

  .mr-sm-0 {
    margin-right: 0 !important;
  }
}

.img-photo-tukang {
  width: 36px;
  height: 36px;
}

.img-photo-tukang img {
  border-radius: 100px;
  object-fit: cover;
  width: 36px;
  height: 36px;
}

.card-photo-before-after{
  display: flex;
  flex-direction: column;
  width: 55px;
  min-width: 55px;
  max-width: 55px;
  height: 55px;
  min-height: 55px;
  max-height: 55px;
  justify-content: center;
  align-items: center;
  background: transparent;
  padding: 0;
  margin: 0;
  gap: 20px;
  border-radius: 10px;
  overflow: hidden !important;
}
</style>
