// eslint-disable-next-line no-unused-vars
import axios from 'axios'
import {
  guideSessions
} from '../../views/v2/am/monitoring-konsultan/example-data'
import { randomTerseHexColor } from '@/helpers'

/* eslint-disable */
const state = () => ({});

const getters = {};

const actions = {
  getMonitoringKonsultan({ commit, state }, payload) {
    let path = "";
    if (payload.vendor_id) path += `&vendor_id=${payload.vendor_id}`;
    if (payload.user_id) path += `&user_id=${payload.user_id}`;
    if (payload.search) path += `&search=${payload.search}`;

    const date = payload.date
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_MONITORING}?date=${date}${path}`)
      .then((response) => {
        let res = response.data.data
        res.surveys = res.surveys.map(obj => ({
          ...obj,
          background:
            obj.vendor_background ||
            obj.vendor_name.toLowerCase().includes('alfa') ?
            '#0000FF' : obj.vendor_name.toLowerCase().includes('summer') ?
            '#FF0000' : randomTerseHexColor()
        }));
        resolve(response)
        }).catch((error) => {
          reject(error)
        })
    });
  },
  getGuideSesi({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ data: guideSessions });
      }, 700);
      // axios.get(`${payload.url}${process.env.VUE_APP_MONITORING}?date=${payload.date}${path}`)
      //   .then((response) => {
      //     resolve(response)
      //   }).catch((error) => {
      //     reject(error)
      //   })
    });
  },
  removeSurveySchedule({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      const body = {
        survey_id: payload.survey_id,
        homecare_project_id: payload.homecare_project_id,
        worker_id: payload.worker_id
      }
      axios.put(`${payload.url}${process.env.VUE_APP_DATA_PROJECT_V2}/detail/consultant/remove`, body)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        console.error('Error:', error)
        reject(error)
      })
    });
  },
  assignMonitoringKonsultan({ commit, state }, payload) {
    const body = {
      consultant_id: payload.consultant_id,
      surveys: payload.surveys
    };

    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${process.env.VUE_APP_MONITORING}/batch-assign`, body)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    });
  },
  getListConsultant({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${payload.url}${process.env.VUE_APP_DATA_PROJECT_V2}/detail/consultant/list`
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
