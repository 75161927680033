<template>
  <ModalTemplate :showModal="showModal" :width="widthModal" @closeModal="closeModal">
    <template v-slot:headerModal>
      <div class="is-flex is-justify-content-space-between is-align-items-center">
        <p class="averta-bold label-18 ml-2">Tentukan Jadwal Pengerjaan</p>
        <div class="is-clickable" @click="closeModal">
          <b-icon icon="close"></b-icon>
        </div>
      </div>
    </template>

    <template v-slot:bodyModal>
      <div class="is-flex">
        <div class="flex-1">
          <div class="body-modal">
            <p class="averta-bold">Pilih satu tanggal</p>
            <div class="vc-date-picker-wrapper mt-2 c-w-100 flex-center-horizontal">
              <v-date-picker
                v-model="selectedDate"
                is-expanded
                expanded
                color="red"
                timezone="utc"
                :min-date="new Date()"
                @input="handleChangeDate"
              />
            </div>
            <ErrorComponent v-if="errorMsg" :title="errorMsg" />
          </div>
        </div>
        <div class="flex-1 body-modal-sesi">
          <p class="averta-bold">Pilih Sesi Survey Tersedia</p>
          <p class="color-grey mb-3">
            Pilihan Sesi Survey yang muncul adalah sesi yang dapat dipilih
          </p>
          <div class="body-time-survey">
            <div v-if="isLoadingSesi"></div>
            <div v-else-if="dataSesi.length === 0" class="empty-time-survey">
              <ProyekEmptyIcon width="160" height="140" />
              <p class="has-text-centered averta-black" style="width: 200px">
                Tentukan tanggal survey terlebih dahulu
              </p>
            </div>
            <div v-else>
              <div
                v-for="(item, index) in dataSesi"
                :key="index"
                class="item-time-survey is-clickable is-justify-content-space-between"
                @click="handleSelectSesi(item)"
              >
                <div class="is-flex is-align-items-center">
                  <input
                    type="radio"
                    :value="item.session_start_time"
                    :checked="selectedSesi && selectedSesi.session_id === item.session_id"
                    :disabled="!item.session_available"
                  />
                  <p v-if="item.session_id === 0" class="ml-2">{{ item.session_name }}</p>
                  <p v-else class="ml-2">
                    {{ item.session_name }} <span class="mx-2">|</span>
                    {{ formatHour(item.session_start_time) }} <span class="mx-2">-</span>
                    {{ item.session_end_time ? formatHour(item.session_end_time) : '' }}
                  </p>
                </div>
                <p v-if="!item.session_available" class="averta-reguler-italic font-red">
                  Tidak Tersedia
                </p>
              </div>
            </div>
          </div>
          <ButtonApp class="c-w-100" :isDisabled="isDisabled" @click="handleSubmitCalender">
            <p class="averta-bold py-2">{{ isLoadingCheck ? 'Dalam Pengecekan...' : 'Proses' }}</p>
          </ButtonApp>
        </div>
      </div>
    </template>
  </ModalTemplate>
</template>

<script>
import { mapGetters } from 'vuex'
import { alertErrorMessage } from '@/helpers'
import ModalTemplate from '@/components/modal/ModalTemplate.vue'
import ButtonApp from '@/components/button/ButtonApp.vue'
import ErrorComponent from '@/components/ErrorComponent.vue'
import moment from 'moment'
import { ProyekEmptyIcon } from '@/components/icons'

export default {
  name: 'ModalWorkDate',
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    dataHeader: {
      type: Object
    },
    dateSelected: {
      type: [String, Date]
    },
    timeSelected: {
      type: Object
    }
  },
  components: {
    ModalTemplate,
    ButtonApp,
    ErrorComponent,
    ProyekEmptyIcon
  },
  data () {
    return {
      isLoading: false,
      isLoadingCheck: false,
      selectedDate: null,
      errorMsg: null,

      isLoadingSesi: false,
      dataSesi: [],
      selectedSesi: null
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    widthModal () {
      return '50em'
    },
    isDisabled () {
      return !this.selectedDate || this.isLoadingCheck || !!this.errorMsg || !this.selectedSesi
    }
  },
  methods: {
    formatHour (time) {
      if (!time) return ''
      return time.slice(0, 5)
    },
    closeModal () {
      this.$emit('closeModal')
      this.selectedDate = null
      this.dataSesi = []
      this.selectedSesi = null
    },
    handleChangeDate (val) {
      this.isLoadingCheck = true
      this.errorMsg = null
      const payload = {
        url: this.url,
        surveyId: this.dataHeader && this.dataHeader.survey_id,
        date: moment(val).format('YYYY-MM-DD')
      }
      this.$store
        .dispatch('project/checkScheduleComplain', payload)
        .then((response) => {
          const res = response.data
          const code = res.code
          this.selectedSesi = null
          this.getSesi()

          if (code === 1 || code === '1') {
            this.errorMsg = null
          }
          this.isLoadingCheck = false
        })
        .catch((error) => {
          this.isLoadingCheck = false
          const res = error.response.data
          if (res.code === 0 || res.code === '0') {
            this.errorMsg =
              res.message || 'Tanggal yang kamu pesan sudah penuh. Mohon ganti ke tanggal lain'
          } else {
            alertErrorMessage(error)
          }
        })
    },
    handleSubmitCalender () {
      const body = {
        date: this.selectedDate
      }

      if (this.selectedSesi) {
        body.sesi = this.selectedSesi
      }
      this.$emit('onSubmitCalender', body)
      this.closeModal()
    },
    handleSelectSesi (data) {
      if (!data.session_available) return
      this.selectedSesi = data
    },
    getSesi () {
      this.isLoadingSesi = true
      const payload = {
        url: this.url,
        survey_id: this.dataHeader && this.dataHeader.survey_id,
        date: moment(this.selectedDate).format('YYYY-MM-DD')
      }
      this.$store
        .dispatch('project/getSesiSurvey', payload)
        .then((res) => {
          if (res.status === 200) {
            this.dataSesi = res.data.data
          }
          this.isLoadingSesi = false
        })
        .catch((err) => {
          this.isLoadingSesi = false
          alertErrorMessage(err)
        })
    }
  },
  watch: {
    showModal (val) {
      if (val) {
        this.selectedDate = this.dateSelected || null
        if (this.timeSelected && Object.keys(this.timeSelected).length > 0) {
          this.selectedSesi = {
            session_id: this.timeSelected.sesi.id || null,
            session_start_time: this.timeSelected.sesi.start_time || null
          }
          this.getSesi()
        }
      }
    }
  }
}
</script>

<style scoped>
.body-modal {
  min-height: 300px;
  padding: 20px 0px 5px 25px;
}

.body-modal-sesi {
  padding: 20px 25px;
}

.footer-modal {
  padding: 10px 20px 15px 20px;
  background: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
}

.vc-date-picker-wrapper {
  height: 230px;
  overflow: hidden;
}

.body-time-survey {
  height: 14.5em;
  overflow: auto;
  margin-bottom: 1em;
  border-bottom: 1px solid #c1c7d3;
}

.empty-time-survey {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  top: 0;
}

.item-time-survey {
  display: flex;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #e1e1e1;
  background: #fafafa;
  padding: 15px;
  margin-bottom: 0.8em;
}

.item-time-survey input[type='radio'] {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
  transform: scale(1.5);
}

.header-date-survey {
  background: #e1eeff;
  padding: 15px 20px;
  display: flex;
  align-items: center;
}
</style>

<style>
.vc-date-picker-wrapper .vc-container {
  border: none !important;
  font-family: 'Averta' !important;
}
</style>
