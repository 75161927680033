<template>
    <div v-if="isLoading">
      <b-loading :is-full-page="true" :can-cancel="false">
        <SpinnerIcon />
      </b-loading>
    </div>
    <div v-else ref="attendanceProyek" id="attendanceProyek">
      <p class="averta-bold label-20 mb-3">Kehadiran Proyek</p>
      <b-button
          v-for="item in listTab"
          :key="item.value"
          class="mr-3 btn-tabs-area mb-4"
          :class="{ 'is-active': selectedTab === item.value }"
          @click="changeIsActiveTab(item.value)"
      >
        <div class="white-space-normal is-flex flex-direction is-position-relative">
          <p class="white-space-md-nowrap" :class="{'averta-bold': selectedTab === item.value}" >{{ item.title }}</p>
        </div>
      </b-button>
      <TabLaporanHarian v-if="selectedTab === 'daily-report'" :statusProject="statusProject" @reloadPageProyek="reloadPageProyek($event)" :dataHeader="dataHeader" />
      <TabRekapHasilProyek v-if="selectedTab === 'recap-project'" :dataHeader="dataHeader" />
    </div>
</template>

<script>
import { SpinnerIcon } from '@/components/icons'
import TabLaporanHarian from '../components/TabLaporanHarian.vue'
import TabRekapHasilProyek from '../components/TabRekapHasilProyek.vue'

export default {
  name: 'AttendanceProyek',
  props: {
    isLoading: {
      type: Boolean
    },
    statusProject: {
      type: String
    },
    dataHeader: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    SpinnerIcon,
    TabLaporanHarian,
    TabRekapHasilProyek
  },
  data () {
    return {
      listTab: [
        {
          title: 'Laporan Harian',
          value: 'daily-report'
        },
        {
          title: 'Rekap Hasil Proyek',
          value: 'recap-project'
        }
      ],
      selectedTab: 'daily-report'
    }
  },
  methods: {
    changeIsActiveTab (tab) {
      this.selectedTab = tab
    },
    reloadPageProyek (data) {
      this.$emit('reloadPageProyek', data)
    }
  }
}
</script>

<style scoped>
    .btn-tabs-area {
        height: auto;
        border-radius: 100px;
        color: #9a9a9a;
        border: 1px solid #e1e1e1;
    }
    .btn-tabs-area.is-active {
        background: rgba(49, 133, 252, 0.1);
        color: #3185fc;
        border: 1px solid #3185fc;
    }
</style>
