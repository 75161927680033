<template>
  <svg width="50" height="50" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#868686" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.5686 8.14952C18.3253 8.23242 17.1944 8.83238 16.0557 9.48273C14.9169 10.1332 13.851 10.7375 13.687 10.8258C12.9123 11.243 10.9543 12.3914 10.5352 12.6743C9.7024 13.2366 9.24989 13.8874 9.09358 14.7477C8.9681 15.4383 8.96912 23.7802 9.09477 24.4592C9.22204 25.1471 9.44991 25.5571 10.0134 26.1121C10.4026 26.4955 11.1935 26.9837 14.2248 28.7119C16.2775 29.8822 18.1942 30.9249 18.4841 31.029C19.1431 31.2657 19.8401 31.2753 20.4897 31.0566C20.9945 30.8867 21.2997 30.7242 23.5259 29.4399C24.2287 29.0344 25.6451 28.226 26.6734 27.6434C28.851 26.4096 29.4502 25.9123 29.7952 25.0521L30 24.5414V19.6048V14.6682L29.7972 14.1654C29.4489 13.302 28.8389 12.8006 26.5815 11.5224C25.51 10.9156 23.8139 9.94787 22.8124 9.37183C21.811 8.7957 20.76 8.24842 20.4771 8.15565C19.8511 7.95027 19.16 7.94805 18.5686 8.14952Z"
      fill="white"
    />
    <path
      d="M18.2331 21.9053C15.5905 21.9053 13.3335 22.3135 13.3335 23.945C13.3335 25.5779 15.5762 26.0001 18.2331 26.0001C20.8756 26.0001 23.1326 25.5919 23.1326 23.9604C23.1326 22.3275 20.8899 21.9053 18.2331 21.9053"
      fill="#868686"
    />
    <path
      opacity="0.4"
      d="M18.2332 20.3499C20.033 20.3499 21.4752 18.9374 21.4752 17.1749C21.4752 15.4124 20.033 14 18.2332 14C16.4341 14 14.9912 15.4124 14.9912 17.1749C14.9912 18.9374 16.4341 20.3499 18.2332 20.3499"
      fill="#868686"
    />
    <path
      opacity="0.4"
      d="M22.7825 17.2325C22.7825 18.13 22.5072 18.9675 22.0245 19.6632C21.9743 19.7347 22.0187 19.8312 22.1061 19.8465C22.2274 19.8663 22.352 19.8785 22.4792 19.881C23.7447 19.9136 24.8804 19.1157 25.1941 17.9141C25.6592 16.1312 24.2946 14.5303 22.5562 14.5303C22.3676 14.5303 22.1869 14.5494 22.0108 14.5846C21.9867 14.5897 21.9606 14.6012 21.9475 14.6216C21.9306 14.6478 21.943 14.6817 21.9599 14.704C22.4824 15.4214 22.7825 16.2947 22.7825 17.2325"
      fill="#868686"
    />
    <path
      d="M26.5198 22.1125C26.2882 21.6289 25.7292 21.2974 24.8786 21.1345C24.4774 21.0386 23.3906 20.9026 22.3802 20.9217C22.3652 20.9237 22.3567 20.9339 22.3554 20.9403C22.3534 20.9505 22.358 20.9658 22.3776 20.9767C22.8446 21.2028 24.6496 22.1866 24.4226 24.2615C24.4128 24.3522 24.4865 24.4289 24.5785 24.4161C25.0227 24.3541 26.1656 24.1133 26.5198 23.3646C26.7161 22.9685 26.7161 22.5086 26.5198 22.1125"
      fill="#868686"
    />
  </svg>
</template>

<script>
export default {
  name: 'PhotoProfileGroupChatGray'
}
</script>
