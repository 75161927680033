<template>
  <div class="columns mt-5 mt-md-0 is-variable is-2 w-100 mx-sm-0">
    <div class="wrap-left column is-9 mb-4 mb-sm-0 pb-sm-0">

      <!-- Start Deskripsi Proyek & Informasi Client -->
      <div class="columns is-variable is-2 mb-1">

        <!-- CLIENT FOR MOBILE ONLY -->
        <div class="client-wrapper is-hidden-desktop is-hidden-tablet px-4 py-3 mb-4">
          <p>Client : </p>
          <div class="wrap-logo">
            <img :src="dataSurvey.vendor.logo" :alt="dataSurvey.vendor.name">
          </div>
        </div>
        <!-- CLIENT FOR MOBILE ONLY -->

        <!-- Deskripsi Proyek -->
        <div class="column px-md-0 pt-sm-0 pb-sm-1">
          <div class="card-deskripsi-proyek p-4">
            <p class="averta-bold label-20 mb-5">Deskripsi Proyek</p>
            <div class="mb-5">
              <p class="sub-title">Pesanan</p>
              <p class="averta-bold">{{ dataSurvey.project.type }}</p>
            </div>

            <div class="mb-5">
              <p class="sub-title">Pesanan Dibuat</p>
              <p class="averta-bold">{{ dataSurvey.project.client_name }}</p>
            </div>
            <div>
              <p class="sub-title">Deskripsi Proyek</p>
              <p class="averta-bold white-space-preline">
                {{ dataSurvey.project.description || '-' }}
              </p>
            </div>
          </div>
        </div>
        <!-- Deskripsi Proyek -->

        <!-- Informasi Client -->
        <div class="column px-md-0 pt-sm-1">
          <CardClientInformation :client="dataSurvey.client" />
        </div>
        <!-- Informasi Client -->
      </div>
      <!-- End Deskripsi Proyek & Informasi Client -->

      <!-- Foto Masalah -->
      <div v-if="dataSurvey.survey_images.length > 0" class="card-deskripsi-proyek px-4 pt-4 mb-4 card-sm-fix-margin">
        <p class="averta-bold label-20 mb-4">Foto Masalah</p>
        <div class="is-flex img-problem-wrapper">
          <div class="img-problem cursor-pointer" v-for="(photo, indexPhoto) in dataSurvey.survey_images" :key="`${indexPhoto}-index-photo`">
            <img :src="photo" alt="foto masalah" @click="modalPreviewImage(dataSurvey.survey_images)">
          </div>
        </div>
      </div>
      <!-- End Foto Masalah -->

      <!-- Start Detail Survey -->
      <div class="card-deskripsi-proyek p-4 mb-4 card-sm-fix-margin mb-sm-1">
        <p class="averta-bold label-20 mb-4">Detail Survey</p>
        <div class="wrapper-tanggal-survey is-flex is-justify-content-space-between mb-3" v-if="!isMobile">
          <div class="is-flex is-align-items-center is-justify-content-space-between flex-sm-column align-items-sm-start">
            <div class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-flex-start">
              <CalendarIcon class="mr-3" />
              <div class="is-flex is-flex-direction-column is-justify-content-flex-start is-align-items-flex-start m-0 gap-2">
                <div class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center gap-3 m-0">
                  <div class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start gap-1">
                    <p class="filename-text m-0 p-0" style="font-size: 12px;">Tanggal & Waktu Survey</p>
                    <div class="is-flex is-align-items-center m-0" v-if="dataSurvey.survey_schedule || dataSurvey.detail">
                      <div class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-flex-start">
                        <p class="averta-bold p-0">{{ convertDate(dataSurvey.survey_schedule ? dataSurvey.survey_schedule.start_date : dataSurvey.detail.date) }}</p>
                        <p class="color-gray ml-1 p-0"
                          v-if="dataSurvey.start_time || (dataSurvey.survey_schedule && dataSurvey.survey_schedule.start_time)"
                        >
                          {{ convertTime(dataSurvey.start_time || (dataSurvey.survey_schedule && dataSurvey.survey_schedule.start_time)) }}
                        </p>
                      </div>
                    </div>
                    <div v-else>
                      <p class="averta-bold">-</p>
                    </div>
                  </div>
                  <div class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center" v-if="dataSurvey.status === 'Survey Dibatalkan'">
                    <code>
                      <p class="averta-bold label-12 color-white">Survey Dibatalkan</p>
                    </code>
                  </div>
                </div>
                <p class="averta-bold-italic color-red" v-if="dataSurvey.status === 'Survey Dibatalkan'"> Dibatalkan oleh
                  {{dataSurvey.detail.cancelled_by}} {{moment(dataSurvey.detail.cancelled_at).format('DD MMMM YYYY - HH:mm:ss')}}
                </p>
              </div>
            </div>
            <div class="wrapper-skill">
              <div class="is-flex is-align-items-center averta-bold is-justify-content-end">
                <img v-if="dataSurvey.detail.skill_image" :src="dataSurvey.detail.skill_image" alt="skill_image" class="skill-image mr-3">
                <p>{{ dataSurvey.detail.skill_name ? dataSurvey.detail.skill_name : '-' }}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Mobile Section -->
         <div v-if="isMobile" class="is-flex is-flex-direction-column is-justify-content-flex-start is-align-items-flex-start gap-5" >
          <div class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center">
            <CalendarIcon class="mr-3" size="27" />
            <p class="averta-bold p-0">{{ convertDate(dataSurvey.survey_schedule ? dataSurvey.survey_schedule.start_date : dataSurvey.detail.date) }}</p>
            <p class="color-gray ml-1 p-0">{{ convertTime(dataSurvey.survey_schedule ? dataSurvey.survey_schedule.start_time : dataSurvey.detail.date) }}</p>
          </div>
          <div class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center">
            <img v-if="dataSurvey.detail.skill_image" :src="dataSurvey.detail.skill_image" alt="skill_image" class="skill-image mr-3">
            <p class="averta-bold label-16">{{ dataSurvey.detail.skill_name ? dataSurvey.detail.skill_name : '-' }}</p>
          </div>
         </div>
        <!-- Mobile Section -->

        <div class="wrapper-tukang" v-if="dataSurvey.status !== 'Survey Dibatalkan' && !isMobile">
          <div class="is-flex is-justify-content-space-between is-align-items-center wrapper-detail-tukang flex-sm-column align-items-sm-start c-w-100">
            <div class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100">
              <div class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center">
                <div v-if="dataSurvey.detail.worker_image && dataSurvey.detail.worker_image !== '-'" class="mr-3 img-photo-tukang">
                  <img :src="dataSurvey.detail.worker_image" alt="worker_image" class="w-100">
                </div>
                <div>
                  <p class="averta-bold" :class="{ 'averta-bold-italic': dataSurvey.detail.worker_name === '-' }">
                  {{ dataSurvey.detail.worker_name &&
                     dataSurvey.detail.worker_name !==
                     '-' ?
                     dataSurvey.detail.worker_name :
                     'Belum diassign' }}
                  </p>
                  <p v-if="dataSurvey.detail.worker_id">{{ dataSurvey.detail.worker_id ? 'ID' + dataSurvey.detail.worker_id : '-'}}</p>
                </div>
              </div>
              <div class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center gap-7">
                <div v-if="dataSurvey.detail.worker_id" class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center">
                  <p class="averta-black">Absensi :</p>
                </div>
                <div v-if="dataSurvey.detail.worker_id" class="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center gap-4">
                  <div class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-end">
                    <p class="averta-bold color-red" :class="{ 'averta-bold-italic': dataSurvey.detail.worker_name === '-' }">
                      Hadir
                    </p>
                    <p v-if="dataSurvey.detail.worker_id"
                    :class="{'averta-bold': isValidDate(dataSurvey.detail.attendance_start_date), 'color-black averta-regular-italic': !isValidDate(dataSurvey.detail.attendance_start_date)}">
                      {{ dataSurvey.detail.attendance_start_date ?
                        absen(dataSurvey.detail.attendance_start_date) :
                        'Belum Absen' }}
                    </p>
                    <p :style="{ display: dataSurvey.detail.attendance_start_date ? 'flex' : 'none'}" class="averta-regular color-black">
                      {{ moment(dataSurvey.detail.attendance_start_date).format('dddd, DD MMM YYYY') }}
                    </p>
                  </div>
                  <div class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-end">
                    <div v-if="dataSurvey.detail.worker_id" class="card-photo-before-after" :class="{ 'cursor-pointer': showImageBefore.filename !== '-' }">
                      <EmptyImageIcon v-if="showImageBefore.filename === '-'"  size="100%" />
                      <img
                        v-else
                        :src="showImageBefore.filename"
                        alt="worker_image"
                        @click="modalPreviewImage(dataSurvey.detail.images.filter(image => image.name === 'Absensi Hadir'), 'photoSurvey')"
                      />
                    </div>
                  </div>
                </div>
                <div v-if="dataSurvey.detail.worker_id" class="divider-vertical-line-1" style="min-height: 35px;"></div>
                <div v-if="dataSurvey.detail.worker_id" class="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center gap-4">
                  <div class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-end">
                    <p class="averta-bold color-red" :class="{ 'averta-bold-italic': dataSurvey.detail.worker_name === '-' }">
                      Pulang
                    </p>
                    <p v-if="dataSurvey.detail.worker_id"
                    :class="{'averta-bold': isValidDate(dataSurvey.detail.attendance_end_date), 'color-black averta-regular-italic': !isValidDate(dataSurvey.detail.attendance_end_date)}">
                    {{ dataSurvey.detail.attendance_end_date ?
                      absen(dataSurvey.detail.attendance_end_date) :
                      'Belum Absen'
                    }}
                    </p>
                    <p :style="{ display: dataSurvey.detail.attendance_end_date ? 'flex' : 'none'}" class="averta-regular color-black">
                      {{ moment(dataSurvey.detail.attendance_end_date).format('dddd, DD MMM YYYY') }}
                    </p>
                  </div>
                  <div v-if="dataSurvey.detail.worker_id" class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-end">
                    <div class="card-photo-before-after" :class="{ 'cursor-pointer': showImageAfter.filename !== '-' }">
                      <EmptyImageIcon v-if="showImageAfter.filename === '-'"  size="100%" />
                      <img
                        v-else
                        :src="showImageAfter.filename"
                        alt="worker_image"
                        @click="modalPreviewImage(dataSurvey.detail.images.filter(image => image.name === 'Absensi Pulang'), 'photoSurvey')"
                      />
                    </div>
                  </div>
                </div>
                <div class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center"
                v-if="!dataSurvey.status === 'Penawaran Disetujui' && !isMobile ||
                (isSM && (dataSurvey.status === 'Survey Berlangsung' || dataSurvey.status === 'Survey Diproses') && dataSurvey.detail.worker_id) ||
                (isBS && (dataSurvey.status === 'Survey Berlangsung' || dataSurvey.status === 'Survey Diproses') && dataSurvey.detail.worker_id)">
                <!-- Button Bullets Option -->
                <div class="bullets-option" @click="handleOpenDropdownMenu(dataSurvey.detail.worker_id)">
                  <OptionThreeBulletsIcon />
                </div>
                <!-- Button Bullets Option -->
                </div>
              </div>
            </div>
            <div class="is-flex wrapper-absen"
            v-if="dataSurvey.detail.worker_name === '-' ||
            (dataSurvey.detail.worker_name === '-' && dataSurvey.status === 'Survey Berlangsung') ||
            (dataSurvey.detail.worker_name === '-' && dataSurvey.status === 'Survey Diproses')">
              <ButtonApp @click="handleOpenModalAssignConsultant" :isSecondary="true" height="35px">
                <p class="averta-bold">Assign Konsultan</p>
              </ButtonApp>
              <!-- <p>Absen Masuk : <span style="padding-right: 2px" :class="{'averta-bold': isValidDate(dataSurvey.detail.attendance_start_date), 'color-red is-italic': !isValidDate(dataSurvey.detail.attendance_start_date)}">{{ absen(dataSurvey.detail.attendance_start_date)}}</span></p>
              <p class="mx-3 is-hidden-mobile">|</p>
              <p>Absen Keluar : <span style="padding-right: 2px" :class="{'averta-bold': isValidDate(dataSurvey.detail.attendance_end_date), 'color-red is-italic': !isValidDate(dataSurvey.detail.attendance_end_date)}">{{ dataSurvey.detail.attendance_end_date ? absen(dataSurvey.detail.attendance_end_date) : 'Belum Absen' }}</span>
              </p> -->
            </div>
          </div>
          <div class="divider-dashed-line-1" v-if="dataSurvey.detail.worker_id"></div>
          <div class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100" v-if="dataSurvey.detail.worker_id">
            <div class="is-flex is-flex-direction-column is-justify-content-flex-start is-align-items-flex-start c-w-100">
              <p class="averta-bold">Catatan dari konsultan</p>
              <p class="white-space-preline">{{ dataSurvey.detail.note !== 'null' && dataSurvey.detail.note ? dataSurvey.detail.note : '-' }}</p>
              <p class="white-space-preline mt-2 averta-regular-italic"
               :style="{display: dataSurvey.detail.note_updated_at === null ? 'none' : 'flex'}">Diperbaharui oleh:
                {{ dataSurvey.detail.note_updated_by }}
                ({{ moment(dataSurvey.detail.note_updated_at).format('dddd, DD MMMM YYYY - HH:mm') }})
              </p>
            </div>
            <div class="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-flex-start c-w-100"
            v-if="(isSM && (dataSurvey.status === 'Survey Berlangsung' || dataSurvey.status === 'Survey Diproses' ||
                  dataSurvey.status === 'Proses Business Support' || dataSurvey.status === 'Survey Selesai' ||
                  dataSurvey.status === 'Proses Site Manager' || dataSurvey.status === 'Proses Purchasing') &&
                  dataSurvey.detail.worker_id) ||
                  (isBS && (dataSurvey.status === 'Survey Berlangsung' || dataSurvey.status === 'Survey Diproses' ||
                  dataSurvey.status === 'Proses Business Support' || dataSurvey.status === 'Survey Selesai' ||
                  dataSurvey.status === 'Proses Site Manager' || dataSurvey.status === 'Proses Purchasing') &&
                  dataSurvey.detail.worker_id)">
              <p class="averta-bold color-red cursor-pointer" @click="handleOpenModalEditNoteSurvey">
                Ubah Catatan
              </p>
            </div>
          </div>

          <!-- Modals Bullets Option -->
           <div class="modal-bullets-option" v-if="isHandleOpenDropdownMenu">
            <div class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center p-5 c-w-100 border-radius-15 background-dropdown-hovered"
            @click=" dataSurvey.detail.attendance_start_date ?
            handleOpenModalAlertConsultantSurveyAttendance(dataSurvey.detail.worker_id) :
            handleOpenModalDeleteConsultant(dataSurvey.detail.worker_id)">
              <div class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center gap-3">
                <TrashIcon color1="#e10009" color2="#e10009" />
                <p class="averta-bold color-red label-16">Hapus Konsultan Survey</p>
              </div>
              <ChevronIcon color="#e10009" />
            </div>
           </div>
          <!-- Modals Bullets Option -->
        </div>

        <div v-if="isMobile" class="wrapper-tukang-mobile mt-5">
          <div class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100 p-0">
            <div class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-100 p-0 gap-3">
              <div class="avatar-consultant">
                <img :src="dataSurvey.detail.worker_image" alt="worker_image">
              </div>
              <div class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start">
                <p class="averta-bold" :class="{ 'averta-bold-italic': dataSurvey.detail.worker_name === '-' }">
                  {{ dataSurvey.detail.worker_name &&
                     dataSurvey.detail.worker_name !==
                     '-' ?
                     dataSurvey.detail.worker_name :
                     'Belum diassign' }}
                  </p>
                  <p v-if="dataSurvey.detail.worker_id">{{ dataSurvey.detail.worker_id ? '# ID' + dataSurvey.detail.worker_id : '-'}}</p>
              </div>
            </div>
            <!-- Button Bullets Option -->
            <div class="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center c-w-100"
            v-if="!dataSurvey.status === 'Penawaran Disetujui' &&
            (isSM && (dataSurvey.status === 'Survey Berlangsung' || dataSurvey.status === 'Survey Diproses') && dataSurvey.detail.worker_id) ||
            (isBS && (dataSurvey.status === 'Survey Berlangsung' || dataSurvey.status === 'Survey Diproses') && dataSurvey.detail.worker_id)">
              <div class="bullets-option" @click="handleOpenDropdownMenu(dataSurvey.detail.worker_id)">
                <OptionThreeBulletsIcon />
              </div>
            </div>
            <!-- Button Bullets Option -->
          </div>
          <div class="is-flex is-flex-direction-column is-justify-content-flex-start is-align-items-center gap-3 c-w-100">
            <div class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-100">
              <p class="averta-bold">Absensi :</p>
            </div>
            <div class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100">
              <div class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start gap-1">
                <div class="is-flex is-flex-direction-column is-justify-content-flex-start is-align-items-flex-start" style="max-width: 100px !important;">
                  <p class="averta-bold color-red">Hadir</p>
                  <p>
                    {{ dataSurvey.detail.attendance_start_date ?
                    absen(dataSurvey.detail.attendance_start_date) :
                    'Belum Absen'
                  }}
                </p>
                <p :style="{ display: dataSurvey.detail.attendance_start_date ? 'flex' : 'none'}" class="averta-regular color-black">
                  {{ moment(dataSurvey.detail.attendance_start_date).format('dddd, DD MMM YYYY') }}
                </p>
                </div>
                <div class="card-photo-before-after">
                  <img
                    v-if="showImageBefore"
                    :src="showImageBefore.filename"
                    alt="worker_image"
                    @click="modalPreviewImage(dataSurvey.detail.images.filter(image => image.name === 'Absensi Hadir'), 'photoSurvey')"
                  />
                  <EmptyImageIcon v-else size="100%" />
                </div>
              </div>
              <div class="dividev-line-1"></div>
              <div class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start gap-1">
                <div class="is-flex is-flex-direction-column is-justify-content-flex-start is-align-items-flex-start" style="max-width: 100px !important;">
                  <p class="averta-bold color-red">Pulang</p>
                  <p>
                    {{ dataSurvey.detail.attendance_end_date ?
                    absen(dataSurvey.detail.attendance_end_date) :
                    'Belum Absen'
                  }}
                </p>
                <p :style="{ display: dataSurvey.detail.attendance_end_date ? 'flex' : 'none'}" class="averta-regular color-black">
                  {{ moment(dataSurvey.detail.attendance_end_date).format('dddd, DD MMM YYYY') }}
                </p>
                </div>
                <div class="card-photo-before-after">
                  <img
                    v-if="showImageAfter"
                    :src="showImageAfter.filename"
                    alt="worker_image"
                    @click="modalPreviewImage(dataSurvey.detail.images.filter(image => image.name === 'Absensi Pulang'), 'photoSurvey')"
                  />
                  <EmptyImageIcon v-else size="100%" />
                </div>
              </div>
            </div>
          </div>
          <div class="divider-dashed-line-1" v-if="dataSurvey.detail.worker_id"></div>
          <div class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start" v-if="dataSurvey.detail.worker_id">
            <p class="averta-bold">Catatan dari konsultan</p>
            <p class="white-space-preline">{{ dataSurvey.detail.note !== 'null' && dataSurvey.detail.note ? dataSurvey.detail.note : '-' }}</p>
            <div class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center mt-4">
              <p class="white-space-preline mt-2 averta-regular-italic"
               :style="{display: dataSurvey.detail.note_updated_at === null ? 'none' : 'flex'}">Diperbaharui oleh:
                {{ dataSurvey.detail.note_updated_by }}
                ({{ moment(dataSurvey.detail.note_updated_at).format('dddd, DD MMMM YYYY - HH:mm') }})
              </p>
            </div>
            <div class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center mt-4" @click="handleOpenModalEditNoteSurvey"
            v-if="(isSM && (dataSurvey.status === 'Survey Berlangsung' || dataSurvey.status === 'Survey Diproses' ||
                  dataSurvey.status === 'Proses Business Support' || dataSurvey.status === 'Survey Selesai' ||
                  dataSurvey.status === 'Proses Site Manager' || dataSurvey.status === 'Proses Purchasing') &&
                  dataSurvey.detail.worker_id) ||
                  (isBS && (dataSurvey.status === 'Survey Berlangsung' || dataSurvey.status === 'Survey Diproses' ||
                  dataSurvey.status === 'Proses Business Support' || dataSurvey.status === 'Survey Selesai' ||
                  dataSurvey.status === 'Proses Site Manager' || dataSurvey.status === 'Proses Purchasing') &&
                  dataSurvey.detail.worker_id)">
              <p class="averta-bold color-red">Ubah Catatan</p>
            </div>

          </div>

          <!-- Modals Bullets Option -->
           <div class="modal-bullets-option" v-if="isHandleOpenDropdownMenu">
            <div class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center p-5 c-w-100"
            @click=" dataSurvey.detail.attendance_start_date ?
            handleOpenModalAlertConsultantSurveyAttendance(dataSurvey.detail.worker_id) :
            handleOpenModalDeleteConsultant(dataSurvey.detail.worker_id)">
              <div class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center gap-3">
                <TrashIcon color1="#252525" color2="#252525" />
                <p class="averta-regular">Hapus Konsultan Survey</p>
              </div>
              <ChevronIcon />
            </div>
           </div>
          <!-- Modals Bullets Option -->
        </div>

        <p v-if="dataSurvey.detail.images.length > 0" class="averta-bold mb-3 mt-4">Foto Kehadiran Survey</p>
        <div v-if="dataSurvey.detail.images.length > 0" class="wrapper-file is-flex is-align-items-flex-start is-justify-content-flex-start gap-10">
          <div v-for="(dataImage, indexImage) in dataSurvey.detail.images.filter(image => image.name !== 'Absensi Hadir' && image.name !== 'Absensi Pulang')"
          :key="dataImage.id" style="min-width: auto" class="wrap-item-image">
            <div v-if="dataImage.filename === '-'" class="image-survey mb-2"
            :class="
            dataSurvey.detail.images.length === 6 ? 'image-survey' :
            dataSurvey.detail.images.length === 7 ? 'image-survey-5' : 'image-survey-6'">
              <EmptyImageIcon size="100%" />
            </div>
            <img v-else :src="dataImage.filename"
            alt="foto_survey"
            class="mb-2 is-clickable image-survey"
            :class="
            dataSurvey.detail.images.length === 6 ? 'image-survey' :
            dataSurvey.detail.images.length === 7 ? 'image-survey-5' : 'image-survey-6'"
            @click="
            modalPreviewImage(dataSurvey.detail.images.filter(image => image.name !== 'Absensi Hadir' && image.name !== 'Absensi Pulang' && image.filename !== '-'),
            'photoSurvey', indexImage)">
            <b-tooltip :position="indexImage > 4 ? indexImage + 1 >= dataSurvey.detail.images.length - 3 ? 'is-left' : 'is-right' : 'is-right'" type="is-dark" :triggers="['hover']" :auto-close="true"
              append-to-body>
              <template v-slot:content>
                <div class="word-wrap-break">
                  <p class="white-space-nowrap">{{ dataImage.name }}</p>
                </div>
              </template>
              <div class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
                <p class="averta-bold image-name m-0 p-0"
                :class="
                dataSurvey.detail.images.length === 6 ? 'image-name' :
                dataSurvey.detail.images.length === 7 ? 'image-name image-name-5' : 'image-name image-name-6'"
                >
                  {{ dataImage.name }}
                </p>
                <p class="averta-bold-italic color-red image-name label-12 m-0 p-0" v-if="dataImage.filename === '-'"
                :class="
                dataSurvey.detail.images.length === 6 ? 'image-name' :
                dataSurvey.detail.images.length === 7 ? 'image-name image-name-5' : 'image-name image-name-6'">
                  Foto Belum Diupload
                </p>
                <div v-else>
                  <p class="averta-bold color-red cursor-pointer label-12 m-0 p-0"
                  :style="{display: dataImage.filename === '-' ? 'none' : 'flex' }"
                  @click="handleOpenModalChangeImage(dataImage.filename, dataImage)"
                  v-if="(isSM && (dataSurvey.status === 'Survey Berlangsung' || dataSurvey.status === 'Survey Diproses' ||
                  dataSurvey.status === 'Proses Business Support' || dataSurvey.status === 'Survey Selesai' ||
                  dataSurvey.status === 'Proses Site Manager' || dataSurvey.status === 'Proses Purchasing') &&
                  dataSurvey.detail.worker_id) ||
                  (isBS && (dataSurvey.status === 'Survey Berlangsung' || dataSurvey.status === 'Survey Diproses' ||
                  dataSurvey.status === 'Proses Business Support' || dataSurvey.status === 'Survey Selesai' ||
                  dataSurvey.status === 'Proses Site Manager' || dataSurvey.status === 'Proses Purchasing') &&
                  dataSurvey.detail.worker_id)">
                  Ganti Foto
                </p>
                </div>
              </div>
            </b-tooltip>
          </div>
        </div>
      </div>
      <!-- End Detail Survey -->

      <!-- Start Riwayat Pemeriksaan -->
      <div class="card-deskripsi-proyek p-3 card-sm-fix-margin" v-if="dataSurvey.checking_history.length > 0">
        <p class="averta-bold label-20 mb-4">Riwayat Pemeriksaan</p>

        <div class="is-flex wrapper-button">
          <b-button class="mr-3 btn-tabs mb-4" v-for="checkHistory in dataSurvey.checking_history"
            :key="checkHistory.areaName" :class="{ 'is-active averta-bold': areaName === checkHistory.area_name }"
            @click="changeIsActive(checkHistory.area_name)">{{ checkHistory.area_name }}</b-button>
        </div>

        <div>
          <div v-for="dataArea in dataSurvey.checking_history" :key="dataArea.areaName">
            <b-tabs v-if="areaName === dataArea.area_name">
              <b-tab-item v-for="(dataComponents, indexComponent) in dataArea.components" :label="dataComponents.component_name"
                :key="`component-${indexComponent}`">
                <p class="averta-bold mb-3">Daftar Komponen Yang Membutuhkan Perbaikan</p>
                <div v-for="(dataDetailComponent, indexDetailComp) in dataComponents.detail_components"
                  :key="`detail-comp-${indexDetailComp}`">
                  <div class="columns">
                    <div class="column">
                      <p class="has-text-grey-light">Nama Detail Komponen</p>
                      <p class="averta-bold">{{ dataDetailComponent.detail_component }}</p>
                    </div>
                    <div class="column">
                      <p class="has-text-grey-light">Catatan</p>
                      <p class="averta-bold">{{ dataDetailComponent.note || '-' }}</p>
                    </div>
                  </div>
                </div>
              </b-tab-item>
            </b-tabs>
          </div>
        </div>
      </div>
      <!-- End Riwayat Pemeriksaan -->
    </div>

    <div class="wrap-right column card-sm-fix-margin">
      <!-- Berkas Lampiran -->
      <BerkasLampiran
        :dataProps="dataSurvey.sik_spk"
        :idSurvey="dataSurvey.id"
        :isSMRC="isSMRC"
        @refreshData="loadDataSurvey"
      />
      <!-- Berkas Lampiran -->

      <!-- Status Proyek -->
      <StatusProyekComponent :dataSurvey="dataSurvey" title="Status Proyek" />
      <!-- End Status Proyek -->

      <!-- Status Proyek -->
      <StatusProyekComponent :dataSurvey="dataSurvey" title="Penawaran Dalam Proses Internal" :isConfirmOffering="true" />
      <!-- End Status Proyek -->
    </div>

    <!-- Modal Preview Image -->
    <ModalPreviewImage :indexImg="indexImg" :isActive="modalPreviewImageActive" :dataImage="imagePreviewCarousel" :title="titlePreviewCarousel" @closeModal="closeModalPreviewImage()" />
    <!-- Modal Preview Image -->

    <!-- Modal Select Consultant -->
     <ModalAssignConsultant
     :dataSurvey="dataSurvey"
     :consultants="consultants"
     :isHandleOpenModalAssignConsultant="isHandleOpenModalAssignConsultant"
     @closeModal="isHandleOpenModalAssignConsultant = false" />
    <!-- Modal Select Consultant -->

    <ModalsConfirmDeleteConsultant
      :isHandleOpenModalConfirmDeleteConsultant="isHandleOpenModalConfirmDeleteConsultant"
      @closeModalConfirmDeleteConsultant="closeModalConfirmDeleteConsultant"
      @submitDeleteConsultant="submitDeleteConsultant"
    />

    <ModalsAlertConsultantSurveyAttendance
      :isHandleOpenModalAlertConsultantSurveyAttendance="isHandleOpenModalAlertConsultantSurveyAttendance"
      @closeModalAlertConsultantSurveyAttendance="closeModalAlertConsultantSurveyAttendance"
    />

    <ModalChangePhotoSurvey
      ref="ModalChangePhotoSurvey"
      :isHandleOpenModalChangePhotos="isHandleOpenModalChangePhotos"
      :selectImage="selectImage"
      :dataSurvey="dataSurvey"
      @closeModalChangePhotosSurvey="closeModalChangePhotosSurvey"
      :selectImageEdit="selectImageEdit"
      :selectImageEditToPost="selectImageEditToPost"
    />

    <ModalEditNoteSurvey
      :isHandleOpenModalsEditNoteSurvey="isHandleOpenModalsEditNoteSurvey"
      :isHandleOpenModalsEditNoteSurveyMobile="isHandleOpenModalsEditNoteSurveyMobile"
      :dataSurvey="dataSurvey"
      @closeModalEditNoteSurvey="closeModalEditNoteSurvey"
    />

   <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="false">
    <SpinnerIcon />
  </b-loading>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { CalendarIcon, OptionThreeBulletsIcon, TrashIcon, ChevronIcon, EmptyImageIcon, SpinnerIcon } from '@/components/icons'
import { formatCurrency, isSMRC, alertErrorMessage, getStatusClassName } from '@/helpers'

import ModalAssignConsultant from '../modal/ModalAssignConsultant.vue'
import ButtonApp from '@/components/button/ButtonApp'
import moment from 'moment'
import ModalPreviewImage from '@/components/modal/ModalPreviewImage'
import StatusProyekComponent from './detail/StatusProyekComponent'
import BerkasLampiran from './detail/BerkasLampiran'
import ModalsConfirmDeleteConsultant from '@/components/modal/ModalsConfirmDeleteConsultant'
import ModalsAlertConsultantSurveyAttendance from '@/components/modal/ModalsAlertConsultantSurveyAttendance'
import ModalChangePhotoSurvey from '@/components/modal/ModalChangePhotoSurvey'
import ModalEditNoteSurvey from '@/components/modal/ModalEditNoteSurvey'
import CardClientInformation from '@/components/card/CardClientInformation'

export default {
  name: 'TabDetailPenawaran',
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      dataSurvey: 'project/getDataDetailSurvey',
      user: 'auth/user'
    }),
    isSMRC () {
      return isSMRC(this.dataSurvey.vendor.code || '')
    },
    isMobile () {
      if (window.innerWidth <= 768) {
        return true
      }
      return false
    },
    showImageBefore () {
      return this.dataSurvey.detail.images.find(
        (image) => image.name === 'Absensi Hadir'
      )
    },
    showImageAfter () {
      return this.dataSurvey.detail.images.find(
        (image) => image.name === 'Absensi Pulang'
      )
    },
    isSM () {
      if (this.user.roles[0].name === 'Site Manager') {
        return true
      } return false
    },
    isBS () {
      if (this.user.roles[0].name === 'Account Manager' || this.user.roles[0].name === 'Business Support') {
        return true
      }
      return false
    }
  },
  props: ['role', 'status', 'loadDataSurvey'],
  components: {
    CalendarIcon,
    ModalPreviewImage,
    StatusProyekComponent,
    BerkasLampiran,
    ButtonApp,
    ModalAssignConsultant,
    OptionThreeBulletsIcon,
    TrashIcon,
    ChevronIcon,
    ModalsConfirmDeleteConsultant,
    ModalsAlertConsultantSurveyAttendance,
    ModalChangePhotoSurvey,
    EmptyImageIcon,
    SpinnerIcon,
    ModalEditNoteSurvey,
    CardClientInformation
  },
  data () {
    return {
      areaName: null,
      modalPreviewImageActive: false,
      imagePreviewCarousel: null,
      titlePreviewCarousel: '',
      dataProjectNote: null,
      dataPersonalNote: null,
      indexImg: 0,
      isHandleOpenModalAssignConsultant: false,
      consultants: [],
      isHandleOpenDropdownMenu: false,
      selectConsultant: null,
      isHandleOpenModalConfirmDeleteConsultant: false,
      isHandleOpenModalAlertConsultantSurveyAttendance: false,
      isHandleOpenModalChangePhotos: false,
      selectImage: {},
      moment: moment,
      isLoading: false,
      isFullPage: true,
      isHandleOpenModalsEditNoteSurvey: false,
      isHandleOpenModalsEditNoteSurveyMobile: false,
      selectImageEdit: '',
      selectImageEditToPost: ''
    }
  },
  methods: {
    modalPreviewImage (data, type, index) {
      this.indexImg = index || 0
      if (type === 'photoSurvey') {
        this.imagePreviewCarousel = data.map((item) => {
          return item.filename
        })
        this.titlePreviewCarousel = 'Foto Survey Konsultan'
      } else {
        this.imagePreviewCarousel = data
        this.titlePreviewCarousel = 'Foto Masalah'
      }
      this.modalPreviewImageActive = true
      console.log(data)
    },
    closeModalPreviewImage () {
      this.modalPreviewImageActive = false
      this.imagePreviewCarousel = null
    },
    formatThousand (x) {
      return formatCurrency(x)
    },
    convertDate (date, dateSurvey) {
      if (date) {
        return moment.utc(date).format('dddd, DD MMMM YYYY')
      } else if (dateSurvey) {
        return moment.utc(dateSurvey).format('dddd, DD MMMM YYYY')
      }
    },
    convertTime (startTime, endTime) {
      let temp = ''
      if (startTime) {
        temp = `${moment(startTime, 'HH:mm:ss').format('HH:mm')}`
      }
      if (endTime) {
        temp += ` - ${moment(endTime, 'HH:mm:ss').format('HH:mm')}`
      }
      return temp ? `(${temp})` : ''
    },
    isValidDate (date) {
      if (moment(date).isValid() && date) {
        return true
      } return false
    },
    absen (date) {
      const varDate = moment(date)
      if (varDate.isValid() && date) {
        return moment.utc(date).format('HH:mm')
      }
      return 'Belum Absen'
    },
    changeIsActive (data) {
      this.areaName = data
    },
    extractFileName (fileName) {
      if (!fileName) {
        return '-'
      }

      const parts = fileName.split('.')
      parts.pop()
      return parts.join('.')
    },
    handleOpenModalAssignConsultant () {
      this.isHandleOpenModalAssignConsultant = true
    },
    getListConsultant () {
      this.isLoading = true
      this.$store
        .dispatch('projectV2/getListConsultant', { url: this.url })
        .then((response) => {
          this.consultants = response.data.data
        })
        .catch((error) => {
          alertErrorMessage(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async submitDeleteConsultant () {
      this.isLoading = true

      try {
        const payload = {
          survey_id: this.dataSurvey.id,
          homecare_project_id: this.dataSurvey.survey_homecare_id,
          worker_id: this.dataSurvey.detail.worker_id
        }

        const res = await this.$store.dispatch('projectV2/postDelectConsultant', {
          url: this.url,
          payload
        })

        if (res?.data?.code === 1) {
          await this.$swal({
            icon: 'success',
            title: 'Success',
            text: res.data.message
          })

          this.$buefy.toast.open({
            message: 'Konsultan Berhasil Dihapus'
          })

          window.location.reload()
        } else {
          this.$swal({
            icon: 'error',
            title: 'Error',
            text: res?.data?.message || 'An error occurred while removing the consultant.'
          })
        }
      } catch (error) {
        console.error('Error submitting Survey Berhasil Dibatalkan:', error.response?.data || error.message)

        this.$swal({
          icon: 'error',
          title: 'Error',
          text: error.response?.data?.message || 'An unexpected error occurred. Please try again.'
        })
      } finally {
        this.isLoading = false
      }
    },
    handleOpenDropdownMenu (workerId) {
      this.selectConsultant = workerId
      this.isHandleOpenDropdownMenu = !this.isHandleOpenDropdownMenu
    },
    handleOpenModalDeleteConsultant () {
      this.isHandleOpenModalConfirmDeleteConsultant = true
      this.isHandleOpenDropdownMenu = false
    },
    closeModalConfirmDeleteConsultant () {
      this.isHandleOpenModalConfirmDeleteConsultant = false
    },
    handleOpenModalAlertConsultantSurveyAttendance (workerId) {
      this.selectConsultant = workerId
      this.isHandleOpenModalAlertConsultantSurveyAttendance = true
      this.isHandleOpenDropdownMenu = false
    },
    closeModalAlertConsultantSurveyAttendance () {
      this.isHandleOpenModalAlertConsultantSurveyAttendance = false
    },
    getClassName (status) {
      return getStatusClassName(status)
    },
    handleOpenModalChangeImage (id, dataImage) {
      this.isHandleOpenModalChangePhotos = true
      this.selectImage = { id, ...dataImage, fromDetail: true }
      this.triggerFileInput()
    },
    closeModalChangePhotosSurvey () {
      this.isHandleOpenModalChangePhotos = false
    },
    handleOpenModalEditNoteSurvey () {
      if (this.isMobile) {
        this.isHandleOpenModalsEditNoteSurveyMobile = true
        this.isHandleOpenModalsEditNoteSurvey = false
      } else {
        this.isHandleOpenModalsEditNoteSurvey = true
        this.isHandleOpenModalsEditNoteSurveyMobile = false
      }
    },
    closeModalEditNoteSurvey () {
      this.isHandleOpenModalsEditNoteSurvey = false
      this.isHandleOpenModalsEditNoteSurveyMobile = false
    },
    triggerFileInput () {
      setTimeout(() => {
        this.$refs.ModalChangePhotoSurvey.triggerFileInput()
      }, 500)
    }
  },
  created () {
    this.getListConsultant()
    this.areaName = this.dataSurvey.checking_history.length > 0 ? this.dataSurvey.checking_history[0].area_name : null
  }
}
</script>

<style scoped>
.w-100 {
  width: 100%;
}

.w-auto {
  width: auto !important;
}

.sub-title {
  color: #868686;
}

.card-deskripsi-proyek {
  border: 0.7px solid #D8D8D8;
  border-radius: 12px;
  font-size: 14px;
}

.address {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.skill-image {
  width: 30px;
}

.text-absen {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.wrapper-file {
  background: #F6F6F6;
  padding: 12px;
  border-radius: 12px;
  height: auto;
  overflow-x: auto;
}

.wrap-item-image {
  display: flex;
  flex-direction: column;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0 !important;
  height: auto;
  width: auto;
}

.image-survey {
  width: 150px;
  height: 150px;
  object-fit: cover;
  padding: 0;
  border-radius: 8px;
}

.image-survey-5 {
  width: 125px;
  height: 125px;
  object-fit: cover;
  padding: 0;
  border-radius: 8px;
}

.image-survey-6 {
  width: 70px;
  height: 70px;
  object-fit: cover;
  padding: 0;
  border-radius: 8px;
}

.image-name {
  width: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  text-align: center;
  word-wrap: break-word;
}

.image-name-5 {
  width: 125px !important;
}

.image-name-6 {
  width: 80px !important;
}

.filename-text {
  color: #3185FC;
}

.filesize-text {
  color: #9A9A9A;
}

.btn-tabs {
  border-radius: 20px;
  color: #9A9A9A;
}

.btn-tabs.is-active {
  background: rgba(49, 133, 252, 0.1);
  color: #3185FC;
  border: 1px solid #3185FC;
}

.divider {
  border: 2px solid red;
}

.wrapper-tukang {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  background: #F8F8F8;
  padding: 20px;
  margin: 0 auto;
  gap: 20px;
  border-radius: 20px;
}

.img-photo-tukang {
  width: 36px;
  height: 36px;
}
.img-photo-tukang img {
  border-radius: 100px;
  object-fit: cover;
  width: 36px;
  height: 36px;
}

.note-konsultan {
}

.total-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 1rem;
  margin-top: 1rem;
  border-top: 1px solid #f1f2f2;
}

.img-problem-wrapper {
  overflow-x: auto;
  width: 100%;
  padding-bottom: 1em;
}

.img-problem {
  width: 7em;
  height: 7em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  overflow: hidden;
  margin-right: 1em;
  flex: none;
}

.img-problem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wrapper-button {
  overflow-x: auto;
}

.wrapper-tanggal-survey {
  flex-direction: column;
}

.client-wrapper {
  background: #FAFAFA;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% + 30px);
  margin-left: -15px;
  margin-top: -27px;
}

.wrap-logo {
  background: white;
  padding: 10px;
  border-radius: 100px;
  border: 1px solid #F0F0F0;
}

.wrap-logo img {
  height: 20px;
  object-fit: contain;
}

.wrap-left {
  height: 100vh;
  overflow-y: hidden;
  scrollbar-gutter: stable both-edges;
}

.wrap-left:hover {
  overflow-y: auto;
}

.wrap-right {
  height: 100vh;
  overflow-y: hidden;
  scrollbar-gutter: stable both-edges;
}

.wrap-right:hover {
  overflow-y: auto;
}

@media only screen and (min-width: 768px) {
  .mt-md-0 {
    margin-top: 0 !important;
  }
}

@media only screen and (max-width: 768px) {
  .wrapper-tukang {
    display: block !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .wrapper-detail-tukang {
    display: block !important;
  }

  .wrapper-tanggal-survey {
    display: block !important;
  }

  .wrapper-skill div {
    justify-content: start !important;
    margin-top: 16px;
  }

  .wrapper-absen {
    display: block !important;
    margin-top: 16px;
  }

  .wrapper-button {
    overflow-y: auto;
  }

  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .wrap-left {
    height: auto;
    overflow: visible;
  }
  .wrap-right {
    height: auto;
    overflow: visible;
  }
}
.bullets-option {
  display: flex;
  flex-direction: row;
  width: 45px;
  min-width: 45px;
  max-width: 45px;
  height: 45px;
  min-height: 45px;
  max-height: 45px;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  background: transparent;
  border: 0.65px solid #e10009;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}
.modal-bullets-option {
  position: absolute;
  top: 10px;
  right: 75px;
  display: flex;
  flex-direction: column;
  width: 350px;
  height: auto;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 5px;
  background: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #E1E1E1;
  -webkit-box-shadow: 0px 0px 105px -35px rgba(0,0,0,1);
  -moz-box-shadow: 0px 0px 105px -35px rgba(0,0,0,1);
  box-shadow: 0px 0px 105px -35px rgba(0,0,0,1);
}
.background-dropdown-hovered{
  background: #f6f6f6;
}
.background-dropdown-hovered:hover{
  background: #e8e8e8;
}
code {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  justify-content: center;
  align-content: center;
  margin: 0 auto;
  padding: 8px;
  background: #868686;
  border-radius: 20px;
}
.avatar-consultant {
  display: flex;
  flex-direction: row;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-content: center;
  margin: 0;
  padding: 0;
  background: transparent;
  border-radius: 50%;
}
.wrapper-tukang-mobile {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: flex-start;
  background: #F8F8F8;
  padding: 20px;
  margin: 0 auto;
  gap: 20px;
  border-radius: 20px;
}
.card-photo-before-after{
  display: flex;
  flex-direction: column;
  width: 55px;
  min-width: 55px;
  max-width: 55px;
  height: 55px;
  min-height: 55px;
  max-height: 55px;
  justify-content: center;
  align-items: center;
  background: transparent;
  padding: 0;
  margin: 0;
  gap: 20px;
  border-radius: 10px;
  overflow: hidden !important;
}
@media screen and (max-width: 768px) {
  .bullets-option  {
    width: 35px;
    min-width: 35px;
    max-width: 35px;
    height: 35px;
    min-height: 35px;
    max-height: 35px;
  }
  .modal-bullets-option {
    width: 250px !important;
    right: 65px;
  }
}
</style>
