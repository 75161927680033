var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap"},[_vm._m(0),_c('div',[(_vm.currentTab === 'pic' && (_vm.typeSection !== 'add' && _vm.typeSection !== 'edit'))?_c('div',{staticClass:"is-flex is-justify-content-space-between mb-5"},[_vm._m(1),_c('BranchAutoComplete')],1):(
        _vm.currentTab === 'branch' &&
          (_vm.typeSection !== 'add' && _vm.typeSection !== 'edit')
      )?_c('div',{staticClass:"is-flex is-justify-content-space-between is-align-items-center mb-5"},[_vm._m(2),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.branchName),expression:"branchName"}],staticClass:"input",attrs:{"type":"text","placeholder":"Masukkan nama cabang","disabled":_vm.disabledBranchName},domProps:{"value":(_vm.branchName)},on:{"input":function($event){if($event.target.composing)return;_vm.branchName=$event.target.value}}})]):_vm._e(),_c('div',{staticClass:"wrap-form"},[_vm._l((_vm.dataPic),function(data,index){return _c('div',{key:index},[_c('FormAdmin',{attrs:{"isDelete":index > 0,"dataProps":data,"index":index,"typeSection":_vm.typeSection,"disabledInput":_vm.disabledInput},on:{"updateAdmin":function($event){return _vm.updateAdmin($event)},"deleteAdmin":function($event){return _vm.deleteAdmin($event)}}})],1)}),(_vm.typeSection !== 'edit')?_c('AddPicBranchBanner',{attrs:{"title":"Tambah Admin lainnya di building ini?","desc":"Kamu dapat menambahkan lebih dari 1 Admin dalam satu building","titleButton":"+ Admin"},on:{"addPic":_vm.addPic}}):_vm._e()],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-5"},[_c('p',{staticClass:"averta-black label-20"},[_vm._v("Informasi Admin")]),_c('p',[_vm._v(" Masukkan admin dari brand yang sudah diinput. kamu dapat input lebih dari 1 admin. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"label-14 mr-3 mt-2 white-space-nowrap"},[_c('span',{staticClass:"averta-bold"},[_vm._v("Cabang ")]),_c('span',{staticClass:"text-merah"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"label-14 mr-3 white-space-nowrap"},[_c('span',{staticClass:"averta-bold"},[_vm._v("Nama Cabang ")]),_c('span',{staticClass:"text-merah"},[_vm._v("*")])])
}]

export { render, staticRenderFns }