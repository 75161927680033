<template>
  <ClientSkeleton v-if="isLoading" class="px-6 pb-6 pt-5" />
  <div class="container is-fluid py-5 px-6 min-h-90vh c-w-shell" v-else>
    <div class="is-flex is-justify-content-space-between mb-5">
      <div class="is-flex is-align-items-center is-clickable" @click="goBack()">
        <ArrowLeftIcon class="mr-3" />
        <p class="averta-bold is-size-5">Kembali</p>
      </div>
      <div class="is-flex is-align-items-center">
        <div class="mr-5 input-custom-1">
          <b-input
            style="width: 17em;"
            :placeholder="`Cari nama ${renderTitle}`"
            type="search"
            icon="magnify"
            :value="getSearchValue"
            @input="updateSearchValue"
            @keyup.native.enter="tabActive === 'unit' ? getListBranch({dontLoading: true}) : tabActive === 'unit-price' ? getListUnitPrice({refresh: true}) : filterAreaK()"
          >
          </b-input>
        </div>
        <div class="is-relative">
          <div ref="listBranchAddButton">
            <ButtonApp
              v-if="tabActive === 'unit' || tabActive === 'unit-price'"
              @click="handleClickAddBtn"
              :is-bold="true"
              height="40px"
            >
              <div class="flex-center pr-1">
                <p class="averta-bold">+ Tambah {{renderTitleButton}}</p>
                <div v-if="isUnitPrice" class="ml-2">
                  <img src="@/assets/icons/arrow-down-used.svg" alt="">
                </div>
              </div>
            </ButtonApp>
          </div>
          <ButtonApp
            v-if="isSMRC && tabActive === 'areaCoordinator'"
            @click="handleAddAreaCoordinator"
            :is-bold="true"
            height="40px"
            width="254px"
          >
            + Tambah Area Koordinator
          </ButtonApp>

          <div
            v-if="isUnitPrice && openDropdownUnitPrice"
            class="unit-price-wrapper"
            v-closable="{
              exclude: ['listBranchAddButton'],
              handler: 'handleCloseUnitPrice'
            }"
          >
            <ButtonDropdownItem title="Tambah Daftar Cabang" @click="handleAddBranch">
              <template v-slot:icon>
                <BranchIcon />
              </template>
            </ButtonDropdownItem>
            <div class="divider"></div>
            <ButtonDropdownItem title="Tambah Harga Satuan Item Pekerjaan" @click="handleUnitPrice">
              <template v-slot:icon>
                <img :src="require('@/assets/icons/store-2-red-used.svg')" alt="">
              </template>
            </ButtonDropdownItem>
          </div>
        </div>
      </div>
    </div>
    <BannerClient :client="dataClient" :areasName="areasName" :refreshData="getListBranch" :listBranch="listBranch" />
    <div v-if="tabActive !== 'areaCoordinator'" class="columns">
      <div class="column card col-1 p-3 m-2" v-for="(item, index) in getListDaftarBranchData" :key="index">
      <img :src="require(`@/assets/img/${item.image}`)"  alt="wave" class="wave" />
          <p class="is-size-5 averta-bold">{{ item.total }}</p>
          <p class="is-size-4 averta-black my-2">{{ item.listBranch }}</p>
      </div>
    </div>

    <div class="flex-center-vertical">
      <div class="flex-center-vertical mb-5">
        <div v-if="isSMRC || isUnitPrice" class="btn-tabs-2 mr-3" @click="handleClickTab('unit')" :class="{'is-active': tabActive === 'unit'}">
          <p>Daftar {{renderTitleBranch}}</p>
        </div>
        <div v-if="isSMRC" class="btn-tabs-2 mr-3" @click="handleClickTab('areaCoordinator')" :class="{'is-active': tabActive === 'areaCoordinator'}">
          <p>Daftar Area Koordinator</p>
        </div>
        <div v-if="isUnitPrice" class="btn-tabs-2 mr-3" @click="handleClickTab('unit-price')" :class="{'is-active': tabActive === 'unit-price'}">
          <p>Harga Satuan Item Pekerjaan</p>
        </div>
      </div>
    </div>

    <TabListAreaBranch
      v-if="tabActive === 'unit'"
      :listBranch="listBranch"
      :refreshData="getListBranch"
      :isSMRC="isSMRC"
      @detailBranch="detailBranch($event)"
      @handleEditBranch="handleEditBranch($event)"
    />

    <TabListAreaKoordinator
      v-if="isSMRC && tabActive === 'areaCoordinator'"
      :data="dataAreaKoordinator"
      :refreshData="getListAreaKoordinator"
    />

    <TableUnitPrice
      v-if="!isLoadingUnitPrice && isUnitPrice && tabActive === 'unit-price'"
      :data="dataUnitPrice"
      :initialData="initUnitPrice"
      :refreshData="getListUnitPrice"
    />

    <div v-else-if="!isSMRC" class="column c-w-100 has-text-centered" style="display: none;">
      <ProyekEmptyIcon />
      <p class="averta-bold mt-2">
        Data Tidak Ditemukan
      </p>
    </div>

    <SidebarBranch
      :show="showSidebar"
      :close="handleCloseSidebar"
      @refreshListBranch="getListBranch"
    />
    <SidebarEditBranch
      :show="showSidebarEdit"
      :close="handleCloseSidebarEdit"
      :selectedBranch="selectedBranch"
      @updateBranch="getListBranch"
    />
    <SidebarAddPICBranch
      type="area-koordinator"
      :show="showSidebarCoordinator"
      :close="handleCloseSidebarAreaCoordinator"
      @reloadData="getListAreaKoordinator($event)"
    />

    <b-loading :is-full-page="true" :active="isLoadingAreaC || isLoadingUnitPrice" :can-cancel="false">
      <SpinnerIcon />
    </b-loading>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  AREACOORD,
  BRANCH_SMRC,
  BRANCH,
  AREA_KOORDINATOR_SMRC,
  PIC_STORE
} from '@/helpers/constants'
import { titleBranch, alertErrorMessage, isSMRC } from '@/helpers'

import { ArrowLeftIcon, BranchIcon, ProyekEmptyIcon, SpinnerIcon } from '@/components/icons'
import { ListDaftarBranchData } from '@/data/views/listbranchData'

import ButtonApp from '@/components/button/ButtonApp.vue'
import ButtonDropdownItem from '@/components/button/ButtonDropdownItem'
import BannerClient from './components/BannerClient.vue'
import ClientSkeleton from '@/components/skeleton/ClientSkeleton.vue'

import SidebarBranch from './components/SidebarBranch.vue'
import SidebarAddPICBranch from './components/branch-components/SidebarAddPICBranch.vue'
import SidebarEditBranch from './components/branch-components/SidebarEditBranch.vue'

import TabListAreaBranch from './components/store-components/TabListAreaBranch.vue'
import TabListAreaKoordinator from './components/area-koordinator-components/TabListAreaKoordinator.vue'
import TableUnitPrice from './components/unit-price-components/TableUnitPrice.vue'

export default {
  name: 'ListBranch',
  components: {
    ArrowLeftIcon,
    BranchIcon,
    ProyekEmptyIcon,
    SpinnerIcon,

    ButtonApp,
    ButtonDropdownItem,
    BannerClient,
    ClientSkeleton,

    SidebarBranch,
    SidebarEditBranch,
    SidebarAddPICBranch,

    TabListAreaBranch,
    TabListAreaKoordinator,
    TableUnitPrice
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    pathDetailBranch () {
      const path = this.$route.path.split('/')
      const lastElement = path[path.length - 1]

      if (lastElement === 'detail-branch') return true
      return false
    },
    getListDaftarBranchData () {
      return ListDaftarBranchData(this.listBranch)
    },
    renderTitleButton () {
      return this.isUnitPrice ? 'Data' : titleBranch(this.listBranch.code || '')
    },
    isSMRC () {
      return isSMRC(this.listBranch.code)
    },
    renderTitle () {
      let temp
      if (this.isSMRC) {
        if (this.tabActive === 'unit') {
          temp = BRANCH_SMRC
        } else if (this.tabActive === 'unit-price') {
          temp = 'Item Pekerjaan'
        } else {
          temp = AREA_KOORDINATOR_SMRC
        }
      } else {
        if (this.tabActive === 'unit') {
          temp = BRANCH
        } else if (this.tabActive === 'unit-price') {
          temp = 'Item Pekerjaan'
        } else {
          temp = PIC_STORE
        }
      }
      return temp
    },
    renderTitleBranch () {
      return isSMRC ? BRANCH_SMRC : BRANCH
    },
    getSearchValue () {
      if (this.tabActive === 'unit') {
        return this.searchUnit
      } else if (this.tabActive === 'unit-price') {
        return this.searchUnitPrice
      } else {
        return this.searchAreaK
      }
    },
    isUnitPrice () {
      return this.dataClient && this.dataClient.is_use_unit_price
    }
  },
  data () {
    return {
      isLoading: false,
      isLoadingAreaC: false,
      isLoadingUnitPrice: false,
      searchUnit: null,
      searchAreaK: null,
      searchUnitPrice: null,
      listBranch: null,
      showSidebar: false,
      showSidebarEdit: false,
      showSidebarCoordinator: false,
      selectedBranch: null,
      dataClient: null,
      areasName: null,
      tabActive: 'unit',
      initAreaKoordinator: [],
      dataAreaKoordinator: [],
      openDropdownUnitPrice: false,
      initUnitPrice: [],
      dataUnitPrice: []
    }
  },

  methods: {
    handleClickTab (tab) {
      this.tabActive = tab
    },
    goBack () {
      if (this.$route.query.unitPrice) {
        if (this.isSMRC) {
          this.$router.push(`/am/vendors/${this.$route.params.vendorId}/area`)
        } else {
          this.$router.push('/am/vendors')
        }
      } else {
        this.$router.go(-1)
      }

      const clearBranch = {
        type: 'clearPayload',
        data: {
          clientSelected: {},
          branchName: '',
          dataPic: []
        }
      }

      const clearPicBranch = {
        type: 'clearPayload',
        data: {
          clientSelected: {},
          branchSelected: {},
          dataPic: []
        }
      }

      this.$store.commit('client/setPayloadAddBranch', clearBranch)
      this.$store.commit('client/setPayloadAddPicBranch', clearPicBranch)
    },
    detailBranch (branchId, data) {
      this.$router.push(`/am/vendors/${branchId}/store`)
    },
    getListBranch (props) {
      if (!props || !props.dontLoading) {
        this.isLoading = true
      }

      const payload = {
        url: this.url,
        vendor_id: this.$route.params.vendorId
      }

      if (this.$route.query.area_id) {
        payload.area_id = this.$route.query.area_id
      }

      this.$store
        .dispatch('client/getListBranch', payload)
        .then(response => {
          const res = response.data.data
          this.listBranch = res
          this.getDataQuotaDivder = response.data.data.quota_divider

          const clientSelected = {
            company_name: res.company_name,
            name: res.name,
            logo: res.logo,
            id: this.$route.params.vendorId
          }

          if (res.code) {
            clientSelected.code = res.code
          }

          this.areasName = response.data.data.area

          this.dataClient = {
            name: res.name,
            company_name: res.company_name,
            logo: res.logo,
            client_type_code: res.client_type_code,
            client_type_name: res.client_type_name
          }

          if (res.is_use_unit_price) {
            this.dataClient.is_use_unit_price = res.is_use_unit_price
          }

          // Tambah response detail client
          this.$store.commit('client/setPayloadAddBranch', {
            type: 'clientSelected',
            clientSelected: clientSelected
          })
          this.$store.commit('client/setPayloadAddPicBranch', {
            type: 'clientSelected',
            clientSelected: clientSelected
          })
          this.$store.commit('client/setDataQuotaDivder', { quota_divider: this.getDataQuotaDivder })
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    getListAreaKoordinator () {
      this.isLoadingAreaC = true
      const payload = {
        url: this.url,
        vendor_id: this.$route.params.vendorId,
        area_id: this.$route.query.area_id,
        role_id: AREACOORD
      }

      this.$store
        .dispatch('client/getUserRoleSMRC', payload)
        .then(response => {
          const res = response.data.data
          this.initAreaKoordinator = JSON.parse(JSON.stringify(res))
          this.dataAreaKoordinator = res
          this.isLoadingAreaC = false
        })
        .catch(error => {
          alertErrorMessage(error)
          this.isLoadingAreaC = false
        })
    },
    getListUnitPrice (props) {
      if (this.initUnitPrice.length > 0 && (!props || !props.refresh)) return

      if ((!props || !props.hideLoading)) {
        this.isLoadingUnitPrice = true
      }

      const payload = {
        url: this.url,
        vendor_id: this.$route.params.vendorId
      }

      if (this.searchUnitPrice) {
        payload.search = this.searchUnitPrice
      }

      if ((props && props.sortBy) && (props && props.sortType)) {
        payload.sort_by = props.sortBy
        payload.sort_type = props.sortType
      }

      this.$store
        .dispatch('unitPrice/getListItemWorkingUnitPrice', payload)
        .then(response => {
          const res = response.data.data
          this.initUnitPrice = JSON.parse(JSON.stringify(res))
          this.dataUnitPrice = res
          this.isLoadingUnitPrice = false
        })
        .catch(error => {
          alertErrorMessage(error)
          this.isLoadingUnitPrice = false
        })
    },
    handleClickAddBtn () {
      this.isUnitPrice ? this.handleAddUnitPrice() : this.handleAddBranch()
    },
    handleAddBranch () {
      this.openDropdownUnitPrice = false
      this.showSidebar = true
      this.$store.commit('client/setAddBranchFromList', true)
    },
    handleAddUnitPrice () {
      this.openDropdownUnitPrice = !this.openDropdownUnitPrice
    },
    handleCloseUnitPrice () {
      this.openDropdownUnitPrice = false
    },
    handleCloseSidebar () {
      this.showSidebar = false
    },
    handleCloseSidebarEdit () {
      this.showSidebarEdit = false
      this.selectedBranch = null
    },
    handleEditBranch (data) {
      this.showSidebarEdit = true
      this.selectedBranch = data
    },
    handleAddAreaCoordinator () {
      this.showSidebarCoordinator = true
    },
    handleCloseSidebarAreaCoordinator () {
      this.showSidebarCoordinator = false
    },
    handleUnitPrice () {
      this.$router.push(`/am/form-unit-price/${this.$route.params.vendorId}`)
    },
    updateSearchValue (value) {
      if (this.tabActive === 'unit') {
        this.searchUnit = value
      } else if (this.tabActive === 'unit-price') {
        this.searchUnitPrice = value
      } else {
        this.searchAreaK = value
      }
    },
    filterAreaK () {
      const filter = this.initAreaKoordinator.filter(item => {
        return item.name
          .toLowerCase()
          .includes(this.searchAreaK.toLowerCase())
      })

      this.dataAreaKoordinator = filter
    },
    clearQuery () {
      const query = this.$route.query.type
      if (query) {
        this.tabActive = query
      }
      setTimeout(() => {
        const query = Object.assign({}, this.$route.query)
        if (query.type) {
          delete query.type
          this.$router.replace({ query })
        }
      }, 200)
    }
  },
  created () {
    this.$store.commit('client/setPayloadAddPicBranch', {
      type: 'branchSelected',
      branchSelected: {}
    })
    this.getListBranch()
    this.getListAreaKoordinator()
    this.getListUnitPrice({ sortBy: 'created_at', sortType: 'desc' })
  },
  mounted () {
    this.clearQuery()
  },
  watch: {
    searchUnit (newValue) {
      if (newValue === '') {
        this.getListBranch({ dontLoading: true })
      }
    },
    searchPic (newValue) {
      if (newValue === '') {
        this.dataPicFilter = {
          branch: this.initListStore.branch,
          pics: this.initListStore.pics
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";

.col-1 {
  background: linear-gradient(90deg, #ed4949 0%, #a40707 100%);
  border-radius: 20px;
  color: white;
}

.wave {
  object-fit: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 20px;
}

.wrapper-vendor {
  background: #f7f7f7;
  border-radius: 18px;
}

.border-right {
  border-right: 2px #eaeaea solid;
}

.btn-detail {
  border-radius: 20px;
  color: #eb4600;
  background: white;
  border: 1px solid #eb4600;
  padding-top: 14px;
  padding-bottom: 14px;
  font-family: "Averta-Bold";
  font-size: 16px;
  width: 100%;
}

.list-branch {
  height: 245px;
}

.branch-name {
  height: 60px;
}

.branch-name p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.unit-price-wrapper {
  width: 18em;
  position: absolute;
  top: 3em;
  right: 0;
  z-index: 2;
  background: $color-white;
  padding: 15px 10px;
  border: 1px solid $color-grey-3;
  border-radius: 20px;
  box-shadow: 0px 6px 24px 0px #2525251A;
}

.divider {
  border: 1px solid $color-grey-3;
  margin: 10px 0;
}
</style>
