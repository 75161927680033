export function updatedCards (cards, initConsultants) {
  const tempArr = initConsultants || []
  for (const item of cards) {
    const newId = randomConsultantId()
    const body = {
      consultant_id: newId,
      is_additional: true,
      list: [{ ...item, consultant_id: newId, removed: false }]
    }
    if (tempArr.length < 1) {
      tempArr.push(body)
      for (let i = 0; i < 1; i++) {
        const additional = {
          consultant_id: randomConsultantId(),
          is_additional: true,
          list: []
        }
        tempArr.push(additional)
      }
    } else {
      let isOverlap = false
      for (const [index, temp] of tempArr.entries()) {
        const overlapping = temp.list.find(e => {
          return (e.start_time >= item.start_time && e.start_time < item.end_time) ||
                (e.end_time > item.start_time && e.end_time <= item.end_time) ||
                (e.start_time <= item.start_time && e.end_time >= item.end_time)
        })
        if (overlapping) {
          isOverlap = true
        } else {
          isOverlap = false
          tempArr[index].list.push({ ...item, consultant_id: temp.consultant_id })
          break
        }
      }
      if (isOverlap) {
        tempArr.push(body)
        const findAdditional = tempArr.filter(e => e.is_additional)
        if (findAdditional && findAdditional.length < 3) {
          for (let i = 0; i < 3; i++) {
            const additional = {
              consultant_id: randomConsultantId(),
              is_additional: true,
              list: []
            }
            tempArr.push(additional)
          }
        }
      }
    }
  }

  const result = {
    consultants: tempArr.map(item => ({ id: item.consultant_id, is_additional: item.is_additional })),
    cards: tempArr.flatMap(item => item.list)
  }
  return result
}

export function randomConsultantId () {
  return `CONS_${Math.floor(5000 + Math.random() * 2000)}`
}

export function mergeListConsultant (cons1, cons2) {
  const result = Array.from(
    new Map(
      [...cons1, ...cons2]
        .filter(item => !('is_additional' in item)) // Exclude objects with is_additional
        .map(item => [item.id, item])
    ).values()
  )

  return result
}

function timeToMinutes (time) {
  const [hours, minutes] = time.split(':').map(Number)
  return hours * 60 + minutes
}

export function findOverlappingCards (cards) {
  // Group cards by consultant_id
  const groupedByConsultant = cards.reduce((acc, card) => {
    acc[card.consultant_id] = acc[card.consultant_id] || []
    acc[card.consultant_id].push(card)
    return acc
  }, {})

  const result = []

  // Process each consultant group separately
  // eslint-disable-next-line camelcase
  for (const [consultant_id, consultantCards] of Object.entries(groupedByConsultant)) {
    // Separate pivot (session_id > 0) and non-pivot (session_id === 0) cards
    const pivotCards = consultantCards.filter(card => card.session_id > 0)
    const nonPivotCards = consultantCards.filter(card => card.session_id === 0)

    // Create pivot groups: for each pivot, group all cards that overlap with it (using <= for boundary overlap)
    const pivotGroups = []
    pivotCards.forEach(pivot => {
      // Filter cards that overlap with the pivot card
      let group = consultantCards.filter(card =>
        timeToMinutes(pivot.start_time) <= timeToMinutes(card.end_time) &&
        timeToMinutes(card.start_time) <= timeToMinutes(pivot.end_time)
      )
      // Ensure the pivot card remains the first element in its group
      const others = group.filter(card => card !== pivot).sort((a, b) => a.start_time.localeCompare(b.start_time))
      group = [pivot, ...others]
      pivotGroups.push(group)
    })

    // Mark cards already included in any pivot group
    const includedIds = new Set()
    pivotGroups.forEach(group => {
      group.forEach(card => includedIds.add(card.id))
    })

    // Group remaining non-pivot cards (those not included in any pivot group) based on overlapping intervals
    const remainingNonPivots = nonPivotCards.filter(card => !includedIds.has(card.id))
    remainingNonPivots.sort((a, b) => a.start_time.localeCompare(b.start_time))
    const nonPivotGroups = []
    let currentGroup = []
    for (const card of remainingNonPivots) {
      if (currentGroup.length === 0 || card.start_time <= currentGroup[currentGroup.length - 1].end_time) {
        currentGroup.push(card)
      } else {
        nonPivotGroups.push([...currentGroup])
        currentGroup = [card]
      }
    }
    if (currentGroup.length > 0) nonPivotGroups.push(currentGroup)

    // Combine pivot groups and non-pivot groups
    const overlappingGroups = [...pivotGroups, ...nonPivotGroups]

    // Further split groups based on session grouping logic (without modifying width/right calculations)
    overlappingGroups.forEach(group => {
      const sessionGroups = []
      let currentSessionGroup = []
      for (const card of group) {
        if (currentSessionGroup.length === 0 || card.session_id === 0 || currentSessionGroup[0].session_id === 0) {
          currentSessionGroup.push(card)
        } else {
          sessionGroups.push([...currentSessionGroup])
          currentSessionGroup = [card]
        }
      }
      if (currentSessionGroup.length > 0) sessionGroups.push(currentSessionGroup)

      // For each session group, calculate width and right as per existing logic
      sessionGroups.forEach(sessionGroup => {
        const sessionCount = sessionGroup.filter(card => card.session_id === 0).length
        const baseWidth = 100 - sessionCount * 10
        let leftOffset = 0
        result.push({
          consultant_id: Number(consultant_id),
          overlapping_cards: sessionGroup.map(card => {
            const updatedCard = {
              ...card,
              width: `${baseWidth}%`,
              left: `${leftOffset}%`
            }
            leftOffset += 10
            return updatedCard
          })
        })
      })
    })
  }
  return result
}

export function findOverlappingCard2 (cards) {
  function isOverlapping (card1, card2) {
    return card1.start_time < card2.end_time && card2.start_time < card1.end_time
  }

  // Perbaiki calculateWidth:
  // - Jika card.session_id !== 0, width = "90%".
  // - Jika card.session_id === 0, hitung width berdasarkan kartu zero overlapping saja.
  function calculateWidth (card, overlappingCards) {
    if (card.session_id !== 0) {
      return '90%'
    } else {
      // Hanya hitung kartu zero saja
      const zeroCount = overlappingCards.filter(c => c.session_id === 0).length
      const totalZero = zeroCount + 1 // termasuk dirinya sendiri
      let width = 100 - totalZero * 10
      if (width < 10) width = 10
      return `${width}%`
    }
  }

  function findOverlaps (cards) {
    const overlapMap = {}

    // Bangun overlapMap untuk kartu yang overlapping (hanya jika consultant_id sama)
    for (let i = 0; i < cards.length; i++) {
      for (let j = 0; j < cards.length; j++) {
        if (
          i !== j &&
          cards[i].consultant_id === cards[j].consultant_id &&
          isOverlapping(cards[i], cards[j])
        ) {
          if (!overlapMap[cards[i].id]) {
            overlapMap[cards[i].id] = { session_id: cards[i].session_id, parent: [] }
          }
          overlapMap[cards[i].id].parent.push(cards[j])
        }
      }
    }

    // Buat array hasil, sertakan consultant_id untuk grouping
    const results = []
    for (const key in overlapMap) {
      const card = cards.find(c => c.id === Number(key))
      const width = calculateWidth(card, overlapMap[key].parent)
      results.push({
        id: Number(key),
        session_id: overlapMap[key].session_id,
        width,
        parent: overlapMap[key].parent,
        consultant_id: card.consultant_id
      })
    }

    // Untuk properti left, hanya berlaku untuk kartu dengan session_id === 0.
    // Kita grupkan kartu zero berdasarkan overlapping (hanya yang consultant_id sama).
    const zeroCards = results.filter(r => r.session_id === 0)

    // Buat adjacency list untuk kartu zero
    const adj = {}
    zeroCards.forEach(card => {
      adj[card.id] = []
      const fullCardA = cards.find(c => c.id === card.id)
      zeroCards.forEach(otherCard => {
        if (card.id === otherCard.id) return
        if (card.consultant_id === otherCard.consultant_id) {
          const fullCardB = cards.find(c => c.id === otherCard.id)
          if (isOverlapping(fullCardA, fullCardB)) {
            adj[card.id].push(otherCard.id)
          }
        }
      })
    })

    // Cari connected components (grup) dengan DFS
    const groups = []
    const visited = new Set()
    zeroCards.forEach(card => {
      if (!visited.has(card.id)) {
        const stack = [card.id]
        const group = []
        while (stack.length) {
          const current = stack.pop()
          if (!visited.has(current)) {
            visited.add(current)
            group.push(current);
            (adj[current] || []).forEach(neighbor => {
              if (!visited.has(neighbor)) stack.push(neighbor)
            })
          }
        }
        groups.push(group)
      }
    })

    // Tetapkan properti left berdasarkan urutan di setiap grup
    groups.forEach(group => {
      // Urutkan misalnya berdasarkan id
      group.sort((a, b) => a - b)
      group.forEach((id, index) => {
        const card = results.find(r => r.id === id)
        if (card) {
          card.left = `${(index + 1) * 10}%`
        }
      })
    })

    return results
  }
  return findOverlaps(cards)
}
