<template>
  <svg width="50" height="50" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#840005" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.5686 8.14952C18.3253 8.23242 17.1944 8.83238 16.0557 9.48273C14.9169 10.1332 13.851 10.7375 13.687 10.8258C12.9123 11.243 10.9543 12.3914 10.5352 12.6743C9.7024 13.2366 9.24989 13.8874 9.09358 14.7477C8.9681 15.4383 8.96912 23.7802 9.09477 24.4592C9.22204 25.1471 9.44991 25.5571 10.0134 26.1121C10.4026 26.4955 11.1935 26.9837 14.2248 28.7119C16.2775 29.8822 18.1942 30.9249 18.4841 31.029C19.1431 31.2657 19.8401 31.2753 20.4897 31.0566C20.9945 30.8867 21.2997 30.7242 23.5259 29.4399C24.2287 29.0344 25.6451 28.226 26.6734 27.6434C28.851 26.4096 29.4502 25.9123 29.7952 25.0521L30 24.5414V19.6048V14.6682L29.7972 14.1654C29.4489 13.302 28.8389 12.8006 26.5815 11.5224C25.51 10.9156 23.8139 9.94787 22.8124 9.37183C21.811 8.7957 20.76 8.24842 20.4771 8.15565C19.8511 7.95027 19.16 7.94805 18.5686 8.14952Z"
      fill="white"
    />
    <path
      d="M18.2328 21.9053C15.5903 21.9053 13.3333 22.3135 13.3333 23.945C13.3333 25.5779 15.5759 26.0001 18.2328 26.0001C20.8753 26.0001 23.1324 25.5919 23.1324 23.9604C23.1324 22.3275 20.8897 21.9053 18.2328 21.9053Z"
      fill="#B40007"
    />
    <path
      opacity="0.4"
      d="M18.2327 20.3499C20.0325 20.3499 21.4748 18.9374 21.4748 17.1749C21.4748 15.4124 20.0325 14 18.2327 14C16.4336 14 14.9907 15.4124 14.9907 17.1749C14.9907 18.9374 16.4336 20.3499 18.2327 20.3499Z"
      fill="#B40007"
    />
    <path
      opacity="0.4"
      d="M22.7823 17.2325C22.7823 18.13 22.507 18.9675 22.0243 19.6632C21.974 19.7347 22.0184 19.8312 22.1058 19.8465C22.2271 19.8663 22.3517 19.8785 22.4789 19.881C23.7444 19.9136 24.8801 19.1157 25.1939 17.9141C25.659 16.1312 24.2943 14.5303 22.5559 14.5303C22.3674 14.5303 22.1867 14.5494 22.0106 14.5846C21.9864 14.5897 21.9603 14.6012 21.9473 14.6216C21.9303 14.6478 21.9427 14.6817 21.9597 14.704C22.4822 15.4214 22.7823 16.2947 22.7823 17.2325Z"
      fill="#B40007"
    />
    <path
      d="M26.5193 22.113C26.2877 21.6294 25.7287 21.2979 24.8781 21.135C24.4769 21.0391 23.3901 20.9031 22.3797 20.9222C22.3647 20.9242 22.3562 20.9344 22.3549 20.9408C22.353 20.951 22.3575 20.9663 22.3771 20.9772C22.8441 21.2033 24.6491 22.1871 24.4221 24.262C24.4123 24.3527 24.486 24.4294 24.578 24.4166C25.0222 24.3546 26.1651 24.1138 26.5193 23.3651C26.7156 22.969 26.7156 22.5091 26.5193 22.113Z"
      fill="#B40007"
    />
  </svg>
</template>

<script>
export default {
  name: 'PhotoProfileGroupChat'
}
</script>
