import { render, staticRenderFns } from "./SidebarEditBranch.vue?vue&type=template&id=364d6ec8&scoped=true"
import script from "./SidebarEditBranch.vue?vue&type=script&lang=js"
export * from "./SidebarEditBranch.vue?vue&type=script&lang=js"
import style0 from "./SidebarEditBranch.vue?vue&type=style&index=0&id=364d6ec8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "364d6ec8",
  null
  
)

export default component.exports