<template>
  <ClientSkeleton v-if="isLoading" class="px-6 pb-6 pt-5" />
  <div class="container is-fluid pt-5 pb-6 px-6 min-h-90vh" v-else>
    <div class="is-flex is-align-items-center is-justify-content-space-between mb-5">
      <p class="averta-bold is-size-5">Daftar Client</p>
      <div class="is-flex is-align-items-center">
        <div class="mr-5">
        <b-input
          placeholder="Cari nama client"
          type="search"
          icon="magnify"
          @icon-click="getListVendor"
          v-model="keyword"
          @keyup.native.enter="getListVendor"
        >
        </b-input>
      </div>
        <ButtonApp @click="handleAddClient">
          <p class="averta-bold color-white py-2 px-5">+ Tambah</p>
        </ButtonApp>
      </div>
    </div>
    <div class="columns mb-5">
      <div class="column card col-1 p-3 m-2" v-for="(item, index) in getListDaftarVendorData" :key="index">
        <img :src="require(`@/assets/img/${item.image}`)"  alt="wave" class="wave" />
        <p class="is-size-5 averta-bold">{{ item.total }}</p>
        <p class="is-size-4 averta-black my-2">{{ item.listVendor }}</p>
      </div>
    </div>
    <div v-if="listVendor.list.length > 0" class="columns is-flex is-flex-wrap-wrap">
      <div class="column is-4" v-for="vendor, index in listVendor.list" :key="index">
        <div class="card border-radius-20 overflow-hidden border-1-grey-2 p-4">
          <div class="mb-3 is-flex is-justify-content-space-between is-align-items-center">
            <img :src="vendor.logo" class="logo">
            <div class="is-flex">
              <p v-if="vendor.client_type_code && vendor.client_type_name" class="mr-1 tag-client">
                {{ vendor.client_type_name }}
              </p>
            </div>
          </div>
          <div class="mb-1 client-name">
            <div>
              <div class="is-flex is-align-items-flex-end pr-3">
                <div class="ellipsis-vendor">
                  <p class="averta-bold label-18">{{ vendor.name }}</p>
                </div>
                <div>
                  <p class="label-16 averta-reguler ml-1" style="margin-top: 1px;">#{{ vendor.id }}</p>
                </div>
              </div>
              <p class="label-14 text-grey">{{ vendor.company_name }}</p>
            </div>
          </div>
          <div class="is-flex is-justify-content-space-between wrapper-vendor p-3">
            <div class="has-text-centered flex-1">
              <p>Jumlah Cabang</p>
              <p class="averta-bold">{{ vendor.total_branch }}</p>
            </div>
            <div class="border-right"></div>
            <div class="has-text-centered flex-1">
              <p>Jumlah Toko</p>
              <p class="averta-bold">{{ vendor.total_store }}</p>
            </div>
          </div>
          <div class="mt-5 is-flex">
            <ButtonApp
              width="100%"
              class="mr-3"
              :isSecondary="true"
              @click="editVendor(vendor)"
            >
              <p class="averta-bold py-2">Edit</p>
            </ButtonApp>
            <ButtonApp
              width="100%"
              @click="detailVendor(vendor.is_having_branch, vendor.id, vendor)"
            >
              <p class="averta-bold py-2">Lihat Detail</p>
            </ButtonApp>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="column c-w-100 has-text-centered">
      <ProyekEmptyIcon />
      <p class="averta-bold mt-2">
        Data Tidak Ditemukan
      </p>
    </div>

    <ModalAddClient
      :showModal="modalAddClient"
      @closeModal="closeModalAddClient"
      @selectMenu="handleSelectMenu($event)"
    />

    <SidebarClient
      :show="sidebarMenu === 'client'"
      :close="handleCloseMenu"
      @updateEdit="getListVendor"
    />
    <SidebarBranch
      :show="sidebarMenu === 'branch'"
      :close="handleCloseMenu"
    />
    <SidebarStore
      :show="sidebarMenu === 'store'"
      :close="handleCloseMenu"
      @refreshData="getListVendor"
    />
  </div>
</template>

<script>
// HELPERS
import { mapGetters } from 'vuex'
import { alertErrorMessage } from '@/helpers'
import { ListDaftarVendorData } from '@/data/views/listvendorData'

// eslint-disable-next-line no-unused-vars
import { SUMMARECON_MTOWN } from '@/helpers/constants'

// COMPONENTS
import { ProyekEmptyIcon } from '@/components/icons'
import ButtonApp from '@/components/button/ButtonApp.vue'
import ModalAddClient from './components/client-components/ModalAddClient.vue'
import SidebarClient from './components/SidebarClient.vue'
import SidebarBranch from './components/SidebarBranch.vue'
import SidebarStore from './components/SidebarStore.vue'
import ClientSkeleton from '@/components/skeleton/ClientSkeleton.vue'

export default {
  name: 'ListVendor',
  components: {
    ProyekEmptyIcon,
    ButtonApp,
    ModalAddClient,
    SidebarClient,
    SidebarBranch,
    SidebarStore,
    ClientSkeleton
  },
  data () {
    return {
      isLoading: false,
      listVendor: null,
      modalAddClient: false,
      keyword: '',
      sidebarMenu: null,
      ListDaftarVendorData
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      detailClient: 'client/getDetailClient'
    }),
    getListDaftarVendorData () {
      return ListDaftarVendorData(this.listVendor)
    }
  },
  methods: {
    detailVendor (havingBranch, id, data) {
      if (data.code === SUMMARECON_MTOWN) {
        this.$router.push(`/am/vendors/${id}/area`)
      } else {
        this.$router.push(`/am/vendors/${id}/branch`)
      }
    },
    editVendor (data) {
      this.sidebarMenu = 'client'
      this.$store.commit('client/setPayloadClient', {
        typeSidebar: 'edit',
        dataForm: { ...data, imgFile: 'from-edit' },
        isEmptyForm: false
      })
      this.handleStoreSidebar(true)
    },
    getListVendor () {
      this.isLoading = true
      const payload = {
        url: this.url
      }

      if (this.keyword) {
        payload.keyword = this.keyword
      }

      this.$store
        .dispatch('client/getListVendor', payload)
        .then(response => {
          this.listVendor = response.data.data
          this.isLoading = false
        })
        .catch(error => {
          alertErrorMessage(error)
          this.isLoading = false
        })
    },
    getListClientType () {
      const payload = {
        url: this.url
      }

      this.$store
        .dispatch('client/getListClientType', payload)
        .then(response => {
          const res = response.data.data
          this.$store.commit('client/setListClientType', res)
        })
        .catch(error => {
          alertErrorMessage(error)
        })
    },
    closeModalAddClient () {
      this.modalAddClient = false
    },
    handleAddClient () {
      this.modalAddClient = !this.modalAddClient
    },
    handleSelectMenu (props = {}) {
      const { id } = props // client, branch, store
      this.sidebarMenu = id
      this.closeModalAddClient()
      this.handleStoreSidebar(true)
    },
    handleCloseMenu () {
      this.sidebarMenu = null
      this.handleStoreSidebar(false)
    },
    handleStoreSidebar (showSidebar) {
      this.$store.commit('client/setPayloadClient', { showSidebar })
    }
  },
  created () {
    this.getListVendor()
    // reset detail client
    this.$store.commit('client/setDetailClient', { data: null })
  // this.getListClientType()
  },
  watch: {
    keyword (val) {
      if (!val) {
        this.getListVendor()
      }
    }
  }
}

</script>

<style scoped>
.col-1 {
background: linear-gradient(90deg, #ED4949 0%, #A40707 100%);
border-radius: 20px;
color: white;
}

.wave {
object-fit: cover;
position: absolute;
height: 100%;
width: 100%;
bottom: 0;
left: 0;
border-radius: 20px;
}

.wrapper-vendor {
background: #F7F7F7;
border-radius: 8px;
}

.border-right {
border-right: 2px #EAEAEA solid;
}

.logo {
object-fit: contain;
height: 27px;
max-width: 43%;
}

.ellipsis-vendor {
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 1;
line-clamp: 1;
-webkit-box-orient: vertical;
}

.client-name {
height: 60px;
}

.tag-client {
background: #E1EEFF;
height: max-content;
padding: 2px 12px;
border-radius: 100px;
color: #3185FC;
font-family: 'Averta-Bold';
}
</style>
