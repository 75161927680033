<template>
  <div class="container-table-unit-price">
    <div v-if="initialData.length > 0" class="is-flex unit-price-container">
      <div class="unit-price-section left">
        <div class="is-flex bg-dark-grey-5 color-white averta-bold br-note-item-6">
          <div
            class="item-price left"
            :style="{
              width: `${S.TBL_WIDTH_PERCENT_NO}`,
              height: `${S.HEIGHT_COLUMN_HEADER}px`
            }"
          >
            <p>NO</p>
          </div>
          <div
            class="item-price left"
            :style="{
              width: `${S.TBL_WIDTH_PERCENT_ID}`,
              height: `${S.HEIGHT_COLUMN_HEADER}px`
            }"
          >
            <p>ID</p>
          </div>
          <div
            class="item-price left"
            :style="{
              width: `${S.TBL_WIDTH_PERCENT_NOTE_ITEM}`,
              height: `${S.HEIGHT_COLUMN_HEADER}px`
            }"
          >
            <p>NAMA ITEM PEKERJAAN</p>
          </div>
          <!-- <div
            class="item-price left"
            :style="{
              width: `${S.TBL_WIDTH_PERCENT_UNIT_NAME}`,
              height: `${S.HEIGHT_COLUMN_HEADER}px`
            }"
          >
            <p>SATUAN</p>
          </div> -->
        </div>
        <div class="item-column br-note-item-6" v-for="(item, index) in dataTable" :key="index">
          <div
            class="flex-center"
            :style="{
              width: `${S.TBL_WIDTH_PERCENT_NO}`,
              height: `${S.TBL_HEIGHT_COLUMN_BODY}px`
            }"
          >
            <p>{{ index + 1 }}</p>
          </div>
          <div
            class="flex-center"
            :style="{
              width: `${S.TBL_WIDTH_PERCENT_ID}`,
              height: `${S.TBL_HEIGHT_COLUMN_BODY}px`
            }"
          >
            <p class="averta-bold">{{ item.id }}</p>
          </div>
          <div
            :style="{
              width: `${S.TBL_WIDTH_PERCENT_NOTE_ITEM}`,
              height: `${S.TBL_HEIGHT_COLUMN_BODY}px`
            }"
          >
            <div class="flex-center c-h-100">
              <div class="max-c-h-100 c-w-100">
                <p>{{ item.note }}</p>
              </div>
            </div>
          </div>
          <!-- <div
            class="flex-center"
            :style="{
              width: `${S.TBL_WIDTH_PERCENT_UNIT_NAME}`,
              height: `${S.TBL_HEIGHT_COLUMN_BODY}px`
            }"
          >
            <p>{{item.name}}</p>
          </div> -->
        </div>
      </div>
      <div class="unit-price-section middle">
        <div class="is-flex color-white averta-bold">
          <div
            class="item-price middle flex-center bg-dark-grey-5"
            :style="{
              minWidth: `${S.TBL_WIDTH_PERCENT_UNIT_NAME}px`,
              width: `${S.TBL_WIDTH_PERCENT_UNIT_NAME}`,
              height: `${S.HEIGHT_COLUMN_HEADER}px`
            }"
          >
            <p class="has-text-centered">VOLUME MAKSIMAL</p>
            <!-- <div @click="handleSort('material_price')" class="is-clickable ml-1">
              <img :src="require('@/assets/icons/arrow-top-bottom-used.svg')" alt="">
            </div> -->
          </div>
          <div
            class="item-price middle flex-center bg-dark-grey-5"
            :style="{
              minWidth: `${S.TBL_WIDTH_PERCENT_UNIT_NAME}px`,
              width: `${S.TBL_WIDTH_PERCENT_UNIT_NAME}`,
              height: `${S.HEIGHT_COLUMN_HEADER}px`
            }"
          >
            <p class="has-text-centered">KUOTA SESI PENGERJAAN</p>
            <!-- <div @click="handleSort('material_price')" class="is-clickable ml-1">
              <img :src="require('@/assets/icons/arrow-top-bottom-used.svg')" alt="">
            </div> -->
          </div>
          <div
            class="item-price middle flex-center bg-dark-grey-5"
            :style="{
              minWidth: `${S.TBL_WIDTH_PERCENT_UNIT_NAME}px`,
              width: `${S.TBL_WIDTH_PERCENT_UNIT_NAME}`,
              height: `${S.HEIGHT_COLUMN_HEADER}px`
            }"
          >
            <p class="has-text-centered">SATUAN</p>
            <!-- <div @click="handleSort('material_price')" class="is-clickable ml-1">
              <img :src="require('@/assets/icons/arrow-top-bottom-used.svg')" alt="">
            </div> -->
          </div>
          <div
            class="item-price middle flex-center bg-dark-grey-5"
            :style="{
              minWidth: `${S.TBL_WIDTH_MATERIAL}px`,
              width: `${S.TBL_WIDTH_PERCENT_MATERIAL}`,
              height: `${S.HEIGHT_COLUMN_HEADER}px`
            }"
          >
            <p class="has-text-centered">HARGA MATERIAL</p>
            <div @click="handleSort('material_price')" class="is-clickable ml-1">
              <img :src="require('@/assets/icons/arrow-top-bottom-used.svg')" alt="">
            </div>
          </div>
          <div
            class="item-price middle flex-center bg-dark-grey-5"
            :style="{
              minWidth: `${S.TBL_WIDTH_MATERIAL}px`,
              width: `${S.TBL_WIDTH_PERCENT_MATERIAL}`,
              height: `${S.HEIGHT_COLUMN_HEADER}px`
            }"
          >
            <p class="has-text-centered">HARGA MATERIAL</p>
            <div @click="handleSort('material_price')" class="is-clickable ml-1">
              <img :src="require('@/assets/icons/arrow-top-bottom-used.svg')" alt="" />
            </div>
          </div>
          <div
            class="item-price middle flex-center bg-dark-grey-5"
            :style="{
              minWidth: `${S.TBL_WIDTH_JASA}px`,
              width: `${S.TBL_WIDTH_PERCENT_JASA}`,
              height: `${S.HEIGHT_COLUMN_HEADER}px`
            }"
          >
            <p>HARGA JASA</p>
            <div @click="handleSort('service_price')" class="is-clickable ml-1">
              <img :src="require('@/assets/icons/arrow-top-bottom-used.svg')" alt="" />
            </div>
          </div>
          <div
            class="item-price middle flex-center bg-dark-grey-5"
            :style="{
              minWidth: `${S.TBL_WIDTH_SATUAN}px`,
              width: `${S.TBL_WIDTH_PERCENT_SATUAN}`,
              height: `${S.HEIGHT_COLUMN_HEADER}px`
            }"
          >
            <p class="has-text-centered">HARGA SATUAN</p>
            <div @click="handleSort('unit_price')" class="is-clickable ml-1">
              <img :src="require('@/assets/icons/arrow-top-bottom-used.svg')" alt="" />
            </div>
          </div>

          <!-- <div class="item-price middle flex-center bg-dark-grey-5" :style="{width: '13em', height: `${S.HEIGHT_COLUMN_HEADER}px`}">
            <p>TANGGAL DIBUAT</p>
            <div @click="handleSort('created_at')" class="is-clickable ml-1">
              <img :src="require('@/assets/icons/arrow-top-bottom-used.svg')" alt="">
            </div>
          </div> -->
          <div
            class="item-price middle flex-center bg-dark-grey-5"
            :style="{
              minWidth: `${S.TBL_WIDTH_UPDATED_AT}px`,
              width: `${S.TBL_WIDTH_PERCENT_UPDATED_AT}`,
              height: `${S.HEIGHT_COLUMN_HEADER}px`
            }"
          >
            <p class="has-text-centered">
              TANGGAL <br />
              TER UPDATE
            </p>
            <div @click="handleSort('last_updated_at')" class="is-clickable ml-1">
              <img :src="require('@/assets/icons/arrow-top-bottom-used.svg')" alt="" />
            </div>
          </div>
          <div
            class="item-price middle bg-dark-grey-5"
            :style="{
              minWidth: '0px',
              width: `${S.TBL_WIDTH_PERCENT_ACTION}`,
              height: `${S.HEIGHT_COLUMN_HEADER}px`
            }"
          >
          </div>
        </div>
        <div class="is-flex" v-for="(item, index) in dataTable" :key="index">
          <div
            class="item-price middle is-justify-content-center averta-bold"
            :style="{
              minWidth: `${S.TBL_WIDTH_PERCENT_UNIT_NAME}px`,
              width: `${S.TBL_WIDTH_PERCENT_UNIT_NAME}`,
              height: `${S.TBL_HEIGHT_COLUMN_BODY}px`
            }"
          >
            <p>{{item.max_volume || '-'}}</p>
          </div>
          <div
            class="item-price middle is-justify-content-center averta-bold"
            :style="{
              minWidth: `${S.TBL_WIDTH_PERCENT_UNIT_NAME}px`,
              width: `${S.TBL_WIDTH_PERCENT_UNIT_NAME}`,
              height: `${S.TBL_HEIGHT_COLUMN_BODY}px`
            }"
          >
            <p>{{item.session_quota || '-'}}</p>
          </div>
          <div
            class="item-price middle is-justify-content-center averta-bold"
            :style="{
              minWidth: `${S.TBL_WIDTH_PERCENT_UNIT_NAME}px`,
              width: `${S.TBL_WIDTH_PERCENT_UNIT_NAME}`,
              height: `${S.TBL_HEIGHT_COLUMN_BODY}px`
            }"
          >
            <p>{{item.name}}</p>
          </div>
          <div
            class="item-price middle is-justify-content-space-between averta-bold"
            :style="{
              minWidth: `${S.TBL_WIDTH_MATERIAL}px`,
              width: `${S.TBL_WIDTH_PERCENT_MATERIAL}`,
              height: `${S.TBL_HEIGHT_COLUMN_BODY}px`
            }"
          >
            <p>Rp</p>
            <p>{{item.material_price ? formatThousand(item.material_price) : 0}}</p>
          </div>
          <div
            class="item-price middle is-justify-content-space-between averta-bold"
            :style="{
              minWidth: `${S.TBL_WIDTH_MATERIAL}px`,
              width: `${S.TBL_WIDTH_PERCENT_MATERIAL}`,
              height: `${S.TBL_HEIGHT_COLUMN_BODY}px`
            }"
          >
            <p>Rp</p>
            <p>{{ item.material_price ? formatThousand(item.material_price) : 0 }}</p>
          </div>
          <div
            class="item-price middle is-justify-content-space-between averta-bold"
            :style="{
              minWidth: `${S.TBL_WIDTH_JASA}px`,
              width: `${S.TBL_WIDTH_PERCENT_JASA}`,
              height: `${S.TBL_HEIGHT_COLUMN_BODY}px`
            }"
          >
            <p>Rp</p>
            <p>{{ item.service_price ? formatThousand(item.service_price) : 0 }}</p>
          </div>
          <div
            class="item-price middle is-justify-content-space-between averta-bold"
            :style="{
              minWidth: `${S.TBL_WIDTH_SATUAN}px`,
              width: `${S.TBL_WIDTH_PERCENT_SATUAN}`,
              height: `${S.TBL_HEIGHT_COLUMN_BODY}px`
            }"
          >
            <p>Rp</p>
            <p>{{ formatThousand(item.unit_price) }}</p>
          </div>
          <!-- <div class="item-price middle" :style="{width: '13em', height: `${S.TBL_HEIGHT_COLUMN_BODY}px`}">
            <p>{{formatDate(item.created_at)}}</p>
          </div> -->
          <div
            class="item-price middle is-flex-direction-column"
            :style="{
              minWidth: `${S.TBL_WIDTH_UPDATED_AT}px`,
              width: `${S.TBL_WIDTH_PERCENT_UPDATED_AT}`,
              height: `${S.TBL_HEIGHT_COLUMN_BODY}px`
            }"
          >
            <p class="has-text-centered averta-bold">{{ formatDate(item.last_updated_at || item.created_at) }}</p>
            <p
              class="has-text-centered label-12 color-grey ellipsis-1"
              v-if="item.created_by_name"
            >
              {{item.created_by_name}} {{item.created_by_role ? `(${formatRoleTitle(item.created_by_role)})` : ''}}
            </p>
          </div>
            <!-- minWidth: `${S.TBL_WIDTH_ACTION}px`, -->
          <div
            class="item-price middle flex-center btn-action-wrapper"
            :style="{
              minWidth: '0px',
              width: `${S.TBL_WIDTH_PERCENT_ACTION}`,
              height: `${S.TBL_HEIGHT_COLUMN_BODY}px`
            }"
          >
            <b-dropdown v-model="selectedDropdownMenu" aria-role="list" position="is-bottom-left">
              <template #trigger>
                <img
                  class="is-clickable"
                  :src="require('@/assets/icons/more-circle-red-used.svg')"
                  alt=""
                />
              </template>

              <b-dropdown-item aria-role="listitem">
                <div class="flex-center-vertical p-1" @click="() => handleEdit(item)">
                  <div class="icon-action-wrapper">
                    <EditIcon color="black" size="17" />
                  </div>
                  <div style="width: 175px;">
                    <p class="color-grey white-space-normal">Ubah Harga Satuan Item Pekerjaan</p>
                  </div>
                </div>
              </b-dropdown-item>

              <b-dropdown-item aria-role="listitem">
                <div class="flex-center-vertical p-1" @click="() => handleDelete(item)">
                  <div class="icon-action-wrapper">
                    <img :src="require('@/assets/icons/trash-red-used.svg')" alt="">
                  </div>
                  <div style="width: 175px;">
                    <p class="color-grey white-space-normal">Hapus Harga Satuan Item Pekerjaan</p>
                  </div>
                </div>
              </b-dropdown-item>

              <b-dropdown-item aria-role="listitem">
                <div class="flex-center-vertical p-1" @click="() => openModalHistory(item)">
                  <div class="icon-action-wrapper">
                    <img :src="require('@/assets/icons/history-line-grey-used.svg')" alt="">
                  </div>
                  <div style="width: 175px;">
                    <p class="color-grey white-space-normal">Lihat Riwayat Perubahan</p>
                  </div>
                </div>
              </b-dropdown-item>
            </b-dropdown>

            <!-- <div class="is-clickable mr-3" @click="() => handleEdit(item)">
              <EditIcon color="black" size="18" />
            </div>
            <div class="is-clickable" @click="() => handleDelete(item)">
              <TrashIcon size="18" />
            </div> -->
          </div>
        </div>
      </div>
      <!-- <div class="unit-price-section right">
        <div class="action-empty bg-dark-grey-5" :style="{ height: `${S.HEIGHT_COLUMN_HEADER}px` }">
        </div>
        <div
          :key="index"
          v-for="(item, index) in dataTable"
          class="flex-center column-action"
          :style="{ height: `${S.TBL_HEIGHT_COLUMN_BODY}px` }"
        >
          <div class="is-clickable mr-3" @click="() => handleEdit(item)">
            <EditIcon color="black" size="18" />
          </div>
          <div class="is-clickable" @click="() => handleDelete(item)">
            <TrashIcon size="18" />
          </div>
        </div>
      </div> -->

      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false">
        <SpinnerIcon />
      </b-loading>
    </div>
    <div v-else class="column c-w-100 has-text-centered">
      <ProyekEmptyIcon />
      <p class="averta-bold mt-2">Data Tidak Ditemukan</p>
    </div>

    <ModalTemplate :showModal="showModalHistory" width="574px" @closeModal="closeModalHistory">
      <template v-slot:headerModal>
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <div class="flex-center-vertical">
            <HistoryLineIcon color="#fff" />
            <p class="averta-bold label-18 ml-3">Riwayat Perubahan</p>
          </div>
          <div class="is-clickable" @click="closeModalHistory">
            <b-icon icon="close"></b-icon>
          </div>
        </div>
      </template>
      <template v-slot:bodyModal>
        <div v-if="isLoading" class="wrap-body-modal"></div>
        <div v-else class="wrap-body-modal">
          <div v-if="listHistory.length > 0" class="header-history is-flex bg-dark-grey-5 color-white averta-bold">
            <div class="flex-center width-number p-3 border-right-1-grey-13">
              <p>NO</p>
            </div>
            <div class="flex-center width-updated-by p-3 border-right-1-grey-13">
              <p>DIUBAH OLEH</p>
            </div>
            <div class="flex-center width-updated-date p-3">
              <p>TANGGAL DIUBAH</p>
            </div>
          </div>
          <div class="body-history">
            <div v-if="listHistory.length > 0">
              <div class="c-w-100 is-flex" v-for="(item, index) in listHistory" :key="index">
                <div class="flex-center width-number p-3 border-1-grey-2">
                  <p>{{index + 1}}</p>
                </div>
                <div class="flex-center-vertical width-updated-by p-3 border-1-grey-2">
                  <p class="averta-bold">{{item.created_by}}</p>
                </div>
                <div class="flex-center-vertical width-updated-date p-3 border-1-grey-2">
                  <p class="averta-bold">{{formatDate(item.created_at, 'DD/MM/YYYY - MM:ss')}}</p>
                </div>
              </div>
            </div>
            <div v-else class="flex-center is-flex-direction-column">
              <ProyekEmptyIcon />
              <p class="has-text-centered averta-bold">Tidak ada riwayat perubahan</p>
            </div>
          </div>
        </div>
      </template>
    </ModalTemplate>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { formatCurrency, alertErrorMessage, toastMessage, formatRoleTitle } from '@/helpers'
import { EditIcon, SpinnerIcon, ProyekEmptyIcon, HistoryLineIcon } from '@/components/icons'
import discardImage from '@/assets/img/delete-area-component.png'
import ModalTemplate from '@/components/modal/ModalTemplate.vue'

export default {
  name: 'TableUnitPrice',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    initialData: {
      type: Array,
      default: () => []
    },
    refreshData: {
      type: Function
    }
  },
  components: {
    EditIcon,
    SpinnerIcon,
    ProyekEmptyIcon,
    HistoryLineIcon,
    ModalTemplate
  },
  data () {
    return {
      isLoading: false,
      showModalHistory: false,
      sortBy: null,
      sortType: null,
      selectedDropdownMenu: null,

      dataTable: [],
      listHistory: []
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      S: 'unitPrice/staticUnitPrice'
    })
  },
  methods: {
    formatRoleTitle (val) {
      return formatRoleTitle(val)
    },
    formatThousand (val) {
      return formatCurrency(val, true)
    },
    formatDate (val, format) {
      const defaultFormat = format || 'dddd, DD MMM YYYY/'
      if (!val) return '-'
      return moment(val).format(defaultFormat)
    },
    handleEdit (data) {
      this.$router.push({
        path: `/am/form-unit-price/${this.$route.params.vendorId}`,
        query: { noteId: data.id }
      })
    },
    handleDelete (data) {
      this.$swal({
        imageUrl: discardImage,
        imageAlt: 'cancel offering',
        width: '600px',
        padding: '10px 10px 30px 10px',
        title: '<p class="averta-bold label-22">Hapus Item Pekerjaan Ini?</p>',
        html: '<p class="averta-reguler">Seluruh informasi pada item pekerjaan ini akan terhapus.</p>',
        showCancelButton: true,
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true
          const payload = {
            url: this.url,
            id: data.id
          }
          this.$store
            .dispatch('unitPrice/deleteItemWorkingUnitPrice', payload)
            .then(() => {
              toastMessage('Data Berhasil Dihapus', 'is-dark')
              this.refreshData({ refresh: true })
              this.isLoading = false
            })
            .catch((err) => {
              this.isLoading = false
              alertErrorMessage(err)
            })
        }
      })
    },
    handleHistory (data) {

    },
    handleSort (type) {
      if (type !== this.sortBy || !this.sortBy) {
        this.sortBy = type
        this.sortType = 'desc'
      } else {
        if (this.sortType === 'desc') {
          this.sortType = 'asc'
        } else if (this.sortType === 'asc') {
          this.sortBy = null
          this.sortType = null
        }
      }

      this.refreshData({
        refresh: true,
        sortBy: this.sortBy,
        sortType: this.sortType,
        hideLoading: true
      })
    },
    openModalHistory (data) {
      this.showModalHistory = !this.showModalHistory
      this.isLoading = true

      const payload = {
        url: this.url,
        id: data.id
      }
      this.$store.dispatch('unitPrice/getListHistory', payload)
        .then(response => {
          const res = response.data.data
          this.listHistory = res
          this.isLoading = false
        }).catch(err => {
          this.isLoading = false
          alertErrorMessage(err)
        })
    },
    closeModalHistory () {
      this.showModalHistory = false
    }
  },
  created () {
    this.dataTable = this.initialData
  },
  watch: {
    initialData: {
      handler (val) {
        this.dataTable = val
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.container-table-unit-price {
  // max-height: 73vh;
  // overflow: auto;
  // border-radius: 10px;
  // border-bottom: 1px solid #C0C0C0;
  // position: relative;
}

.unit-price-container {
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
}

.unit-price-section {
}

.unit-price-section.left {
  width: 40%;
}

.unit-price-section.middle {
  width: 60%;
  overflow-x: auto;
  overflow-y: hidden;
  border-right: 1px solid #c0c0c0;
}

.unit-price-section.right {
  width: 8%;
}

.item-price.left {
  border: 1px solid #c0c0c0;
  height: 5em;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-price.middle {
  border: 1px solid #c0c0c0;
  height: 5em;
  min-width: 13%;
  flex-shrink: 0;
  padding: 6px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-column {
  display: flex;

  > div {
    border: 1px solid #c0c0c0;
    overflow: auto;
    padding: 6px 10px;
  }
}

.action-empty {
  // border-left: 6px solid #C0C0C0;
  border-left: 4px solid #c0c0c0;
}

.column-action {
  border: 1px solid #c0c0c0;
  // border-left: 6px solid #C0C0C0;
  border-left: 4px solid #c0c0c0;
}

.br-note-item-6 {
  // border-right: 6px solid #C0C0C0;
  border-right: 4px solid #c0c0c0;
}

.icon-action-wrapper {
  width: 35px;
  display: flex;
  justify-content: center;
}

.wrap-body-modal {
  background: white;
  padding: 15px;
  height: 400px;
  overflow: auto;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.header-history {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  div {
    height: 50px;
  }
}

.body-history {
  max-height: 315px;
  overflow: auto;
}

.width-number {
  width: 13%;
}

.width-updated-by {
  width: 48%;
}

.width-updated-date {
  width: 47%;
}

.max-height-table-unit-price {
  max-height: 80vh;
  overflow: auto;
}
</style>

<style>
.btn-action-wrapper .dropdown-item {
  padding: 0.375rem 0.3rem !important;
}

.btn-action-wrapper .dropdown-content {
  border-radius: 20px;
  border: 1px solid #e1e1e1;
}
</style>
