import Vue from 'vue'
import Vuex from 'vuex'
import project from './modules/project'
import skill from './modules/skill'
import auth from './modules/auth'
import member from './modules/member'
import setup from './modules/setup'
import invoice from './modules/invoice'
import pembayaran from './modules/pembayaran'
import attendance from './modules/attendance'
import penawaran from './modules/penawaran'
import dashboard from './modules/dashboard'
import report from './modules/report'
import product from './modules/product'
import client from './modules/client'
import settings from './modules/settings'
import store from './modules/store'
import list from './modules/list'
import projectV2 from './modules/project_v2'
import user from './modules/user'
import rating from './modules/rating'
import unitPrice from './modules/unit_price'
import groupChat from './modules/group_chat'
import session from './modules/session'
import projectV3 from './modules/project_v3'
import monitoring from './modules/monitoring'
// import createLogger from '../../../src/plugins/logger'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    project,
    skill,
    auth,
    pembayaran,
    member,
    setup,
    invoice,
    attendance,
    penawaran,
    dashboard,
    report,
    product,
    client,
    settings,
    store,
    list,
    projectV2,
    user,
    rating,
    unitPrice,
    groupChat,
    session,
    projectV3,
    monitoring
  },
  state: {
    /* NavBar */
    isNavBarVisible: true,

    /* FooterBar */
    isFooterBarVisible: true,

    /* Aside */
    isAsideVisible: true,
    isAsideMobileExpanded: false,

    /* Dark mode */
    isDarkModeActive: false
  },
  mutations: {
    /* A fit-them-all commit */
    basic (state, payload) {
      state[payload.key] = payload.value
    },

    /* Aside Mobile */
    asideMobileStateToggle (state, payload = null) {
      const htmlClassName = 'has-aside-mobile-expanded'

      let isShow

      if (payload !== null) {
        isShow = payload
      } else {
        isShow = !state.isAsideMobileExpanded
      }

      if (isShow) {
        document.documentElement.classList.add(htmlClassName)
      } else {
        document.documentElement.classList.remove(htmlClassName)
      }

      state.isAsideMobileExpanded = isShow
    },

    /* Dark Mode */
    darkModeToggle (state, payload = null) {
      const htmlClassName = 'is-dark-mode-active'

      state.isDarkModeActive = !state.isDarkModeActive

      if (state.isDarkModeActive) {
        document.documentElement.classList.add(htmlClassName)
      } else {
        document.documentElement.classList.remove(htmlClassName)
      }
    },

    /* Full Page mode */
    fullPage (state, payload) {
      state.isNavBarVisible = !payload
      state.isAsideVisible = !payload
      state.isFooterBarVisible = !payload
    }
  },
  actions: {
    asideDesktopOnlyToggle (store, payload = null) {
      let method

      switch (payload) {
        case true:
          method = 'add'
          break
        case false:
          method = 'remove'
          break
        default:
          method = 'toggle'
      }
      document.documentElement.classList[method]('has-aside-desktop-only-visible')
    },
    toggleFullPage ({ commit }, payload) {
      commit('fullPage', payload)

      document.documentElement.classList[!payload ? 'add' : 'remove']('has-aside-left', 'has-navbar-fixed-top')
    }
  }
})
