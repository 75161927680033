import { initializeApp } from 'firebase/app'
import { getAuth, signInWithCustomToken } from 'firebase/auth'
import { collection, getDocs, getFirestore, onSnapshot, orderBy, query } from 'firebase/firestore'
import store from '@/store'

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: 'kang-go',
  storageBucket: 'kang-go.appspot.com',
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const firestore = getFirestore(app)

async function getDataMembers (topic) {
  try {
    if (!topic || typeof topic !== 'string') {
      throw new Error('Topic must be a valid string.')
    }

    const user = auth.currentUser

    if (!user) {
      const dataLocalStorage = JSON.parse(localStorage.getItem('webapp_shell_kanggo'))
      const firebaseToken = dataLocalStorage.user.firebase_token
      if (!firebaseToken || typeof firebaseToken !== 'string') {
        return {
          error: 'Invalid response data',
          data: firebaseToken
        }
      }
      const userCredential = await signInWithCustomToken(auth, firebaseToken)
      console.log('User Credential:', userCredential)
    }

    const memberGroups = {
      'https://shellb2b.devkanggo.com': 'chat_group_dev',
      'https://shellb2bnew.stagging.kanggo.id': 'chat_group_staging',
      'https://shellb2bnew-api.prodkanggo.com/api/': 'chat_group'
    }
    const memberGroup = memberGroups[window.location.origin] || 'chat_group_dev'
    const memberRef = collection(firestore, memberGroup, topic, 'members')
    const q = query(memberRef, orderBy('joined_at', 'asc'))
    const snapshots = await getDocs(q)

    const data = snapshots.docs.map(doc => ({ uid: doc.id, ...doc.data() }))

    onSnapshot(q, (querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === 'added') {
          const doc = change.doc
          const id = doc.get('id')
          const name = doc.get('name')
          // eslint-disable-next-line camelcase
          const joined_at = doc.get('joined_at')

          const obj = {
            id: doc.get('id'),
            image: doc.get('image'),
            joined_at: doc.get('joined_at'),
            is_deleted: doc.get('is_deleted'),
            name: doc.get('name'),
            phone: doc.get('phone'),
            role: doc.get('role')
          }

          if (typeof name === 'string') {
            store.commit('groupChat/setNewMember', { ...obj, id, name, joined_at })
          }
        }
      })
    })

    return {
      data: data,
      user: auth.currentUser
    }
  } catch (e) {
    console.error(e)
    return { error: e }
  }
}

export { getDataMembers }
