import { initializeApp } from 'firebase/app'
import { getAuth, signInWithCustomToken } from 'firebase/auth'
import { collection, getDocs, getFirestore, onSnapshot, orderBy, query } from 'firebase/firestore'
import store from '@/store'

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: 'kang-go',
  storageBucket: 'kang-go.appspot.com',
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const firestore = getFirestore(app)

async function getChatData (topic) {
  try {
    if (!topic || typeof topic !== 'string') {
      throw new Error('Topic must be a valid string.')
    }

    const user = auth.currentUser

    if (!user) {
      const dataLocalStorage = JSON.parse(localStorage.getItem('webapp_shell_kanggo'))
      const firebaseToken = dataLocalStorage.user.firebase_token
      if (!firebaseToken || typeof firebaseToken !== 'string') {
        return {
          error: 'Invalid response data',
          data: firebaseToken
        }
      }
      const userCredential = await signInWithCustomToken(auth, firebaseToken)
      console.log('User Credential:', userCredential)
    }

    const chatGroups = {
      'https://shellb2b.devkanggo.com': 'chat_group_dev',
      'https://shellb2bnew.stagging.kanggo.id': 'chat_group_staging',
      'https://shellb2bnew-api.prodkanggo.com/api/': 'chat_group'
    }
    // const chatGroup = 'chat_group_dev'
    const chatGroup = chatGroups[window.location.origin] || 'chat_group_dev'
    const docRef = collection(firestore, chatGroup, topic, 'chats')
    const q = query(docRef, orderBy('created_at', 'asc'))
    const snapshots = await getDocs(q)

    const data = []
    snapshots.docs.forEach((doc) => data.push({ id: doc.id, ...doc.data() }))

    // Listen to updates
    onSnapshot(q, (querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === 'added') {
          const doc = change.doc
          const sender = doc.get('sender_name')
          const message = doc.get('message')
          const image = doc.get('image_url')

          const obj = {
            message: doc.get('message'),
            image_url: doc.get('image_url'),
            created_at: doc.get('created_at'),
            sender_image: doc.get('sender_image'),
            sender_name: doc.get('sender_name'),
            sender_role: doc.get('sender_role'),
            status: doc.get('status'),
            sender_id: doc.get('sender_id'),
            id: doc.get('id')
          }

          if (typeof message === 'string') {
            store.commit('groupChat/setNewChat', { ...obj, sender })
          } else if (typeof image === 'string') {
            store.commit('groupChat/setNewChat', { ...obj })
          }
        }
      })
    })

    return {
      data: data,
      user: auth.currentUser
    }
  } catch (e) {
    console.error(e)
    return { error: e }
  }
}

export { getChatData }
