<template>
  <svg width="20" height="auto" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.913 0.658C5.98967 0.387333 8.102 0.251333 10.25 0.25C12.397 0.25 14.512 0.389 16.587 0.658C18.509 0.908 19.878 2.519 19.992 4.385C19.6577 4.2736 19.3112 4.20275 18.96 4.174C16.1581 3.94192 13.3419 3.94192 10.54 4.174C8.182 4.37 6.5 6.364 6.5 8.608V12.894C6.49906 13.7178 6.72577 14.5258 7.15512 15.2288C7.58446 15.9319 8.19973 16.5026 8.933 16.878L6.28 19.53C6.17511 19.6348 6.04153 19.7061 5.89614 19.735C5.75074 19.7638 5.60004 19.749 5.46308 19.6923C5.32611 19.6356 5.20903 19.5395 5.12661 19.4163C5.04419 19.2931 5.00013 19.1482 5 19V14.97C4.6372 14.9314 4.27485 14.8887 3.913 14.842C1.905 14.58 0.5 12.833 0.5 10.862V4.638C0.5 2.668 1.905 0.919 3.913 0.658Z"
      :fill="color"
    />
    <path
      d="M14.75 5.50001C13.3733 5.50001 12.0113 5.55634 10.664 5.66901C9.124 5.79701 8 7.10301 8 8.60901V12.894C8 14.401 9.128 15.708 10.67 15.834C11.9133 15.936 13.1693 15.991 14.438 15.999L17.22 18.78C17.3249 18.8848 17.4585 18.9561 17.6039 18.985C17.7493 19.0139 17.9 18.999 18.0369 18.9423C18.1739 18.8856 18.291 18.7896 18.3734 18.6663C18.4558 18.5431 18.4999 18.3983 18.5 18.25V15.86L18.83 15.834C20.372 15.709 21.5 14.401 21.5 12.894V8.60801C21.5 7.10301 20.375 5.79701 18.836 5.66801C17.4769 5.55518 16.1137 5.49913 14.75 5.50001Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'MessageGroupIcon',
  props: {
    color: {
      type: String,
      default: '#000000'
    }
  }
}
</script>
