<template>
  <svg :width="size" :height="size" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.2"
      d="M11.6999 4.5H15.2999C15.5386 4.5 15.7675 4.59482 15.9363 4.7636C16.1051 4.93239 16.1999 5.16131 16.1999 5.4V9C16.1999 9.2387 16.1051 9.46761 15.9363 9.6364C15.7675 9.80518 15.5386 9.9 15.2999 9.9H11.6999C11.4612 9.9 11.2323 9.80518 11.0635 9.6364C10.8947 9.46761 10.7999 9.2387 10.7999 9V5.4C10.7999 5.16131 10.8947 4.93239 11.0635 4.7636C11.2323 4.59482 11.4612 4.5 11.6999 4.5ZM6.2999 9.9H9.8999C10.1386 9.9 10.3675 9.99482 10.5363 10.1636C10.7051 10.3324 10.7999 10.5613 10.7999 10.8V14.4C10.7999 14.6387 10.7051 14.8676 10.5363 15.0364C10.3675 15.2052 10.1386 15.3 9.8999 15.3H6.2999C6.06121 15.3 5.83229 15.2052 5.66351 15.0364C5.49472 14.8676 5.3999 14.6387 5.3999 14.4V10.8C5.3999 10.5613 5.49472 10.3324 5.66351 10.1636C5.83229 9.99482 6.06121 9.9 6.2999 9.9Z"
      fill="white"
    />
    <path
      d="M10.2185 8.41861C10.134 8.50311 10.0194 8.55058 9.89988 8.55058C9.78039 8.55058 9.66578 8.50311 9.58128 8.41861C9.49679 8.33411 9.44932 8.21951 9.44932 8.10001C9.44932 7.98051 9.49679 7.8659 9.58128 7.78141L13.1813 4.18141C13.2231 4.13957 13.2728 4.10638 13.3275 4.08374C13.3821 4.06109 13.4407 4.04944 13.4999 4.04944C13.5591 4.04944 13.6176 4.06109 13.6723 4.08374C13.727 4.10638 13.7766 4.13957 13.8185 4.18141C13.8603 4.22325 13.8935 4.27292 13.9162 4.32758C13.9388 4.38225 13.9505 4.44084 13.9505 4.50001C13.9505 4.55918 13.9388 4.61777 13.9162 4.67243C13.8935 4.7271 13.8603 4.77677 13.8185 4.81861L10.2185 8.41861ZM4.81849 13.8186C4.77665 13.8604 4.72698 13.8936 4.67231 13.9163C4.61764 13.9389 4.55905 13.9506 4.49989 13.9506C4.44072 13.9506 4.38213 13.9389 4.32746 13.9163C4.27279 13.8936 4.22312 13.8604 4.18128 13.8186C4.13945 13.7768 4.10626 13.7271 4.08361 13.6724C4.06097 13.6178 4.04932 13.5592 4.04932 13.5C4.04932 13.4408 4.06097 13.3822 4.08361 13.3276C4.10626 13.2729 4.13945 13.2232 4.18128 13.1814L7.78128 9.58141C7.86578 9.49691 7.98039 9.44944 8.09988 9.44944C8.21938 9.44944 8.33399 9.49691 8.41849 9.58141C8.50298 9.6659 8.55045 9.78051 8.55045 9.90001C8.55045 10.0195 8.50298 10.1341 8.41849 10.2186L4.81849 13.8186Z"
      fill="white"
    />
    <path
      d="M4.4998 13.95C4.38046 13.95 4.266 13.9026 4.18161 13.8182C4.09722 13.7339 4.0498 13.6194 4.0498 13.5C4.0498 13.3807 4.09722 13.2662 4.18161 13.1819C4.266 13.0975 4.38046 13.05 4.4998 13.05H8.0998C8.21915 13.05 8.33361 13.0975 8.418 13.1819C8.50239 13.2662 8.5498 13.3807 8.5498 13.5C8.5498 13.6194 8.50239 13.7339 8.418 13.8182C8.33361 13.9026 8.21915 13.95 8.0998 13.95H4.4998Z"
      fill="white"
    />
    <path
      d="M4.95005 13.5C4.95005 13.6194 4.90264 13.7339 4.81825 13.8182C4.73386 13.9026 4.6194 13.95 4.50005 13.95C4.3807 13.95 4.26624 13.9026 4.18185 13.8182C4.09746 13.7339 4.05005 13.6194 4.05005 13.5V9.90005C4.05005 9.7807 4.09746 9.66624 4.18185 9.58185C4.26624 9.49746 4.3807 9.45005 4.50005 9.45005C4.6194 9.45005 4.73386 9.49746 4.81825 9.58185C4.90264 9.66624 4.95005 9.7807 4.95005 9.90005V13.5ZM13.95 8.10005C13.95 8.2194 13.9026 8.33386 13.8182 8.41825C13.7339 8.50264 13.6194 8.55005 13.5 8.55005C13.3807 8.55005 13.2662 8.50264 13.1819 8.41825C13.0975 8.33386 13.05 8.2194 13.05 8.10005V4.50005C13.05 4.3807 13.0975 4.26624 13.1819 4.18185C13.2662 4.09746 13.3807 4.05005 13.5 4.05005C13.6194 4.05005 13.7339 4.09746 13.8182 4.18185C13.9026 4.26624 13.95 4.3807 13.95 4.50005V8.10005Z"
      fill="white"
    />
    <path
      d="M9.89995 4.95005C9.7806 4.95005 9.66614 4.90264 9.58175 4.81825C9.49736 4.73386 9.44995 4.6194 9.44995 4.50005C9.44995 4.3807 9.49736 4.26624 9.58175 4.18185C9.66614 4.09746 9.7806 4.05005 9.89995 4.05005H13.5C13.6193 4.05005 13.7338 4.09746 13.8181 4.18185C13.9025 4.26624 13.95 4.3807 13.95 4.50005C13.95 4.6194 13.9025 4.73386 13.8181 4.81825C13.7338 4.90264 13.6193 4.95005 13.5 4.95005H9.89995Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'ResizeArrowIcon',
  props: {
    size: {
      type: String,
      default: '25'
    }
  }
}
</script>
