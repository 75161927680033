<template>
  <svg width="22" height="auto" viewBox="0 0 51 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_7781_125496"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="4"
      y="20"
      width="43"
      height="22"
    >
      <path
        d="M26.4844 20.8008C20.8531 20.8008 15.7531 23.3253 11.8219 27.4308L7.80563 22.6113C6.46688 21.0048 4.17188 22.1268 4.17188 24.3963V38.6508C4.17188 40.0533 5.12813 41.2008 6.29688 41.2008H18.1756C20.0669 41.2008 21.0231 38.4468 19.6844 36.8403L15.6256 31.9698C18.5794 29.0118 22.3406 27.1758 26.5056 27.1758C33.2206 27.1758 39.0219 31.8678 41.7844 38.6508C42.3581 40.0788 43.7181 40.7928 44.9719 40.2828C46.4806 39.6963 47.2456 37.6308 46.5656 35.8968C42.9106 26.9718 35.3031 20.8008 26.4844 20.8008Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0_7781_125496)">
      <rect y="0.398438" width="51" height="61.2" fill="#E10009" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'UndoIcon'
}
</script>
