<template>
  <div class="is-relative complete-offering-wrapper">
    <div v-if="!step2" class="bg-purple-gradient-1 flex-center-vertical revisi-wrapper px-6">
      <EditIcon color="#000" />
      <p class="averta-black label-16 ml-3">Revisi Harga Penawaran</p>
    </div>

    <div
      v-if="step2"
      class="notification is-success is-light has-text-dark preview-notif-wrapper flex-center-vertical mb-0 py-3 c-w-100 is-justify-content-space-between is-align-items-center"
      :style="{
        top: `${heightNavbar}px`
      }"
    >
      <div
        class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-50"
      >
        <p class="averta-black label-20">Preview Penawaran</p>
        <p class="averta-regular-italic ml-1">
          (Periksa kembali penawaran sebelum dikirim ke client)
        </p>
      </div>
      <div
        style="gap: 1rem"
        class="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center c-w-50"
      >
        <div
          v-if="currentStep > 1"
          class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center is-clickable text-red averta-bold mr-4"
          @click="previousStep(currentStep)"
        >
          <ArrowLeftIcon color="red" class="mr-2" />
          Sebelumnya
        </div>
        <button
          v-if="step2"
          @click="isSMRC ? handleOpenModalOffering(true) : previewOffering()"
          class="btn-download-offering is-flex is-align-items-center is-clickable"
        >
          <DocumentPappersIcon color="#D9272D" class="mr-2" />
          <p class="averta-bold">Preview Penawaran</p>
        </button>
        <button
          v-if="step2 && currentPath === 'complete-offering'"
          class="btn-kirim is-flex is-align-items-center is-clickable"
          @click="postPenawaran()"
        >
          <p class="mr-2">Kirim</p>
          <CheckIcon />
        </button>
      </div>
    </div>

    <ModalPreviewOffering
      :dataPreview="dataPreview"
      :isHandleOpenModalOffering="isHandleOpenModalOffering"
      @close="isHandleOpenModalOffering = false"
    />

    <div class="c-w-100 min-h-80vh pt-5">
      <div class="is-flex is-align-items-center is-justify-content-space-between px-6 mb-5 mt-3">
        <div class="is-flex is-align-items-center is-clickable" @click="backToDetail()">
          <ArrowLeftIcon class="mr-3" />
          <p class="averta-bold is-size-5">Kembali ke Penawaran</p>
        </div>

        <div class="is-flex is-align-items-center">
          <!-- <span v-if="currentStep > 1" @click="previousStep(currentStep)" class="is-clickable text-red averta-bold mr-4">
            Sebelumnya
          </span> -->

          <div class="is-flex gap-3" v-if="step1 && currentPath === 'complete-offering'">
            <button @click="showHideSidebar()" class="is-flex is-align-items-center is-clickable mr-3 btn-update py-3 px-5">
              <p class="averta-bold">Edit Item Pekerjaan</p>
            </button>
            <ButtonApp
              height="41px"
              :isDisabled="disableButton"
              @click="nextStep(currentStep)"
            >
              <div class="flex-center">
                <CheckIcon class="mr-2" />
                <p class="averta-bold">Preview Penawaran</p>
              </div>
            </ButtonApp>
          </div>

          <!-- <button v-if="currentStep === 2 && currentPath === 'complete-offering'" class="btn-kirim is-flex is-align-items-center is-clickable" @click="postPenawaran()">
            <p class="mr-2">Kirim</p>
            <CheckIcon />
          </button> -->

          <div
            class="is-flex is-align-items-center"
            v-if="(step1 || stepDetailPrice) && currentPath === 'update-price'"
          >
            <ButtonApp
              v-if="step1"
              height="41px"
              class="mr-4"
              :isSecondary="true"
              @click="showHideSidebar"
            >
              <p class="averta-bold">Edit Item Pekerjaan</p>
            </ButtonApp>

            <ButtonApp
              v-if="stepDetailPrice"
              height="41px"
              class="mr-4"
              :isSecondary="true"
              @click="previousStep"
            >
              <div class="flex-center">
                <ArrowLeftIcon color="#E10009" class="mr-2" />
                <p class="averta-bold">Sebelumnya</p>
              </div>
            </ButtonApp>

            <ButtonApp
              v-if="step1"
              height="40px"
              class="mr-4"
              :isDisabled="disableButton"
              @click="nextStep(currentStep)"
            >
              <div class="flex-center">
                <p class="mr-2 averta-bold">Lanjut ke Rincian Harga</p>
                <ArrowRightIcon color="#fff" />
              </div>
            </ButtonApp>

            <ButtonApp
              v-if="stepDetailPrice"
              height="41px"
              :isDisabled="disableButton"
              @click="nextStep(currentStep)"
            >
              <div class="flex-center">
                <CheckIcon class="mr-2" />
                <p class="averta-bold">Preview Penawaran</p>
              </div>
            </ButtonApp>

          </div>

          <button
            v-if="step2 && currentPath === 'update-price'"
            class="btn-kirim is-flex is-align-items-center is-clickable"
            @click="sendRejectOffering"
          >
            <p class="mr-2">Kirim</p>
            <CheckIcon />
          </button>
        </div>
      </div>

      <div class="notification is-light is-danger mx-6" v-if="message !== ''">
        <p>{{ message }}</p>
      </div>

      <div class="notification is-light is-danger mx-6" v-if="message_alfa !== ''">
        <button class="delete" @click="closeNotification"></button>
        <div class="content">
          <p class="label-16 averta-bold">{{ message_alfa.data.message }}</p>
          <ul>
            <li>
              <span class="averta-bold">{{ message_alfa.data.data.item_name }}</span>
              <ul>
                <li v-if="message_alfa.data.data.area_code !== null">
                  Area Code: {{ message_alfa.data.data.area_code }}
                </li>
                <li v-if="message_alfa.data.data.kanggo_price !== null">
                  Kanggo: {{ formatThousand(message_alfa.data.data.kanggo_price) }}
                </li>
                <li v-if="message_alfa.data.data.alfamart_price !== null">
                  Alfamart: {{ formatThousand(message_alfa.data.data.alfamart_price) }}
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <!-- Jenis Proyek -->
      <div class="mb-5 px-6 is-relative" v-if="currentStep === 1 && typeClient.list.length > 0">
        <p class="averta-black label-18 mb-3">
          Pilih Jenis Proyek <span class="text-merah">*</span>
        </p>
        <!-- <div class="is-flex is-justify-content-space-between">
        <div v-for="type, index in typeClient.list" :key="index"
            class="wrap-type is-flex is-align-items-center"
            :class="{
              'is-active': selectTypeClient === type,
              'is-disabled': !typeClient.editable && !type.selected
            }"
        >
          <input type="radio"
            name="typeClient"
            class="mr-3 is-clickable"
            :id="type.code"
            :value="type"
            v-model="selectTypeClient"
            :disabled="!typeClient.editable && !type.selected"
            @change="disableButtonFunc"
          >
          <div>
            <p class="averta-black label-14">{{ type.name }}</p>
            <p>{{ type.description }}</p>
          </div>
        </div>
      </div> -->
        <div class="type-dropdown" :class="{ 'is-disabled': currentPath === 'update-price' }">
          <div class="is-flex is-align-items-center is-justify-content-space-between">
            <div class="p-2">
              <p class="text-merah" v-if="selectTypeClient === null">
                <i>Belum ditentukan</i>
              </p>
              <div v-else>
                <div class="is-flex is-align-items-center">
                  <p class="averta-black label-14">{{ selectTypeClient.name }}</p>
                  <CheckIcon color="#10BD41" size="16" class="ml-2" />
                  <p class="averta-bold-italic label-14 color-green ml-1"><i>Saved</i></p>
                </div>
                <p class="color-gray">({{ selectTypeClient.description }})</p>
              </div>
            </div>
            <div
              v-if="currentPath !== 'update-price'"
              class="btn-dropdown is-flex is-align-items-center is-clickable"
              @click="dropdownTypeFunc"
            >
              <p class="averta-bold">{{ conditionalWordingDropdown }}</p>
              <ArrowFoward class="ml-3 arrow-icon" :class="{ 'is-rotate': showDropdownType }" />
            </div>
          </div>
          <div v-if="showDropdownType" class="mt-3 border-top">
            <div
              v-for="(type, index) in typeClient.list"
              :key="index"
              class="list-type mb-3 px-2 py-3 is-clickable"
              :class="{ 'mt-3': index === 0 }"
              @click="selectTypeClientFunc(type)"
            >
              <div class="is-flex is-align-items-center">
                <p class="averta-black label-14">{{ type.name }}</p>
                <div v-if="selectTypeClient === type" class="is-flex is-align-items-center">
                  <CheckIcon color="#10BD41" size="16" class="ml-2" />
                  <p class="averta-bold-italic label-14 color-green ml-1"><i>Saved</i></p>
                </div>
              </div>
              <p class="color-gray label-14">{{ type.description }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Jenis Proyek -->

      <div class="divider" v-if="currentStep === 1 && typeClient.list.length > 0"></div>

      <CompleteOffering
        class="px-6"
        @postLengkapiPenawaran="lengkapiPenawaran($event)"
        v-if="currentStep === 1 && !isLoading"
        :isFilled="isFilled"
      />
      <PreviewCompleteOffering
        class="px-6"
        v-if="currentStep === 2"
        @handleDataPreview="handleDataPreview"
        :isProjectUnitPrice="isProjectUnitPrice"
      />
      <DetailPriceOffering
        v-if="currentStep === 'detail-price'"
        @changeDiscount="changeDiscount"
      />

      <b-sidebar
        :can-cancel="false"
        type="is-light"
        :fullheight="true"
        :fullwidth="true"
        :overlay="true"
        :right="true"
        v-model="showSidebar"
      >
        <div v-if="!isLoading && sidebarMounted">
          <NewOffering
            @showHideSidebar="($event) => showHideSidebar($event)"
            v-if="currentPath === 'update-price' || currentPath === 'complete-offering'"
          />
        </div>
      </b-sidebar>

      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false">
        <SpinnerIcon />
      </b-loading>

      <!-- MODAL REVISI OFFERING -->
      <ModalConfirm
        :showModal="showModalRevisi"
        imageProps="delete-area-component.png"
        titleProps="Kirim Revisi Penawaran"
        descProps="Pastikan seluruh informasi yang dimasukan telah benar dan sesuai."
        imageClass="mb-3"
      >
        <template v-slot:additionalDesc v-if="updatedItemPekerjaan || discount">
          <div class="p-3 bg-lightgray border-radius-20 has-text-left mt-2">
            <p class="averta-bold mb-1">Perubahan meliputi, sbb:</p>
            <p v-if="updatedItemPekerjaan">1. Perubahan Harga Satuan Item Pekerjaan</p>
            <p v-if="discount">{{updatedItemPekerjaan ? '2' : '1'}}. Penambahan Discount</p>
          </div>
        </template>
        <template v-slot:button>
          <div class="is-flex is-align-items-center">
            <ButtonApp
              :isSecondary="true"
              :is-bold="true"
              width="185px"
              height="52px"
              class="mr-3"
              @click="cancelRejectOffering"
            >
              Cek Kembali
            </ButtonApp>
            <ButtonApp :is-bold="true" width="185px" height="52px" @click="rejectOffering">
              Simpan
            </ButtonApp>
          </div>
        </template>
      </ModalConfirm>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatCurrency, alertErrorMessage, isSMRC, toastMessage } from '@/helpers'
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CheckIcon,
  SpinnerIcon,
  ArrowFoward,
  DocumentPappersIcon,
  EditIcon
} from '@/components/icons'

import ModalPreviewOffering from '@/components/modal/ModalPreviewOffering.vue'
import CompleteOffering from './CompleteOffering.vue'
import PreviewCompleteOffering from './PreviewCompleteOffering.vue'
// import confirmOfferingImage from '@/assets/img/delete-area-component.png'
import confirmOfferingImage from '@/assets/img/image-button-send-offering.png'
import successCreateImage from '../../../../../assets/img/survey-selesai.png'
import discardImage from '../../../../../assets/img/discard-data.png'
import NewOffering from '../../../project/NewOffering.vue'

export default {
  name: 'LengkapiPenawaran',
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      dataPenawaran: 'project/getDataDetailPenawaran',
      heightNavbar: 'dashboard/getNavbarHeight',
      updatedItemPekerjaan: 'project/getUpdatedItemPekerjaan'
    }),
    currentPath () {
      const path = this.$route.path.split('/')
      const lastElement = path[path.length - 1]

      return lastElement
    },
    conditionalWordingDropdown () {
      if (this.showDropdownType) {
        return 'Tutup Pilihan'
      } else {
        if (this.selectTypeClient === null) {
          return 'Pilih Jenis'
        } else {
          return 'Ubah Jenis'
        }
      }
    },
    isSMRC () {
      return isSMRC(this.dataPreview.vendor_code || '')
    },
    step1 () {
      return this.currentStep === 1
    },
    step2 () {
      return this.currentStep === 2
    },
    stepDetailPrice () {
      return this.currentStep === 'detail-price'
    }
  },
  components: {
    ArrowLeftIcon,
    ArrowRightIcon,
    CheckIcon,
    CompleteOffering,
    PreviewCompleteOffering,
    SpinnerIcon,
    NewOffering,
    ArrowFoward,
    EditIcon,
    DocumentPappersIcon,
    ModalPreviewOffering,
    DetailPriceOffering: () => import('./DetailPriceOffering.vue'),
    ButtonApp: () => import('@/components/button/ButtonApp.vue'),
    ModalConfirm: () => import('../../vendor/components/ModalConfirm.vue')
  },
  data () {
    return {
      currentStep: 1,
      // currentStep: 'detail-price',
      dataLengkapiPenawaran: null,
      message: '',
      message_alfa: '',
      isLoading: false,
      showSidebar: false,
      showModalRevisi: false,
      typeClient: {
        list: [],
        editable: false
      },
      selectTypeClient: null,
      disableButton: false,
      sidebarMounted: false,
      surveyIdFromOffering: null,
      showDropdownType: false,
      isProjectUnitPrice: false,
      dataPreview: null,
      isHandleOpenModalOffering: false,
      isFilled: false,

      discount: 0
    }
  },
  methods: {
    formatThousand (x) {
      return formatCurrency(x)
    },
    backToDetail () {
      localStorage.removeItem(`stepData-${this.$route.params.surveyId}`)
      if (this.currentPath === 'update-price') {
        this.$router.push(`/am/project/survey/${this.dataPenawaran.survey_id}/detail`)
      } else {
        this.$router.push(`/am/project/survey/${this.$route.params.surveyId}/detail`)
      }
      this.$store.commit('project/setUpdatedItemPekerjaan', false)
    },
    previousStep (currentStep) {
      if (this.stepDetailPrice) {
        this.currentStep = 1
      } else if (this.step2) {
        if (this.currentPath === 'complete-offering') {
          this.currentStep = 1
        } else {
          this.currentStep = 'detail-price'
        }
      } else {
        this.currentStep = currentStep - 1
      }
    },
    nextStep (currentStep) {
      if (this.currentPath === 'complete-offering') {
        if (this.step1) {
          if (this.dataLengkapiPenawaran === null) {
            this.message = 'Pastikan harga tukang dan material sudah terisi dengan benar'
          } else {
            this.postLengkapiPenawaran()
          }
        }
      } else {
        if (this.step1) {
          this.updateHargaRejectPenawaran()
        } else if (this.stepDetailPrice) {
          this.discountOffering()
        } else {
          this.currentStep = 2
        }
      }
    },
    lengkapiPenawaran (props) {
      this.dataLengkapiPenawaran = props.data || props
      if (props.is_use_unit_price) {
        this.isProjectUnitPrice = true
      } else {
        this.isProjectUnitPrice = false
        this.disableButtonFunc()
      }
    },
    async postLengkapiPenawaran () {
      let validate
      if (!this.isProjectUnitPrice) {
        this.dataLengkapiPenawaran.forEach((area) => {
          area.components.forEach((components) => {
            components.detail_components.forEach((detailComponent) => {
              detailComponent.item.forEach((item) => {
                item.workers.forEach((worker) => {
                  if (
                    worker.unit_price < 0 ||
                    worker.unit_price === '' ||
                    worker.unit_price === null
                  ) {
                    validate = 1
                  }
                })
                item.materials.forEach((material) => {
                  if (
                    material.unit_price < 0 ||
                    material.unit_price === '' ||
                    material.unit_price === null
                  ) {
                    validate = 1
                  }
                })
              })
            })
          })
        })
      }

      if (validate === 1) {
        this.message = 'Pastikan harga tukang dan material sudah terisi dengan benar'
        return validate
      } else {
        this.message = ''

        // Change selected type client to true when selected
        this.selectTypeClient.selected = true

        const payload = {
          url: this.url,
          survey_id: this.$route.params.surveyId,
          data: this.dataLengkapiPenawaran,
          client_type: this.selectTypeClient
        }

        if (this.isProjectUnitPrice) {
          payload.is_use_unit_price = true
        }

        if (!this.isProjectUnitPrice) {
          await this.dataLengkapiPenawaran.forEach((area) => {
            area.components.forEach((components) => {
              components.detail_components.forEach((detailComponent) => {
                detailComponent.item.forEach((item) => {
                  item.workers.forEach((worker) => {
                    worker.unit_price = +String(worker.unit_price).replace(/,/g, '') || 0
                  })
                  item.materials.forEach((material) => {
                    material.unit_price = +String(material.unit_price).replace(/,/g, '') || 0
                  })
                })
              })
            })
          })
        }

        this.isLoading = true
        this.$store
          .dispatch('project/lengkapiPenawaran', payload)
          .then((response) => {
            this.currentStep = 2
            this.isLoading = false
          })
          .catch((error) => {
            this.isLoading = false
            alertErrorMessage(error)
          })
      }
    },
    postPenawaran () {
      this.$swal({
        imageUrl: confirmOfferingImage,
        imageAlt: 'confirm offering',
        width: '600px',
        padding: '10px 10px 30px 10px',
        title: '<p class="averta-bold label-22">Lanjut Proses Penawaran?</p>',
        html: '<p class="averta-regular">Pastikan kamu sudah cek kembali penawaran ini sebelum di proses</p>',
        showCancelButton: true,
        confirmButtonText: 'Kirim Penawaran',
        cancelButtonText: 'Cek Kembali',
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const payload = {
            url: this.url,
            survey_id: this.$route.params.surveyId
          }

          this.isLoading = true
          this.$store
            .dispatch('project/postPenawaran', payload)
            .then((response) => {
              this.isLoading = false
              this.backToDetail()
              this.$swal({
                width: '600px',
                padding: '10px 10px 30px 10px',
                html: `
                  <img src="${successCreateImage}" class="img-create-penawaran mb-4" />
                  <p class="averta-bold mb-4 label-22">Penawaran berhasil dibuat</p>
                  <p>Penawaran telah berhasil dibuat dan terkirim ke client. Mohon tunggu respon dari client untuk melanjutkan pemesanan</p>
                `,
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonText: 'Lihat Detail Proyek',
                cancelButtonText: 'Lihat Daftar Proyek',
                customClass: {
                  confirmButton: 'btn-hapus-detail-component',
                  cancelButton: 'btn-batal-detail-component'
                }
              }).then((result) => {
                this.isLoading = false
                if (result.isConfirmed) {
                  this.backToDetail()
                } else {
                  this.$router.push('/am/project')
                }
              })
            })
            .catch((error) => {
              this.isLoading = false
              const dataError = error.response
              if (dataError) {
                this.message_alfa = dataError
              }
              alertErrorMessage(error)
            })
        }
      })
    },
    async updateHargaRejectPenawaran () {
      let validate
      this.dataLengkapiPenawaran.forEach((area) => {
        area.components.forEach((components) => {
          components.detail_components.forEach((detailComponent) => {
            detailComponent.item.forEach((item) => {
              item.workers.forEach((worker) => {
                if (
                  worker.unit_price < 0 ||
                  worker.unit_price === '' ||
                  worker.unit_price === null
                ) {
                  validate = 1
                }
              })
              item.materials.forEach((material) => {
                if (
                  material.unit_price < 0 ||
                  material.unit_price === '' ||
                  material.unit_price === null
                ) {
                  validate = 1
                }
              })
            })
          })
        })
      })

      if (validate === 1) {
        this.message = 'Pastikan harga tukang dan material sudah terisi dengan benar'
        return validate
      } else {
        await this.dataLengkapiPenawaran.forEach((area) => {
          area.components.forEach((components) => {
            components.detail_components.forEach((detailComponent) => {
              detailComponent.item.forEach((item) => {
                item.workers.forEach((worker) => {
                  worker.unit_price = +String(worker.unit_price).replace(/,/g, '') || 0
                })
                item.materials.forEach((material) => {
                  material.unit_price = +String(material.unit_price).replace(/,/g, '') || 0
                })
              })
            })
          })
        })

        this.$swal({
          imageUrl: confirmOfferingImage,
          imageAlt: 'confirm offering',
          width: '600px',
          padding: '10px 10px 30px 10px',
          title: '<p class="averta-bold label-22">Lanjut Proses Penawaran Ini?</p>',
          html: '<p class="averta-regular">Pastikan kamu sudah cek kembali penawaran ini sebelum di proses</p>',
          showCancelButton: true,
          confirmButtonText: 'Proses',
          cancelButtonText: 'Cek Kembali',
          reverseButtons: true,
          customClass: {
            confirmButton: 'btn-hapus-detail-component',
            cancelButton: 'btn-batal-detail-component'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            // Change selected type client to true when selected
            // this.selectTypeClient.selected = true

            const payload = {
              url: this.url,
              offering_id: this.$route.params.offeringId,
              data: this.dataLengkapiPenawaran
              // client_type: this.selectTypeClient
            }

            this.isLoading = true
            this.$store
              .dispatch('project/updateHargaSatuanPenawaranV2', payload)
              .then((response) => {
                this.isLoading = false
                if (this.currentStep === 1) {
                  this.currentStep = 'detail-price'
                } else {
                  this.currentStep = 2
                }
              })
              .catch((error) => {
                this.isLoading = false
                if (error.response.data.code === 0) {
                  this.message = error.response.data.message
                }
                alertErrorMessage(error)
              })
          } else {
            this.dataLengkapiPenawaran.forEach((area) => {
              area.components.forEach((components) => {
                components.detail_components.forEach((detailComponent) => {
                  detailComponent.item.forEach((item) => {
                    item.workers.forEach((worker) => {
                      worker.unit_price = this.formatPrice(worker.unit_price)
                    })
                    item.materials.forEach((material) => {
                      material.unit_price = this.formatPrice(material.unit_price)
                    })
                  })
                })
              })
            })
          }
        })
      }
    },
    sendRejectOffering () {
      this.showModalRevisi = !this.showModalRevisi
    },
    cancelRejectOffering () {
      this.showModalRevisi = false
    },
    updateRejectPenawaran () {
      this.$swal({
        imageUrl: confirmOfferingImage,
        imageAlt: 'confirm offering',
        width: '600px',
        padding: '10px 10px 30px 10px',
        title: '<p class="averta-bold label-22">Lanjut Proses Penawaran Ini?</p>',
        html: '<p class="averta-regular">Pastikan kamu sudah cek kembali penawaran ini sebelum di proses</p>',
        showCancelButton: true,
        confirmButtonText: 'Proses',
        cancelButtonText: 'Cek Kembali',
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.rejectOffering()
        }
      })
    },
    rejectOffering () {
      const payload = {
        url: this.url,
        offering_id: this.$route.params.offeringId
      }

      this.isLoading = true
      this.$store
        .dispatch('projectV2/updateRejectPenawaran', payload)
        .then(() => {
          this.isLoading = false
          this.backToDetail()
          toastMessage('Revisi Penawaran Berhasil Dikirimkan', 'is-dark')
          this.$store.commit('project/setUpdatedItemPekerjaan', false)
          // this.$swal({
          //   width: '600px',
          //   padding: '10px 10px 30px 10px',
          //   html: `
          //         <img src="${successCreateImage}" class="img-create-penawaran mb-4" />
          //         <p class="averta-bold mb-4 label-22">Penawaran berhasil dibuat</p>
          //         <p>Penawaran telah berhasil dibuat dan terkirim ke client. Mohon tunggu respon dari client untuk melanjutkan pemesanan</p>
          //       `,
          //   showCancelButton: true,
          //   confirmButtonText: 'Lihat Detail',
          //   cancelButtonText: 'Lihat Daftar Proyek',
          //   customClass: {
          //     confirmButton: 'btn-hapus-detail-component',
          //     cancelButton: 'btn-batal-detail-component'
          //   }
          // }).then((result) => {
          //   this.isLoading = false
          //   if (result.isConfirmed) {
          //     this.backToDetail()
          //   } else {
          //     this.$router.push('/am/project')
          //   }
          // })
        })
        .catch((error) => {
          this.isLoading = false
          const dataError = error.response
          if (dataError) {
            this.message_alfa = dataError
          }
          alertErrorMessage(error)
        })
    },
    closeNotification () {
      this.message_alfa = ''
    },
    formatPrice (price) {
      if (price !== null) {
        price = price.toString().replace(/\D/g, '')
        const formattedPrice = Number(price).toLocaleString('en-US')
        return formattedPrice
      }
    },
    showHideSidebar (event) {
      if (event === 'success-update-item' || event === 'cancel-item' || event === 'complete-offering') {
        this.getDetailPenawaran()
      } else {
        const temp = []
        this.dataLengkapiPenawaran.map(area => {
          area.components.map(component => {
            component.detail_components.map(detailComponent => {
              detailComponent.item.map((item, indexItem) => {
                temp.push({
                  area_id: area.area_id,
                  component_id: component.component_id,
                  detail_component_id: detailComponent.id,
                  indexItem: indexItem,
                  note_item_working: item.note_item_working,
                  workers: item.workers,
                  materials: item.materials
                  // Kurang Unit Price
                })
              })
            })
          })
        })
        localStorage.setItem(`preview_offering_${this.$route.params.offeringId}`, JSON.stringify(temp))
      }
      this.showSidebar = !this.showSidebar
    },
    getDetailPenawaran () {
      if (this.$route.params.offeringId) {
        this.isLoading = true
        const payload = {
          url: this.url,
          offering_id: this.$route.params.offeringId
        }

        this.$store
          .dispatch('project/getDetailPenawaran', payload)
          .then((response) => {
            this.$store.commit('project/setDataUpdateItemPekerjaanBS', {
              surveyId: response.data.data.survey_id,
              Id: response.data.data.id
            })
            this.surveyIdFromOffering = response.data.data.survey_id
            this.getOfferingClientType()
            this.sidebarMounted = true
            this.isLoading = false
          })
          .catch((error) => {
            this.isLoading = false
            alertErrorMessage(error)
          })
      }
    },
    getOfferingClientType () {
      let surveyId = null

      if (this.currentPath === 'complete-offering') {
        surveyId = this.$route.params.surveyId
      } else {
        surveyId = this.surveyIdFromOffering
      }

      const payload = {
        url: this.url,
        survey_id: surveyId
      }

      this.isLoading = true

      this.$store
        .dispatch('project/getOfferingClientType', payload)
        .then((response) => {
          this.typeClient = response.data.data
          const selectedType = response.data.data.list.find((item) => item.selected)
          if (selectedType) {
            this.selectTypeClient = selectedType
          }
          this.disableButtonFunc('initTypeClient')

          // window.scrollTo({
          //   top: 0,
          //   behavior: 'smooth'
          // })
          this.isLoading = false
        })
        .catch((error) => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    disableButtonFunc (type) {
      let validateData = false
      let validateTypeClient = false
      if (type !== 'initTypeClient') {
        this.dataLengkapiPenawaran.forEach((area) => {
          area.components.forEach((components) => {
            components.detail_components.forEach((detailComponent) => {
              detailComponent.item.forEach((item) => {
                item.workers.forEach((worker) => {
                  if (
                    worker.unit_price < 0 ||
                    worker.unit_price === '' ||
                    worker.unit_price === null
                  ) {
                    validateData = true
                  }
                })
                item.materials.forEach((material) => {
                  if (
                    material.unit_price < 0 ||
                    material.unit_price === '' ||
                    material.unit_price === null
                  ) {
                    validateData = true
                  }
                })
              })
            })
          })
        })
      }

      if (this.typeClient.list.length < 1) {
        // Kondisi untuk data lama yang tidak ada tipe client dan penawarannya direject
        validateTypeClient = false
      } else if (this.selectTypeClient === null) {
        // Kondisi jika tipe client belum dipilih
        validateTypeClient = true
      }

      if (validateData === true || validateTypeClient === true) {
        this.disableButton = true
      } else {
        this.disableButton = false
      }
    },
    dropdownTypeFunc () {
      this.showDropdownType = !this.showDropdownType
    },
    selectTypeClientFunc (type) {
      this.selectTypeClient = type
      this.showDropdownType = false
      this.disableButtonFunc()
    },
    handleOpenModalOffering (value) {
      this.isHandleOpenModalOffering = value
    },
    previewOffering () {
      const path = `/am/offering-project/${this.dataPreview.offering_id}`
      const query = { preview: true }
      const routeData = this.$router.resolve({ path, query })
      window.open(routeData.href, '_blank')
    },
    handleDataPreview (data) {
      this.dataPreview = data
    },
    changeDiscount (props) {
      const { empty, discount } = props
      this.disableButton = empty
      this.discount = discount
    },
    discountOffering () {
      this.isLoading = true
      const payload = {
        url: this.url,
        offering_id: this.$route.params.offeringId,
        discount: Number(this.discount) || 0
      }
      this.$store
        .dispatch('projectV2/discountRejectOffering', payload)
        .then(() => {
          this.currentStep = 2
          this.isLoading = false
        })
        .catch((error) => {
          this.isLoading = false
          if (error.response.data.code === 0) {
            this.message = error.response.data.message
          }
          alertErrorMessage(error)
        })
    }
  },
  async created () {
    await this.getDetailPenawaran()
    if (this.currentPath === 'complete-offering') {
      this.getOfferingClientType()
    }
    if (this.currentPath === 'update-price') {
      this.$store.commit('project/setDataUpdateItemPekerjaanBS', {
        isUpdate: true
      })
    }
    //  else {
    //   this.$store.commit('project/setDataUpdateItemPekerjaanBS', {
    //     clearData: true
    //   })
    // }
  },
  beforeRouteLeave (to, from, next) {
    if (this.showSidebar === true && this.currentStep !== 5 && !this.isBS && this.currentPath !== 'update-price' && this.currentPath !== 'complete-offering') {
      next(false)
      this.$swal({
        width: '600px',
        padding: '10px 10px 30px 10px',
        html: `
          <img src="${discardImage}" class="img-create-penawaran mb-4" />
          <p class="averta-bold mb-4">Keluar Dari Halaman Ini?</p>
          <p class="averta-reguler">Informasi yang telah diedit akan hilang</p>
        `,
        showCancelButton: true,
        confirmButtonText: 'Keluar',
        cancelButtonText: 'Tetap Dihalaman Ini',
        customClass: {
          confirmButton: 'btn-batal-detail-component',
          cancelButton: 'btn-hapus-detail-component'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const payload = {
            url: this.url,
            survey_id: this.$route.params.surveyId // ini harus ada agar id survey yang di ambil dari vuex
          }
          this.$store
            .dispatch('projectV2/updateLatestStep', payload)
            .then(() => {
              this.showHideSidebar('cancel-item')
              next()
            })
            .catch((error) => {
              alertErrorMessage(error)
            })
        }
      })
    } else {
      next()
    }
  },
  watch: {
    currentStep (newVal, oldVal) {
      if (newVal === 1) {
        this.selectTypeClient = null
        this.getDetailPenawaran()
        this.getOfferingClientType()
        if (this.currentPath === 'update-price') {
          this.$store.commit('project/setDataUpdateItemPekerjaanBS', {
            isUpdate: true
          })
        }
      }
      if (oldVal === 2) {
        this.isFilled = true
      }
    },
    surveyIdFromOffering (newVal) {
      if (newVal) {
        this.getOfferingClientType()
      }
    }
  }
}
</script>

<style scoped>
.complete-offering-wrapper {
  width: calc(100% - 75px);
}

.btn-download-offering {
  background: transparent;
  border-radius: 100px;
  font-family: 'Averta-Bold';
  color: #d9272d;
  padding: 8px 24px;
  border: 1px solid #d9272d;
  max-height: 3em;
}

.btn-kirim {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.25%);
  border-radius: 100px;
  font-family: 'Averta-Bold';
  color: white;
  padding: 8px 20px;
  border: none;
  max-height: 3em;
}

.btn-kirim.is-disabled {
  background: #9a9a9a !important;
  cursor: not-allowed !important;
}

.text-red {
  color: #eb4600;
}

.btn-update {
  background: white;
  color: #d9272d;
  border: 2px solid #d9272d;
  border-radius: 24px;
}

.preview-notif-wrapper {
  position: sticky;
  z-index: 5;
}

.wrap-type {
  background: #fafafa;
  border: 1px solid #e1e1e1;
  border-radius: 16px;
  padding: 16px;
  width: 49.5%;
}

.wrap-type.is-active {
  background: #e1eeff;
  border: 1px solid #3185fc;
}

.wrap-type.is-disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.wrap-type.is-disabled input[type='radio'] {
  cursor: not-allowed !important;
}

.wrap-type input[type='radio'] {
  height: 20px;
  width: 20px;
}

.divider {
  border-bottom: 8px solid #e1e1e1;
  margin: 24px 0px;
}

.type-dropdown {
  border: 1px solid #e1e1e1;
  border-radius: 16px;
  padding: 10px 24px 10px 16px;
  width: 100%;
}

.type-dropdown.is-active {
  position: absolute;
  z-index: 10;
  top: 40px;
  /* left: 0px; */
  width: 94%;
  background: white;
}

.type-dropdown.is-disabled {
  cursor: not-allowed !important;
  background: #f5f5f5;
}

.btn-dropdown {
  border: 1px solid #e10009;
  border-radius: 100px;
  color: #e10009;
  padding: 6px 24px;
}

.arrow-icon {
  rotate: 90deg;
}

.arrow-icon.is-rotate {
  rotate: -90deg;
}

.list-type {
  border-radius: 12px;
}

.list-type:hover {
  background: #fcecec;
}

.list-type.is-active {
  background: #fcecec;
}

.border-top {
  border-top: 1.5px solid #e1e1e1;
}

.revisi-wrapper {
  position: sticky;
  top: 65px;
  width: 100%;
  height: 50px;
  z-index: 5;
}
</style>
