import Dashboard from '../views/v2/am/Dashboard.vue'
import ListProject from '../views/v2/am/project/ListProject.vue'
import LengkapiPenawaran from '../views/v2/am/project/complete-offering/LengkapiPenawaran.vue'
import DetailOrder from '../views/v2/am/project/DetailOrder.vue'
import DetailProyek from '../views/v2/project/detail-proyek/DetailProyek.vue'
import ListVendor from '../views/v2/am/vendor/ListVendor.vue'
import ListArea from '../views/v2/am/vendor/ListArea.vue'
import ListBranch from '../views/v2/am/vendor/ListBranch.vue'
import ListStore from '../views/v2/am/vendor/ListStore.vue'
import ListDetailStore from '../views/v2/am/vendor/ListDetailStore.vue'
import DataComponents from '../views/v2/am/setting/DataComponents.vue'
import Notification from '../views/v2/notification/Notification.vue'
import SchedulePage from '../views/v2/am/schedule/SchedulePage.vue'
import InvoicePDF from '../views/v2/am/download/InvoicePDF'
import DetailSurvey from '../views/v2/project/detail-survey/views/DetailSurvey'
import SearchGlobal from '../views/v2/search/SearchGlobal.vue'
import Report from '../views/v2/am/report/Report.vue'
import FollowUp from '../views/v2/am/report/FollowUp.vue'
import FollowUpDownload from '../views/v2/am/download/FollowUpDownload'
import ReportProgress from '../views/v2/am/report/ReportProgress.vue'
import OfferingPDF from '../views/v2/am/download/OfferingPDF.vue'
import OfferingTenantPDF from '../views/v2/am/download/OfferingTenantPDF.vue'
import SurveyComplain from '../views/v2/am/project/survey-complaint/SurveyComplain.vue'
import SpecificSearch from '../views/v2/search/SpecificSearch.vue'
import UnitPrice from '../views/v2/am/unit-price/views/UnitPrice.vue'
import GroupChatAM from '../views/v2/sm/group-chat/index.vue'
import MonitoringKonsultan from '../views/v2/am/monitoring-konsultan/views/MonitoringKonsultan.vue'

const routesAM = [
  {
    path: '/am/dashboard',
    name: 'AM Dashboard',
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      const rs = localStorage.getItem('webapp_shell_kanggo')
      const auth = JSON.parse(rs)
      if (auth.user.is_default_password === 1) {
        next({ name: 'ChangeDefaultPassword' })
      } else if (auth.user.is_default_password === 0 && auth.user.roles[0].name.split(' ') === 'Konsultan') {
        next({ name: 'AM Dashboard' })
      } else if (auth.user.is_default_password === 0 && auth.user.roles[0].name === 'Site Manager') {
        next({ name: 'SM Dashboard' })
      } else if (auth.user.is_default_password === 0 && auth.user.roles[0].name === 'Purchasing') {
        next({ name: 'Purchasing Dashboard' })
      } else if (!auth) {
        next({ name: 'Login' })
      } else {
        next()
      }
    }
  },
  {
    path: '/am/project',
    name: 'Project AM',
    component: ListProject
  },
  {
    path: '/am/project/survey/:surveyId/detail',
    name: 'Detail Project AM',
    component: DetailSurvey
  },
  {
    path: '/am/project/survey/:surveyId/:offeringId/complete-offering',
    name: 'Lengkapi Penawaran AM',
    component: LengkapiPenawaran
  },
  {
    path: '/am/project/offering/:offeringId/update-price',
    name: 'Update Harga Penawaran AM',
    component: LengkapiPenawaran
  },
  {
    path: '/am/project/offering/:offeringId/order',
    name: 'Order Project',
    component: DetailOrder
  },
  {
    path: '/am/project/:proyekId/detail',
    name: 'DetailProyek',
    component: DetailProyek
  },
  {
    path: '/am/vendors',
    name: 'ListVendor',
    component: ListVendor
  },
  {
    path: '/am/vendors/:vendorId/area',
    name: 'ListArea',
    component: ListArea
  },
  {
    path: '/am/vendors/:vendorId/branch',
    name: 'ListBranch',
    component: ListBranch
  },
  {
    path: '/am/vendors/:vendorId/detail-branch',
    name: 'DetailBranch',
    component: ListBranch
  },
  {
    path: '/am/vendors/:branchId/store',
    name: 'ListStore',
    component: ListStore
  },
  {
    path: '/am/vendors/:storeId/store-detail',
    name: 'ListDetailStore',
    component: ListDetailStore
  },
  {
    path: '/am/setting',
    name: 'SettingMenuAM',
    component: DataComponents
  },
  {
    path: '/am/notification',
    name: 'NotificationAM',
    component: Notification
  },
  {
    path: '/am/schedule',
    name: 'ScheduleAM',
    component: SchedulePage
  },
  {
    path: '/am/print-invoice-project/:projectId',
    name: 'PrintInvoicePDF',
    component: InvoicePDF,
    meta: { title: 'Unduh Invoice Project' }
  },
  {
    path: '/am/print-follow-up',
    name: 'PrintFollowUp',
    component: FollowUpDownload,
    meta: { title: 'Unduh Report Follow Up' }
  },
  {
    path: '/am/search',
    name: 'SearchGlobalAM',
    component: SearchGlobal
  },
  {
    path: '/am/report',
    name: 'Laporan',
    component: Report
  },
  {
    path: '/am/report/follow-up',
    name: 'Laporan Follow Up',
    component: FollowUp
  },
  {
    path: '/am/report/progress',
    name: 'Report Progress',
    component: ReportProgress
  },
  {
    path: '/am/offering-project/:offeringId',
    name: 'PDF Offering Project',
    component: OfferingPDF,
    meta: { title: 'Unduh Penawaran Project' }
  },
  {
    path: '/am/offering-tenant/:offeringId',
    name: 'PDF Offering Tenant',
    component: OfferingTenantPDF,
    meta: { title: 'Unduh Penawaran Tenant' }
  },
  {
    path: '/am/project/survey-complaint/:projectId',
    name: 'Survey Complaint',
    component: SurveyComplain
  },
  {
    path: '/am/specific-search',
    name: 'Specific Search',
    component: SpecificSearch
  },
  {
    path: '/am/form-unit-price/:vendorId',
    name: 'Form Harga Satuan',
    component: UnitPrice
  },
  {
    path: '/am/group-chat',
    name: 'Group Chat AM',
    component: GroupChatAM
  },
  {
    path: '/am/monitoring-konsultan',
    name: 'Monitoring Konsultan',
    component: MonitoringKonsultan
  }
]
export default routesAM
