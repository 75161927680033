
import {
  collection, getDocs, getFirestore, onSnapshot, orderBy, query
} from 'firebase/firestore'
import { getAuth, signInWithCustomToken } from 'firebase/auth'
import store from '@/store'

const firestore = getFirestore()
const auth = getAuth()
const activeListeners = new Map()

async function getDataMultiTopic (topics) {
  try {
    if (!topics || (Array.isArray(topics) && topics.length === 0)) {
      console.warn('No topics provided to getDataMultiTopic')
      return { data: [], error: 'Topics parameter is required.' }
    }

    if (typeof topics === 'string') {
      topics = [topics]
    }

    if (!Array.isArray(topics) || topics.some(t => typeof t !== 'string')) {
      console.error('Topics must be an array of strings.')
      return { data: [], error: 'Topics must be an array of strings.' }
    }

    topics = topics.filter(topic => topic && topic.trim() !== '')

    if (topics.length === 0) {
      return { data: [], error: 'No valid topics provided.' }
    }

    let user = auth.currentUser
    if (!user) {
      try {
        const dataLocalStorage = JSON.parse(localStorage.getItem('webapp_shell_kanggo'))
        const firebaseToken = dataLocalStorage.user.firebase_token

        if (!firebaseToken || typeof firebaseToken !== 'string') {
          return { error: 'Invalid response data', data: [] }
        }

        const userCredential = await signInWithCustomToken(auth, firebaseToken)
        console.log('User Credential:', userCredential)
        user = userCredential.user
      } catch (error) {
        console.error('Authentication failed:', error)
        return { data: [], error: 'Authentication failed' }
      }
    }

    const chatGroups = {
      'https://shellb2b.devkanggo.com': 'chat_group_dev',
      'https://shellb2bnew.stagging.kanggo.id': 'chat_group_staging',
      'https://shellb2bnew-api.prodkanggo.com/api/': 'chat_group'
    }
    const chatGroup = chatGroups[window.location.origin] || 'chat_group_dev'

    cleanupUnusedListeners(topics)

    const allData = []

    for (const topic of topics) {
      if (!topic) continue

      const docRef = collection(firestore, chatGroup, topic, 'chats')
      const q = query(docRef, orderBy('created_at', 'asc'))

      try {
        const snapshots = await getDocs(q)
        snapshots.docs.forEach((doc) => {
          allData.push({
            id: doc.id,
            topic,
            ...doc.data()
          })
        })
      } catch (error) {
        console.error(`Error fetching data for topic ${topic}:`, error)
      }

      if (!activeListeners.has(topic)) {
        try {
          const unsubscribe = onSnapshot(q, (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
              if (change.type === 'added') {
                const doc = change.doc
                const data = doc.data()
                const sender = data.sender_name
                const message = data.message
                const image = data.image_url

                const obj = {
                  id: doc.id,
                  topic,
                  message: data.message,
                  image_url: data.image_url,
                  created_at: data.created_at,
                  sender_image: data.sender_image,
                  sender_name: data.sender_name,
                  sender_role: data.sender_role,
                  status: data.status,
                  sender_id: data.sender_id,
                  reply_to: data.reply_to
                }

                if (typeof message === 'string') {
                  store.commit('groupChat/setNewChatMultiple', { ...obj, sender })
                } else if (typeof image === 'string') {
                  store.commit('groupChat/setNewChatMultiple', { ...obj })
                }
              }
            })
          }, error => {
            console.error(`Error in snapshot listener for topic ${topic}:`, error)
          })

          activeListeners.set(topic, unsubscribe)
        } catch (error) {
          console.error(`Error setting up listener for topic ${topic}:`, error)
        }
      }
    }

    return {
      data: allData,
      user: user
    }
  } catch (error) {
    console.error('Error in getDataMultiTopic:', error)
    return { error: error.message || 'Unknown error', data: [] }
  }
}

function cleanupUnusedListeners (currentTopics) {
  for (const [topic, unsubscribe] of activeListeners.entries()) {
    if (!currentTopics.includes(topic)) {
      unsubscribe()
      activeListeners.delete(topic)
      console.log(`Unsubscribed from topic: ${topic}`)
    }
  }
}

function unsubscribeAllListeners () {
  for (const [topic, unsubscribe] of activeListeners.entries()) {
    unsubscribe()
    console.log(`Unsubscribed from topic: ${topic}`)
  }
  activeListeners.clear()
}

export { getDataMultiTopic, unsubscribeAllListeners }
