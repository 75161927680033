<template>
  <div class="sidebar-menu is-flex is-justify-content-center is-hidden-touch" v-if="isLogin">
    <div class="sidebar-menu-wrapper has-text-centered">
      <router-link :to='menuRoles[0]' exact>
        <b-tooltip position="is-right" type="is-dark" :triggers="['hover']" :auto-close="true">
          <template v-slot:content>
            <div class="is-flex is-align-items-center svg-tooltip">
              <DashboardIcon />
              <span class="ml-3">Dashboard</span>
            </div>
          </template>
          <div class="p-5">
            <DashboardIcon class="has-tooltip-right" data-tooltip="Tooltip Text" />
          </div>
        </b-tooltip>
      </router-link>
      <router-link :to='menuRoles[1]'>
        <b-tooltip position="is-right" type="is-dark" :triggers="['hover']" :auto-close="true">
          <template v-slot:content>
            <div class="is-flex is-align-items-center svg-tooltip">
              <ProyekIcon />
              <span class="ml-3">Proyek</span>
            </div>
          </template>
          <div class="p-5">
            <ProyekIcon />
          </div>
        </b-tooltip>
      </router-link>
      <router-link :to='searchSpecific'>
        <b-tooltip position="is-right" type="is-dark" :triggers="['hover']" :auto-close="true">
          <template v-slot:content>
            <div class="is-flex is-align-items-center svg-tooltip">
              <!-- <SearchLongIcon color="#000" /> -->
              <span class="ml-3">Pencarian</span>
            </div>
          </template>
          <div class="p-5 search-long-icon">
            <SearchLongIcon size="20" :color="specificPath ? '#d9272d' : '#000'" />
          </div>
        </b-tooltip>
      </router-link>
      <!-- <router-link :to='menuRoles[3]' v-if="user.roles[0].name.split(' ')[0] !== 'Konsultan'"> -->
      <router-link v-if="menuRoles.length > 2 && user.roles[0].name === 'Account Manager'" :to='menuRoles[2]'>
        <b-tooltip position="is-right" type="is-dark" :triggers="['hover']" :auto-close="true">
          <template v-slot:content>
            <div class="is-flex is-align-items-center svg-tooltip">
              <ScheduleIcon color="#fff" />
              <span class="ml-3">Jadwal Survey</span>
            </div>
          </template>
          <div class="p-5">
            <ScheduleIcon :color="currentPath ? '#d9272d' : '#000'"/>
          </div>
        </b-tooltip>
      </router-link>
      <router-link v-if="menuRoles.length > 3 && showLaporanClient" :to='menuRoles[3]'>
        <b-tooltip position="is-right" type="is-dark" :triggers="['hover']" :auto-close="true">
          <template v-slot:content>
            <div class="is-flex is-align-items-center svg-tooltip">
              <LaporanIcon />
              <span class="ml-3">Laporan</span>
            </div>
          </template>
          <div class="p-5">
            <LaporanIcon />
          </div>
        </b-tooltip>
      </router-link>
      <router-link v-if="menuRoles.length > 2 && user.roles[0].name === 'Purchasing'" :to='menuRoles[2]'>
        <b-tooltip position="is-right" type="is-dark" :triggers="['hover']" :auto-close="true">
          <template v-slot:content>
            <div class="is-flex is-align-items-center svg-tooltip">
              <CatalogIcon />
              <span class="ml-3">Katalog</span>
            </div>
          </template>
          <div class="p-5">
            <CatalogIcon :color="currentPath ? '#d9272d' : '#000'"/>
          </div>
        </b-tooltip>
      </router-link>
      <DaftarClientStatic size="0" /> <!-- do not delete this -->
      <router-link v-if="menuRoles.length > 4 && user.roles[0].name === 'Account Manager'" :to='menuRoles[5]'>
        <b-tooltip position="is-right" type="is-dark" :triggers="['hover']" :auto-close="true">
          <template v-slot:content>
            <div class="is-flex is-align-items-center svg-tooltip">
              <DaftarClientStatic />
              <span class="ml-3">Daftar Client</span>
            </div>
          </template>
          <div class="p-5">
            <DaftarClientStatic/>
          </div>
        </b-tooltip>
      </router-link>
      <router-link
        v-if="user.roles && user.roles.length > 0 && getGroupChatLink(user.roles[0].name)"
        :to="getGroupChatLink(user.roles[0].name)"
      >
        <b-tooltip position="is-right" type="is-dark" :triggers="['hover']" :auto-close="true">
          <template v-slot:content>
            <div class="is-flex is-align-items-center svg-tooltip">
              <MessageGroupIcon color="#ffffff" />
              <span class="ml-3">Group Chat</span>
            </div>
          </template>
          <div class="p-5">
            <MessageGroupIcon :color="currentPathGroupChat ? '#d9272d' : '#000000'" />
          </div>
        </b-tooltip>
      </router-link>
      <router-link v-if="menuRoles.length > 4 && user.roles[0].name === 'Account Manager'" :to='menuRoles[4]'>
        <b-tooltip position="is-right" type="is-dark" :triggers="['hover']" :auto-close="true">
          <template v-slot:content>
            <div class="is-flex is-align-items-center svg-tooltip">
              <PengaturanIcon />
              <span class="ml-3">Pengaturan</span>
            </div>
          </template>
          <div class="p-5">
            <PengaturanIcon />
          </div>
        </b-tooltip>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  DashboardIcon,
  ProyekIcon,
  LaporanIcon,
  PengaturanIcon,
  ScheduleIcon,
  CatalogIcon,
  DaftarClientStatic,
  SearchLongIcon,
  MessageGroupIcon
} from '../components/icons'
import { userMenuPermissions } from '@/helpers'

export default {
  name: 'SidebarMenu',
  computed: {
    ...mapGetters({
      isLogin: 'auth/isLogin',
      user: 'auth/user'
    }),
    menuRoles () {
      const menuKonsultan = ['/', '/project']
      const menuAM = ['/am/dashboard', '/am/project', '/am/schedule', '/am/report', '/am/setting', '/am/vendors']
      const menuSM = ['/sm/dashboard', '/sm/project']
      const menuPurchasing = ['/purchasing/dashboard', '/purchasing/project', '/purchasing/catalog']

      if (this.user.roles[0].name === 'Purchasing') {
        return menuPurchasing
      } else if (this.user.roles[0].name === 'Account Manager') {
        return menuAM
      } else if (this.user.roles[0].name === 'Site Manager') {
        return menuSM
      }

      return menuKonsultan
    },
    searchSpecific () {
      const roleId = this.user.roles[0].id
      let temp = ''
      if (roleId === 1) {
        temp = '/am/specific-search'
      } else if (roleId === 2) {
        temp = '/specific-search'
      } else if (roleId === 4) {
        temp = '/purchasing/specific-search'
      } else if (roleId === 5) {
        temp = '/purchasing/specific-search'
      }
      return temp
    },
    currentPath () {
      if (this.$route.path === '/am/schedule') return true
      return false
    },
    currentPathGroupChat () {
      if (this.$route.path === '/am/group-chat') return true
      if (this.$route.path === '/sm/group-chat') return true
      if (this.$route.path === '/group-chat') return true
      return false
    },
    specificPath () {
      if (this.$route.path.includes('specific-search')) return true
      return false
    },
    showLaporanClient () {
      if (this.user.roles[0].id === 1 && userMenuPermissions('MN_RPRT')) {
        return true
      }
      return false
    }
  },
  components: {
    DashboardIcon,
    ProyekIcon,
    // eslint-disable-next-line vue/no-unused-components
    LaporanIcon,
    PengaturanIcon,
    ScheduleIcon,
    CatalogIcon,
    DaftarClientStatic,
    SearchLongIcon,
    MessageGroupIcon
  },
  data () {
    return {
      isHide: true
    }
  },
  methods: {
    getGroupChatLink (role) {
      if (role === 'Account Manager') return '/am/group-chat'
      if (role === 'Site Manager') return '/sm/group-chat'
      if (role === 'Konsultan Survey') return '/group-chat'
      if (role === 'Purchasing') return false
      return '#'
    }
  }
}
</script>

<style scoped>
.sidebar-menu {
  background: #f8f7fa;
  width: 75px;
  position: relative;
  flex-shrink: 0;
  z-index: 31;
}

.sidebar-menu-wrapper {
  /* width: 100%; */
  position: fixed;
  display: flex;
  left: 0px;
  flex-direction: column;
  z-index: 5;
}

.active {
  background: rgba(214, 15, 39, 0.1);
  border-left: 8px solid #d9272d;
  padding: 0px;
}

.active svg {
  fill: #d9272d;
}

.svg-tooltip svg {
  fill: white;
}

.search-long-icon {
  transform: scale(1.5);
}
</style>
